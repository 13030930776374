<template>
  <div
    style="border-radius: var(--border-radius-rounded)"
    :style="styles"
  ></div>
</template>

<script>
import { utils } from '../../other/utils/utils.js'

export default {
  name: 'Break',
  props: {
    height: {
      type: [String, Number],
      default: '2',
    },
    color: {
      type: String,
      default: 'dark',
    },
  },
  computed: {
    styles() {
      const styles = {}
      styles.height = this.height + 'px'
      styles.background =
        this.color[0] === '#'
          ? this.color
          : utils.css.getVar(`color-${this.color}`)
      return styles
    },
  },
}
</script>

<style scoped lang="scss"></style>
