import {
  GetMultipleAirlinesRequest,
  GetMultipleAirportsRequest,
} from '../../../util/gen/proto/location/location_request_pb'
import { Grpc } from './base/Grpc'

export const AirService = {
  getMultipleAirlines: (iataCodes = []) =>
    Grpc.snt
      .getMultipleAirlines(
        new GetMultipleAirlinesRequest().setIataCodesList(iataCodes),
      )
      .then(res => res.toObject()),
  getMultipleAirports: (iataCodes = []) =>
    Grpc.snt
      .getMultipleAirports(
        new GetMultipleAirportsRequest().setIataCodesList(iataCodes),
      )
      .then(res => res.toObject()),
}
