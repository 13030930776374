<template>
  <div class="container-s">
    <AppHeader @back="$router.go(-1)" />
    <p class="app-subtitle" style="margin-top: 20px">{{ title }}</p>
    <!-- <x-back-header style="margin-bottom: 2rem" @back="closeWindow">{{
      title
    }}</x-back-header> -->
    <div class="pp-tos-content">
      <component
        :is="getComponent(key)"
        v-for="[key, value] in content"
        :key="key"
      >
        {{ value }}
      </component>
    </div>
  </div>
</template>

<script>
import AppHeader from '../../components-rf/AppHeader.vue'
import AppNavButton from '../../components-rf/AppNavButton.vue'
export default {
  name: 'PosTos',
  props: {
    type: String,
  },
  computed: {
    title() {
      return this.$t(`settings.${this.type}-title`)
    },
    content() {
      return Object.entries(this.$t(`settings.${this.type}`))
    },
  },
  methods: {
    closeWindow() {
      window.close()
      this.$router.go(-1)
    },
    getComponent(key) {
      if (key.includes('paragraph')) return 'p'
      if (key.includes('header')) return 'h2'
    },
  },
  components: { AppNavButton, AppHeader },
}
</script>

<style scoped lang="scss">
.pp-tos-content h2 {
  margin-top: 1.5rem;
  font-family: DDin;
  font-weight: bold;
  font-size: 1.25rem;
}
.pp-tos-content p {
  font-family: DDin;
  margin-top: 1rem;
  font-size: 1rem;
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
}
</style>
