import { CityService } from '../../services/CityService'

const regionRegex = /(?:\w+-)?(\w+)/
const getRegionIso = city => {
  const iso = city?.region?.isoCode
  if (!iso) return
  const re = regionRegex.exec(iso)
  return re[1]
}

export const CityModule = () => ({
  state: {
    cities: [],
    departureCities: [],
    cityWeather: {},
  },
  getters: {
    displayDepartureCities: s =>
      s.departureCities.map(city => {
        const region = city.region
        const country = city.region.country

        const long = `${city.name}, ${region.name}, ${country.isoCode}`
        const short = `${city.name}, ${getRegionIso(city)}, ${country.isoCode}`

        return {
          ...city,
          display: { long, short },
        }
      }),
    cityByIata: s => iata => s.cities.find(({ iataCode }) => iataCode === iata),
    formattedCities: (s, g) => s.cities.map(g.formatCity),
    formattedDepartureCities: (s, g) => {
      return s.departureCities.reduce((a, el) => {
        if (!el?.airportsList?.length) return a
        const city = { ...el }
        delete city.airportsList
        const cities = el.airportsList.map(airport =>
          g.formatCity({
            ...city,
            airport,
          }),
        )
        a.push(...cities)
        return a
      }, [])
    },
    formatCity: () => city => {
      if (!city?.id) return {}
      const name = city.name
      const regIso = getRegionIso(city)
      const regName = city?.region?.name
      const hasReg = regIso && regName
      const cntName = city?.region?.country?.isoCode
      const cntIso = city?.region?.country?.isoCode
      const cntIso3 = city?.region?.country?.isoCode3
      const airport = city?.airport

      const output = {}
      output.id = city.id
      output.iata = city.iataCode
      output.short = `${name}${hasReg ? ', ' + regIso : ''}, ${cntIso}`
      output.long = `${name}, ${hasReg ? regName : cntName}, ${cntIso}`

      if (airport) {
        const airIata = airport.iataCode
        // const airName = airport.name
        output.airportShort = `${name}${
          hasReg ? ', ' + regIso : ''
        }, ${cntIso3} (${airIata})`
        output.airportLong = `${name}, ${
          hasReg ? regName : cntName
        }, ${cntIso3} (${airIata})`
      }

      return output
    },
  },
  actions: {
    getCityByIata: ({ commit, getters }, iata) =>
      new Promise((resolve, reject) => {
        let city = getters.cityByIata(iata)
        if (city) resolve(city)
        else
          CityService.getMultiple([iata]).then(res => {
            if (!res.citiesList.length) return
            commit('addCities', res.citiesList)
            city = res.citiesList[0]
            city ? resolve(city) : reject()
          })
      }),

    getCities: ({ commit }) => {
      const promise = CityService.getCities()
      promise.then(res => commit('addCities', res.citiesList))
      return promise
    },
    searchCities: ({ commit }, search = '') => {
      if (search.length < 3) return
      return CityService.searchCities(search).then(res => {
        commit('addCities', res.citiesList)
      })
    },
    searchDepartureCities: ({ commit }, search = '') => {
      if (search.length < 3) return
      return CityService.searchDepartureCities(search).then(res => {
        commit('addDepartureCities', res.citiesList)
        commit('addCities', res.citiesList)
      })
    },
    getDepartureCities: ({ commit }) => {
      const promise = CityService.getDepartureCities()
      promise.then(res => {
        commit('addDepartureCities', res.citiesList)
        commit('addCities', res.citiesList)
      })
    },
    getWeatherByCity: ({ commit }, cityId) => {
      const promise = CityService.getWeatherByCity({ cityId })
      promise.then(res => {
        const weather = res?.city?.weather
        if (weather) commit('setCityWeather', weather)
      })
      return promise
    },
  },
})
