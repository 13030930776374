import { selectLongestStayPackage } from '../../constants/SEARCH'
import { formatPrice } from '../../other/utils/formatPrice'
import { SearchService } from '../../services/SearchService'
import { parseHotel } from './SearchStream.parser'

const minutes = num => 1000 * 60 * num

const defaultState = () => ({
  searchParams: {},
  tripId: undefined,
  flightId: undefined,
  hotelId: undefined,
  roomId: undefined,
  paymentId: undefined,
  passengers: [],
  showIncompleteData: false,
  selection: {
    trips: [],
    flights: [],
    hotels: [],
  },
  discounts: {},
})

export const SearchState = () => ({
  state: defaultState(),
  mutations: {
    clearState(s) {
      const defaults = defaultState()
      Object.keys(s).forEach(key => (s[key] = defaults[key]))
    },
    selectTraveler: (s, id) => {
      if (!s.passengers.includes(id)) s.passengers.push(id)
    },
    removeTraveler: (s, id) =>
      (s.passengers = s.passengers.filter(el => el !== id)),
  },
  actions: {
    initPaymentMethod({ state, rootState }) {
      if (state.paymentId) return
      const paymentMethod = rootState.UserModule.paymentMethods[0]
      if (paymentMethod) state.paymentId = paymentMethod.id
    },
    initTrips({ state, dispatch }, trips) {
      const init = { trip: undefined, flight: undefined, hotel: undefined }

      trips.forEach(trip => {
        if (!state.selection.trips.some(({ id }) => id === trip.id)) {
          const { flight, hotel } = selectLongestStayPackage({
            flights: trip.flights,
            hotels: trip.hotels,
            searchType: state.searchParams?.searchType,
            budget: state.searchParams.budget,
          })

          if (!init.trip) init.trip = trip
          if (!init.flight) init.flight = flight
          if (!init.hotel) init.hotel = hotel

          state.selection.trips.push({
            id: trip.id,
            flightId: flight?.id,
            confirmedFlightId: flight?.id,
            hotelId: hotel?.id,
            confirmedHotelId: hotel?.id,
          })
        }

        trip.flights.forEach(flight => {
          if (!state.selection.flights.some(({ id }) => id === flight.id))
            state.selection.flights.push({
              id: flight.id,
              tipId: trip.id,
            })
        })

        trip.hotels.forEach(hotel => {
          if (!state.selection.hotels.some(({ id }) => id === hotel.id)) {
            const hotelSelection = {
              id: hotel.id,
              tripId: trip.id,
              getDetails: () =>
                dispatch('getHotelDetails', { hotel, trip }).then(
                  () => (hotelSelection.hasDetails = true),
                ),
              hasDetails: false,
              roomId: hotel.rooms[0].id,
              confirmedRoomId: hotel.rooms[0].id,
            }
            state.selection.hotels.push(hotelSelection)
          }
        })
      })

      if (!state.tripId && init.trip) {
        const tripId = init.trip.id
        const flightId = init.flight?.id
        const hotelId = init.hotel?.id
        const roomId = init.hotel?.rooms?.[0]?.id

        dispatch('select', {
          tripId,
          flightId,
          hotelId,
          roomId,
          confirm: true,
          ignoreHotelDetails: true,
        })
      }
    },
    select(
      { state, getters },
      {
        tripId,
        flightId,
        hotelId,
        roomId,
        confirm,
        preview,
        ignoreHotelDetails,
      },
    ) {
      const { trip, flight, hotel, room, tripSelection, hotelSelection } =
        getters.getSelected({
          tripId,
          flightId,
          hotelId,
          roomId,
          confirmed: confirm && preview,
        })

      if (flight) tripSelection.flightId = flight.id
      if (hotel) tripSelection.hotelId = hotel.id
      if (room) hotelSelection.roomId = room.id

      if (hotel && !ignoreHotelDetails && !hotelSelection.hasDetails)
        hotelSelection.getDetails()

      if (confirm) {
        state.tripId = trip?.id
        state.flightId = flight?.id
        state.hotelId = hotel?.id
        state.roomId = room?.id
        if (!preview) {
          if (flight) tripSelection.confirmedFlightId = flight.id
          if (hotel) tripSelection.confirmedHotelId = hotel.id
          if (room) hotelSelection.confirmedRoomId = room.id
        }
      }
    },
    getHotelDetails(ctx, { hotel, trip }) {
      return SearchService.getHotelDetails({
        hotelId: hotel._id,
        tripId: hotel.tripId,
        gdsName: hotel.gds.name,
      }).then(({ hotel: newHotel }) => {
        const parsedHotel = parseHotel(newHotel, trip)

        const hasChanged = hotel.checkInDate !== parsedHotel.checkInDate
        if (hasChanged)
          console.warn('Get hotel details resulted in different hotel dates!', {
            oldHotelDetailsCheckIn: hotel.checkInDate,
            newHotelDetailsCheckIn: parsedHotel.checkInDate,
          })
        Object.assign(hotel, parsedHotel)
      })
    },
    validatePromoCode: ({ commit, state, getters }, { promoCode }) => {
      const active = getters.getActive
      const tripId = active.trip.id
      const passengers = state.passengers

      const request = SearchService.validatePromoCode({
        tripId,
        flightId: active.flight?._id,
        flightGds: active.flight?.gds.name,
        hotelId: active.hotel?._id,
        hotelGds: active.hotel?.gds.name,
        roomId: active.room?._id,
        travelerIdsList: passengers,
        promoCode,
      })

      const discountId = `${tripId}-${active.hotel?.id}-${active.flight?.id}`

      request
        .then(res => {
          commit('setDiscounts', {
            ...state.discounts,
            [discountId]: {
              id: res.promotionCode.id,
              code: res.promotionCode.code,
              name: res.promotionCode.amountOff
                ? `-${formatPrice(res.promotionCode.amountOff)} off`
                : `-${res.promotionCode.percentOff}% off`,
              saved: formatPrice(res.discount),
              totalPrice: res.price.total,
            },
          })
        })
        .catch(() => {})

      return request
    },
    revalidateItinerary({ state, getters }) {
      const active = getters.getActive
      const now = Date.now()
      const revalidationTimeout = minutes(2)

      const flightNeedsRevalidation =
        active.flight && now - active.flight.revalidatedAt > revalidationTimeout
      const hotelNeedsRevalidation =
        active.room && now - active.room.revalidatedAt > revalidationTimeout
      const revalidationRequired =
        flightNeedsRevalidation || hotelNeedsRevalidation

      if (!revalidationRequired) return Promise.resolve({ priceChanged: false })

      const oldFlightPriceDiff = active.flight?.priceDifference ?? 0
      const oldHotelPriceDiff = active.room?.priceDifference ?? 0

      return SearchService.revalidateItinerary({
        tripId: active.trip.id,
        flightId: active.flight?._id,
        flightGds: active.flight?.gds.name,
        hotelId: active.hotel?._id,
        hotelGds: active.hotel?.gds.name,
        roomId: active.room?._id,
        travelerIds: state.passengers,
      }).then(res => {
        const { flight, room } = getters.getActive
        const now = Date.now()
        const { flightPriceDifference, hotelPriceDifference } = res
        const flightPriceChanged = oldFlightPriceDiff !== flightPriceDifference
        const hotelPriceChanged = oldHotelPriceDiff !== hotelPriceDifference
        const priceChanged = flightPriceChanged || hotelPriceChanged

        if (flight) {
          flight.revalidatedAt = now
          flight.priceDifference = flightPriceDifference
        }
        if (room) {
          room.revalidatedAt = now
          room.priceDifference = hotelPriceDifference
        }

        return {
          ...res,
          priceChanged,
          flightPriceDifference: flightPriceChanged && flightPriceDifference,
          hotelPriceDifference: hotelPriceChanged && hotelPriceDifference,
        }
      })
    },
    validateData({ getters, state }) {
      if (getters.validData.all) return true
      state.showIncompleteData = true
      setTimeout(() => (state.showIncompleteData = false), 1000)
      return false
    },
    abandonTrip({ state, getters, rootState }) {
      const { isLoggedIn } = rootState.AuthModule
      if (!isLoggedIn) return

      const { searchParams } = state
      const { trip, hotel, flight, room } = getters.getActive

      SearchService.abandonTrip({
        searchType: searchParams.searchType,
        tier: searchParams.tier,
        budget: searchParams.budget,
        departure: searchParams.departure,
        departureDate: searchParams.departureDate,
        destination: trip.arrivalLocation.id,
        returnDate: searchParams.returnDate,
        adults: searchParams.adults,
        children: searchParams.children,
        infants: searchParams.infants,
        flightId: flight?._id,
        hotelId: hotel?._id,
        roomId: room?._id,
        tripId: trip.id,
      })
    },
  },
  getters: {
    trips: (s, g, rs, rg) => rg['SearchStream/trips'],
    searchType: s => s.searchParams?.searchType,
    searchBudget: s => s.searchParams?.budget,
    getSelected:
      (s, g) =>
      ({ tripId, flightId, hotelId, roomId, confirmed }) => {
        tripId = tripId ?? s.tripId
        if (!tripId) return
        const trip = g.trips.find(({ id }) => id === tripId)
        const tripSelection = s.selection.trips.find(({ id }) => id === tripId)

        flightId =
          flightId ??
          (confirmed
            ? tripSelection?.confirmedFlightId
            : tripSelection?.flightId) ??
          trip.flights?.[0]?.id
        const flight = trip.flights.find(({ id }) => id === flightId)
        const flightPrice =
          (flight?.price?.total ?? 0) + (flight?.priceDifference ?? 0)

        hotelId =
          hotelId ??
          (confirmed
            ? tripSelection?.confirmedHotelId
            : tripSelection?.hotelId) ??
          trip.hotels?.[0]?.id

        // old and working (no hotel timezone case)
        // const hotel = trip.hotels.find(({ id }) => id === hotelId)
        // const hotelSelection = s.selection.hotels.find(({ id }) => id === hotelId)
        // new - hotel timezone case - selects new hotel if flight changes arrival dates
        let hotel = trip.hotels.find(({ id }) => id === hotelId)
        const selectableHotels =
          flight && hotel
            ? trip.hotels.filter(
                ({ checkInDate }) => checkInDate === flight.checkInDate,
              )
            : trip.hotels

        if (flight && hotel && flight.checkInDate !== hotel.checkInDate) {
          const sameHotel = selectableHotels.find(
            ({ name, address }) =>
              hotel.name === name && hotel.address === address,
          )
          hotelId = sameHotel ? sameHotel.id : selectableHotels[0]?.id
        }
        hotel = trip.hotels.find(({ id }) => id === hotelId)
        const hotelSelection = s.selection.hotels.find(
          ({ id }) => id === hotelId,
        )

        roomId =
          roomId ??
          (confirmed
            ? hotelSelection?.confirmedRoomId
            : hotelSelection?.roomId) ??
          hotel?.rooms?.[0]?.id
        const room = hotel?.rooms?.find(({ id }) => id === roomId)
        const hotelPrice =
          (room?.price?.total ?? 0) + (room?.priceDifference ?? 0)

        const discountId = `${tripId}-${hotel?.id}-${flight?.id}`
        const discount = s.discounts[discountId]
        const totalPrice = discount
          ? discount.totalPrice
          : flightPrice + hotelPrice

        const selection = {
          trip,
          flightPrice,
          flight,
          selectableHotels,
          hotel,
          hotelPrice,
          room,
          tripSelection,
          hotelSelection,
          totalPrice,
          discount,
        }
        return selection
      },
    getActive: (s, g) =>
      g.getSelected({
        tripId: s.tripId,
        flightId: s.flightId,
        hotelId: s.hotelId,
        roomId: s.roomId,
      }),
    travelerCount: s => {
      if (!s.searchParams) return 0
      const { adults, children, infants } = s.searchParams
      return adults + children + infants
    },
    activeTravelers: (s, g, rs, rg) => {
      const { adults, children, infants } = rg['TravelerModule/travelers']
      const isSaved = ({ id }) => s.passengers.includes(id)
      return {
        adults: adults.filter(isSaved),
        children: children.filter(isSaved),
        infants: infants.filter(isSaved),
      }
    },
    passengerText: (s, g) => {
      const { adults, children, infants } = s.searchParams
      const count = g.travelerCount

      const pax = []
      if (adults) pax.push(`${adults} ${adults === 1 ? 'Adult' : 'Adults'}`)
      if (children)
        pax.push(`${children} ${children === 1 ? 'Child' : 'Children'}`)
      if (infants)
        pax.push(`${infants} ${infants === 1 ? 'Infant' : 'Infants'}`)

      return {
        total: `${count || 0} ${count === 1 ? 'Passenger' : 'Passengers'}`,
        passengers: pax,
      }
    },
    activePrice: (s, { getActive }) =>
      [getActive?.flight, getActive?.room].reduce(
        (a, item) => (item ? a + item.price.total : a),
        0,
      ),
    activePayment: (s, g, rs) =>
      rs.UserModule.paymentMethods.find(payment => payment.id === s.paymentId),
    hasTravelers: (s, g) => {
      const { adults, children, infants } = s.searchParams
      const active = g.activeTravelers
      const hasTravelers = {
        adults: adults - 1 <= active.adults.length,
        children: children <= active.children.length,
        infants: infants <= active.infants.length,
      }
      return {
        ...hasTravelers,
        all: Object.values(hasTravelers).every(value => !!value),
        isUserOnly: adults === 1 && children === 0 && infants === 0,
      }
    },
    userValid: (s, g, rs) =>
      [
        'titleId',
        'firstName',
        'lastName',
        'dateOfBirth',
        'email',
        'phoneCountryId',
        'phone',
      ].every(field => rs.UserModule.user?.[field]),
    validData: (s, g) => {
      const data = {
        user: !!g.userValid,
        travelers: !!g.hasTravelers.all,
        payment: !!g.activePayment,
      }

      return { ...data, all: Object.values(data).every(el => el === true) }
    },
  },
})
