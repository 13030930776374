<template>
  <button id="back-button" class="x-class" @click.prevent="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'XLabelButton',
}
</script>

<style scoped lang="scss">
.x-class {
  color: var(--color-grey);
  letter-spacing: -0.03em;
  font-size: 0.825rem;
  &:hover {
    color: darker(get('color.grey'));
  }
  &:active,
  &:focus {
    color: darker(get('color.grey'), 20%);
  }
}
</style>
