import PaypalButton from '@/components/common/global/PaypalButton.vue'
import XBackButton from '@/components/common/global/XBackButton.vue'
import XBackHeader from '@/components/common/global/XBackHeader.vue'
import XButton from '@/components/common/global/XButton.vue'
import XCloseButton from '@/components/common/global/XCloseButton.vue'
import XLabel from '@/components/common/global/XLabel.vue'
import XLabelButton from '@/components/common/global/XLabelButton.vue'
import XSubtitle from '@/components/common/global/XSubtitle.vue'
import XTitle from '@/components/common/global/XTitle.vue'
import Break from '@/components/utils/Break.vue'
import Carousel from '@/components/utils/Carousel.vue'
import Flex from '@/components/utils/Flex.vue'
import FormInput from '@/components/utils/FormInput.vue'
import Grid from '@/components/utils/Grid.vue'
import Icon from '@/components/utils/Icon.vue'
import IconList from '@/components/utils/IconList.vue'
import Modal from '@/components/utils/Modal.vue'
import Vue from 'vue'

const utils = [Break, Carousel, Flex, FormInput, Grid, Icon, IconList, Modal]
const globals = [
  XBackHeader,
  XButton,
  XBackButton,
  XCloseButton,
  XLabel,
  XLabelButton,
  XSubtitle,
  XTitle,
  PaypalButton,
]

;[...utils, ...globals].forEach(comp => Vue.component(comp.name, comp))
