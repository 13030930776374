<script>
import { utils } from '../../other/utils/utils.js'

const getUnit = utils.css.calcUnits('rem')

export default {
  props: {
    tag: String,
    gap: [String, Number],
    rowGap: [String, Number],
    colGap: [String, Number],
  },
  computed: {
    baseStyles() {
      const styles = {}
      if (this.gap) styles.gap = getUnit(this.gap.toString())
      if (this.colGap) styles.columnGap = getUnit(this.colGap.toString())
      if (this.rowGap) styles.rowGap = getUnit(this.rowGap.toString())

      return styles
    },
    options() {
      return {
        attrs: this.$props,
        on: this.$listeners,
        style: {
          ...this.baseStyles,
          ...this.styles,
        },
      }
    },
  },
  render(el) {
    return el(this.tag || 'div', this.options, this.$slots.default)
  },
}
</script>
