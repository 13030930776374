import { store } from '../../store/store'
import { createGrpc } from './Grpc.factory'

export const Grpc = {
  snt: createGrpc({
    hostname: process.env.VUE_APP_GRPC_API_URL,
    getToken: () => store?.state?.AuthModule?.token,
  }),
}

export function hydrateGrpcModel(modelInstance, data) {
  const model = modelInstance.toObject()

  Object.keys(data).forEach(key => {
    const value = model[key]

    const isPrimitive = value !== undefined && !Array.isArray(value)
    const isModel = !!data[key]?.toObject?.()?.id

    if (isPrimitive || isModel) {
      const setter = 'set' + key.charAt(0).toUpperCase() + key.slice(1)
      modelInstance[setter](data[key])
    }
  })

  return modelInstance
}
