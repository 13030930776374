import { LOCATION } from '../../constants/LOCATION'
import { utils } from '../../other/utils/utils'
import { getCoordinates } from '../../services/CoordinatesService'
import { LocationService } from '../../services/LocationService'

const flattenLocations = locationsList => {
  const allLocations = []
  const addLocations = locations =>
    locations.forEach(location => {
      allLocations.push(location)
      addLocations(location.sublocationsList)
    })
  addLocations(locationsList)
  return allLocations
}

const DEFAULT_LOCATION_TYPE = LOCATION.TYPE.AIRPORT

const defaultState = () => ({
  featuredLocations: [],
  locations: {
    [LOCATION.TYPE.FLIGHT]: [],
    [LOCATION.TYPE.COMBO]: [],
    [LOCATION.TYPE.HOTEL]: [],
    [LOCATION.TYPE.AIRPORT]: [],
  },
})

export const LocationModule = () => ({
  state: defaultState(),
  getters: {
    locations: (s, g) => g.allLocations[DEFAULT_LOCATION_TYPE],
    allLocations: s =>
      Object.fromEntries(
        Object.entries(s.locations).map(([type, locations]) => [
          type,
          locations.sort((a, b) =>
            a.displayName < b.displayName
              ? -1
              : a.displayName > b.displayName
              ? 1
              : 0,
          ),
        ]),
      ),
    flatLocations: (s, g) =>
      Object.fromEntries(
        Object.entries(g.allLocations).map(([type, locations]) => [
          type,
          flattenLocations(locations),
        ]),
      ),
    findLocationByName:
      (s, g) =>
      (displayName, type = DEFAULT_LOCATION_TYPE) =>
        g.flatLocations[type].find(el => el.displayName === displayName),
    findLocationById:
      (s, g) =>
      (id, type = DEFAULT_LOCATION_TYPE) =>
        g.flatLocations[type].find(el => el.id === id),
  },
  actions: {
    getLocations: ({ commit }, payload) => {
      const type = payload?.type ?? DEFAULT_LOCATION_TYPE
      const search = payload?.search
      const ADD_MUTATION = `addLocations${utils.capitalize(type)}`
      const getLocations = LocationService.getLocations[type]
      return getLocations(search).then(res => {
        commit(ADD_MUTATION, res.locationsList)
        return res.locationsList
      })
    },
    getUserLocation: async ({ dispatch, rootGetters }) => {
      const isLoggedIn = rootGetters['AuthModule/getIsLoggedIn']
      if (isLoggedIn) {
        if (!rootGetters['UserModule/getUser'])
          await dispatch('UserModule/getUser', undefined, { root: true })
        if (rootGetters['UserModule/userHasLocation']) {
          if (!rootGetters['userModule/userLocation'])
            await dispatch('getLocations')
          const userLocation = rootGetters['UserModule/userLocation']
          if (userLocation) return userLocation
        }
      }
      return dispatch('getNearestLocation')
    },
    getNearestLocation: async ({ rootGetters }) => {
      const user = rootGetters['UserModule/getUser']
      const userHasLocation = rootGetters['UserModule/userHasLocation']
      const userLocation = rootGetters['UserModule/userLocation']
      const getByCoordinates = !user || (user && !userHasLocation)

      return (
        getByCoordinates
          ? LocationService.getNearestLocationByCoordinates()
          : LocationService.getNearestLocationByLocation({
              id: userLocation.identifier,
            })
      ).then(res => res.location)
    },
    getFeaturedLocations: async ({ commit, rootGetters }) => {
      let call = LocationService.getFeaturedLocationsWithoutDepartureLocation
      let payload = undefined
      const userLocationId = rootGetters['UserModule/userLocationId']
      if (userLocationId) {
        call = LocationService.getFeaturedLocationsByLocation
        payload = { locationId: userLocationId }
      } else {
        try {
          const coordinates = await getCoordinates()
          call = LocationService.getFeaturedLocationsByCoordinates
          payload = { coordinates }
        } catch (e) {
          call = LocationService.getFeaturedLocationsWithoutDepartureLocation
          payload = undefined
        }
      }
      return call(payload).then(res => {
        commit('setFeaturedLocations', res.featuredLocationsList)
        const arrivalLocations = res.featuredLocationsList.map(
          el => el.arrivalLocation,
        )
        commit('addLocationsCombo', arrivalLocations)
        return res
      })
    },
  },
})
