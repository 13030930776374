// source: proto/models/featured_location.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_models_location_pb = require('../../proto/models/location_pb.js');
goog.object.extend(proto, proto_models_location_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.elude.proto.FeaturedLocation', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.FeaturedLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.FeaturedLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.FeaturedLocation.displayName = 'proto.elude.proto.FeaturedLocation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.FeaturedLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.FeaturedLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.FeaturedLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FeaturedLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    budgetTier: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    children: jspb.Message.getFieldWithDefault(msg, 5, 0),
    infants: jspb.Message.getFieldWithDefault(msg, 6, 0),
    numberOfResults: jspb.Message.getFieldWithDefault(msg, 7, 0),
    departureDate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    returnDate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    departureLocation: (f = msg.getDepartureLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f),
    arrivalLocation: (f = msg.getArrivalLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.FeaturedLocation}
 */
proto.elude.proto.FeaturedLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.FeaturedLocation;
  return proto.elude.proto.FeaturedLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.FeaturedLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.FeaturedLocation}
 */
proto.elude.proto.FeaturedLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.elude.proto.BudgetTierEnum} */ (reader.readEnum());
      msg.setBudgetTier(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildren(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInfants(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfResults(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartureDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnDate(value);
      break;
    case 10:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setDepartureLocation(value);
      break;
    case 11:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setArrivalLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.FeaturedLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.FeaturedLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.FeaturedLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FeaturedLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBudgetTier();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdults();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getChildren();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getInfants();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNumberOfResults();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDepartureDate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReturnDate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDepartureLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getArrivalLocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.FeaturedLocation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BudgetTierEnum budget_tier = 2;
 * @return {!proto.elude.proto.BudgetTierEnum}
 */
proto.elude.proto.FeaturedLocation.prototype.getBudgetTier = function() {
  return /** @type {!proto.elude.proto.BudgetTierEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.elude.proto.BudgetTierEnum} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setBudgetTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.elude.proto.FeaturedLocation.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 adults = 4;
 * @return {number}
 */
proto.elude.proto.FeaturedLocation.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setAdults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 children = 5;
 * @return {number}
 */
proto.elude.proto.FeaturedLocation.prototype.getChildren = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setChildren = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 infants = 6;
 * @return {number}
 */
proto.elude.proto.FeaturedLocation.prototype.getInfants = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setInfants = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 number_of_results = 7;
 * @return {number}
 */
proto.elude.proto.FeaturedLocation.prototype.getNumberOfResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setNumberOfResults = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string departure_date = 8;
 * @return {string}
 */
proto.elude.proto.FeaturedLocation.prototype.getDepartureDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setDepartureDate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string return_date = 9;
 * @return {string}
 */
proto.elude.proto.FeaturedLocation.prototype.getReturnDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.setReturnDate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Location departure_location = 10;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.FeaturedLocation.prototype.getDepartureLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 10));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
*/
proto.elude.proto.FeaturedLocation.prototype.setDepartureLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.clearDepartureLocation = function() {
  return this.setDepartureLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocation.prototype.hasDepartureLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Location arrival_location = 11;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.FeaturedLocation.prototype.getArrivalLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 11));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.FeaturedLocation} returns this
*/
proto.elude.proto.FeaturedLocation.prototype.setArrivalLocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocation} returns this
 */
proto.elude.proto.FeaturedLocation.prototype.clearArrivalLocation = function() {
  return this.setArrivalLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocation.prototype.hasArrivalLocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.elude.proto);
