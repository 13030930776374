import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
// import segmentPlugin from '@analytics/segment'

export const appAnalytics = Analytics({
  app: 'elude-client-web',
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.VUE_APP_GOOGLE_ANALYTICS_ID],
    }),
    // segmentPlugin({
    //   writeKey: process.env.VUE_APP_SEGMENT_WRITE_KEY,
    // }),
  ],
})
