import { AirService } from '../../services/AirService'

export const AirModule = () => ({
  state: {
    airlines: [],
    airports: [],
  },
  getters: {
    airlineByIata: s => iata =>
      s.airlines.find(({ iataCode }) => iataCode === iata),
    airlineIataList: s => s.airline.map(airline => airline.iataCode),
    airportByIata: s => iata =>
      s.airports.find(({ iataCode }) => iataCode === iata),
    airportIataList: s => s.airport.map(airport => airport.iataCode),
    airlineName: (s, g) => iata =>
      g['find']('airlines')({ iataCode: iata })?.name || iata,
  },
  actions: {
    getAirlineByIata: ({ commit, getters }, iata) =>
      new Promise((resolve, reject) => {
        let airline = getters.airlineByIata(iata)
        if (airline) resolve(airline)
        else
          AirService.getMultipleAirlines([iata]).then(res => {
            if (!res.airlinesList.length) return
            commit('addAirlines', res.airlinesList)
            airline = res.airlinesList[0]
            airline ? resolve(airline) : reject()
          })
      }),
    getAirportByIata: ({ commit, getters }, iata) => {
      if (getters.airportByIata(iata)) return
      return AirService.getMultipleAirports([iata]).then(res => {
        if (res.airportsList.length) commit('addAirports', res.airportsList)
      })
    },
  },
})
