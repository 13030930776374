<script>
export default {
  name: 'XLabel',
  props: {
    tag: {
      default: 'p',
      type: String,
    },
    button: Boolean,
  },
  render(el) {
    const classes = ['label-element']
    if (this.button) classes.push('label-element-button')
    return el(
      this.tag ? this.tag : 'p',
      {
        class: classes,
        on: this.$listeners,
      },
      this.$slots.default,
    )
  },
}
</script>

<style scoped lang="scss">
.label-element {
  font-family: LuciferSans;
  letter-spacing: 0.06em;
  font-size: 13px;
}
.label-element-button {
  color: var(--color-primary);

  cursor: pointer;
}
</style>
