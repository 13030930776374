<template>
  <h3 class="x-class">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'XSubtitle',
}
</script>

<style scoped lang="scss">
.x-class {
  font-family: GTSuper;
  font-size: 2.0625rem;
}
</style>
