import { SEARCH } from '../constants/SEARCH'
import { store } from '../store/store'

export const beforeEachSearch = (to, from, next) => {
  const hasResults = !!store.getters['SearchState/trips'].length
  const validToPaths = [
    '/search',
    '/search/form',
    '/search/q',
    '/search/prepare',
  ]
  const validTo = validToPaths.includes(to.path)
  const isValid = hasResults || validTo

  if (!isValid) return next('/search')
  return next()
}

export const searchRoutes = {
  path: '/search',
  component: () => import('@/views/search/Search.vue'),
  meta: { title: 'Search', searchRoute: true },
  children: [
    {
      path: '/',
      component: () => import('@/views/search/SearchScreen.vue'),
      beforeEnter(to, from, next) {
        store.dispatch('SearchStream/stopSearch')
        return next()
      },
      meta: { showNav: true },
    },
    {
      path: 'form',
      component: () => import('@/views/search/SearchForm.vue'),
      beforeEnter(to, from, next) {
        store.dispatch('SearchStream/stopSearch')
        const searchType = store.state.SearchForm.form.searchType
        const searchTypeValid = Object.values(SEARCH.TYPE).includes(searchType)
        if (!searchTypeValid) return next('/search')
        return next()
      },
    },
    {
      path: 'prepare',
      component: () => import('@/views/search/SearchPrepare.vue'),
    },
    {
      path: 'q',
      component: () => import('@/views/search/SearchStream.vue'),
      async beforeEnter(to, from, next) {
        store.dispatch('SearchStream/stopSearch')

        const validFromPaths = [
          '/',
          '/search/form',
          '/search/prepare',
          '/search/shortlist',
        ]
        const validPath = validFromPaths.includes(from.path)
        if (!validPath) return next('/search')

        const searchParams = store.getters['SearchForm/parseQueryParameters'](
          to.query,
        )
        const result = await store.dispatch('SearchForm/resolveParameters', {
          params: searchParams,
        })
        store.commit('SearchForm/setParams', result.params)

        if (result.valid) {
          if (result.changed) next({ path: '/search/q', query: result.params })
          else next()
        } else if (result.missingJustAsync)
          next({ path: '/search/prepare', query: result.params })
        else next('/search/form')
      },
    },
    {
      path: 'shortlist',
      component: () => import('@/views/search/TripShortlist.vue'),
      meta: { title: 'Trip Shortlist' },
    },
    {
      path: 'summary',
      component: () => import('@/views/search/TripSummary.vue'),
      meta: { title: 'Trip Summary' },
    },
    {
      path: 'travelers',
      component: () => import('@/views/search/travelers/TripTravelers.vue'),
      meta: { title: 'Trip Travelers' },
    },
    {
      path: 'travelers/user-details',
      component: () => import('@/views/search/travelers/TripUserEdit.vue'),
      meta: { title: 'Trip Travelers' },
    },
    {
      path: 'travelers/select',
      component: () =>
        import('@/views/search/travelers/TripTravelersSelect.vue'),
      meta: { title: 'Trip Travelers' },
    },
    {
      path: 'travelers/create/:type',
      component: () =>
        import('@/views/search/travelers/TripTravelerCreate.vue'),
      meta: { title: 'Trip Travelers' },
    },
    {
      path: 'travelers/edit/:id',
      component: () => import('@/views/search/travelers/TripTravelerEdit.vue'),
      meta: { title: 'Trip Travelers' },
    },
    {
      path: 'payments',
      component: () => import('@/views/search/payments/TripPayments.vue'),
      meta: { title: 'Trip Payments' },
    },
    {
      path: 'payments/add',
      component: () => import('@/views/search/payments/TripPaymentAdd.vue'),
      meta: { title: 'Trip Payments' },
    },
    {
      path: 'promo-code',
      component: () => import('@/views/search/payments/TripAddPromoCode.vue'),
      meta: { title: 'Reservation Promo Code' },
    },
    {
      path: 'hotels',
      component: () => import('@/views/search/hotels/TripHotels.vue'),
      meta: { title: 'Trip Hotels', disableScrollBehavior: true },
    },
    {
      path: 'hotels/map',
      component: () => import('@/views/search/hotels/TripHotelsMap.vue'),
      meta: { title: 'Search - Hotel', fullScreen: true },
    },
    {
      path: 'hotel/details',
      component: () => import('@/views/search/hotels/TripHotelDetails.vue'),
      meta: { title: 'Trip Hotels' },
    },
    {
      path: 'hotel/rooms',
      component: () => import('@/views/search/hotels/TripHotelRooms.vue'),
      meta: { title: 'Trip Hotels', disableScrollBehavior: true },
    },
    {
      path: 'flights',
      component: () => import('@/views/search/flights/TripFlights.vue'),
      meta: { title: 'Trip Flights', disableScrollBehavior: true },
    },
    {
      path: 'flight/details',
      component: () => import('@/views/search/flights/TripFlightDetails.vue'),
      meta: { title: 'Trip Flights' },
    },
    {
      path: 'reservation',
      component: () => import('@/views/search/TripReservation.vue'),
      meta: { title: 'Trip Reservation' },
    },
  ],
}
