// source: services/services.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_air_hotel_reservation_search_request_pb = require('../proto/air_hotel_reservation_search/request_pb.js');
goog.object.extend(proto, proto_air_hotel_reservation_search_request_pb);
var proto_air_hotel_reservation_search_response_pb = require('../proto/air_hotel_reservation_search/response_pb.js');
goog.object.extend(proto, proto_air_hotel_reservation_search_response_pb);
var proto_auth_auth_request_pb = require('../proto/auth/auth_request_pb.js');
goog.object.extend(proto, proto_auth_auth_request_pb);
var proto_auth_auth_response_pb = require('../proto/auth/auth_response_pb.js');
goog.object.extend(proto, proto_auth_auth_response_pb);
var proto_booking_booking_request_pb = require('../proto/booking/booking_request_pb.js');
goog.object.extend(proto, proto_booking_booking_request_pb);
var proto_booking_booking_response_pb = require('../proto/booking/booking_response_pb.js');
goog.object.extend(proto, proto_booking_booking_response_pb);
var proto_location_location_request_pb = require('../proto/location/location_request_pb.js');
goog.object.extend(proto, proto_location_location_request_pb);
var proto_location_location_response_pb = require('../proto/location/location_response_pb.js');
goog.object.extend(proto, proto_location_location_response_pb);
var proto_payment_payment_request_pb = require('../proto/payment/payment_request_pb.js');
goog.object.extend(proto, proto_payment_payment_request_pb);
var proto_payment_payment_response_pb = require('../proto/payment/payment_response_pb.js');
goog.object.extend(proto, proto_payment_payment_response_pb);
var proto_saved_search_saved_search_request_pb = require('../proto/saved_search/saved_search_request_pb.js');
goog.object.extend(proto, proto_saved_search_saved_search_request_pb);
var proto_saved_search_saved_search_response_pb = require('../proto/saved_search/saved_search_response_pb.js');
goog.object.extend(proto, proto_saved_search_saved_search_response_pb);
