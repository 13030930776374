<template>
  <div :id="internalId" class="paypal-button"></div>
</template>

<script>
import { PaypalProvider } from '../../../services/payment-providers/PaypalProvider'

export default {
  name: 'PaypalButton',
  props: {
    id: String,
    createOrder: {
      type: Function,
      required: true,
    },
    onApprove: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    PaypalProvider.button({
      createOrder: this.createOrder,
      onApprove: this.onApprove,
      elementId: `#${this.internalId}`,
    })
  },
  data() {
    return {
      internalId: this.id ?? 'paypal-button',
    }
  },
}
</script>

<style scoped lang="scss">
.paypal-button {
  position: relative;
  z-index: 0;
}
</style>
