<template>
  <div style="display: grid; gap: 24px">
    <div v-if="hasBack || hasClose" style="display: flex">
      <AppNavButton
        v-if="hasBack"
        icon="back"
        :to="backTo"
        @click="$emit('back')"
      />
      <AppNavButton
        v-if="hasClose"
        style="margin-left: auto"
        icon="close"
        :to="closeTo"
        @click="$emit('close')"
      />
    </div>
    <template v-if="title">
      <transition name="slide" appear>
        <p class="app-title">{{ title }}</p>
      </transition>
    </template>
  </div>
</template>

<script>
import AppNavButton from './AppNavButton.vue'

export default {
  name: 'AppHeader',
  components: { AppNavButton },
  props: {
    title: String,
    backTo: String,
    closeTo: String,
  },
  emits: ['back', 'close'],
  computed: {
    hasBack: vm => vm.checkButton('back'),
    hasClose: vm => vm.checkButton('close'),
  },
  methods: {
    checkButton(action) {
      return this[action + 'To'] || this.$listeners[action]
    },
  },
}
</script>

<style scoped lang="scss"></style>
