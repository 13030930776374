// source: proto/commons/image_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_options_pb = require('../../proto/commons/options_pb.js');
goog.object.extend(proto, proto_commons_options_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_media_pb = require('../../proto/models/media_pb.js');
goog.object.extend(proto, proto_models_media_pb);
var proto_models_airline_pb = require('../../proto/models/airline_pb.js');
goog.object.extend(proto, proto_models_airline_pb);
var proto_models_amenity_pb = require('../../proto/models/amenity_pb.js');
goog.object.extend(proto, proto_models_amenity_pb);
var proto_models_sticker_pb = require('../../proto/models/sticker_pb.js');
goog.object.extend(proto, proto_models_sticker_pb);
var proto_models_parent_location_pb = require('../../proto/models/parent_location_pb.js');
goog.object.extend(proto, proto_models_parent_location_pb);
goog.exportSymbol('proto.elude.proto.UpdateAirlineWithImageData', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAmenityWithImageData', null, global);
goog.exportSymbol('proto.elude.proto.UpdateCityWithImageData', null, global);
goog.exportSymbol('proto.elude.proto.UpdateParentLocationWithImageData', null, global);
goog.exportSymbol('proto.elude.proto.UpdateStickerWithImageData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateCityWithImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateCityWithImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateCityWithImageData.displayName = 'proto.elude.proto.UpdateCityWithImageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAirlineWithImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAirlineWithImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAirlineWithImageData.displayName = 'proto.elude.proto.UpdateAirlineWithImageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateStickerWithImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateStickerWithImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateStickerWithImageData.displayName = 'proto.elude.proto.UpdateStickerWithImageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAmenityWithImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAmenityWithImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAmenityWithImageData.displayName = 'proto.elude.proto.UpdateAmenityWithImageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateParentLocationWithImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateParentLocationWithImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateParentLocationWithImageData.displayName = 'proto.elude.proto.UpdateParentLocationWithImageData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateCityWithImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateCityWithImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityWithImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    city: (f = msg.getCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateCityWithImageData}
 */
proto.elude.proto.UpdateCityWithImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateCityWithImageData;
  return proto.elude.proto.UpdateCityWithImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateCityWithImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateCityWithImageData}
 */
proto.elude.proto.UpdateCityWithImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 4:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateCityWithImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateCityWithImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityWithImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
*/
proto.elude.proto.UpdateCityWithImageData.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
 */
proto.elude.proto.UpdateCityWithImageData.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
*/
proto.elude.proto.UpdateCityWithImageData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
 */
proto.elude.proto.UpdateCityWithImageData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional City city = 3;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 3));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
*/
proto.elude.proto.UpdateCityWithImageData.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
 */
proto.elude.proto.UpdateCityWithImageData.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.hasCity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Media media = 4;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.getMedia = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 4));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
*/
proto.elude.proto.UpdateCityWithImageData.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
 */
proto.elude.proto.UpdateCityWithImageData.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
*/
proto.elude.proto.UpdateCityWithImageData.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageData} returns this
 */
proto.elude.proto.UpdateCityWithImageData.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageData.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAirlineWithImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAirlineWithImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineWithImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData}
 */
proto.elude.proto.UpdateAirlineWithImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAirlineWithImageData;
  return proto.elude.proto.UpdateAirlineWithImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAirlineWithImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData}
 */
proto.elude.proto.UpdateAirlineWithImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 4:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAirlineWithImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAirlineWithImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineWithImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
*/
proto.elude.proto.UpdateAirlineWithImageData.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
*/
proto.elude.proto.UpdateAirlineWithImageData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Airline airline = 3;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 3));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
*/
proto.elude.proto.UpdateAirlineWithImageData.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Media media = 4;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.getMedia = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 4));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
*/
proto.elude.proto.UpdateAirlineWithImageData.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
*/
proto.elude.proto.UpdateAirlineWithImageData.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageData} returns this
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageData.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateStickerWithImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateStickerWithImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerWithImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    sticker: (f = msg.getSticker()) && proto_models_sticker_pb.Sticker.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateStickerWithImageData}
 */
proto.elude.proto.UpdateStickerWithImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateStickerWithImageData;
  return proto.elude.proto.UpdateStickerWithImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateStickerWithImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateStickerWithImageData}
 */
proto.elude.proto.UpdateStickerWithImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_sticker_pb.Sticker;
      reader.readMessage(value,proto_models_sticker_pb.Sticker.deserializeBinaryFromReader);
      msg.setSticker(value);
      break;
    case 4:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateStickerWithImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateStickerWithImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerWithImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getSticker();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_sticker_pb.Sticker.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
*/
proto.elude.proto.UpdateStickerWithImageData.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
*/
proto.elude.proto.UpdateStickerWithImageData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Sticker sticker = 3;
 * @return {?proto.elude.proto.Sticker}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.getSticker = function() {
  return /** @type{?proto.elude.proto.Sticker} */ (
    jspb.Message.getWrapperField(this, proto_models_sticker_pb.Sticker, 3));
};


/**
 * @param {?proto.elude.proto.Sticker|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
*/
proto.elude.proto.UpdateStickerWithImageData.prototype.setSticker = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.clearSticker = function() {
  return this.setSticker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.hasSticker = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Media media = 4;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.getMedia = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 4));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
*/
proto.elude.proto.UpdateStickerWithImageData.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
*/
proto.elude.proto.UpdateStickerWithImageData.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageData} returns this
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageData.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAmenityWithImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAmenityWithImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityWithImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    amenity: (f = msg.getAmenity()) && proto_models_amenity_pb.Amenity.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData}
 */
proto.elude.proto.UpdateAmenityWithImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAmenityWithImageData;
  return proto.elude.proto.UpdateAmenityWithImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAmenityWithImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData}
 */
proto.elude.proto.UpdateAmenityWithImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_amenity_pb.Amenity;
      reader.readMessage(value,proto_models_amenity_pb.Amenity.deserializeBinaryFromReader);
      msg.setAmenity(value);
      break;
    case 4:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAmenityWithImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAmenityWithImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityWithImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmenity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_amenity_pb.Amenity.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
*/
proto.elude.proto.UpdateAmenityWithImageData.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
*/
proto.elude.proto.UpdateAmenityWithImageData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Amenity amenity = 3;
 * @return {?proto.elude.proto.Amenity}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.getAmenity = function() {
  return /** @type{?proto.elude.proto.Amenity} */ (
    jspb.Message.getWrapperField(this, proto_models_amenity_pb.Amenity, 3));
};


/**
 * @param {?proto.elude.proto.Amenity|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
*/
proto.elude.proto.UpdateAmenityWithImageData.prototype.setAmenity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.clearAmenity = function() {
  return this.setAmenity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.hasAmenity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Media media = 4;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.getMedia = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 4));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
*/
proto.elude.proto.UpdateAmenityWithImageData.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
*/
proto.elude.proto.UpdateAmenityWithImageData.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageData} returns this
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageData.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateParentLocationWithImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateParentLocationWithImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationWithImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    parentLocation: (f = msg.getParentLocation()) && proto_models_parent_location_pb.ParentLocation.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData}
 */
proto.elude.proto.UpdateParentLocationWithImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateParentLocationWithImageData;
  return proto.elude.proto.UpdateParentLocationWithImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateParentLocationWithImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData}
 */
proto.elude.proto.UpdateParentLocationWithImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_parent_location_pb.ParentLocation;
      reader.readMessage(value,proto_models_parent_location_pb.ParentLocation.deserializeBinaryFromReader);
      msg.setParentLocation(value);
      break;
    case 4:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateParentLocationWithImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateParentLocationWithImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationWithImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_parent_location_pb.ParentLocation.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageData.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ParentLocation parent_location = 3;
 * @return {?proto.elude.proto.ParentLocation}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.getParentLocation = function() {
  return /** @type{?proto.elude.proto.ParentLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_parent_location_pb.ParentLocation, 3));
};


/**
 * @param {?proto.elude.proto.ParentLocation|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageData.prototype.setParentLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.clearParentLocation = function() {
  return this.setParentLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.hasParentLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Media media = 4;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.getMedia = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 4));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageData.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageData.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageData} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageData.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.elude.proto);
