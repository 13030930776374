import {
  DeleteUserRequest,
  LoginRequest,
  RegisterRequest,
  ResendConfirmationEmailRequest,
  VerifyEmailRequest,
} from '../../../util/gen/proto/auth/auth_request_pb'
import { AuthProviderEnum } from '../../../util/gen/proto/commons/enum_pb'
import { deviceType } from '../other/utils/deviceType'
import { AppleAuth } from './auth-providers/AppleAuth'
import { FacebookAuth } from './auth-providers/FacebookAuth'
import { GoogleAuth } from './auth-providers/GoogleAuth'
import { TwitterAuth } from './auth-providers/TwitterAuth'
import { Grpc } from './base/Grpc'
import { useOptions } from './base/Grpc.utils'

export const AuthService = {
  register: ({
    email,
    first_name,
    last_name,
    password,
    password_confirmation,
  }) =>
    Grpc.snt
      .register(
        new RegisterRequest()
          .setEmail(email)
          .setFirstName(first_name)
          .setLastName(last_name)
          .setPassword(password)
          .setDeviceType(deviceType)
          .setPasswordConfirmation(password_confirmation),
        useOptions({ useToasts: false }),
      )
      .then(res => res.toObject()),
  login: ({ email, password }) =>
    Grpc.snt
      .login(
        new LoginRequest().setEmail(email).setPassword(password),
        useOptions({ useToasts: false }),
      )
      .then(res => res.toObject()),
  googleLogin: () =>
    GoogleAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.login(
          new LoginRequest()
            .setToken(credential.idToken)
            .setProvider(AuthProviderEnum.GOOGLE),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  facebookLogin: () =>
    FacebookAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.login(
          new LoginRequest()
            .setToken(credential.accessToken)
            .setProvider(AuthProviderEnum.FACEBOOK),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  twitterLogin: () =>
    TwitterAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.login(
          new LoginRequest()
            .setToken(credential.accessToken)
            .setTokenSecret(credential.secret)
            .setProvider(AuthProviderEnum.TWITTER),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  appleLogin: () =>
    AppleAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.login(
          new LoginRequest()
            .setToken(credential.idToken)
            .setProvider(AuthProviderEnum.APPLE),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  logout: () => Promise.resolve(),
  verifyEmail: ({ token }) =>
    Grpc.snt
      .verifyEmail(
        new VerifyEmailRequest().setToken(token),
        useOptions({ useToasts: true }),
      )
      .then(res => res.toObject()),
  resendEmail: ({ id }) =>
    Grpc.snt.resendConfirmationEmail(
      new ResendConfirmationEmailRequest().setUserId(id),
    ),
  deleteUser: ({ userId }) =>
    Grpc.snt.deleteUser(new DeleteUserRequest().setId(userId)),
}
