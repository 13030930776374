import { PreferenceService } from '../../services/PreferenceService'

export const PreferenceModule = () => ({
  state: {
    preferences: [],
  },
  actions: {
    getPreferences: ({ commit }) =>
      PreferenceService.getPreferences().then(
        ({ preferencesList, userPreferencesList }) => {
          commit('setPreferences', preferencesList)
          commit('UserModule/setPreferences', userPreferencesList, {
            root: true,
          })
        },
      ),
  },
  getters: {
    displayPreferences: state => idList => {
      if (!idList.length) return 'All'
      return idList
        .map(id => state.preferences.find(el => el.id === id)?.name)
        .filter(el => !!el)
        .join(', ')
    },
  },
})
