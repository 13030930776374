import {
  GetBookingByEludeReferenceRequest,
  GetBookingsRequest,
} from '../../../util/gen/proto/booking/booking_request_pb'
import { Grpc } from './base/Grpc'

export const BookingService = {
  getBookingByReference: ({ eludeReference }) =>
    Grpc.snt
      .getBookingByEludeReference(
        new GetBookingByEludeReferenceRequest().setEludeReference(
          eludeReference,
        ),
      )
      .then(res => res.toObject()),
  getUpcomingBookings: ({ userId }) =>
    Grpc.snt
      .getUpcomingBookings(new GetBookingsRequest().setUserId(userId))
      .then(res => res.toObject()),
  getCompletedBookings: ({ userId }) =>
    Grpc.snt
      .getCompletedBookings(new GetBookingsRequest().setUserId(userId))
      .then(res => res.toObject()),
  getHomeBookings: ({ userId }) =>
    Grpc.snt
      .getHomeBookings(new GetBookingsRequest().setUserId(userId))
      .then(res => res.toObject()),
}

export const getBookings = userId =>
  Grpc.snt.getBookings(new GetBookingsRequest().setUserId(userId))
