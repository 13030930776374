import { StickerService } from '../../services/StickerService'

export const StickerModule = () => ({
  state: {
    stickers: [],
    activeSticker: {},
  },
  actions: {
    getStickers: ({ commit, rootState }) =>
      StickerService.getStickers({
        userId: rootState.UserModule.user.id,
      }).then(res => commit('setStickers', res.stickersList)),
  },
})
