import { hashPassword } from '../../other/utils/hashPassword'
import { RecoveryService } from '../../services/RecoveryService'

export const RecoveryModule = () => ({
  state: {
    email: '',
    token: '',
  },
  actions: {
    recoverPassword: (ctx, payload) => RecoveryService.recoverPassword(payload),
    resetPassword: async ({ commit }, { email, password, token }) =>
      RecoveryService.resetPassword({
        email,
        token,
        password: await hashPassword(password),
      }).then(res => {
        commit('setToken', '')
        return res
      }),
    validateToken: (ctx, { email, token }) =>
      RecoveryService.validateToken({ email, token }),
  },
})
