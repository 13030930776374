import { AUTH_PROVIDERS } from '../../constants/AUTH_PROVIDERS'
import { hashPassword } from '../../other/utils/hashPassword'
import { router } from '../../router/router'
import { appAnalytics } from '../../services/analytics/analytics'
import { AuthService } from '../../services/AuthService'
import { Api } from '../../services/base/Api'

const root = { root: true }
const DISABLE_ONBOARDING = true

const trackLogin = (loginType, success = true) => {
  const eventName = success ? 'login_result_success' : 'login_result_fail'
  appAnalytics.track(eventName, { login_type: loginType })
}

export const AuthModule = () => ({
  state: {
    token: undefined,
    isLoggedIn: false,
  },
  actions: {
    login: async ({ dispatch }, payload) => {
      const promise = AuthService.login({
        ...payload,
        password: await hashPassword(payload.password),
      })
      promise.catch(() => trackLogin('email', false))
      return promise.then(res =>
        dispatch('loginHandler', { ...res, trackingId: 'email' }),
      )
    },
    loginHandler: async ({ commit, dispatch }, payload) => {
      const { token, user, trackingId } = payload
      const { id, email, paymentMethodsList } = user
      if (!user.id) {
        trackLogin(trackingId, false)
        throw new Error('Failed to log in')
      }
      dispatch('authenticateUser', token)
      appAnalytics.identify(id, { email })
      trackLogin(trackingId, true)
      commit('UserModule/setUser', user, root)
      if (paymentMethodsList)
        commit('UserModule/setPaymentMethods', paymentMethodsList, root)
      dispatch('onLogin', undefined, root)
      if (process.env.NODE_ENV === 'test') return

      const isVisitorSearch = await dispatch(
        'PersistModule/visitorSearchGuardAfterLogin',
        undefined,
        root,
      )
      if (isVisitorSearch) return

      if (DISABLE_ONBOARDING) router.push('/search')
      else {
        const hasFinishedOnboarding =
          user.onboardingData && JSON.parse(user.onboardingData)?.finished
        router.push(hasFinishedOnboarding ? '/search' : '/onboarding')
      }
    },
    authenticateUser: ({ commit, dispatch }, token) => {
      commit('setToken', token)
      dispatch('initSession')
    },
    initSession({ state, commit }) {
      Api.setHeader(state.token)
      if (state.token) commit('setIsLoggedIn', true)
    },
    socialLogin: ({ dispatch }, authProviderId) => {
      const social = AUTH_PROVIDERS.find(({ id }) => id === authProviderId)
      if (!social) return
      const promise = social.login()
      promise.catch(() => trackLogin(social.trackingId, false))
      return promise.then(res =>
        dispatch('loginHandler', { ...res, trackingId: social.trackingId }),
      )
    },
    logout: ({ dispatch }) => {
      appAnalytics.track('logged_out')
      dispatch('onLogout', undefined, root)
      dispatch('ToastModule/success', 'Logged out', root)
      Api.removeHeader()
      dispatch('resetState', undefined, root)
      window.location.assign('/')
    },
    register: async (ctx, payload) =>
      AuthService.register({
        ...payload,
        password: await hashPassword(payload.password),
        password_confirmation: await hashPassword(
          payload.password_confirmation,
        ),
      }),
    verifyEmail: ({ dispatch }, { token }) =>
      AuthService.verifyEmail({ token }).then(res =>
        dispatch('loginHandler', res),
      ),
    resendEmail: (ctx, { id }) => AuthService.resendEmail({ id }),
  },
})
