import { uniq } from 'ramda'

const SEARCH_TYPE = {
  PACKAGE: 'package',
  COMBO: 'combo',
  FLIGHT: 'flight',
  HOTEL: 'hotel',
}

export const sortByPrice = list =>
  [...list].sort((a, b) => (a.price.total < b.price.total ? -1 : 1))

const crossFilter = (a, b) =>
  a.filter(ia => b.some(ib => ia.checkInDate === ib.checkInDate))

const getMaxBudget = budget => budget * 1.1

const packageValidator =
  ({ tripValidator, flightParser, hotelParser }) =>
  ({ flights, hotels, trips, budget }) => {
    const maxBudget = getMaxBudget(budget)
    return trips.reduce(
      (acc, trip) => {
        const filterTripEntities = ({ tripId }) => tripId === trip.id

        const tripFlights = flights.filter(filterTripEntities)
        const tripHotels = hotels.filter(filterTripEntities)

        const validTripFlights = flightParser({ tripFlights, tripHotels })
        const validTripHotels = hotelParser({ tripFlights, tripHotels })

        if (tripValidator({ validTripFlights, validTripHotels, maxBudget }))
          acc.tripIds.push(trip.id)

        if (validTripFlights.length)
          acc.flightIds.push(...validTripFlights.map(({ id }) => id))

        if (validTripHotels.length)
          acc.hotelIds.push(...validTripHotels.map(({ id }) => id))

        return acc
      },
      { flightIds: [], hotelIds: [], tripIds: [] },
    )
  }

export const SEARCH = {
  TYPE: SEARCH_TYPE,
  meta: {
    [SEARCH_TYPE.PACKAGE]: {
      hasHotels: true,
      hasFlights: true,
      validatePackage: packageValidator({
        tripValidator: ({ validTripFlights, validTripHotels, maxBudget }) => {
          const hasFlights = validTripFlights.length
          const hasHotels = validTripHotels.length
          if (!hasFlights || !hasHotels) return false

          const dates = validTripHotels.map(hotel => hotel.checkInDate)
          const minPrice = dates.reduce((currentPrice, date) => {
            const dateFilter = item => item.checkInDate === date
            const flight = validTripFlights.filter(dateFilter)[0]
            const hotel = validTripHotels.filter(dateFilter)[0]
            const price = flight.price.total + hotel.price.total
            return price < currentPrice ? price : currentPrice
          }, maxBudget + 1)

          return minPrice < maxBudget
        },
        flightParser: ({ tripFlights, tripHotels }) =>
          crossFilter(tripFlights, tripHotels),
        hotelParser: ({ tripFlights, tripHotels }) =>
          crossFilter(tripHotels, tripFlights),
      }),
    },
    [SEARCH_TYPE.COMBO]: {
      hasHotels: true,
      hasFlights: true,
      validatePackage: packageValidator({
        tripValidator: ({ validTripFlights, validTripHotels }) =>
          validTripFlights.length && validTripHotels.length,
        flightParser: ({ tripFlights, tripHotels }) =>
          crossFilter(tripFlights, tripHotels),
        hotelParser: ({ tripFlights, tripHotels }) =>
          crossFilter(tripHotels, tripFlights),
      }),
    },
    [SEARCH_TYPE.FLIGHT]: {
      hasHotels: false,
      hasFlights: true,
      validatePackage: packageValidator({
        tripValidator: ({ validTripFlights }) => !!validTripFlights.length,
        flightParser: ({ tripFlights }) => tripFlights,
        hotelParser: () => [],
      }),
    },
    [SEARCH_TYPE.HOTEL]: {
      hasHotels: true,
      hasFlights: false,
      validatePackage: packageValidator({
        tripValidator: ({ validTripHotels }) => !!validTripHotels.length,
        flightParser: () => [],
        hotelParser: ({ tripHotels }) => tripHotels,
      }),
    },
  },
}

export const selectLongestStayPackage = ({
  flights: rawFlights,
  hotels: rawHotels,
  budget,
  searchType,
}) => {
  const maxBudget = getMaxBudget(budget)
  const hasFlights = !!rawFlights.length
  const dates = uniq(
    (hasFlights ? rawFlights : rawHotels)
      .map(flight => flight.checkInDate)
      .sort((a, b) => (a < b ? -1 : 1)),
  )

  const isPackage = searchType === SEARCH.TYPE.PACKAGE

  return dates.reduce((acc, date) => {
    if (acc) return acc
    const filterByDate = ({ checkInDate }) => checkInDate === date
    const flight = rawFlights.filter(filterByDate)[0]
    const hotel = rawHotels.filter(filterByDate)[0]

    if (isPackage) {
      const price = flight.price.total + hotel.price.total
      return price < maxBudget ? { flight, hotel } : acc
    } else return { flight, hotel }
  }, undefined)
}
