var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
    if (this) { return this; }
    if (typeof window !== 'undefined') { return window; }
    if (typeof global !== 'undefined') { return global; }
    if (typeof self !== 'undefined') { return self; }
    return Function('return this')();
}.call(null));

goog.exportSymbol('proto.tagger', null, global);
