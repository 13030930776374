// source: proto/location/location_response.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_pagination_pb = require('../../proto/commons/pagination_pb.js');
goog.object.extend(proto, proto_commons_pagination_pb);
var proto_commons_weather_pb = require('../../proto/commons/weather_pb.js');
goog.object.extend(proto, proto_commons_weather_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_flow_common_pb = require('../../proto/commons/flow_common_pb.js');
goog.object.extend(proto, proto_commons_flow_common_pb);
var proto_models_media_pb = require('../../proto/models/media_pb.js');
goog.object.extend(proto, proto_models_media_pb);
var proto_models_country_pb = require('../../proto/models/country_pb.js');
goog.object.extend(proto, proto_models_country_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_airline_pb = require('../../proto/models/airline_pb.js');
goog.object.extend(proto, proto_models_airline_pb);
var proto_models_featured_location_pb = require('../../proto/models/featured_location_pb.js');
goog.object.extend(proto, proto_models_featured_location_pb);
var proto_models_connected_city_pb = require('../../proto/models/connected_city_pb.js');
goog.object.extend(proto, proto_models_connected_city_pb);
var proto_models_region_pb = require('../../proto/models/region_pb.js');
goog.object.extend(proto, proto_models_region_pb);
var proto_models_location_pb = require('../../proto/models/location_pb.js');
goog.object.extend(proto, proto_models_location_pb);
var proto_models_shard_pb = require('../../proto/models/shard_pb.js');
goog.object.extend(proto, proto_models_shard_pb);
var proto_models_preference_pb = require('../../proto/models/preference_pb.js');
goog.object.extend(proto, proto_models_preference_pb);
var proto_models_parent_location_pb = require('../../proto/models/parent_location_pb.js');
goog.object.extend(proto, proto_models_parent_location_pb);
goog.exportSymbol('proto.elude.proto.AirlineResponse', null, global);
goog.exportSymbol('proto.elude.proto.AirlinesResponse', null, global);
goog.exportSymbol('proto.elude.proto.AirportResponse', null, global);
goog.exportSymbol('proto.elude.proto.AirportsResponse', null, global);
goog.exportSymbol('proto.elude.proto.CitiesResponse', null, global);
goog.exportSymbol('proto.elude.proto.CityResponse', null, global);
goog.exportSymbol('proto.elude.proto.ConnectedCitiesResponse', null, global);
goog.exportSymbol('proto.elude.proto.ConnectedCityResponse', null, global);
goog.exportSymbol('proto.elude.proto.CountriesResponse', null, global);
goog.exportSymbol('proto.elude.proto.CountryResponse', null, global);
goog.exportSymbol('proto.elude.proto.FeaturedLocationResponse', null, global);
goog.exportSymbol('proto.elude.proto.FeaturedLocationsResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetAffordableDestinationsResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetAverageCostResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetDestinationCitiesResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetLocationsByIdentifiersResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetPreferencesResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetRecommendedCitiesResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetSearchLocationsResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetTopDestinationsResponse', null, global);
goog.exportSymbol('proto.elude.proto.GetWeatherByCoordinatesResponse', null, global);
goog.exportSymbol('proto.elude.proto.LocationResponse', null, global);
goog.exportSymbol('proto.elude.proto.LocationsResponse', null, global);
goog.exportSymbol('proto.elude.proto.ParentLocationResponse', null, global);
goog.exportSymbol('proto.elude.proto.ParentLocationsResponse', null, global);
goog.exportSymbol('proto.elude.proto.RegionResponse', null, global);
goog.exportSymbol('proto.elude.proto.RegionsResponse', null, global);
goog.exportSymbol('proto.elude.proto.ShardResponse', null, global);
goog.exportSymbol('proto.elude.proto.ShardsResponse', null, global);
goog.exportSymbol('proto.elude.proto.UpdateUserPreferencesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CityResponse.displayName = 'proto.elude.proto.CityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.CitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.CitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CitiesResponse.displayName = 'proto.elude.proto.CitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CountryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CountryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CountryResponse.displayName = 'proto.elude.proto.CountryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CountriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.CountriesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.CountriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CountriesResponse.displayName = 'proto.elude.proto.CountriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAverageCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAverageCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAverageCostResponse.displayName = 'proto.elude.proto.GetAverageCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetDestinationCitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetDestinationCitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetDestinationCitiesResponse.displayName = 'proto.elude.proto.GetDestinationCitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetTopDestinationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetTopDestinationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetTopDestinationsResponse.displayName = 'proto.elude.proto.GetTopDestinationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAffordableDestinationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAffordableDestinationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAffordableDestinationsResponse.displayName = 'proto.elude.proto.GetAffordableDestinationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AirportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.AirportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AirportResponse.displayName = 'proto.elude.proto.AirportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AirportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.AirportsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.AirportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AirportsResponse.displayName = 'proto.elude.proto.AirportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AirlineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.AirlineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AirlineResponse.displayName = 'proto.elude.proto.AirlineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AirlinesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.AirlinesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.AirlinesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AirlinesResponse.displayName = 'proto.elude.proto.AirlinesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.FeaturedLocationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.FeaturedLocationsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.FeaturedLocationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.FeaturedLocationsResponse.displayName = 'proto.elude.proto.FeaturedLocationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.FeaturedLocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.FeaturedLocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.FeaturedLocationResponse.displayName = 'proto.elude.proto.FeaturedLocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ConnectedCitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.ConnectedCitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.ConnectedCitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ConnectedCitiesResponse.displayName = 'proto.elude.proto.ConnectedCitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ConnectedCityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.ConnectedCityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ConnectedCityResponse.displayName = 'proto.elude.proto.ConnectedCityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetWeatherByCoordinatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetWeatherByCoordinatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetWeatherByCoordinatesResponse.displayName = 'proto.elude.proto.GetWeatherByCoordinatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RegionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RegionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RegionResponse.displayName = 'proto.elude.proto.RegionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RegionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.RegionsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.RegionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RegionsResponse.displayName = 'proto.elude.proto.RegionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.LocationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.LocationsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.LocationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.LocationsResponse.displayName = 'proto.elude.proto.LocationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.LocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.LocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.LocationResponse.displayName = 'proto.elude.proto.LocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetLocationsByIdentifiersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetLocationsByIdentifiersResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetLocationsByIdentifiersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetLocationsByIdentifiersResponse.displayName = 'proto.elude.proto.GetLocationsByIdentifiersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetSearchLocationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetSearchLocationsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetSearchLocationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetSearchLocationsResponse.displayName = 'proto.elude.proto.GetSearchLocationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetPreferencesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetPreferencesResponse.displayName = 'proto.elude.proto.GetPreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateUserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateUserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateUserPreferencesResponse.displayName = 'proto.elude.proto.UpdateUserPreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetRecommendedCitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetRecommendedCitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetRecommendedCitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetRecommendedCitiesResponse.displayName = 'proto.elude.proto.GetRecommendedCitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ShardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.ShardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ShardResponse.displayName = 'proto.elude.proto.ShardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ShardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.ShardsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.ShardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ShardsResponse.displayName = 'proto.elude.proto.ShardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ParentLocationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.ParentLocationsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.ParentLocationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ParentLocationsResponse.displayName = 'proto.elude.proto.ParentLocationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ParentLocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.ParentLocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ParentLocationResponse.displayName = 'proto.elude.proto.ParentLocationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    city: (f = msg.getCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CityResponse}
 */
proto.elude.proto.CityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CityResponse;
  return proto.elude.proto.CityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CityResponse}
 */
proto.elude.proto.CityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CityResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CityResponse} returns this
*/
proto.elude.proto.CityResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CityResponse} returns this
 */
proto.elude.proto.CityResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CityResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional City city = 2;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.CityResponse.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 2));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.CityResponse} returns this
*/
proto.elude.proto.CityResponse.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CityResponse} returns this
 */
proto.elude.proto.CityResponse.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CityResponse.prototype.hasCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CityResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CityResponse} returns this
*/
proto.elude.proto.CityResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CityResponse} returns this
 */
proto.elude.proto.CityResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CityResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.CitiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto_models_city_pb.City.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CitiesResponse}
 */
proto.elude.proto.CitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CitiesResponse;
  return proto.elude.proto.CitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CitiesResponse}
 */
proto.elude.proto.CitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CitiesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CitiesResponse} returns this
*/
proto.elude.proto.CitiesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CitiesResponse} returns this
 */
proto.elude.proto.CitiesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CitiesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated City cities = 2;
 * @return {!Array<!proto.elude.proto.City>}
 */
proto.elude.proto.CitiesResponse.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.elude.proto.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_city_pb.City, 2));
};


/**
 * @param {!Array<!proto.elude.proto.City>} value
 * @return {!proto.elude.proto.CitiesResponse} returns this
*/
proto.elude.proto.CitiesResponse.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.City}
 */
proto.elude.proto.CitiesResponse.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.CitiesResponse} returns this
 */
proto.elude.proto.CitiesResponse.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CitiesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CitiesResponse} returns this
*/
proto.elude.proto.CitiesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CitiesResponse} returns this
 */
proto.elude.proto.CitiesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CitiesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.CitiesResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.CitiesResponse} returns this
*/
proto.elude.proto.CitiesResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CitiesResponse} returns this
 */
proto.elude.proto.CitiesResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CitiesResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CountryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CountryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CountryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CountryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    country: (f = msg.getCountry()) && proto_models_country_pb.Country.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CountryResponse}
 */
proto.elude.proto.CountryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CountryResponse;
  return proto.elude.proto.CountryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CountryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CountryResponse}
 */
proto.elude.proto.CountryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CountryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CountryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CountryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CountryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CountryResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CountryResponse} returns this
*/
proto.elude.proto.CountryResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CountryResponse} returns this
 */
proto.elude.proto.CountryResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CountryResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Country country = 2;
 * @return {?proto.elude.proto.Country}
 */
proto.elude.proto.CountryResponse.prototype.getCountry = function() {
  return /** @type{?proto.elude.proto.Country} */ (
    jspb.Message.getWrapperField(this, proto_models_country_pb.Country, 2));
};


/**
 * @param {?proto.elude.proto.Country|undefined} value
 * @return {!proto.elude.proto.CountryResponse} returns this
*/
proto.elude.proto.CountryResponse.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CountryResponse} returns this
 */
proto.elude.proto.CountryResponse.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CountryResponse.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CountryResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CountryResponse} returns this
*/
proto.elude.proto.CountryResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CountryResponse} returns this
 */
proto.elude.proto.CountryResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CountryResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.CountriesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CountriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CountriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CountriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CountriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    countriesList: jspb.Message.toObjectList(msg.getCountriesList(),
    proto_models_country_pb.Country.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CountriesResponse}
 */
proto.elude.proto.CountriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CountriesResponse;
  return proto.elude.proto.CountriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CountriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CountriesResponse}
 */
proto.elude.proto.CountriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.addCountries(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CountriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CountriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CountriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CountriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CountriesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CountriesResponse} returns this
*/
proto.elude.proto.CountriesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CountriesResponse} returns this
 */
proto.elude.proto.CountriesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CountriesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Country countries = 2;
 * @return {!Array<!proto.elude.proto.Country>}
 */
proto.elude.proto.CountriesResponse.prototype.getCountriesList = function() {
  return /** @type{!Array<!proto.elude.proto.Country>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_country_pb.Country, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Country>} value
 * @return {!proto.elude.proto.CountriesResponse} returns this
*/
proto.elude.proto.CountriesResponse.prototype.setCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Country=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Country}
 */
proto.elude.proto.CountriesResponse.prototype.addCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Country, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.CountriesResponse} returns this
 */
proto.elude.proto.CountriesResponse.prototype.clearCountriesList = function() {
  return this.setCountriesList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CountriesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CountriesResponse} returns this
*/
proto.elude.proto.CountriesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CountriesResponse} returns this
 */
proto.elude.proto.CountriesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CountriesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.CountriesResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.CountriesResponse} returns this
*/
proto.elude.proto.CountriesResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CountriesResponse} returns this
 */
proto.elude.proto.CountriesResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CountriesResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAverageCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAverageCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAverageCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAverageCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAverageCostResponse}
 */
proto.elude.proto.GetAverageCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAverageCostResponse;
  return proto.elude.proto.GetAverageCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAverageCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAverageCostResponse}
 */
proto.elude.proto.GetAverageCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAverageCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAverageCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAverageCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAverageCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAverageCostResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAverageCostResponse} returns this
*/
proto.elude.proto.GetAverageCostResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAverageCostResponse} returns this
 */
proto.elude.proto.GetAverageCostResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAverageCostResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetAverageCostResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetAverageCostResponse} returns this
*/
proto.elude.proto.GetAverageCostResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAverageCostResponse} returns this
 */
proto.elude.proto.GetAverageCostResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAverageCostResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetDestinationCitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetDestinationCitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetDestinationCitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetDestinationCitiesResponse}
 */
proto.elude.proto.GetDestinationCitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetDestinationCitiesResponse;
  return proto.elude.proto.GetDestinationCitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetDestinationCitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetDestinationCitiesResponse}
 */
proto.elude.proto.GetDestinationCitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetDestinationCitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetDestinationCitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetDestinationCitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetDestinationCitiesResponse} returns this
*/
proto.elude.proto.GetDestinationCitiesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetDestinationCitiesResponse} returns this
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetDestinationCitiesResponse} returns this
*/
proto.elude.proto.GetDestinationCitiesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetDestinationCitiesResponse} returns this
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetDestinationCitiesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetTopDestinationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetTopDestinationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTopDestinationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetTopDestinationsResponse}
 */
proto.elude.proto.GetTopDestinationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetTopDestinationsResponse;
  return proto.elude.proto.GetTopDestinationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetTopDestinationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetTopDestinationsResponse}
 */
proto.elude.proto.GetTopDestinationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetTopDestinationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetTopDestinationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTopDestinationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetTopDestinationsResponse} returns this
*/
proto.elude.proto.GetTopDestinationsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTopDestinationsResponse} returns this
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetTopDestinationsResponse} returns this
*/
proto.elude.proto.GetTopDestinationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTopDestinationsResponse} returns this
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTopDestinationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAffordableDestinationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAffordableDestinationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAffordableDestinationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAffordableDestinationsResponse}
 */
proto.elude.proto.GetAffordableDestinationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAffordableDestinationsResponse;
  return proto.elude.proto.GetAffordableDestinationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAffordableDestinationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAffordableDestinationsResponse}
 */
proto.elude.proto.GetAffordableDestinationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAffordableDestinationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAffordableDestinationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAffordableDestinationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAffordableDestinationsResponse} returns this
*/
proto.elude.proto.GetAffordableDestinationsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAffordableDestinationsResponse} returns this
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetAffordableDestinationsResponse} returns this
*/
proto.elude.proto.GetAffordableDestinationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAffordableDestinationsResponse} returns this
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAffordableDestinationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AirportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AirportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AirportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airport: (f = msg.getAirport()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AirportResponse}
 */
proto.elude.proto.AirportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AirportResponse;
  return proto.elude.proto.AirportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AirportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AirportResponse}
 */
proto.elude.proto.AirportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setAirport(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AirportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AirportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AirportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirport();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AirportResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AirportResponse} returns this
*/
proto.elude.proto.AirportResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirportResponse} returns this
 */
proto.elude.proto.AirportResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirportResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Airport airport = 2;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.AirportResponse.prototype.getAirport = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 2));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.AirportResponse} returns this
*/
proto.elude.proto.AirportResponse.prototype.setAirport = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirportResponse} returns this
 */
proto.elude.proto.AirportResponse.prototype.clearAirport = function() {
  return this.setAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirportResponse.prototype.hasAirport = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AirportResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AirportResponse} returns this
*/
proto.elude.proto.AirportResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirportResponse} returns this
 */
proto.elude.proto.AirportResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirportResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.AirportsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AirportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AirportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AirportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airportsList: jspb.Message.toObjectList(msg.getAirportsList(),
    proto_models_city_pb.Airport.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AirportsResponse}
 */
proto.elude.proto.AirportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AirportsResponse;
  return proto.elude.proto.AirportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AirportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AirportsResponse}
 */
proto.elude.proto.AirportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.addAirports(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AirportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AirportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AirportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AirportsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AirportsResponse} returns this
*/
proto.elude.proto.AirportsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirportsResponse} returns this
 */
proto.elude.proto.AirportsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirportsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Airport airports = 2;
 * @return {!Array<!proto.elude.proto.Airport>}
 */
proto.elude.proto.AirportsResponse.prototype.getAirportsList = function() {
  return /** @type{!Array<!proto.elude.proto.Airport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_city_pb.Airport, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Airport>} value
 * @return {!proto.elude.proto.AirportsResponse} returns this
*/
proto.elude.proto.AirportsResponse.prototype.setAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Airport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Airport}
 */
proto.elude.proto.AirportsResponse.prototype.addAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Airport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.AirportsResponse} returns this
 */
proto.elude.proto.AirportsResponse.prototype.clearAirportsList = function() {
  return this.setAirportsList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AirportsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AirportsResponse} returns this
*/
proto.elude.proto.AirportsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirportsResponse} returns this
 */
proto.elude.proto.AirportsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirportsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.AirportsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.AirportsResponse} returns this
*/
proto.elude.proto.AirportsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirportsResponse} returns this
 */
proto.elude.proto.AirportsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirportsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AirlineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AirlineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AirlineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirlineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AirlineResponse}
 */
proto.elude.proto.AirlineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AirlineResponse;
  return proto.elude.proto.AirlineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AirlineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AirlineResponse}
 */
proto.elude.proto.AirlineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AirlineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AirlineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AirlineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirlineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AirlineResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AirlineResponse} returns this
*/
proto.elude.proto.AirlineResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirlineResponse} returns this
 */
proto.elude.proto.AirlineResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirlineResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Airline airline = 2;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.AirlineResponse.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 2));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.AirlineResponse} returns this
*/
proto.elude.proto.AirlineResponse.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirlineResponse} returns this
 */
proto.elude.proto.AirlineResponse.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirlineResponse.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AirlineResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AirlineResponse} returns this
*/
proto.elude.proto.AirlineResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirlineResponse} returns this
 */
proto.elude.proto.AirlineResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirlineResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.AirlinesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AirlinesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AirlinesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AirlinesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirlinesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airlinesList: jspb.Message.toObjectList(msg.getAirlinesList(),
    proto_models_airline_pb.Airline.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AirlinesResponse}
 */
proto.elude.proto.AirlinesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AirlinesResponse;
  return proto.elude.proto.AirlinesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AirlinesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AirlinesResponse}
 */
proto.elude.proto.AirlinesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.addAirlines(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AirlinesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AirlinesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AirlinesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AirlinesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AirlinesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AirlinesResponse} returns this
*/
proto.elude.proto.AirlinesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirlinesResponse} returns this
 */
proto.elude.proto.AirlinesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirlinesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Airline airlines = 2;
 * @return {!Array<!proto.elude.proto.Airline>}
 */
proto.elude.proto.AirlinesResponse.prototype.getAirlinesList = function() {
  return /** @type{!Array<!proto.elude.proto.Airline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_airline_pb.Airline, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Airline>} value
 * @return {!proto.elude.proto.AirlinesResponse} returns this
*/
proto.elude.proto.AirlinesResponse.prototype.setAirlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Airline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Airline}
 */
proto.elude.proto.AirlinesResponse.prototype.addAirlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Airline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.AirlinesResponse} returns this
 */
proto.elude.proto.AirlinesResponse.prototype.clearAirlinesList = function() {
  return this.setAirlinesList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AirlinesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AirlinesResponse} returns this
*/
proto.elude.proto.AirlinesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirlinesResponse} returns this
 */
proto.elude.proto.AirlinesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirlinesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.AirlinesResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.AirlinesResponse} returns this
*/
proto.elude.proto.AirlinesResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AirlinesResponse} returns this
 */
proto.elude.proto.AirlinesResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AirlinesResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.FeaturedLocationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.FeaturedLocationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.FeaturedLocationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FeaturedLocationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    featuredLocationsList: jspb.Message.toObjectList(msg.getFeaturedLocationsList(),
    proto_models_featured_location_pb.FeaturedLocation.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.FeaturedLocationsResponse}
 */
proto.elude.proto.FeaturedLocationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.FeaturedLocationsResponse;
  return proto.elude.proto.FeaturedLocationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.FeaturedLocationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.FeaturedLocationsResponse}
 */
proto.elude.proto.FeaturedLocationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_featured_location_pb.FeaturedLocation;
      reader.readMessage(value,proto_models_featured_location_pb.FeaturedLocation.deserializeBinaryFromReader);
      msg.addFeaturedLocations(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.FeaturedLocationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.FeaturedLocationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FeaturedLocationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_featured_location_pb.FeaturedLocation.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
*/
proto.elude.proto.FeaturedLocationsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FeaturedLocation featured_locations = 2;
 * @return {!Array<!proto.elude.proto.FeaturedLocation>}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.getFeaturedLocationsList = function() {
  return /** @type{!Array<!proto.elude.proto.FeaturedLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_featured_location_pb.FeaturedLocation, 2));
};


/**
 * @param {!Array<!proto.elude.proto.FeaturedLocation>} value
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
*/
proto.elude.proto.FeaturedLocationsResponse.prototype.setFeaturedLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.FeaturedLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.FeaturedLocation}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.addFeaturedLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.FeaturedLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.clearFeaturedLocationsList = function() {
  return this.setFeaturedLocationsList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
*/
proto.elude.proto.FeaturedLocationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
*/
proto.elude.proto.FeaturedLocationsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocationsResponse} returns this
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocationsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.FeaturedLocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.FeaturedLocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FeaturedLocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    featuredLocation: (f = msg.getFeaturedLocation()) && proto_models_featured_location_pb.FeaturedLocation.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.FeaturedLocationResponse}
 */
proto.elude.proto.FeaturedLocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.FeaturedLocationResponse;
  return proto.elude.proto.FeaturedLocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.FeaturedLocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.FeaturedLocationResponse}
 */
proto.elude.proto.FeaturedLocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_featured_location_pb.FeaturedLocation;
      reader.readMessage(value,proto_models_featured_location_pb.FeaturedLocation.deserializeBinaryFromReader);
      msg.setFeaturedLocation(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.FeaturedLocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.FeaturedLocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.FeaturedLocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_featured_location_pb.FeaturedLocation.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.FeaturedLocationResponse} returns this
*/
proto.elude.proto.FeaturedLocationResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocationResponse} returns this
 */
proto.elude.proto.FeaturedLocationResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FeaturedLocation featured_location = 2;
 * @return {?proto.elude.proto.FeaturedLocation}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.getFeaturedLocation = function() {
  return /** @type{?proto.elude.proto.FeaturedLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_featured_location_pb.FeaturedLocation, 2));
};


/**
 * @param {?proto.elude.proto.FeaturedLocation|undefined} value
 * @return {!proto.elude.proto.FeaturedLocationResponse} returns this
*/
proto.elude.proto.FeaturedLocationResponse.prototype.setFeaturedLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocationResponse} returns this
 */
proto.elude.proto.FeaturedLocationResponse.prototype.clearFeaturedLocation = function() {
  return this.setFeaturedLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.hasFeaturedLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.FeaturedLocationResponse} returns this
*/
proto.elude.proto.FeaturedLocationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.FeaturedLocationResponse} returns this
 */
proto.elude.proto.FeaturedLocationResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.FeaturedLocationResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.ConnectedCitiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ConnectedCitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ConnectedCitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ConnectedCitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    connectedCitiesList: jspb.Message.toObjectList(msg.getConnectedCitiesList(),
    proto_models_connected_city_pb.ConnectedCity.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ConnectedCitiesResponse}
 */
proto.elude.proto.ConnectedCitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ConnectedCitiesResponse;
  return proto.elude.proto.ConnectedCitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ConnectedCitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ConnectedCitiesResponse}
 */
proto.elude.proto.ConnectedCitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_connected_city_pb.ConnectedCity;
      reader.readMessage(value,proto_models_connected_city_pb.ConnectedCity.deserializeBinaryFromReader);
      msg.addConnectedCities(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ConnectedCitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ConnectedCitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ConnectedCitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getConnectedCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_connected_city_pb.ConnectedCity.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
*/
proto.elude.proto.ConnectedCitiesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ConnectedCity connected_cities = 2;
 * @return {!Array<!proto.elude.proto.ConnectedCity>}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.getConnectedCitiesList = function() {
  return /** @type{!Array<!proto.elude.proto.ConnectedCity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_connected_city_pb.ConnectedCity, 2));
};


/**
 * @param {!Array<!proto.elude.proto.ConnectedCity>} value
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
*/
proto.elude.proto.ConnectedCitiesResponse.prototype.setConnectedCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.ConnectedCity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.ConnectedCity}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.addConnectedCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.ConnectedCity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.clearConnectedCitiesList = function() {
  return this.setConnectedCitiesList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
*/
proto.elude.proto.ConnectedCitiesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
*/
proto.elude.proto.ConnectedCitiesResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ConnectedCitiesResponse} returns this
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ConnectedCitiesResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ConnectedCityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ConnectedCityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ConnectedCityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ConnectedCityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    connectedCity: (f = msg.getConnectedCity()) && proto_models_connected_city_pb.ConnectedCity.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ConnectedCityResponse}
 */
proto.elude.proto.ConnectedCityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ConnectedCityResponse;
  return proto.elude.proto.ConnectedCityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ConnectedCityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ConnectedCityResponse}
 */
proto.elude.proto.ConnectedCityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_connected_city_pb.ConnectedCity;
      reader.readMessage(value,proto_models_connected_city_pb.ConnectedCity.deserializeBinaryFromReader);
      msg.setConnectedCity(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ConnectedCityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ConnectedCityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ConnectedCityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ConnectedCityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getConnectedCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_connected_city_pb.ConnectedCity.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ConnectedCityResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ConnectedCityResponse} returns this
*/
proto.elude.proto.ConnectedCityResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ConnectedCityResponse} returns this
 */
proto.elude.proto.ConnectedCityResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ConnectedCityResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConnectedCity connected_city = 2;
 * @return {?proto.elude.proto.ConnectedCity}
 */
proto.elude.proto.ConnectedCityResponse.prototype.getConnectedCity = function() {
  return /** @type{?proto.elude.proto.ConnectedCity} */ (
    jspb.Message.getWrapperField(this, proto_models_connected_city_pb.ConnectedCity, 2));
};


/**
 * @param {?proto.elude.proto.ConnectedCity|undefined} value
 * @return {!proto.elude.proto.ConnectedCityResponse} returns this
*/
proto.elude.proto.ConnectedCityResponse.prototype.setConnectedCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ConnectedCityResponse} returns this
 */
proto.elude.proto.ConnectedCityResponse.prototype.clearConnectedCity = function() {
  return this.setConnectedCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ConnectedCityResponse.prototype.hasConnectedCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ConnectedCityResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ConnectedCityResponse} returns this
*/
proto.elude.proto.ConnectedCityResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ConnectedCityResponse} returns this
 */
proto.elude.proto.ConnectedCityResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ConnectedCityResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetWeatherByCoordinatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetWeatherByCoordinatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    weather: (f = msg.getWeather()) && proto_commons_weather_pb.Weather.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetWeatherByCoordinatesResponse;
  return proto.elude.proto.GetWeatherByCoordinatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetWeatherByCoordinatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_weather_pb.Weather;
      reader.readMessage(value,proto_commons_weather_pb.Weather.deserializeBinaryFromReader);
      msg.setWeather(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetWeatherByCoordinatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetWeatherByCoordinatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getWeather();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_weather_pb.Weather.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Weather weather = 2;
 * @return {?proto.elude.proto.Weather}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.getWeather = function() {
  return /** @type{?proto.elude.proto.Weather} */ (
    jspb.Message.getWrapperField(this, proto_commons_weather_pb.Weather, 2));
};


/**
 * @param {?proto.elude.proto.Weather|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.setWeather = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.clearWeather = function() {
  return this.setWeather(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.hasWeather = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesResponse} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RegionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RegionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RegionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RegionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    region: (f = msg.getRegion()) && proto_models_region_pb.Region.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RegionResponse}
 */
proto.elude.proto.RegionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RegionResponse;
  return proto.elude.proto.RegionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RegionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RegionResponse}
 */
proto.elude.proto.RegionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_region_pb.Region;
      reader.readMessage(value,proto_models_region_pb.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RegionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RegionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RegionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RegionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_region_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RegionResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RegionResponse} returns this
*/
proto.elude.proto.RegionResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RegionResponse} returns this
 */
proto.elude.proto.RegionResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RegionResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Region region = 2;
 * @return {?proto.elude.proto.Region}
 */
proto.elude.proto.RegionResponse.prototype.getRegion = function() {
  return /** @type{?proto.elude.proto.Region} */ (
    jspb.Message.getWrapperField(this, proto_models_region_pb.Region, 2));
};


/**
 * @param {?proto.elude.proto.Region|undefined} value
 * @return {!proto.elude.proto.RegionResponse} returns this
*/
proto.elude.proto.RegionResponse.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RegionResponse} returns this
 */
proto.elude.proto.RegionResponse.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RegionResponse.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.RegionResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.RegionResponse} returns this
*/
proto.elude.proto.RegionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RegionResponse} returns this
 */
proto.elude.proto.RegionResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RegionResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.RegionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RegionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RegionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RegionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RegionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    regionsList: jspb.Message.toObjectList(msg.getRegionsList(),
    proto_models_region_pb.Region.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RegionsResponse}
 */
proto.elude.proto.RegionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RegionsResponse;
  return proto.elude.proto.RegionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RegionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RegionsResponse}
 */
proto.elude.proto.RegionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_region_pb.Region;
      reader.readMessage(value,proto_models_region_pb.Region.deserializeBinaryFromReader);
      msg.addRegions(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RegionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RegionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RegionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RegionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_region_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RegionsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RegionsResponse} returns this
*/
proto.elude.proto.RegionsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RegionsResponse} returns this
 */
proto.elude.proto.RegionsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RegionsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Region regions = 2;
 * @return {!Array<!proto.elude.proto.Region>}
 */
proto.elude.proto.RegionsResponse.prototype.getRegionsList = function() {
  return /** @type{!Array<!proto.elude.proto.Region>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_region_pb.Region, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Region>} value
 * @return {!proto.elude.proto.RegionsResponse} returns this
*/
proto.elude.proto.RegionsResponse.prototype.setRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Region=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Region}
 */
proto.elude.proto.RegionsResponse.prototype.addRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Region, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.RegionsResponse} returns this
 */
proto.elude.proto.RegionsResponse.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.RegionsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.RegionsResponse} returns this
*/
proto.elude.proto.RegionsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RegionsResponse} returns this
 */
proto.elude.proto.RegionsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RegionsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.RegionsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.RegionsResponse} returns this
*/
proto.elude.proto.RegionsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RegionsResponse} returns this
 */
proto.elude.proto.RegionsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RegionsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.LocationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.LocationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.LocationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.LocationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto_models_location_pb.Location.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.LocationsResponse}
 */
proto.elude.proto.LocationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.LocationsResponse;
  return proto.elude.proto.LocationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.LocationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.LocationsResponse}
 */
proto.elude.proto.LocationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.LocationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.LocationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.LocationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.LocationsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.LocationsResponse} returns this
*/
proto.elude.proto.LocationsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationsResponse} returns this
 */
proto.elude.proto.LocationsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Location locations = 2;
 * @return {!Array<!proto.elude.proto.Location>}
 */
proto.elude.proto.LocationsResponse.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.elude.proto.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_location_pb.Location, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Location>} value
 * @return {!proto.elude.proto.LocationsResponse} returns this
*/
proto.elude.proto.LocationsResponse.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Location}
 */
proto.elude.proto.LocationsResponse.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.LocationsResponse} returns this
 */
proto.elude.proto.LocationsResponse.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.LocationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.LocationsResponse} returns this
*/
proto.elude.proto.LocationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationsResponse} returns this
 */
proto.elude.proto.LocationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.LocationsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.LocationsResponse} returns this
*/
proto.elude.proto.LocationsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationsResponse} returns this
 */
proto.elude.proto.LocationsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.LocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.LocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.LocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.LocationResponse}
 */
proto.elude.proto.LocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.LocationResponse;
  return proto.elude.proto.LocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.LocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.LocationResponse}
 */
proto.elude.proto.LocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.LocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.LocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.LocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.LocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.LocationResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.LocationResponse} returns this
*/
proto.elude.proto.LocationResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationResponse} returns this
 */
proto.elude.proto.LocationResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.LocationResponse.prototype.getLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 2));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.LocationResponse} returns this
*/
proto.elude.proto.LocationResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationResponse} returns this
 */
proto.elude.proto.LocationResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.LocationResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.LocationResponse} returns this
*/
proto.elude.proto.LocationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.LocationResponse} returns this
 */
proto.elude.proto.LocationResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.LocationResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetLocationsByIdentifiersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetLocationsByIdentifiersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto_models_city_pb.City.toObject, includeInstance),
    airportsList: jspb.Message.toObjectList(msg.getAirportsList(),
    proto_models_city_pb.Airport.toObject, includeInstance),
    regionsList: jspb.Message.toObjectList(msg.getRegionsList(),
    proto_models_region_pb.Region.toObject, includeInstance),
    countriesList: jspb.Message.toObjectList(msg.getCountriesList(),
    proto_models_country_pb.Country.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetLocationsByIdentifiersResponse;
  return proto.elude.proto.GetLocationsByIdentifiersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetLocationsByIdentifiersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    case 5:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.addAirports(value);
      break;
    case 6:
      var value = new proto_models_region_pb.Region;
      reader.readMessage(value,proto_models_region_pb.Region.deserializeBinaryFromReader);
      msg.addRegions(value);
      break;
    case 7:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.addCountries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetLocationsByIdentifiersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetLocationsByIdentifiersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_models_region_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 2));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated City cities = 4;
 * @return {!Array<!proto.elude.proto.City>}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.elude.proto.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_city_pb.City, 4));
};


/**
 * @param {!Array<!proto.elude.proto.City>} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.elude.proto.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.City}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.elude.proto.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};


/**
 * repeated Airport airports = 5;
 * @return {!Array<!proto.elude.proto.Airport>}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getAirportsList = function() {
  return /** @type{!Array<!proto.elude.proto.Airport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_city_pb.Airport, 5));
};


/**
 * @param {!Array<!proto.elude.proto.Airport>} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.elude.proto.Airport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Airport}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.addAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.elude.proto.Airport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearAirportsList = function() {
  return this.setAirportsList([]);
};


/**
 * repeated Region regions = 6;
 * @return {!Array<!proto.elude.proto.Region>}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getRegionsList = function() {
  return /** @type{!Array<!proto.elude.proto.Region>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_region_pb.Region, 6));
};


/**
 * @param {!Array<!proto.elude.proto.Region>} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.elude.proto.Region=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Region}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.addRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.elude.proto.Region, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};


/**
 * repeated Country countries = 7;
 * @return {!Array<!proto.elude.proto.Country>}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.getCountriesList = function() {
  return /** @type{!Array<!proto.elude.proto.Country>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_country_pb.Country, 7));
};


/**
 * @param {!Array<!proto.elude.proto.Country>} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.setCountriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.elude.proto.Country=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Country}
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.addCountries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.elude.proto.Country, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersResponse} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersResponse.prototype.clearCountriesList = function() {
  return this.setCountriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetSearchLocationsResponse.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetSearchLocationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetSearchLocationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSearchLocationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto_commons_flow_common_pb.SearchLocation.toObject, includeInstance),
    departureLocation: (f = msg.getDepartureLocation()) && proto_commons_flow_common_pb.SearchLocation.toObject(includeInstance, f),
    shardConnectionsList: jspb.Message.toObjectList(msg.getShardConnectionsList(),
    proto_models_shard_pb.ShardConnections.toObject, includeInstance),
    totalDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalTravelers: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetSearchLocationsResponse}
 */
proto.elude.proto.GetSearchLocationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetSearchLocationsResponse;
  return proto.elude.proto.GetSearchLocationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetSearchLocationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetSearchLocationsResponse}
 */
proto.elude.proto.GetSearchLocationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_flow_common_pb.SearchLocation;
      reader.readMessage(value,proto_commons_flow_common_pb.SearchLocation.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.SearchLocation;
      reader.readMessage(value,proto_commons_flow_common_pb.SearchLocation.deserializeBinaryFromReader);
      msg.setDepartureLocation(value);
      break;
    case 4:
      var value = new proto_models_shard_pb.ShardConnections;
      reader.readMessage(value,proto_models_shard_pb.ShardConnections.deserializeBinaryFromReader);
      msg.addShardConnections(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDays(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTravelers(value);
      break;
    case 7:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetSearchLocationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetSearchLocationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSearchLocationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_commons_flow_common_pb.SearchLocation.serializeBinaryToWriter
    );
  }
  f = message.getDepartureLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.SearchLocation.serializeBinaryToWriter
    );
  }
  f = message.getShardConnectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_models_shard_pb.ShardConnections.serializeBinaryToWriter
    );
  }
  f = message.getTotalDays();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalTravelers();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
*/
proto.elude.proto.GetSearchLocationsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SearchLocation locations = 2;
 * @return {!Array<!proto.elude.proto.SearchLocation>}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.elude.proto.SearchLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.SearchLocation, 2));
};


/**
 * @param {!Array<!proto.elude.proto.SearchLocation>} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
*/
proto.elude.proto.GetSearchLocationsResponse.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.SearchLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.SearchLocation}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.SearchLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * optional SearchLocation departure_location = 3;
 * @return {?proto.elude.proto.SearchLocation}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getDepartureLocation = function() {
  return /** @type{?proto.elude.proto.SearchLocation} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.SearchLocation, 3));
};


/**
 * @param {?proto.elude.proto.SearchLocation|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
*/
proto.elude.proto.GetSearchLocationsResponse.prototype.setDepartureLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.clearDepartureLocation = function() {
  return this.setDepartureLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.hasDepartureLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ShardConnections shard_connections = 4;
 * @return {!Array<!proto.elude.proto.ShardConnections>}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getShardConnectionsList = function() {
  return /** @type{!Array<!proto.elude.proto.ShardConnections>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_shard_pb.ShardConnections, 4));
};


/**
 * @param {!Array<!proto.elude.proto.ShardConnections>} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
*/
proto.elude.proto.GetSearchLocationsResponse.prototype.setShardConnectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.elude.proto.ShardConnections=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.ShardConnections}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.addShardConnections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.elude.proto.ShardConnections, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.clearShardConnectionsList = function() {
  return this.setShardConnectionsList([]);
};


/**
 * optional int32 total_days = 5;
 * @return {number}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getTotalDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.setTotalDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_travelers = 6;
 * @return {number}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getTotalTravelers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.setTotalTravelers = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Status status = 7;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 7));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
*/
proto.elude.proto.GetSearchLocationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsResponse} returns this
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetPreferencesResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    preferencesList: jspb.Message.toObjectList(msg.getPreferencesList(),
    proto_models_preference_pb.Preference.toObject, includeInstance),
    userPreferencesList: jspb.Message.toObjectList(msg.getUserPreferencesList(),
    proto_models_preference_pb.Preference.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetPreferencesResponse}
 */
proto.elude.proto.GetPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetPreferencesResponse;
  return proto.elude.proto.GetPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetPreferencesResponse}
 */
proto.elude.proto.GetPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_preference_pb.Preference;
      reader.readMessage(value,proto_models_preference_pb.Preference.deserializeBinaryFromReader);
      msg.addPreferences(value);
      break;
    case 3:
      var value = new proto_models_preference_pb.Preference;
      reader.readMessage(value,proto_models_preference_pb.Preference.deserializeBinaryFromReader);
      msg.addUserPreferences(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_preference_pb.Preference.serializeBinaryToWriter
    );
  }
  f = message.getUserPreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_models_preference_pb.Preference.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetPreferencesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
*/
proto.elude.proto.GetPreferencesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
 */
proto.elude.proto.GetPreferencesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPreferencesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Preference preferences = 2;
 * @return {!Array<!proto.elude.proto.Preference>}
 */
proto.elude.proto.GetPreferencesResponse.prototype.getPreferencesList = function() {
  return /** @type{!Array<!proto.elude.proto.Preference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_preference_pb.Preference, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Preference>} value
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
*/
proto.elude.proto.GetPreferencesResponse.prototype.setPreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Preference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Preference}
 */
proto.elude.proto.GetPreferencesResponse.prototype.addPreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Preference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
 */
proto.elude.proto.GetPreferencesResponse.prototype.clearPreferencesList = function() {
  return this.setPreferencesList([]);
};


/**
 * repeated Preference user_preferences = 3;
 * @return {!Array<!proto.elude.proto.Preference>}
 */
proto.elude.proto.GetPreferencesResponse.prototype.getUserPreferencesList = function() {
  return /** @type{!Array<!proto.elude.proto.Preference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_preference_pb.Preference, 3));
};


/**
 * @param {!Array<!proto.elude.proto.Preference>} value
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
*/
proto.elude.proto.GetPreferencesResponse.prototype.setUserPreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.elude.proto.Preference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Preference}
 */
proto.elude.proto.GetPreferencesResponse.prototype.addUserPreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.elude.proto.Preference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
 */
proto.elude.proto.GetPreferencesResponse.prototype.clearUserPreferencesList = function() {
  return this.setUserPreferencesList([]);
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetPreferencesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
*/
proto.elude.proto.GetPreferencesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPreferencesResponse} returns this
 */
proto.elude.proto.GetPreferencesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPreferencesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateUserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateUserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateUserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateUserPreferencesResponse}
 */
proto.elude.proto.UpdateUserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateUserPreferencesResponse;
  return proto.elude.proto.UpdateUserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateUserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateUserPreferencesResponse}
 */
proto.elude.proto.UpdateUserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateUserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateUserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateUserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateUserPreferencesResponse} returns this
*/
proto.elude.proto.UpdateUserPreferencesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateUserPreferencesResponse} returns this
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.UpdateUserPreferencesResponse} returns this
*/
proto.elude.proto.UpdateUserPreferencesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateUserPreferencesResponse} returns this
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateUserPreferencesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetRecommendedCitiesResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetRecommendedCitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetRecommendedCitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRecommendedCitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto_models_city_pb.City.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse}
 */
proto.elude.proto.GetRecommendedCitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetRecommendedCitiesResponse;
  return proto.elude.proto.GetRecommendedCitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetRecommendedCitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse}
 */
proto.elude.proto.GetRecommendedCitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetRecommendedCitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetRecommendedCitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRecommendedCitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse} returns this
*/
proto.elude.proto.GetRecommendedCitiesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse} returns this
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 2));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse} returns this
*/
proto.elude.proto.GetRecommendedCitiesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse} returns this
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated City cities = 3;
 * @return {!Array<!proto.elude.proto.City>}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.elude.proto.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_city_pb.City, 3));
};


/**
 * @param {!Array<!proto.elude.proto.City>} value
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse} returns this
*/
proto.elude.proto.GetRecommendedCitiesResponse.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.elude.proto.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.City}
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.elude.proto.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetRecommendedCitiesResponse} returns this
 */
proto.elude.proto.GetRecommendedCitiesResponse.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ShardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ShardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ShardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ShardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    shard: (f = msg.getShard()) && proto_models_shard_pb.Shard.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ShardResponse}
 */
proto.elude.proto.ShardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ShardResponse;
  return proto.elude.proto.ShardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ShardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ShardResponse}
 */
proto.elude.proto.ShardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_shard_pb.Shard;
      reader.readMessage(value,proto_models_shard_pb.Shard.deserializeBinaryFromReader);
      msg.setShard(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ShardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ShardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ShardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ShardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getShard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_shard_pb.Shard.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ShardResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ShardResponse} returns this
*/
proto.elude.proto.ShardResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ShardResponse} returns this
 */
proto.elude.proto.ShardResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ShardResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Shard shard = 2;
 * @return {?proto.elude.proto.Shard}
 */
proto.elude.proto.ShardResponse.prototype.getShard = function() {
  return /** @type{?proto.elude.proto.Shard} */ (
    jspb.Message.getWrapperField(this, proto_models_shard_pb.Shard, 2));
};


/**
 * @param {?proto.elude.proto.Shard|undefined} value
 * @return {!proto.elude.proto.ShardResponse} returns this
*/
proto.elude.proto.ShardResponse.prototype.setShard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ShardResponse} returns this
 */
proto.elude.proto.ShardResponse.prototype.clearShard = function() {
  return this.setShard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ShardResponse.prototype.hasShard = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ShardResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ShardResponse} returns this
*/
proto.elude.proto.ShardResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ShardResponse} returns this
 */
proto.elude.proto.ShardResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ShardResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.ShardsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ShardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ShardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ShardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ShardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    shardsList: jspb.Message.toObjectList(msg.getShardsList(),
    proto_models_shard_pb.Shard.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ShardsResponse}
 */
proto.elude.proto.ShardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ShardsResponse;
  return proto.elude.proto.ShardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ShardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ShardsResponse}
 */
proto.elude.proto.ShardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_shard_pb.Shard;
      reader.readMessage(value,proto_models_shard_pb.Shard.deserializeBinaryFromReader);
      msg.addShards(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ShardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ShardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ShardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ShardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getShardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_shard_pb.Shard.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ShardsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ShardsResponse} returns this
*/
proto.elude.proto.ShardsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ShardsResponse} returns this
 */
proto.elude.proto.ShardsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ShardsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Shard shards = 2;
 * @return {!Array<!proto.elude.proto.Shard>}
 */
proto.elude.proto.ShardsResponse.prototype.getShardsList = function() {
  return /** @type{!Array<!proto.elude.proto.Shard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_shard_pb.Shard, 2));
};


/**
 * @param {!Array<!proto.elude.proto.Shard>} value
 * @return {!proto.elude.proto.ShardsResponse} returns this
*/
proto.elude.proto.ShardsResponse.prototype.setShardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.Shard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Shard}
 */
proto.elude.proto.ShardsResponse.prototype.addShards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.Shard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ShardsResponse} returns this
 */
proto.elude.proto.ShardsResponse.prototype.clearShardsList = function() {
  return this.setShardsList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ShardsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ShardsResponse} returns this
*/
proto.elude.proto.ShardsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ShardsResponse} returns this
 */
proto.elude.proto.ShardsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ShardsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.ShardsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.ShardsResponse} returns this
*/
proto.elude.proto.ShardsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ShardsResponse} returns this
 */
proto.elude.proto.ShardsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ShardsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.ParentLocationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ParentLocationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ParentLocationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ParentLocationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ParentLocationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    parentLocationsList: jspb.Message.toObjectList(msg.getParentLocationsList(),
    proto_models_parent_location_pb.ParentLocation.toObject, includeInstance),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ParentLocationsResponse}
 */
proto.elude.proto.ParentLocationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ParentLocationsResponse;
  return proto.elude.proto.ParentLocationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ParentLocationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ParentLocationsResponse}
 */
proto.elude.proto.ParentLocationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_parent_location_pb.ParentLocation;
      reader.readMessage(value,proto_models_parent_location_pb.ParentLocation.deserializeBinaryFromReader);
      msg.addParentLocations(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ParentLocationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ParentLocationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ParentLocationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ParentLocationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_parent_location_pb.ParentLocation.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ParentLocationsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
*/
proto.elude.proto.ParentLocationsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
 */
proto.elude.proto.ParentLocationsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ParentLocationsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ParentLocation parent_locations = 2;
 * @return {!Array<!proto.elude.proto.ParentLocation>}
 */
proto.elude.proto.ParentLocationsResponse.prototype.getParentLocationsList = function() {
  return /** @type{!Array<!proto.elude.proto.ParentLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_parent_location_pb.ParentLocation, 2));
};


/**
 * @param {!Array<!proto.elude.proto.ParentLocation>} value
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
*/
proto.elude.proto.ParentLocationsResponse.prototype.setParentLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.ParentLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.ParentLocation}
 */
proto.elude.proto.ParentLocationsResponse.prototype.addParentLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.ParentLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
 */
proto.elude.proto.ParentLocationsResponse.prototype.clearParentLocationsList = function() {
  return this.setParentLocationsList([]);
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ParentLocationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
*/
proto.elude.proto.ParentLocationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
 */
proto.elude.proto.ParentLocationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ParentLocationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.ParentLocationsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
*/
proto.elude.proto.ParentLocationsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ParentLocationsResponse} returns this
 */
proto.elude.proto.ParentLocationsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ParentLocationsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ParentLocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ParentLocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ParentLocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ParentLocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    parentLocation: (f = msg.getParentLocation()) && proto_models_parent_location_pb.ParentLocation.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ParentLocationResponse}
 */
proto.elude.proto.ParentLocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ParentLocationResponse;
  return proto.elude.proto.ParentLocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ParentLocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ParentLocationResponse}
 */
proto.elude.proto.ParentLocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_parent_location_pb.ParentLocation;
      reader.readMessage(value,proto_models_parent_location_pb.ParentLocation.deserializeBinaryFromReader);
      msg.setParentLocation(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ParentLocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ParentLocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ParentLocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ParentLocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_parent_location_pb.ParentLocation.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ParentLocationResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ParentLocationResponse} returns this
*/
proto.elude.proto.ParentLocationResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ParentLocationResponse} returns this
 */
proto.elude.proto.ParentLocationResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ParentLocationResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ParentLocation parent_location = 2;
 * @return {?proto.elude.proto.ParentLocation}
 */
proto.elude.proto.ParentLocationResponse.prototype.getParentLocation = function() {
  return /** @type{?proto.elude.proto.ParentLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_parent_location_pb.ParentLocation, 2));
};


/**
 * @param {?proto.elude.proto.ParentLocation|undefined} value
 * @return {!proto.elude.proto.ParentLocationResponse} returns this
*/
proto.elude.proto.ParentLocationResponse.prototype.setParentLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ParentLocationResponse} returns this
 */
proto.elude.proto.ParentLocationResponse.prototype.clearParentLocation = function() {
  return this.setParentLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ParentLocationResponse.prototype.hasParentLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ParentLocationResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ParentLocationResponse} returns this
*/
proto.elude.proto.ParentLocationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ParentLocationResponse} returns this
 */
proto.elude.proto.ParentLocationResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ParentLocationResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.elude.proto);
