<template>
  <component
    :is="component"
    class="nav-button"
    :data-schema="schema"
    :to="to"
    @click.prevent="$emit('click')"
  >
    <IconBack v-if="icon === 'back'" width="100%" height="100%" />
    <IconClose v-if="icon === 'close'" width="40%" height="40%" />
  </component>
</template>

<script>
import IconBack from './icons/common/IconBack.vue'
import IconClose from './icons/common/IconClose.vue'

export default {
  name: 'AppNavButton',
  components: { IconBack, IconClose },
  props: {
    to: String,
    icon: {
      type: String,
      validator: value => ['back', 'close'].includes(value),
      default: 'back',
    },
    schema: {
      type: String,
      validator: value => ['fill', 'black', 'white', 'outline'].includes(value),
      default: 'fill',
    },
  },
  computed: {
    component() {
      return this.to ? 'router-link' : 'button'
    },
  },
}
</script>

<style scoped lang="scss">
.nav-button {
  display: block;
  height: 43px;
  width: 43px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.nav-button[data-schema='fill'] {
  --bg-default: white;
  --bg-active: #fafafa;
  color: #e24d0d;
  background: var(--bg-default);
  border-color: var(--bg-default);
  box-shadow: 0 0 4px #0005;
  &:focus,
  &:hover,
  &:active {
    background: var(--bg-active);
    border-color: var(--bg-active);
    box-shadow: 0 0 2px #0005;
  }
}
.nav-button[data-schema='black'] {
  color: #000;
  border-color: #000;
  &:focus,
  &:hover,
  &:active {
    background: #ddd5;
  }
}
.nav-button[data-schema='white'] {
  color: #fff;
  border-color: white;
  &:focus,
  &:hover,
  &:active {
    background: #ddd5;
  }
}
.nav-button[data-schema='outline'] {
  color: #e24d0d;
  border-color: black;
  &:focus,
  &:hover,
  &:active {
    background: #ddd5;
  }
}
</style>
