import {
  CreateTravellerRequest,
  DeleteTravellerRequest,
  GetTravellersRequest,
  UpdateTravellerRequest,
} from '../../../util/gen/proto/booking/booking_request_pb'
import { Pagination } from '../../../util/gen/proto/commons/pagination_pb'
import { Traveller } from '../../../util/gen/proto/models/traveller_pb'
import { Grpc, hydrateGrpcModel } from './base/Grpc'
import { useOptions } from './base/Grpc.utils'

export const TravelerService = {
  updateTraveler: partialTraveler =>
    Grpc.snt
      .updateTraveller(
        new UpdateTravellerRequest().setTraveller(
          hydrateGrpcModel(new Traveller(), partialTraveler),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => {
        const o = res.toObject()
        o.traveller.dateOfBirth =
          o.traveller.dateOfBirth.split('T')[0] + 'T00:00:00Z'
        return o
      }),
  createTraveler: traveler =>
    Grpc.snt
      .createTraveller(
        new CreateTravellerRequest().setTraveller(
          hydrateGrpcModel(new Traveller(), traveler),
        ),
        useOptions({ useToasts: true }),
      )
      .then(res => {
        const o = res.toObject()
        o.traveller.dateOfBirth =
          o.traveller.dateOfBirth.split('T')[0] + 'T00:00:00Z'
        return o
      }),
  deleteTraveler: ({ id }) =>
    Grpc.snt
      .deleteTraveller(
        new DeleteTravellerRequest().setId(id),
        useOptions({ useToasts: true }),
      )
      .then(res => res.toObject()),
  getTravelers: ({ userId }) =>
    Grpc.snt
      .getTravellers(
        new GetTravellersRequest()
          .setUserid(userId)
          .setPagination(new Pagination().setOverridePagination(true)),
      )
      .then(res => res.toObject()),
}
