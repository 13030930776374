import { BookingService } from '../../services/BookingService'
import { date } from '../../other/utils/helpers/date'
import { getNumberOfNights } from '../../components-rf/bookings/util'

export const BookingModule = () => ({
  state: {
    upcomingBookings: [],
    completedBookings: [],
    otherBookings: [],
    upcomingBooking: undefined,
    currentBooking: undefined,
    activeBooking: undefined,
  },
  getters: {
    bookings: s => [
      ...s.upcomingBookings,
      ...s.completedBookings,
      ...s.otherBookings,
    ],
    activeBookingDetails: (state, getters, rootState) => {
      const hasFlight = !!state.activeBooking?.itinerary.departureFlight.id
      const hasHotel = !!state.activeBooking?.itinerary.hotel.id
      const booking = state.activeBooking

      const formatDate = timestamp => date.formatUTC('-ms -d, -dl', timestamp)
      const formatTime = timestamp =>
        date.formatUTC('-h:-min2', timestamp, true)
      const formatDuration = duration =>
        date.prettyDuration('-hh -minm')(duration * 60 * 1000)

      const travelerCount = booking.bookingTravellersList.length + 1
      const user = booking.user.id ? booking.user : rootState.UserModule.user
      const passengers = [
        {
          traveller: user,
          ticketNumber: booking.itinerary.mainTicketNumber,
        },
        ...booking.bookingTravellersList,
      ].map(({ traveller, ticketNumber }) => ({
        label: `${traveller.firstName} ${traveller.lastName}`,
        value: ticketNumber,
      }))

      const createFlight = direction => {
        if (!hasFlight) return false
        const first = direction.segmentsList.at(0)

        return {
          direction,
          date: formatDate(first.departureDateTime),
          reservationDetails: [
            {
              label: 'Departure Date',
              value: formatDate(first.departureDateTime),
            },
            {
              label: 'Departure Time',
              value: formatTime(first.departureDateTime),
            },
            {
              label: 'Duration',
              value: formatDuration(direction.duration),
            },
            {
              label: 'Passengers',
              value: travelerCount,
            },
            {
              label: 'Terminal',
              value: first.departureTerminal,
            },
            {
              label: 'Gate',
              value: first.gate,
            },
          ],
          passengers,
        }
      }

      const createHotel = hotel => {
        if (!hasHotel) return false
        return {
          data: hotel,
          date: formatDate(hotel.checkInDate),
          reservationDetails: [
            {
              label: 'Confirmation Code',
              value: hotel.confirmationCode,
            },
            {
              label: 'Check-in',
              value: formatDate(hotel.checkInDate),
            },
            {
              label: 'Check-out',
              value: formatDate(hotel.checkOutDate),
            },
            {
              label: 'Duration',
              value:
                getNumberOfNights(hotel.checkInDate, hotel.checkOutDate) +
                ' nights',
            },
            {
              label: 'Number of Guests',
              value: travelerCount,
            },
          ],
        }
      }

      return {
        hasFlight,
        hasHotel,
        departureFlight: createFlight(booking.itinerary.departureFlight),
        returnFlight: createFlight(booking.itinerary.returnFlight),
        hotel: createHotel(booking.itinerary.hotel),
        booking,
      }
    },
    activeBookingHasFlight: s =>
      !!s.activeBooking?.itinerary.departureFlight.id,
    activeBookingHasHotel: s => !!s.activeBooking?.itinerary.hotel.id,
  },
  actions: {
    getBookingByReference: ({ getters, commit }, eludeReference) => {
      const booking = getters.bookings.find(
        booking => booking.eludeReference === eludeReference,
      )
      return booking
        ? booking
        : BookingService.getBookingByReference({ eludeReference }).then(res => {
            const hasBooking = !!res.booking?.id
            if (!hasBooking) return
            commit('addOtherBookings', res.booking)
            return res.booking
          })
    },
    getCompletedBookings: ({ commit, rootState }) =>
      BookingService.getCompletedBookings({
        userId: rootState.UserModule.user?.id,
      }).then(res => commit('setCompletedBookings', res.bookingsList)),
    getUpcomingBookings: ({ commit, rootState }) =>
      BookingService.getUpcomingBookings({
        userId: rootState.UserModule.user?.id,
      }).then(res => commit('setUpcomingBookings', res.bookingsList)),
    getHomeBookings: ({ commit, rootState }) => {
      BookingService.getHomeBookings({
        userId: rootState.UserModule.user?.id,
      }).then(({ currentBooking, upcomingBooking }) => {
        const getBooking = booking => (booking?.id ? booking : undefined)
        commit('setCurrentBooking', getBooking(currentBooking))
        commit('setUpcomingBooking', getBooking(upcomingBooking))
      })
    },
  },
})
