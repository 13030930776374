import { TEMPERATURE } from '../../constants/WEATHER'
import { WeatherService } from '../../services/WeatherService'

export const WeatherModule = () => ({
  state: {
    weather: '',
  },
  actions: {
    getWeatherByCoordinates: ({ commit }) =>
      WeatherService.getWeatherByCoordinates().then(({ weather }) => {
        commit('setWeather', weather)
      }),
    toggleTemperatureUnit({ rootState, commit }) {
      commit(
        'PersistModule/setTemperatureUnit',
        rootState.PersistModule.temperatureUnit === TEMPERATURE.FAHRENHEIT
          ? TEMPERATURE.CELSIUS
          : TEMPERATURE.FAHRENHEIT,
        { root: true },
      )
    },
  },
  getters: {
    getTemperature: (s, g, rs) => temperature => {
      const unit = rs.PersistModule.temperatureUnit
      const temp =
        unit === TEMPERATURE.FAHRENHEIT
          ? temperature
          : (5 / 9) * (temperature - 32)
      return Math.round(temp) + `°${rs.PersistModule.temperatureUnit}`
    },
  },
})
