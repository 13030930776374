/**
 * @fileoverview gRPC-Web generated client stub for elude.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v0.0.0
// source: services/services.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var proto_air_hotel_reservation_search_request_pb = require('../proto/air_hotel_reservation_search/request_pb.js')

var proto_air_hotel_reservation_search_response_pb = require('../proto/air_hotel_reservation_search/response_pb.js')

var proto_auth_auth_request_pb = require('../proto/auth/auth_request_pb.js')

var proto_auth_auth_response_pb = require('../proto/auth/auth_response_pb.js')

var proto_booking_booking_request_pb = require('../proto/booking/booking_request_pb.js')

var proto_booking_booking_response_pb = require('../proto/booking/booking_response_pb.js')

var proto_location_location_request_pb = require('../proto/location/location_request_pb.js')

var proto_location_location_response_pb = require('../proto/location/location_response_pb.js')

var proto_payment_payment_request_pb = require('../proto/payment/payment_request_pb.js')

var proto_payment_payment_response_pb = require('../proto/payment/payment_response_pb.js')

var proto_saved_search_saved_search_request_pb = require('../proto/saved_search/saved_search_request_pb.js')

var proto_saved_search_saved_search_response_pb = require('../proto/saved_search/saved_search_response_pb.js')
const proto = {};
proto.elude = {};
proto.elude.proto = require('./services_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.elude.proto.GatewayControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.elude.proto.GatewayControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ItineraryRequest,
 *   !proto.elude.proto.ItineraryResponse>}
 */
const methodDescriptor_GatewayController_RunSearch = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/RunSearch',
  grpc.web.MethodType.SERVER_STREAMING,
  proto_air_hotel_reservation_search_request_pb.ItineraryRequest,
  proto_air_hotel_reservation_search_response_pb.ItineraryResponse,
  /**
   * @param {!proto.elude.proto.ItineraryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.ItineraryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ItineraryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ItineraryResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.runSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/RunSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RunSearch);
};


/**
 * @param {!proto.elude.proto.ItineraryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ItineraryResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.runSearch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/RunSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RunSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ItineraryRequest,
 *   !proto.elude.proto.ItineraryResponse>}
 */
const methodDescriptor_GatewayController_RunSearchGroupResponses = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/RunSearchGroupResponses',
  grpc.web.MethodType.SERVER_STREAMING,
  proto_air_hotel_reservation_search_request_pb.ItineraryRequest,
  proto_air_hotel_reservation_search_response_pb.ItineraryResponse,
  /**
   * @param {!proto.elude.proto.ItineraryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.ItineraryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ItineraryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ItineraryResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.runSearchGroupResponses =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/RunSearchGroupResponses',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RunSearchGroupResponses);
};


/**
 * @param {!proto.elude.proto.ItineraryRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ItineraryResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.runSearchGroupResponses =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/RunSearchGroupResponses',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RunSearchGroupResponses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetSavedSearchRequest,
 *   !proto.elude.proto.GetSavedSearchResponse>}
 */
const methodDescriptor_GatewayController_GetSavedSearch = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetSavedSearch',
  grpc.web.MethodType.UNARY,
  proto_saved_search_saved_search_request_pb.GetSavedSearchRequest,
  proto_saved_search_saved_search_response_pb.GetSavedSearchResponse,
  /**
   * @param {!proto.elude.proto.GetSavedSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_saved_search_saved_search_response_pb.GetSavedSearchResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetSavedSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetSavedSearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetSavedSearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getSavedSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetSavedSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetSavedSearch,
      callback);
};


/**
 * @param {!proto.elude.proto.GetSavedSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetSavedSearchResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getSavedSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetSavedSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetSavedSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetSavedSearchesRequest,
 *   !proto.elude.proto.GetSavedSearchesResponse>}
 */
const methodDescriptor_GatewayController_GetSavedSearches = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetSavedSearches',
  grpc.web.MethodType.UNARY,
  proto_saved_search_saved_search_request_pb.GetSavedSearchesRequest,
  proto_saved_search_saved_search_response_pb.GetSavedSearchesResponse,
  /**
   * @param {!proto.elude.proto.GetSavedSearchesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_saved_search_saved_search_response_pb.GetSavedSearchesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetSavedSearchesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetSavedSearchesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetSavedSearchesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getSavedSearches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetSavedSearches',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetSavedSearches,
      callback);
};


/**
 * @param {!proto.elude.proto.GetSavedSearchesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetSavedSearchesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getSavedSearches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetSavedSearches',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetSavedSearches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateSavedSearchRequest,
 *   !proto.elude.proto.CreateSavedSearchResponse>}
 */
const methodDescriptor_GatewayController_CreateSavedSearch = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateSavedSearch',
  grpc.web.MethodType.UNARY,
  proto_saved_search_saved_search_request_pb.CreateSavedSearchRequest,
  proto_saved_search_saved_search_response_pb.CreateSavedSearchResponse,
  /**
   * @param {!proto.elude.proto.CreateSavedSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_saved_search_saved_search_response_pb.CreateSavedSearchResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateSavedSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CreateSavedSearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CreateSavedSearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createSavedSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateSavedSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateSavedSearch,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateSavedSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CreateSavedSearchResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createSavedSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateSavedSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateSavedSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteSavedSearchRequest,
 *   !proto.elude.proto.DeleteSavedSearchResponse>}
 */
const methodDescriptor_GatewayController_DeleteSavedSearch = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteSavedSearch',
  grpc.web.MethodType.UNARY,
  proto_saved_search_saved_search_request_pb.DeleteSavedSearchRequest,
  proto_saved_search_saved_search_response_pb.DeleteSavedSearchResponse,
  /**
   * @param {!proto.elude.proto.DeleteSavedSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_saved_search_saved_search_response_pb.DeleteSavedSearchResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteSavedSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.DeleteSavedSearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.DeleteSavedSearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteSavedSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteSavedSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteSavedSearch,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteSavedSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.DeleteSavedSearchResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteSavedSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteSavedSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteSavedSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.HotelDetailsRequest,
 *   !proto.elude.proto.HotelDetailsResponse>}
 */
const methodDescriptor_GatewayController_GetHotelDetails = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetHotelDetails',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.HotelDetailsRequest,
  proto_air_hotel_reservation_search_response_pb.HotelDetailsResponse,
  /**
   * @param {!proto.elude.proto.HotelDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.HotelDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.HotelDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.HotelDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.HotelDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getHotelDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetHotelDetails',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetHotelDetails,
      callback);
};


/**
 * @param {!proto.elude.proto.HotelDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.HotelDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getHotelDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetHotelDetails',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetHotelDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAbandonTripSearchRequest,
 *   !proto.elude.proto.GetAbandonTripSearchResponse>}
 */
const methodDescriptor_GatewayController_GetAbandonTripSearch = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAbandonTripSearch',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.GetAbandonTripSearchRequest,
  proto_air_hotel_reservation_search_response_pb.GetAbandonTripSearchResponse,
  /**
   * @param {!proto.elude.proto.GetAbandonTripSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.GetAbandonTripSearchResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAbandonTripSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetAbandonTripSearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetAbandonTripSearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAbandonTripSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAbandonTripSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAbandonTripSearch,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAbandonTripSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetAbandonTripSearchResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAbandonTripSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAbandonTripSearch',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAbandonTripSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.AbandonTripRequest,
 *   !proto.elude.proto.AbandonTripResponse>}
 */
const methodDescriptor_GatewayController_AbandonTrip = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/AbandonTrip',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.AbandonTripRequest,
  proto_air_hotel_reservation_search_response_pb.AbandonTripResponse,
  /**
   * @param {!proto.elude.proto.AbandonTripRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.AbandonTripResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.AbandonTripRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AbandonTripResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AbandonTripResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.abandonTrip =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/AbandonTrip',
      request,
      metadata || {},
      methodDescriptor_GatewayController_AbandonTrip,
      callback);
};


/**
 * @param {!proto.elude.proto.AbandonTripRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AbandonTripResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.abandonTrip =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/AbandonTrip',
      request,
      metadata || {},
      methodDescriptor_GatewayController_AbandonTrip);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.SearchReturnFlightsRequest,
 *   !proto.elude.proto.ItineraryResponse>}
 */
const methodDescriptor_GatewayController_SearchReturnFlights = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/SearchReturnFlights',
  grpc.web.MethodType.SERVER_STREAMING,
  proto_air_hotel_reservation_search_request_pb.SearchReturnFlightsRequest,
  proto_air_hotel_reservation_search_response_pb.ItineraryResponse,
  /**
   * @param {!proto.elude.proto.SearchReturnFlightsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.ItineraryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.SearchReturnFlightsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ItineraryResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.searchReturnFlights =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/SearchReturnFlights',
      request,
      metadata || {},
      methodDescriptor_GatewayController_SearchReturnFlights);
};


/**
 * @param {!proto.elude.proto.SearchReturnFlightsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ItineraryResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.searchReturnFlights =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/SearchReturnFlights',
      request,
      metadata || {},
      methodDescriptor_GatewayController_SearchReturnFlights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.AuthRequest,
 *   !proto.elude.proto.AuthResponse>}
 */
const methodDescriptor_GatewayController_Authorize = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/Authorize',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.AuthRequest,
  proto_auth_auth_response_pb.AuthResponse,
  /**
   * @param {!proto.elude.proto.AuthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AuthResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.AuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.authorize =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/Authorize',
      request,
      metadata || {},
      methodDescriptor_GatewayController_Authorize,
      callback);
};


/**
 * @param {!proto.elude.proto.AuthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AuthResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.authorize =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/Authorize',
      request,
      metadata || {},
      methodDescriptor_GatewayController_Authorize);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.RegisterRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_Register = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/Register',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.RegisterRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/Register',
      request,
      metadata || {},
      methodDescriptor_GatewayController_Register,
      callback);
};


/**
 * @param {!proto.elude.proto.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/Register',
      request,
      metadata || {},
      methodDescriptor_GatewayController_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.LoginRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_Login = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/Login',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.LoginRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/Login',
      request,
      metadata || {},
      methodDescriptor_GatewayController_Login,
      callback);
};


/**
 * @param {!proto.elude.proto.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/Login',
      request,
      metadata || {},
      methodDescriptor_GatewayController_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.VerifyEmailRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_VerifyEmail = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.VerifyEmailRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.VerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_GatewayController_VerifyEmail,
      callback);
};


/**
 * @param {!proto.elude.proto.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_GatewayController_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.PasswordRecoverRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_PasswordRecover = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/PasswordRecover',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.PasswordRecoverRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.PasswordRecoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.PasswordRecoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.passwordRecover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/PasswordRecover',
      request,
      metadata || {},
      methodDescriptor_GatewayController_PasswordRecover,
      callback);
};


/**
 * @param {!proto.elude.proto.PasswordRecoverRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.passwordRecover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/PasswordRecover',
      request,
      metadata || {},
      methodDescriptor_GatewayController_PasswordRecover);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.VerifyRecoveryTokenRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_VerifyRecoveryToken = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/VerifyRecoveryToken',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.VerifyRecoveryTokenRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.VerifyRecoveryTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.VerifyRecoveryTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.verifyRecoveryToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/VerifyRecoveryToken',
      request,
      metadata || {},
      methodDescriptor_GatewayController_VerifyRecoveryToken,
      callback);
};


/**
 * @param {!proto.elude.proto.VerifyRecoveryTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.verifyRecoveryToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/VerifyRecoveryToken',
      request,
      metadata || {},
      methodDescriptor_GatewayController_VerifyRecoveryToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.PasswordResetRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_PasswordReset = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/PasswordReset',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.PasswordResetRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.PasswordResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.passwordReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/PasswordReset',
      request,
      metadata || {},
      methodDescriptor_GatewayController_PasswordReset,
      callback);
};


/**
 * @param {!proto.elude.proto.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.passwordReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/PasswordReset',
      request,
      metadata || {},
      methodDescriptor_GatewayController_PasswordReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetUsersRequest,
 *   !proto.elude.proto.UsersResponse>}
 */
const methodDescriptor_GatewayController_GetUsers = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetUsers',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.GetUsersRequest,
  proto_auth_auth_response_pb.UsersResponse,
  /**
   * @param {!proto.elude.proto.GetUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.UsersResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.UsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.UsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetUsers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetUsers,
      callback);
};


/**
 * @param {!proto.elude.proto.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.UsersResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetUsers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetUserRequest,
 *   !proto.elude.proto.UserResponse>}
 */
const methodDescriptor_GatewayController_GetUser = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetUser',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.GetUserRequest,
  proto_auth_auth_response_pb.UserResponse,
  /**
   * @param {!proto.elude.proto.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.UserResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetUser,
      callback);
};


/**
 * @param {!proto.elude.proto.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateUserRequest,
 *   !proto.elude.proto.UserResponse>}
 */
const methodDescriptor_GatewayController_CreateUser = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateUser',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.CreateUserRequest,
  proto_auth_auth_response_pb.UserResponse,
  /**
   * @param {!proto.elude.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.UserResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateUser,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateUserRequest,
 *   !proto.elude.proto.UserResponse>}
 */
const methodDescriptor_GatewayController_UpdateUser = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.UpdateUserRequest,
  proto_auth_auth_response_pb.UserResponse,
  /**
   * @param {!proto.elude.proto.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.UserResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateUser,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteUserRequest,
 *   !proto.elude.proto.UserResponse>}
 */
const methodDescriptor_GatewayController_DeleteUser = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.DeleteUserRequest,
  proto_auth_auth_response_pb.UserResponse,
  /**
   * @param {!proto.elude.proto.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.UserResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteUser,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.UserResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetTitlesRequest,
 *   !proto.elude.proto.TitlesResponse>}
 */
const methodDescriptor_GatewayController_GetTitles = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetTitles',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.GetTitlesRequest,
  proto_auth_auth_response_pb.TitlesResponse,
  /**
   * @param {!proto.elude.proto.GetTitlesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.TitlesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetTitlesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.TitlesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.TitlesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getTitles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTitles',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTitles,
      callback);
};


/**
 * @param {!proto.elude.proto.GetTitlesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.TitlesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getTitles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTitles',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTitles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ChangePasswordRequest,
 *   !proto.elude.proto.AccountResponse>}
 */
const methodDescriptor_GatewayController_ChangePassword = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.ChangePasswordRequest,
  proto_auth_auth_response_pb.AccountResponse,
  /**
   * @param {!proto.elude.proto.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AccountResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ChangePassword,
      callback);
};


/**
 * @param {!proto.elude.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AccountResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.AddSocialRequest,
 *   !proto.elude.proto.AddSocialResponse>}
 */
const methodDescriptor_GatewayController_AddSocial = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/AddSocial',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.AddSocialRequest,
  proto_auth_auth_response_pb.AddSocialResponse,
  /**
   * @param {!proto.elude.proto.AddSocialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.AddSocialResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.AddSocialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AddSocialResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AddSocialResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.addSocial =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/AddSocial',
      request,
      metadata || {},
      methodDescriptor_GatewayController_AddSocial,
      callback);
};


/**
 * @param {!proto.elude.proto.AddSocialRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AddSocialResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.addSocial =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/AddSocial',
      request,
      metadata || {},
      methodDescriptor_GatewayController_AddSocial);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetRolesRequest,
 *   !proto.elude.proto.RolesResponse>}
 */
const methodDescriptor_GatewayController_GetRoles = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetRoles',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.GetRolesRequest,
  proto_auth_auth_response_pb.RolesResponse,
  /**
   * @param {!proto.elude.proto.GetRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.RolesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRoles',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRoles,
      callback);
};


/**
 * @param {!proto.elude.proto.GetRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RolesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRoles',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetRoleRequest,
 *   !proto.elude.proto.RoleResponse>}
 */
const methodDescriptor_GatewayController_GetRole = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetRole',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.GetRoleRequest,
  proto_auth_auth_response_pb.RoleResponse,
  /**
   * @param {!proto.elude.proto.GetRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.RoleResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRole,
      callback);
};


/**
 * @param {!proto.elude.proto.GetRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoleResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateRoleRequest,
 *   !proto.elude.proto.RoleResponse>}
 */
const methodDescriptor_GatewayController_CreateRole = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateRole',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.CreateRoleRequest,
  proto_auth_auth_response_pb.RoleResponse,
  /**
   * @param {!proto.elude.proto.CreateRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.RoleResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateRole,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoleResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateRoleRequest,
 *   !proto.elude.proto.RoleResponse>}
 */
const methodDescriptor_GatewayController_UpdateRole = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateRole',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.UpdateRoleRequest,
  proto_auth_auth_response_pb.RoleResponse,
  /**
   * @param {!proto.elude.proto.UpdateRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.RoleResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateRole,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoleResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteRoleRequest,
 *   !proto.elude.proto.RoleResponse>}
 */
const methodDescriptor_GatewayController_DeleteRole = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteRole',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.DeleteRoleRequest,
  proto_auth_auth_response_pb.RoleResponse,
  /**
   * @param {!proto.elude.proto.DeleteRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.RoleResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteRole,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoleResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteRole',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPermissionsRequest,
 *   !proto.elude.proto.PermissionsResponse>}
 */
const methodDescriptor_GatewayController_GetPermissions = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPermissions',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.GetPermissionsRequest,
  proto_auth_auth_response_pb.PermissionsResponse,
  /**
   * @param {!proto.elude.proto.GetPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPermissions,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ValidateJwtTokenRequest,
 *   !proto.elude.proto.ValidateJwtTokenResponse>}
 */
const methodDescriptor_GatewayController_ValidateJwtToken = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/ValidateJwtToken',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.ValidateJwtTokenRequest,
  proto_auth_auth_response_pb.ValidateJwtTokenResponse,
  /**
   * @param {!proto.elude.proto.ValidateJwtTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_auth_auth_response_pb.ValidateJwtTokenResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ValidateJwtTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ValidateJwtTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ValidateJwtTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.validateJwtToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/ValidateJwtToken',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ValidateJwtToken,
      callback);
};


/**
 * @param {!proto.elude.proto.ValidateJwtTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ValidateJwtTokenResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.validateJwtToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/ValidateJwtToken',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ValidateJwtToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ResendConfirmationEmailRequest,
 *   !proto.elude.proto.SendEmailsResponse>}
 */
const methodDescriptor_GatewayController_ResendConfirmationEmail = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/ResendConfirmationEmail',
  grpc.web.MethodType.UNARY,
  proto_auth_auth_request_pb.ResendConfirmationEmailRequest,
  proto_booking_booking_response_pb.SendEmailsResponse,
  /**
   * @param {!proto.elude.proto.ResendConfirmationEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.SendEmailsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ResendConfirmationEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.SendEmailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.SendEmailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.resendConfirmationEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/ResendConfirmationEmail',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ResendConfirmationEmail,
      callback);
};


/**
 * @param {!proto.elude.proto.ResendConfirmationEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.SendEmailsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.resendConfirmationEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/ResendConfirmationEmail',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ResendConfirmationEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ReservationRequest,
 *   !proto.elude.proto.ReservationResponse>}
 */
const methodDescriptor_GatewayController_CreateReservation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateReservation',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.ReservationRequest,
  proto_air_hotel_reservation_search_response_pb.ReservationResponse,
  /**
   * @param {!proto.elude.proto.ReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.ReservationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateReservation,
      callback);
};


/**
 * @param {!proto.elude.proto.ReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ReservationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ReservationRequest,
 *   !proto.elude.proto.ReservationResponse>}
 */
const methodDescriptor_GatewayController_StreamCreateReservation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/StreamCreateReservation',
  grpc.web.MethodType.SERVER_STREAMING,
  proto_air_hotel_reservation_search_request_pb.ReservationRequest,
  proto_air_hotel_reservation_search_response_pb.ReservationResponse,
  /**
   * @param {!proto.elude.proto.ReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.ReservationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ReservationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ReservationResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.streamCreateReservation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/StreamCreateReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_StreamCreateReservation);
};


/**
 * @param {!proto.elude.proto.ReservationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ReservationResponse>}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.streamCreateReservation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/elude.proto.GatewayController/StreamCreateReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_StreamCreateReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CancelRequest,
 *   !proto.elude.proto.CancelResponse>}
 */
const methodDescriptor_GatewayController_CancelReservation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CancelReservation',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.CancelRequest,
  proto_air_hotel_reservation_search_response_pb.CancelResponse,
  /**
   * @param {!proto.elude.proto.CancelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.CancelResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CancelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CancelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CancelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.cancelReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CancelReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CancelReservation,
      callback);
};


/**
 * @param {!proto.elude.proto.CancelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CancelResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.cancelReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CancelReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CancelReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.RetrieveRequest,
 *   !proto.elude.proto.RetrieveResponse>}
 */
const methodDescriptor_GatewayController_RetrieveReservation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/RetrieveReservation',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.RetrieveRequest,
  proto_air_hotel_reservation_search_response_pb.RetrieveResponse,
  /**
   * @param {!proto.elude.proto.RetrieveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.RetrieveResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.RetrieveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RetrieveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RetrieveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.retrieveReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/RetrieveReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RetrieveReservation,
      callback);
};


/**
 * @param {!proto.elude.proto.RetrieveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RetrieveResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.retrieveReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/RetrieveReservation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RetrieveReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.RevalidateItineraryRequest,
 *   !proto.elude.proto.RevalidateItineraryResponse>}
 */
const methodDescriptor_GatewayController_RevalidateItinerary = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/RevalidateItinerary',
  grpc.web.MethodType.UNARY,
  proto_air_hotel_reservation_search_request_pb.RevalidateItineraryRequest,
  proto_air_hotel_reservation_search_response_pb.RevalidateItineraryResponse,
  /**
   * @param {!proto.elude.proto.RevalidateItineraryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_air_hotel_reservation_search_response_pb.RevalidateItineraryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.RevalidateItineraryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RevalidateItineraryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RevalidateItineraryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.revalidateItinerary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/RevalidateItinerary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RevalidateItinerary,
      callback);
};


/**
 * @param {!proto.elude.proto.RevalidateItineraryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RevalidateItineraryResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.revalidateItinerary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/RevalidateItinerary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RevalidateItinerary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.AddPaymentMethodRequest,
 *   !proto.elude.proto.AddPaymentMethodResponse>}
 */
const methodDescriptor_GatewayController_AddPaymentMethod = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/AddPaymentMethod',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.AddPaymentMethodRequest,
  proto_payment_payment_response_pb.AddPaymentMethodResponse,
  /**
   * @param {!proto.elude.proto.AddPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.AddPaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.AddPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AddPaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AddPaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.addPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/AddPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_AddPaymentMethod,
      callback);
};


/**
 * @param {!proto.elude.proto.AddPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AddPaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.addPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/AddPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_AddPaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateChargeRequest,
 *   !proto.elude.proto.CreateChargeResponse>}
 */
const methodDescriptor_GatewayController_CreateCharge = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateCharge',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.CreateChargeRequest,
  proto_payment_payment_response_pb.CreateChargeResponse,
  /**
   * @param {!proto.elude.proto.CreateChargeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.CreateChargeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateChargeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CreateChargeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CreateChargeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createCharge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateCharge',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateCharge,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateChargeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CreateChargeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createCharge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateCharge',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateCharge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CaptureChargeRequest,
 *   !proto.elude.proto.CaptureChargeResponse>}
 */
const methodDescriptor_GatewayController_CaptureCharge = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CaptureCharge',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.CaptureChargeRequest,
  proto_payment_payment_response_pb.CaptureChargeResponse,
  /**
   * @param {!proto.elude.proto.CaptureChargeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.CaptureChargeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CaptureChargeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CaptureChargeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CaptureChargeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.captureCharge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CaptureCharge',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CaptureCharge,
      callback);
};


/**
 * @param {!proto.elude.proto.CaptureChargeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CaptureChargeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.captureCharge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CaptureCharge',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CaptureCharge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.RefundChargeRequest,
 *   !proto.elude.proto.RefundChargeResponse>}
 */
const methodDescriptor_GatewayController_RefundCharge = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/RefundCharge',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.RefundChargeRequest,
  proto_payment_payment_response_pb.RefundChargeResponse,
  /**
   * @param {!proto.elude.proto.RefundChargeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.RefundChargeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.RefundChargeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RefundChargeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RefundChargeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.refundCharge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/RefundCharge',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RefundCharge,
      callback);
};


/**
 * @param {!proto.elude.proto.RefundChargeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RefundChargeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.refundCharge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/RefundCharge',
      request,
      metadata || {},
      methodDescriptor_GatewayController_RefundCharge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCityRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_GetCity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCity',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCityRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.GetCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCity,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCitiesRequest,
 *   !proto.elude.proto.CitiesResponse>}
 */
const methodDescriptor_GatewayController_GetCities = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCities',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCitiesRequest,
  proto_location_location_response_pb.CitiesResponse,
  /**
   * @param {!proto.elude.proto.GetCitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CitiesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCities,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CitiesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateCityRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_CreateCity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateCity',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateCityRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.CreateCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateCity,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateCityRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_UpdateCity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateCity',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateCityRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.UpdateCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateCity,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteCityRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_DeleteCity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteCity',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteCityRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.DeleteCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteCity,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCityRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_GetCityWithWeather = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCityWithWeather',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCityRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.GetCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCityWithWeather =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCityWithWeather',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCityWithWeather,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCityWithWeather =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCityWithWeather',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCityWithWeather);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCitiesRequest,
 *   !proto.elude.proto.CitiesResponse>}
 */
const methodDescriptor_GatewayController_GetDepartureCities = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetDepartureCities',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCitiesRequest,
  proto_location_location_response_pb.CitiesResponse,
  /**
   * @param {!proto.elude.proto.GetCitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CitiesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getDepartureCities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetDepartureCities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetDepartureCities,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CitiesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getDepartureCities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetDepartureCities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetDepartureCities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetLocationsRequest,
 *   !proto.elude.proto.LocationsResponse>}
 */
const methodDescriptor_GatewayController_GetLocations = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetLocations',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetLocationsRequest,
  proto_location_location_response_pb.LocationsResponse,
  /**
   * @param {!proto.elude.proto.GetLocationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.LocationsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetLocationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.LocationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.LocationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getLocations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetLocations',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetLocations,
      callback);
};


/**
 * @param {!proto.elude.proto.GetLocationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.LocationsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getLocations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetLocations',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetLocations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCityRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_GetNearestConnectedCity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetNearestConnectedCity',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCityRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.GetCityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getNearestConnectedCity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestConnectedCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestConnectedCity,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getNearestConnectedCity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestConnectedCity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestConnectedCity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCityByCoordinatesRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_GetNearestConnectedCityByCoordinates = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetNearestConnectedCityByCoordinates',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCityByCoordinatesRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.GetCityByCoordinatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCityByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getNearestConnectedCityByCoordinates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestConnectedCityByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestConnectedCityByCoordinates,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCityByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getNearestConnectedCityByCoordinates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestConnectedCityByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestConnectedCityByCoordinates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetLocationRequest,
 *   !proto.elude.proto.LocationResponse>}
 */
const methodDescriptor_GatewayController_GetNearestLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetNearestLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetLocationRequest,
  proto_location_location_response_pb.LocationResponse,
  /**
   * @param {!proto.elude.proto.GetLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getNearestLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.GetLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getNearestLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetLocationByCoordinatesRequest,
 *   !proto.elude.proto.LocationResponse>}
 */
const methodDescriptor_GatewayController_GetNearestLocationByCoordinates = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetNearestLocationByCoordinates',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetLocationByCoordinatesRequest,
  proto_location_location_response_pb.LocationResponse,
  /**
   * @param {!proto.elude.proto.GetLocationByCoordinatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetLocationByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getNearestLocationByCoordinates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestLocationByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestLocationByCoordinates,
      callback);
};


/**
 * @param {!proto.elude.proto.GetLocationByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getNearestLocationByCoordinates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetNearestLocationByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetNearestLocationByCoordinates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetLocationsByIdentifiersRequest,
 *   !proto.elude.proto.GetLocationsByIdentifiersResponse>}
 */
const methodDescriptor_GatewayController_GetLocationsByIdentifiers = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetLocationsByIdentifiers',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetLocationsByIdentifiersRequest,
  proto_location_location_response_pb.GetLocationsByIdentifiersResponse,
  /**
   * @param {!proto.elude.proto.GetLocationsByIdentifiersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.GetLocationsByIdentifiersResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetLocationsByIdentifiersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetLocationsByIdentifiersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetLocationsByIdentifiersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getLocationsByIdentifiers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetLocationsByIdentifiers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetLocationsByIdentifiers,
      callback);
};


/**
 * @param {!proto.elude.proto.GetLocationsByIdentifiersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetLocationsByIdentifiersResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getLocationsByIdentifiers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetLocationsByIdentifiers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetLocationsByIdentifiers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMultipleCitiesRequest,
 *   !proto.elude.proto.CitiesResponse>}
 */
const methodDescriptor_GatewayController_GetMultipleCities = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMultipleCities',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetMultipleCitiesRequest,
  proto_location_location_response_pb.CitiesResponse,
  /**
   * @param {!proto.elude.proto.GetMultipleCitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CitiesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMultipleCities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleCities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleCities,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CitiesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMultipleCities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleCities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleCities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMultipleCitiesRequest,
 *   !proto.elude.proto.CitiesResponse>}
 */
const methodDescriptor_GatewayController_GetMultipleCitiesWithWeather = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMultipleCitiesWithWeather',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetMultipleCitiesRequest,
  proto_location_location_response_pb.CitiesResponse,
  /**
   * @param {!proto.elude.proto.GetMultipleCitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CitiesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMultipleCitiesWithWeather =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleCitiesWithWeather',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleCitiesWithWeather,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CitiesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMultipleCitiesWithWeather =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleCitiesWithWeather',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleCitiesWithWeather);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateCityWithImageUnaryRequest,
 *   !proto.elude.proto.CityResponse>}
 */
const methodDescriptor_GatewayController_UpdateCityWithImageUnary = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateCityWithImageUnary',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateCityWithImageUnaryRequest,
  proto_location_location_response_pb.CityResponse,
  /**
   * @param {!proto.elude.proto.UpdateCityWithImageUnaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateCityWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateCityWithImageUnary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateCityWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateCityWithImageUnary,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateCityWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateCityWithImageUnary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateCityWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateCityWithImageUnary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetWeatherByCoordinatesRequest,
 *   !proto.elude.proto.GetWeatherByCoordinatesResponse>}
 */
const methodDescriptor_GatewayController_GetWeatherByCoordinates = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetWeatherByCoordinates',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetWeatherByCoordinatesRequest,
  proto_location_location_response_pb.GetWeatherByCoordinatesResponse,
  /**
   * @param {!proto.elude.proto.GetWeatherByCoordinatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.GetWeatherByCoordinatesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetWeatherByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetWeatherByCoordinatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetWeatherByCoordinatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getWeatherByCoordinates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetWeatherByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetWeatherByCoordinates,
      callback);
};


/**
 * @param {!proto.elude.proto.GetWeatherByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetWeatherByCoordinatesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getWeatherByCoordinates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetWeatherByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetWeatherByCoordinates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetFeaturedLocationsByLocationRequest,
 *   !proto.elude.proto.FeaturedLocationsResponse>}
 */
const methodDescriptor_GatewayController_GetFeaturedLocationsByLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetFeaturedLocationsByLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetFeaturedLocationsByLocationRequest,
  proto_location_location_response_pb.FeaturedLocationsResponse,
  /**
   * @param {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.FeaturedLocationsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FeaturedLocationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FeaturedLocationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getFeaturedLocationsByLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFeaturedLocationsByLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFeaturedLocationsByLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FeaturedLocationsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getFeaturedLocationsByLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFeaturedLocationsByLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFeaturedLocationsByLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest,
 *   !proto.elude.proto.FeaturedLocationsResponse>}
 */
const methodDescriptor_GatewayController_GetFeaturedLocationsByCoordinates = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetFeaturedLocationsByCoordinates',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetFeaturedLocationsByCoordinatesRequest,
  proto_location_location_response_pb.FeaturedLocationsResponse,
  /**
   * @param {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.FeaturedLocationsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FeaturedLocationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FeaturedLocationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getFeaturedLocationsByCoordinates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFeaturedLocationsByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFeaturedLocationsByCoordinates,
      callback);
};


/**
 * @param {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FeaturedLocationsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getFeaturedLocationsByCoordinates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFeaturedLocationsByCoordinates',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFeaturedLocationsByCoordinates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest,
 *   !proto.elude.proto.FeaturedLocationsResponse>}
 */
const methodDescriptor_GatewayController_GetFeaturedLocationsWithoutDepartureLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetFeaturedLocationsWithoutDepartureLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetFeaturedLocationsWithoutDepartureLocationRequest,
  proto_location_location_response_pb.FeaturedLocationsResponse,
  /**
   * @param {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.FeaturedLocationsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FeaturedLocationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FeaturedLocationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getFeaturedLocationsWithoutDepartureLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFeaturedLocationsWithoutDepartureLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFeaturedLocationsWithoutDepartureLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FeaturedLocationsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getFeaturedLocationsWithoutDepartureLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFeaturedLocationsWithoutDepartureLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFeaturedLocationsWithoutDepartureLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAirportRequest,
 *   !proto.elude.proto.AirportResponse>}
 */
const methodDescriptor_GatewayController_GetAirport = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAirport',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetAirportRequest,
  proto_location_location_response_pb.AirportResponse,
  /**
   * @param {!proto.elude.proto.GetAirportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirportResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAirport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirport,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirportResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAirport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAirportsRequest,
 *   !proto.elude.proto.AirportsResponse>}
 */
const methodDescriptor_GatewayController_GetAirports = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAirports',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetAirportsRequest,
  proto_location_location_response_pb.AirportsResponse,
  /**
   * @param {!proto.elude.proto.GetAirportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirportsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAirportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirportsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirportsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirports',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirports,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAirportsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirportsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirports',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateAirportRequest,
 *   !proto.elude.proto.AirportResponse>}
 */
const methodDescriptor_GatewayController_CreateAirport = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateAirport',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateAirportRequest,
  proto_location_location_response_pb.AirportResponse,
  /**
   * @param {!proto.elude.proto.CreateAirportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirportResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createAirport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateAirport,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirportResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createAirport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateAirport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateAirportRequest,
 *   !proto.elude.proto.AirportResponse>}
 */
const methodDescriptor_GatewayController_UpdateAirport = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateAirport',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateAirportRequest,
  proto_location_location_response_pb.AirportResponse,
  /**
   * @param {!proto.elude.proto.UpdateAirportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirportResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateAirport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAirport,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirportResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateAirport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAirport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteAirportRequest,
 *   !proto.elude.proto.AirportResponse>}
 */
const methodDescriptor_GatewayController_DeleteAirport = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteAirport',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteAirportRequest,
  proto_location_location_response_pb.AirportResponse,
  /**
   * @param {!proto.elude.proto.DeleteAirportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirportResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteAirport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteAirport,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteAirportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirportResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteAirport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteAirport',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteAirport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMultipleAirportsRequest,
 *   !proto.elude.proto.AirportsResponse>}
 */
const methodDescriptor_GatewayController_GetMultipleAirports = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMultipleAirports',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetMultipleAirportsRequest,
  proto_location_location_response_pb.AirportsResponse,
  /**
   * @param {!proto.elude.proto.GetMultipleAirportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirportsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMultipleAirportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirportsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirportsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMultipleAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleAirports',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleAirports,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMultipleAirportsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirportsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMultipleAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleAirports',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAirlineRequest,
 *   !proto.elude.proto.AirlineResponse>}
 */
const methodDescriptor_GatewayController_GetAirline = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAirline',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetAirlineRequest,
  proto_location_location_response_pb.AirlineResponse,
  /**
   * @param {!proto.elude.proto.GetAirlineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlineResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAirline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirline,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlineResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAirline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAirlinesRequest,
 *   !proto.elude.proto.AirlinesResponse>}
 */
const methodDescriptor_GatewayController_GetAirlines = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAirlines',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetAirlinesRequest,
  proto_location_location_response_pb.AirlinesResponse,
  /**
   * @param {!proto.elude.proto.GetAirlinesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlinesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAirlinesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlinesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlinesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAirlines =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirlines',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirlines,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAirlinesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlinesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAirlines =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAirlines',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAirlines);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateAirlineRequest,
 *   !proto.elude.proto.AirlineResponse>}
 */
const methodDescriptor_GatewayController_CreateAirline = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateAirline',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateAirlineRequest,
  proto_location_location_response_pb.AirlineResponse,
  /**
   * @param {!proto.elude.proto.CreateAirlineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlineResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createAirline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateAirline,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlineResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createAirline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateAirline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateAirlineRequest,
 *   !proto.elude.proto.AirlineResponse>}
 */
const methodDescriptor_GatewayController_UpdateAirline = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateAirline',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateAirlineRequest,
  proto_location_location_response_pb.AirlineResponse,
  /**
   * @param {!proto.elude.proto.UpdateAirlineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlineResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateAirline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAirline,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlineResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateAirline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAirline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteAirlineRequest,
 *   !proto.elude.proto.AirlineResponse>}
 */
const methodDescriptor_GatewayController_DeleteAirline = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteAirline',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteAirlineRequest,
  proto_location_location_response_pb.AirlineResponse,
  /**
   * @param {!proto.elude.proto.DeleteAirlineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlineResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteAirline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteAirline,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteAirlineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlineResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteAirline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteAirline',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteAirline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMultipleAirlinesRequest,
 *   !proto.elude.proto.AirlinesResponse>}
 */
const methodDescriptor_GatewayController_GetMultipleAirlines = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMultipleAirlines',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetMultipleAirlinesRequest,
  proto_location_location_response_pb.AirlinesResponse,
  /**
   * @param {!proto.elude.proto.GetMultipleAirlinesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlinesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMultipleAirlinesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlinesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlinesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMultipleAirlines =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleAirlines',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleAirlines,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMultipleAirlinesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlinesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMultipleAirlines =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleAirlines',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleAirlines);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateAirlineWithImageUnaryRequest,
 *   !proto.elude.proto.AirlineResponse>}
 */
const methodDescriptor_GatewayController_UpdateAirlineWithImageUnary = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateAirlineWithImageUnary',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateAirlineWithImageUnaryRequest,
  proto_location_location_response_pb.AirlineResponse,
  /**
   * @param {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.AirlineResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AirlineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AirlineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateAirlineWithImageUnary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAirlineWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAirlineWithImageUnary,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AirlineResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateAirlineWithImageUnary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAirlineWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAirlineWithImageUnary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCountryRequest,
 *   !proto.elude.proto.CountryResponse>}
 */
const methodDescriptor_GatewayController_GetCountry = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCountry',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCountryRequest,
  proto_location_location_response_pb.CountryResponse,
  /**
   * @param {!proto.elude.proto.GetCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CountryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CountryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CountryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCountry,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CountryResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetCountriesRequest,
 *   !proto.elude.proto.CountriesResponse>}
 */
const methodDescriptor_GatewayController_GetCountries = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCountries',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetCountriesRequest,
  proto_location_location_response_pb.CountriesResponse,
  /**
   * @param {!proto.elude.proto.GetCountriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CountriesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CountriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CountriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCountries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCountries',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCountries,
      callback);
};


/**
 * @param {!proto.elude.proto.GetCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CountriesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCountries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCountries',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCountries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateCountryRequest,
 *   !proto.elude.proto.CountryResponse>}
 */
const methodDescriptor_GatewayController_CreateCountry = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateCountry',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateCountryRequest,
  proto_location_location_response_pb.CountryResponse,
  /**
   * @param {!proto.elude.proto.CreateCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CountryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CountryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CountryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateCountry,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CountryResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateCountryRequest,
 *   !proto.elude.proto.CountryResponse>}
 */
const methodDescriptor_GatewayController_UpdateCountry = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateCountry',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateCountryRequest,
  proto_location_location_response_pb.CountryResponse,
  /**
   * @param {!proto.elude.proto.UpdateCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CountryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CountryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CountryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateCountry,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CountryResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteCountryRequest,
 *   !proto.elude.proto.CountryResponse>}
 */
const methodDescriptor_GatewayController_DeleteCountry = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteCountry',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteCountryRequest,
  proto_location_location_response_pb.CountryResponse,
  /**
   * @param {!proto.elude.proto.DeleteCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CountryResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CountryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CountryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteCountry,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CountryResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteCountry',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMultipleCountriesRequest,
 *   !proto.elude.proto.CountriesResponse>}
 */
const methodDescriptor_GatewayController_GetMultipleCountries = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMultipleCountries',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetMultipleCountriesRequest,
  proto_location_location_response_pb.CountriesResponse,
  /**
   * @param {!proto.elude.proto.GetMultipleCountriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.CountriesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMultipleCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.CountriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.CountriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMultipleCountries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleCountries',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleCountries,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMultipleCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.CountriesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMultipleCountries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMultipleCountries',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMultipleCountries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingsRequest,
 *   !proto.elude.proto.GetBookingsResponse>}
 */
const methodDescriptor_GatewayController_GetBookings = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetBookings',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingsRequest,
  proto_booking_booking_response_pb.GetBookingsResponse,
  /**
   * @param {!proto.elude.proto.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GetBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetBookings,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingsRequest,
 *   !proto.elude.proto.GetBookingsResponse>}
 */
const methodDescriptor_GatewayController_GetCompletedBookings = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCompletedBookings',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingsRequest,
  proto_booking_booking_response_pb.GetBookingsResponse,
  /**
   * @param {!proto.elude.proto.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GetBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCompletedBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCompletedBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCompletedBookings,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCompletedBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCompletedBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCompletedBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingsRequest,
 *   !proto.elude.proto.GetBookingsResponse>}
 */
const methodDescriptor_GatewayController_GetUpcomingBookings = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetUpcomingBookings',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingsRequest,
  proto_booking_booking_response_pb.GetBookingsResponse,
  /**
   * @param {!proto.elude.proto.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GetBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getUpcomingBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetUpcomingBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetUpcomingBookings,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getUpcomingBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetUpcomingBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetUpcomingBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingsRequest,
 *   !proto.elude.proto.GetBookingsResponse>}
 */
const methodDescriptor_GatewayController_GetCurrentBookings = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetCurrentBookings',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingsRequest,
  proto_booking_booking_response_pb.GetBookingsResponse,
  /**
   * @param {!proto.elude.proto.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GetBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getCurrentBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCurrentBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCurrentBookings,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getCurrentBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetCurrentBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetCurrentBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingsRequest,
 *   !proto.elude.proto.GetHomeBookingsResponse>}
 */
const methodDescriptor_GatewayController_GetHomeBookings = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetHomeBookings',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingsRequest,
  proto_booking_booking_response_pb.GetHomeBookingsResponse,
  /**
   * @param {!proto.elude.proto.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GetHomeBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetHomeBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetHomeBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getHomeBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetHomeBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetHomeBookings,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetHomeBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getHomeBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetHomeBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetHomeBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingRequest,
 *   !proto.elude.proto.BookingResponse>}
 */
const methodDescriptor_GatewayController_GetBooking = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetBooking',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingRequest,
  proto_booking_booking_response_pb.BookingResponse,
  /**
   * @param {!proto.elude.proto.GetBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.BookingResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.BookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.BookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetBooking',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetBooking,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.BookingResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetBooking',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ChangeBookingStatusRequest,
 *   !proto.elude.proto.BookingResponse>}
 */
const methodDescriptor_GatewayController_ChangeBookingStatus = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/ChangeBookingStatus',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.ChangeBookingStatusRequest,
  proto_booking_booking_response_pb.BookingResponse,
  /**
   * @param {!proto.elude.proto.ChangeBookingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.BookingResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ChangeBookingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.BookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.BookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.changeBookingStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/ChangeBookingStatus',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ChangeBookingStatus,
      callback);
};


/**
 * @param {!proto.elude.proto.ChangeBookingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.BookingResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.changeBookingStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/ChangeBookingStatus',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ChangeBookingStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetBookingByEludeReferenceRequest,
 *   !proto.elude.proto.BookingResponse>}
 */
const methodDescriptor_GatewayController_GetBookingByEludeReference = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetBookingByEludeReference',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetBookingByEludeReferenceRequest,
  proto_booking_booking_response_pb.BookingResponse,
  /**
   * @param {!proto.elude.proto.GetBookingByEludeReferenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.BookingResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetBookingByEludeReferenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.BookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.BookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getBookingByEludeReference =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetBookingByEludeReference',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetBookingByEludeReference,
      callback);
};


/**
 * @param {!proto.elude.proto.GetBookingByEludeReferenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.BookingResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getBookingByEludeReference =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetBookingByEludeReference',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetBookingByEludeReference);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetTravelStatsRequest,
 *   !proto.elude.proto.GetTravelStatsResponse>}
 */
const methodDescriptor_GatewayController_GetTravelStats = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetTravelStats',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetTravelStatsRequest,
  proto_booking_booking_response_pb.GetTravelStatsResponse,
  /**
   * @param {!proto.elude.proto.GetTravelStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GetTravelStatsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetTravelStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetTravelStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetTravelStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getTravelStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTravelStats',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTravelStats,
      callback);
};


/**
 * @param {!proto.elude.proto.GetTravelStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetTravelStatsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getTravelStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTravelStats',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTravelStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.SendEmailsRequest,
 *   !proto.elude.proto.SendEmailsResponse>}
 */
const methodDescriptor_GatewayController_SendEmailsForUserBookings = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/SendEmailsForUserBookings',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.SendEmailsRequest,
  proto_booking_booking_response_pb.SendEmailsResponse,
  /**
   * @param {!proto.elude.proto.SendEmailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.SendEmailsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.SendEmailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.SendEmailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.SendEmailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.sendEmailsForUserBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/SendEmailsForUserBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_SendEmailsForUserBookings,
      callback);
};


/**
 * @param {!proto.elude.proto.SendEmailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.SendEmailsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.sendEmailsForUserBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/SendEmailsForUserBookings',
      request,
      metadata || {},
      methodDescriptor_GatewayController_SendEmailsForUserBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.SendEmailsRequest,
 *   !proto.elude.proto.SendEmailsResponse>}
 */
const methodDescriptor_GatewayController_SendEmailsForBooking = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/SendEmailsForBooking',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.SendEmailsRequest,
  proto_booking_booking_response_pb.SendEmailsResponse,
  /**
   * @param {!proto.elude.proto.SendEmailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.SendEmailsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.SendEmailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.SendEmailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.SendEmailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.sendEmailsForBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/SendEmailsForBooking',
      request,
      metadata || {},
      methodDescriptor_GatewayController_SendEmailsForBooking,
      callback);
};


/**
 * @param {!proto.elude.proto.SendEmailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.SendEmailsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.sendEmailsForBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/SendEmailsForBooking',
      request,
      metadata || {},
      methodDescriptor_GatewayController_SendEmailsForBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateBookingRequest,
 *   !proto.elude.proto.BookingResponse>}
 */
const methodDescriptor_GatewayController_UpdateBooking = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateBooking',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateBookingRequest,
  proto_booking_booking_response_pb.BookingResponse,
  /**
   * @param {!proto.elude.proto.UpdateBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.BookingResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.BookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.BookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateBooking,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.BookingResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetTravellersRequest,
 *   !proto.elude.proto.TravellersResponse>}
 */
const methodDescriptor_GatewayController_GetTravellers = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetTravellers',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetTravellersRequest,
  proto_booking_booking_response_pb.TravellersResponse,
  /**
   * @param {!proto.elude.proto.GetTravellersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.TravellersResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetTravellersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.TravellersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.TravellersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getTravellers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTravellers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTravellers,
      callback);
};


/**
 * @param {!proto.elude.proto.GetTravellersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.TravellersResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getTravellers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTravellers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTravellers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetTravellerRequest,
 *   !proto.elude.proto.TravellerResponse>}
 */
const methodDescriptor_GatewayController_GetTraveller = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetTraveller',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetTravellerRequest,
  proto_booking_booking_response_pb.TravellerResponse,
  /**
   * @param {!proto.elude.proto.GetTravellerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.TravellerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.TravellerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.TravellerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getTraveller =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTraveller,
      callback);
};


/**
 * @param {!proto.elude.proto.GetTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.TravellerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getTraveller =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetTraveller);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateTravellerRequest,
 *   !proto.elude.proto.TravellerResponse>}
 */
const methodDescriptor_GatewayController_CreateTraveller = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateTraveller',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateTravellerRequest,
  proto_booking_booking_response_pb.TravellerResponse,
  /**
   * @param {!proto.elude.proto.CreateTravellerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.TravellerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.TravellerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.TravellerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createTraveller =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateTraveller,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.TravellerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createTraveller =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateTraveller);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateTravellerRequest,
 *   !proto.elude.proto.TravellerResponse>}
 */
const methodDescriptor_GatewayController_UpdateTraveller = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateTraveller',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateTravellerRequest,
  proto_booking_booking_response_pb.TravellerResponse,
  /**
   * @param {!proto.elude.proto.UpdateTravellerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.TravellerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.TravellerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.TravellerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateTraveller =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateTraveller,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.TravellerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateTraveller =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateTraveller);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteTravellerRequest,
 *   !proto.elude.proto.TravellerResponse>}
 */
const methodDescriptor_GatewayController_DeleteTraveller = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteTraveller',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteTravellerRequest,
  proto_booking_booking_response_pb.TravellerResponse,
  /**
   * @param {!proto.elude.proto.DeleteTravellerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.TravellerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.TravellerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.TravellerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteTraveller =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteTraveller,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteTravellerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.TravellerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteTraveller =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteTraveller',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteTraveller);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPaymentMethodRequest,
 *   !proto.elude.proto.PaymentMethodResponse>}
 */
const methodDescriptor_GatewayController_GetPaymentMethod = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPaymentMethod',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.GetPaymentMethodRequest,
  proto_payment_payment_response_pb.PaymentMethodResponse,
  /**
   * @param {!proto.elude.proto.GetPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentMethod,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPaymentMethodsRequest,
 *   !proto.elude.proto.PaymentMethodsResponse>}
 */
const methodDescriptor_GatewayController_GetPaymentMethods = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPaymentMethods',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.GetPaymentMethodsRequest,
  proto_payment_payment_response_pb.PaymentMethodsResponse,
  /**
   * @param {!proto.elude.proto.GetPaymentMethodsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentMethodsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPaymentMethodsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentMethodsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentMethodsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPaymentMethods =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentMethods',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentMethods,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPaymentMethodsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentMethodsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPaymentMethods =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentMethods',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentMethods);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreatePaymentMethodRequest,
 *   !proto.elude.proto.PaymentMethodResponse>}
 */
const methodDescriptor_GatewayController_CreatePaymentMethod = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreatePaymentMethod',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.CreatePaymentMethodRequest,
  proto_payment_payment_response_pb.PaymentMethodResponse,
  /**
   * @param {!proto.elude.proto.CreatePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreatePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreatePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreatePaymentMethod,
      callback);
};


/**
 * @param {!proto.elude.proto.CreatePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreatePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreatePaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdatePaymentMethodRequest,
 *   !proto.elude.proto.PaymentMethodResponse>}
 */
const methodDescriptor_GatewayController_UpdatePaymentMethod = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdatePaymentMethod',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.UpdatePaymentMethodRequest,
  proto_payment_payment_response_pb.PaymentMethodResponse,
  /**
   * @param {!proto.elude.proto.UpdatePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdatePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updatePaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdatePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdatePaymentMethod,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdatePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updatePaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdatePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdatePaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeletePaymentMethodRequest,
 *   !proto.elude.proto.PaymentMethodResponse>}
 */
const methodDescriptor_GatewayController_DeletePaymentMethod = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeletePaymentMethod',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.DeletePaymentMethodRequest,
  proto_payment_payment_response_pb.PaymentMethodResponse,
  /**
   * @param {!proto.elude.proto.DeletePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeletePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deletePaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeletePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeletePaymentMethod,
      callback);
};


/**
 * @param {!proto.elude.proto.DeletePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deletePaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeletePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeletePaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPaymentProviderRequest,
 *   !proto.elude.proto.PaymentProviderResponse>}
 */
const methodDescriptor_GatewayController_GetPaymentProvider = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPaymentProvider',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.GetPaymentProviderRequest,
  proto_payment_payment_response_pb.PaymentProviderResponse,
  /**
   * @param {!proto.elude.proto.GetPaymentProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentProviderResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPaymentProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentProvider,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentProviderResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPaymentProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPaymentProvidersRequest,
 *   !proto.elude.proto.PaymentProvidersResponse>}
 */
const methodDescriptor_GatewayController_GetPaymentProviders = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPaymentProviders',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.GetPaymentProvidersRequest,
  proto_payment_payment_response_pb.PaymentProvidersResponse,
  /**
   * @param {!proto.elude.proto.GetPaymentProvidersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentProvidersResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPaymentProvidersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentProvidersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentProvidersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPaymentProviders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentProviders',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentProviders,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPaymentProvidersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentProvidersResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPaymentProviders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPaymentProviders',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPaymentProviders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreatePaymentProviderRequest,
 *   !proto.elude.proto.PaymentProviderResponse>}
 */
const methodDescriptor_GatewayController_CreatePaymentProvider = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreatePaymentProvider',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.CreatePaymentProviderRequest,
  proto_payment_payment_response_pb.PaymentProviderResponse,
  /**
   * @param {!proto.elude.proto.CreatePaymentProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentProviderResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreatePaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createPaymentProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreatePaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreatePaymentProvider,
      callback);
};


/**
 * @param {!proto.elude.proto.CreatePaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentProviderResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createPaymentProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreatePaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreatePaymentProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdatePaymentProviderRequest,
 *   !proto.elude.proto.PaymentProviderResponse>}
 */
const methodDescriptor_GatewayController_UpdatePaymentProvider = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdatePaymentProvider',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.UpdatePaymentProviderRequest,
  proto_payment_payment_response_pb.PaymentProviderResponse,
  /**
   * @param {!proto.elude.proto.UpdatePaymentProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentProviderResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdatePaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updatePaymentProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdatePaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdatePaymentProvider,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdatePaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentProviderResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updatePaymentProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdatePaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdatePaymentProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeletePaymentProviderRequest,
 *   !proto.elude.proto.PaymentProviderResponse>}
 */
const methodDescriptor_GatewayController_DeletePaymentProvider = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeletePaymentProvider',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.DeletePaymentProviderRequest,
  proto_payment_payment_response_pb.PaymentProviderResponse,
  /**
   * @param {!proto.elude.proto.DeletePaymentProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PaymentProviderResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeletePaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PaymentProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PaymentProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deletePaymentProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeletePaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeletePaymentProvider,
      callback);
};


/**
 * @param {!proto.elude.proto.DeletePaymentProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PaymentProviderResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deletePaymentProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeletePaymentProvider',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeletePaymentProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetStickerRequest,
 *   !proto.elude.proto.StickerResponse>}
 */
const methodDescriptor_GatewayController_GetSticker = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetSticker',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetStickerRequest,
  proto_booking_booking_response_pb.StickerResponse,
  /**
   * @param {!proto.elude.proto.GetStickerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getSticker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetSticker,
      callback);
};


/**
 * @param {!proto.elude.proto.GetStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getSticker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetSticker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetStickersRequest,
 *   !proto.elude.proto.StickersResponse>}
 */
const methodDescriptor_GatewayController_GetStickers = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetStickers',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetStickersRequest,
  proto_booking_booking_response_pb.StickersResponse,
  /**
   * @param {!proto.elude.proto.GetStickersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickersResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetStickersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getStickers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetStickers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetStickers,
      callback);
};


/**
 * @param {!proto.elude.proto.GetStickersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickersResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getStickers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetStickers',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetStickers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateStickerRequest,
 *   !proto.elude.proto.StickerResponse>}
 */
const methodDescriptor_GatewayController_CreateSticker = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateSticker',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateStickerRequest,
  proto_booking_booking_response_pb.StickerResponse,
  /**
   * @param {!proto.elude.proto.CreateStickerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createSticker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateSticker,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createSticker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateSticker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateStickerRequest,
 *   !proto.elude.proto.StickerResponse>}
 */
const methodDescriptor_GatewayController_UpdateSticker = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateSticker',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateStickerRequest,
  proto_booking_booking_response_pb.StickerResponse,
  /**
   * @param {!proto.elude.proto.UpdateStickerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateSticker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateSticker,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateSticker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateSticker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteStickerRequest,
 *   !proto.elude.proto.StickerResponse>}
 */
const methodDescriptor_GatewayController_DeleteSticker = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteSticker',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteStickerRequest,
  proto_booking_booking_response_pb.StickerResponse,
  /**
   * @param {!proto.elude.proto.DeleteStickerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteSticker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteSticker,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteStickerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteSticker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteSticker',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteSticker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetStickersForUserRequest,
 *   !proto.elude.proto.StickerUserResponse>}
 */
const methodDescriptor_GatewayController_GetStickersForUser = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetStickersForUser',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetStickersForUserRequest,
  proto_booking_booking_response_pb.StickerUserResponse,
  /**
   * @param {!proto.elude.proto.GetStickersForUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickerUserResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetStickersForUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickerUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickerUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getStickersForUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetStickersForUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetStickersForUser,
      callback);
};


/**
 * @param {!proto.elude.proto.GetStickersForUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickerUserResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getStickersForUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetStickersForUser',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetStickersForUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateStickerWithImageUnaryRequest,
 *   !proto.elude.proto.StickerResponse>}
 */
const methodDescriptor_GatewayController_UpdateStickerWithImageUnary = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateStickerWithImageUnary',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateStickerWithImageUnaryRequest,
  proto_booking_booking_response_pb.StickerResponse,
  /**
   * @param {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.StickerResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.StickerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.StickerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateStickerWithImageUnary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateStickerWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateStickerWithImageUnary,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.StickerResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateStickerWithImageUnary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateStickerWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateStickerWithImageUnary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAmenityRequest,
 *   !proto.elude.proto.AmenityResponse>}
 */
const methodDescriptor_GatewayController_GetAmenity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAmenity',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetAmenityRequest,
  proto_booking_booking_response_pb.AmenityResponse,
  /**
   * @param {!proto.elude.proto.GetAmenityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AmenityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AmenityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AmenityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAmenity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAmenity,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AmenityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAmenity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAmenity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAmenitiesRequest,
 *   !proto.elude.proto.AmenitiesResponse>}
 */
const methodDescriptor_GatewayController_GetAmenities = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAmenities',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetAmenitiesRequest,
  proto_booking_booking_response_pb.AmenitiesResponse,
  /**
   * @param {!proto.elude.proto.GetAmenitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AmenitiesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAmenitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AmenitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AmenitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAmenities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAmenities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAmenities,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAmenitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AmenitiesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAmenities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAmenities',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAmenities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateAmenityRequest,
 *   !proto.elude.proto.AmenityResponse>}
 */
const methodDescriptor_GatewayController_CreateAmenity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateAmenity',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateAmenityRequest,
  proto_booking_booking_response_pb.AmenityResponse,
  /**
   * @param {!proto.elude.proto.CreateAmenityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AmenityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AmenityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AmenityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createAmenity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateAmenity,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AmenityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createAmenity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateAmenity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateAmenityRequest,
 *   !proto.elude.proto.AmenityResponse>}
 */
const methodDescriptor_GatewayController_UpdateAmenity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateAmenity',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateAmenityRequest,
  proto_booking_booking_response_pb.AmenityResponse,
  /**
   * @param {!proto.elude.proto.UpdateAmenityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AmenityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AmenityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AmenityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateAmenity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAmenity,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AmenityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateAmenity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAmenity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteAmenityRequest,
 *   !proto.elude.proto.AmenityResponse>}
 */
const methodDescriptor_GatewayController_DeleteAmenity = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteAmenity',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteAmenityRequest,
  proto_booking_booking_response_pb.AmenityResponse,
  /**
   * @param {!proto.elude.proto.DeleteAmenityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AmenityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AmenityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AmenityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteAmenity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteAmenity,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteAmenityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AmenityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteAmenity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteAmenity',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteAmenity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateAmenityWithImageUnaryRequest,
 *   !proto.elude.proto.AmenityResponse>}
 */
const methodDescriptor_GatewayController_UpdateAmenityWithImageUnary = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateAmenityWithImageUnary',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateAmenityWithImageUnaryRequest,
  proto_booking_booking_response_pb.AmenityResponse,
  /**
   * @param {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AmenityResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AmenityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AmenityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateAmenityWithImageUnary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAmenityWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAmenityWithImageUnary,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AmenityResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateAmenityWithImageUnary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateAmenityWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateAmenityWithImageUnary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetRegionRequest,
 *   !proto.elude.proto.RegionResponse>}
 */
const methodDescriptor_GatewayController_GetRegion = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetRegion',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetRegionRequest,
  proto_location_location_response_pb.RegionResponse,
  /**
   * @param {!proto.elude.proto.GetRegionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.RegionResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getRegion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRegion,
      callback);
};


/**
 * @param {!proto.elude.proto.GetRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RegionResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getRegion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRegion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetRegionsRequest,
 *   !proto.elude.proto.RegionsResponse>}
 */
const methodDescriptor_GatewayController_GetRegions = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetRegions',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetRegionsRequest,
  proto_location_location_response_pb.RegionsResponse,
  /**
   * @param {!proto.elude.proto.GetRegionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.RegionsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetRegionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RegionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RegionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getRegions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRegions',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRegions,
      callback);
};


/**
 * @param {!proto.elude.proto.GetRegionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RegionsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getRegions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRegions',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRegions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateRegionRequest,
 *   !proto.elude.proto.RegionResponse>}
 */
const methodDescriptor_GatewayController_CreateRegion = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateRegion',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateRegionRequest,
  proto_location_location_response_pb.RegionResponse,
  /**
   * @param {!proto.elude.proto.CreateRegionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.RegionResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createRegion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateRegion,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RegionResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createRegion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateRegion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateRegionRequest,
 *   !proto.elude.proto.RegionResponse>}
 */
const methodDescriptor_GatewayController_UpdateRegion = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateRegion',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateRegionRequest,
  proto_location_location_response_pb.RegionResponse,
  /**
   * @param {!proto.elude.proto.UpdateRegionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.RegionResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateRegion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateRegion,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RegionResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateRegion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateRegion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteRegionRequest,
 *   !proto.elude.proto.RegionResponse>}
 */
const methodDescriptor_GatewayController_DeleteRegion = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteRegion',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteRegionRequest,
  proto_location_location_response_pb.RegionResponse,
  /**
   * @param {!proto.elude.proto.DeleteRegionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.RegionResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteRegion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteRegion,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RegionResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteRegion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteRegion',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteRegion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetGdsRequest,
 *   !proto.elude.proto.GdsResponse>}
 */
const methodDescriptor_GatewayController_GetGds = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetGds',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetGdsRequest,
  proto_booking_booking_response_pb.GdsResponse,
  /**
   * @param {!proto.elude.proto.GetGdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GdsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getGds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetGds,
      callback);
};


/**
 * @param {!proto.elude.proto.GetGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GdsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getGds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetGds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetAllGdsRequest,
 *   !proto.elude.proto.AllGdsResponse>}
 */
const methodDescriptor_GatewayController_GetAllGds = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetAllGds',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetAllGdsRequest,
  proto_booking_booking_response_pb.AllGdsResponse,
  /**
   * @param {!proto.elude.proto.GetAllGdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.AllGdsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetAllGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.AllGdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.AllGdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getAllGds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAllGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAllGds,
      callback);
};


/**
 * @param {!proto.elude.proto.GetAllGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.AllGdsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getAllGds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetAllGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetAllGds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateGdsRequest,
 *   !proto.elude.proto.GdsResponse>}
 */
const methodDescriptor_GatewayController_CreateGds = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateGds',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateGdsRequest,
  proto_booking_booking_response_pb.GdsResponse,
  /**
   * @param {!proto.elude.proto.CreateGdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GdsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createGds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateGds,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GdsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createGds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateGds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateGdsRequest,
 *   !proto.elude.proto.GdsResponse>}
 */
const methodDescriptor_GatewayController_UpdateGds = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateGds',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateGdsRequest,
  proto_booking_booking_response_pb.GdsResponse,
  /**
   * @param {!proto.elude.proto.UpdateGdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GdsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateGds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateGds,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GdsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateGds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateGds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteGdsRequest,
 *   !proto.elude.proto.GdsResponse>}
 */
const methodDescriptor_GatewayController_DeleteGds = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteGds',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteGdsRequest,
  proto_booking_booking_response_pb.GdsResponse,
  /**
   * @param {!proto.elude.proto.DeleteGdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.GdsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteGds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteGds,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteGdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GdsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteGds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteGds',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteGds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMealPlanRequest,
 *   !proto.elude.proto.MealPlanResponse>}
 */
const methodDescriptor_GatewayController_GetMealPlan = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMealPlan',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetMealPlanRequest,
  proto_booking_booking_response_pb.MealPlanResponse,
  /**
   * @param {!proto.elude.proto.GetMealPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.MealPlanResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.MealPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.MealPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMealPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMealPlan,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.MealPlanResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMealPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMealPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetMealPlansRequest,
 *   !proto.elude.proto.MealPlansResponse>}
 */
const methodDescriptor_GatewayController_GetMealPlans = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetMealPlans',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetMealPlansRequest,
  proto_booking_booking_response_pb.MealPlansResponse,
  /**
   * @param {!proto.elude.proto.GetMealPlansRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.MealPlansResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetMealPlansRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.MealPlansResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.MealPlansResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getMealPlans =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMealPlans',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMealPlans,
      callback);
};


/**
 * @param {!proto.elude.proto.GetMealPlansRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.MealPlansResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getMealPlans =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetMealPlans',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetMealPlans);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateMealPlanRequest,
 *   !proto.elude.proto.MealPlanResponse>}
 */
const methodDescriptor_GatewayController_CreateMealPlan = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateMealPlan',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateMealPlanRequest,
  proto_booking_booking_response_pb.MealPlanResponse,
  /**
   * @param {!proto.elude.proto.CreateMealPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.MealPlanResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.MealPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.MealPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createMealPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateMealPlan,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.MealPlanResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createMealPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateMealPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateMealPlanRequest,
 *   !proto.elude.proto.MealPlanResponse>}
 */
const methodDescriptor_GatewayController_UpdateMealPlan = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateMealPlan',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateMealPlanRequest,
  proto_booking_booking_response_pb.MealPlanResponse,
  /**
   * @param {!proto.elude.proto.UpdateMealPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.MealPlanResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.MealPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.MealPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateMealPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateMealPlan,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.MealPlanResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateMealPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateMealPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteMealPlanRequest,
 *   !proto.elude.proto.MealPlanResponse>}
 */
const methodDescriptor_GatewayController_DeleteMealPlan = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteMealPlan',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteMealPlanRequest,
  proto_booking_booking_response_pb.MealPlanResponse,
  /**
   * @param {!proto.elude.proto.DeleteMealPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.MealPlanResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.MealPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.MealPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteMealPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteMealPlan,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteMealPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.MealPlanResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteMealPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteMealPlan',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteMealPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetRoomViewTypeRequest,
 *   !proto.elude.proto.RoomViewTypeResponse>}
 */
const methodDescriptor_GatewayController_GetRoomViewType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetRoomViewType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetRoomViewTypeRequest,
  proto_booking_booking_response_pb.RoomViewTypeResponse,
  /**
   * @param {!proto.elude.proto.GetRoomViewTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.RoomViewTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoomViewTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoomViewTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getRoomViewType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRoomViewType,
      callback);
};


/**
 * @param {!proto.elude.proto.GetRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoomViewTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getRoomViewType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRoomViewType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetRoomViewTypesRequest,
 *   !proto.elude.proto.RoomViewTypesResponse>}
 */
const methodDescriptor_GatewayController_GetRoomViewTypes = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetRoomViewTypes',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetRoomViewTypesRequest,
  proto_booking_booking_response_pb.RoomViewTypesResponse,
  /**
   * @param {!proto.elude.proto.GetRoomViewTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.RoomViewTypesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetRoomViewTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoomViewTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoomViewTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getRoomViewTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRoomViewTypes',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRoomViewTypes,
      callback);
};


/**
 * @param {!proto.elude.proto.GetRoomViewTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoomViewTypesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getRoomViewTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetRoomViewTypes',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetRoomViewTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateRoomViewTypeRequest,
 *   !proto.elude.proto.RoomViewTypeResponse>}
 */
const methodDescriptor_GatewayController_CreateRoomViewType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateRoomViewType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateRoomViewTypeRequest,
  proto_booking_booking_response_pb.RoomViewTypeResponse,
  /**
   * @param {!proto.elude.proto.CreateRoomViewTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.RoomViewTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoomViewTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoomViewTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createRoomViewType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateRoomViewType,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoomViewTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createRoomViewType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateRoomViewType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateRoomViewTypeRequest,
 *   !proto.elude.proto.RoomViewTypeResponse>}
 */
const methodDescriptor_GatewayController_UpdateRoomViewType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateRoomViewType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateRoomViewTypeRequest,
  proto_booking_booking_response_pb.RoomViewTypeResponse,
  /**
   * @param {!proto.elude.proto.UpdateRoomViewTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.RoomViewTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoomViewTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoomViewTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateRoomViewType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateRoomViewType,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoomViewTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateRoomViewType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateRoomViewType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteRoomViewTypeRequest,
 *   !proto.elude.proto.RoomViewTypeResponse>}
 */
const methodDescriptor_GatewayController_DeleteRoomViewType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteRoomViewType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteRoomViewTypeRequest,
  proto_booking_booking_response_pb.RoomViewTypeResponse,
  /**
   * @param {!proto.elude.proto.DeleteRoomViewTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.RoomViewTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.RoomViewTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.RoomViewTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteRoomViewType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteRoomViewType,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteRoomViewTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.RoomViewTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteRoomViewType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteRoomViewType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteRoomViewType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetFlightTypeRequest,
 *   !proto.elude.proto.FlightTypeResponse>}
 */
const methodDescriptor_GatewayController_GetFlightType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetFlightType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetFlightTypeRequest,
  proto_booking_booking_response_pb.FlightTypeResponse,
  /**
   * @param {!proto.elude.proto.GetFlightTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.FlightTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FlightTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FlightTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getFlightType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFlightType,
      callback);
};


/**
 * @param {!proto.elude.proto.GetFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FlightTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getFlightType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFlightType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetFlightTypesRequest,
 *   !proto.elude.proto.FlightTypesResponse>}
 */
const methodDescriptor_GatewayController_GetFlightTypes = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetFlightTypes',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.GetFlightTypesRequest,
  proto_booking_booking_response_pb.FlightTypesResponse,
  /**
   * @param {!proto.elude.proto.GetFlightTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.FlightTypesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetFlightTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FlightTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FlightTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getFlightTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFlightTypes',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFlightTypes,
      callback);
};


/**
 * @param {!proto.elude.proto.GetFlightTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FlightTypesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getFlightTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetFlightTypes',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetFlightTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateFlightTypeRequest,
 *   !proto.elude.proto.FlightTypeResponse>}
 */
const methodDescriptor_GatewayController_CreateFlightType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateFlightType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.CreateFlightTypeRequest,
  proto_booking_booking_response_pb.FlightTypeResponse,
  /**
   * @param {!proto.elude.proto.CreateFlightTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.FlightTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FlightTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FlightTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createFlightType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateFlightType,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FlightTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createFlightType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateFlightType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateFlightTypeRequest,
 *   !proto.elude.proto.FlightTypeResponse>}
 */
const methodDescriptor_GatewayController_UpdateFlightType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateFlightType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.UpdateFlightTypeRequest,
  proto_booking_booking_response_pb.FlightTypeResponse,
  /**
   * @param {!proto.elude.proto.UpdateFlightTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.FlightTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FlightTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FlightTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateFlightType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateFlightType,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FlightTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateFlightType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateFlightType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteFlightTypeRequest,
 *   !proto.elude.proto.FlightTypeResponse>}
 */
const methodDescriptor_GatewayController_DeleteFlightType = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteFlightType',
  grpc.web.MethodType.UNARY,
  proto_booking_booking_request_pb.DeleteFlightTypeRequest,
  proto_booking_booking_response_pb.FlightTypeResponse,
  /**
   * @param {!proto.elude.proto.DeleteFlightTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_booking_booking_response_pb.FlightTypeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.FlightTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.FlightTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteFlightType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteFlightType,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteFlightTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.FlightTypeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteFlightType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteFlightType',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteFlightType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPromotionCodeRequest,
 *   !proto.elude.proto.PromotionCodeResponse>}
 */
const methodDescriptor_GatewayController_GetPromotionCode = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPromotionCode',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.GetPromotionCodeRequest,
  proto_payment_payment_response_pb.PromotionCodeResponse,
  /**
   * @param {!proto.elude.proto.GetPromotionCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PromotionCodeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PromotionCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PromotionCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPromotionCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPromotionCode,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PromotionCodeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPromotionCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPromotionCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPromotionCodesRequest,
 *   !proto.elude.proto.PromotionCodesResponse>}
 */
const methodDescriptor_GatewayController_GetPromotionCodes = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPromotionCodes',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.GetPromotionCodesRequest,
  proto_payment_payment_response_pb.PromotionCodesResponse,
  /**
   * @param {!proto.elude.proto.GetPromotionCodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PromotionCodesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPromotionCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PromotionCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PromotionCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPromotionCodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPromotionCodes',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPromotionCodes,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPromotionCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PromotionCodesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPromotionCodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPromotionCodes',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPromotionCodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreatePromotionCodeRequest,
 *   !proto.elude.proto.PromotionCodeResponse>}
 */
const methodDescriptor_GatewayController_CreatePromotionCode = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreatePromotionCode',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.CreatePromotionCodeRequest,
  proto_payment_payment_response_pb.PromotionCodeResponse,
  /**
   * @param {!proto.elude.proto.CreatePromotionCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PromotionCodeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreatePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PromotionCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PromotionCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createPromotionCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreatePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreatePromotionCode,
      callback);
};


/**
 * @param {!proto.elude.proto.CreatePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PromotionCodeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createPromotionCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreatePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreatePromotionCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdatePromotionCodeRequest,
 *   !proto.elude.proto.PromotionCodeResponse>}
 */
const methodDescriptor_GatewayController_UpdatePromotionCode = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdatePromotionCode',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.UpdatePromotionCodeRequest,
  proto_payment_payment_response_pb.PromotionCodeResponse,
  /**
   * @param {!proto.elude.proto.UpdatePromotionCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PromotionCodeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdatePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PromotionCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PromotionCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updatePromotionCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdatePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdatePromotionCode,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdatePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PromotionCodeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updatePromotionCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdatePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdatePromotionCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeletePromotionCodeRequest,
 *   !proto.elude.proto.PromotionCodeResponse>}
 */
const methodDescriptor_GatewayController_DeletePromotionCode = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeletePromotionCode',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.DeletePromotionCodeRequest,
  proto_payment_payment_response_pb.PromotionCodeResponse,
  /**
   * @param {!proto.elude.proto.DeletePromotionCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.PromotionCodeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeletePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.PromotionCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.PromotionCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deletePromotionCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeletePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeletePromotionCode,
      callback);
};


/**
 * @param {!proto.elude.proto.DeletePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.PromotionCodeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deletePromotionCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeletePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeletePromotionCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.ValidatePromotionCodeRequest,
 *   !proto.elude.proto.ValidatePromotionCodeResponse>}
 */
const methodDescriptor_GatewayController_ValidatePromotionCode = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/ValidatePromotionCode',
  grpc.web.MethodType.UNARY,
  proto_payment_payment_request_pb.ValidatePromotionCodeRequest,
  proto_payment_payment_response_pb.ValidatePromotionCodeResponse,
  /**
   * @param {!proto.elude.proto.ValidatePromotionCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_payment_payment_response_pb.ValidatePromotionCodeResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.ValidatePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ValidatePromotionCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ValidatePromotionCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.validatePromotionCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/ValidatePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ValidatePromotionCode,
      callback);
};


/**
 * @param {!proto.elude.proto.ValidatePromotionCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ValidatePromotionCodeResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.validatePromotionCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/ValidatePromotionCode',
      request,
      metadata || {},
      methodDescriptor_GatewayController_ValidatePromotionCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetPreferencesRequest,
 *   !proto.elude.proto.GetPreferencesResponse>}
 */
const methodDescriptor_GatewayController_GetPreferences = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetPreferences',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetPreferencesRequest,
  proto_location_location_response_pb.GetPreferencesResponse,
  /**
   * @param {!proto.elude.proto.GetPreferencesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.GetPreferencesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetPreferencesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.GetPreferencesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.GetPreferencesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getPreferences =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPreferences',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPreferences,
      callback);
};


/**
 * @param {!proto.elude.proto.GetPreferencesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.GetPreferencesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getPreferences =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetPreferences',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetPreferences);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateUserPreferencesRequest,
 *   !proto.elude.proto.UpdateUserPreferencesResponse>}
 */
const methodDescriptor_GatewayController_UpdateUserPreferences = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateUserPreferences',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateUserPreferencesRequest,
  proto_location_location_response_pb.UpdateUserPreferencesResponse,
  /**
   * @param {!proto.elude.proto.UpdateUserPreferencesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.UpdateUserPreferencesResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateUserPreferencesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.UpdateUserPreferencesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.UpdateUserPreferencesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateUserPreferences =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateUserPreferences',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateUserPreferences,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateUserPreferencesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.UpdateUserPreferencesResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateUserPreferences =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateUserPreferences',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateUserPreferences);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetShardsRequest,
 *   !proto.elude.proto.ShardsResponse>}
 */
const methodDescriptor_GatewayController_GetShards = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetShards',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetShardsRequest,
  proto_location_location_response_pb.ShardsResponse,
  /**
   * @param {!proto.elude.proto.GetShardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ShardsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetShardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ShardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ShardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getShards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetShards',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetShards,
      callback);
};


/**
 * @param {!proto.elude.proto.GetShardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ShardsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getShards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetShards',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetShards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetShardRequest,
 *   !proto.elude.proto.ShardResponse>}
 */
const methodDescriptor_GatewayController_GetShard = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetShard',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetShardRequest,
  proto_location_location_response_pb.ShardResponse,
  /**
   * @param {!proto.elude.proto.GetShardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ShardResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetShardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ShardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ShardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getShard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetShard,
      callback);
};


/**
 * @param {!proto.elude.proto.GetShardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ShardResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getShard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetShard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateShardRequest,
 *   !proto.elude.proto.ShardResponse>}
 */
const methodDescriptor_GatewayController_CreateShard = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateShard',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateShardRequest,
  proto_location_location_response_pb.ShardResponse,
  /**
   * @param {!proto.elude.proto.CreateShardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ShardResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateShardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ShardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ShardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createShard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateShard,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateShardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ShardResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createShard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateShard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateShardRequest,
 *   !proto.elude.proto.ShardResponse>}
 */
const methodDescriptor_GatewayController_UpdateShard = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateShard',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateShardRequest,
  proto_location_location_response_pb.ShardResponse,
  /**
   * @param {!proto.elude.proto.UpdateShardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ShardResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateShardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ShardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ShardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateShard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateShard,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateShardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ShardResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateShard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateShard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteShardRequest,
 *   !proto.elude.proto.ShardResponse>}
 */
const methodDescriptor_GatewayController_DeleteShard = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteShard',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteShardRequest,
  proto_location_location_response_pb.ShardResponse,
  /**
   * @param {!proto.elude.proto.DeleteShardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ShardResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteShardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ShardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ShardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteShard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteShard,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteShardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ShardResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteShard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteShard',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteShard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetParentLocationRequest,
 *   !proto.elude.proto.ParentLocationResponse>}
 */
const methodDescriptor_GatewayController_GetParentLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetParentLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetParentLocationRequest,
  proto_location_location_response_pb.ParentLocationResponse,
  /**
   * @param {!proto.elude.proto.GetParentLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ParentLocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ParentLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ParentLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getParentLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetParentLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.GetParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ParentLocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getParentLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetParentLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.GetParentLocationsRequest,
 *   !proto.elude.proto.ParentLocationsResponse>}
 */
const methodDescriptor_GatewayController_GetParentLocations = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/GetParentLocations',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.GetParentLocationsRequest,
  proto_location_location_response_pb.ParentLocationsResponse,
  /**
   * @param {!proto.elude.proto.GetParentLocationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ParentLocationsResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.GetParentLocationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ParentLocationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ParentLocationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.getParentLocations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/GetParentLocations',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetParentLocations,
      callback);
};


/**
 * @param {!proto.elude.proto.GetParentLocationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ParentLocationsResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.getParentLocations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/GetParentLocations',
      request,
      metadata || {},
      methodDescriptor_GatewayController_GetParentLocations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.CreateParentLocationRequest,
 *   !proto.elude.proto.ParentLocationResponse>}
 */
const methodDescriptor_GatewayController_CreateParentLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/CreateParentLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.CreateParentLocationRequest,
  proto_location_location_response_pb.ParentLocationResponse,
  /**
   * @param {!proto.elude.proto.CreateParentLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ParentLocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.CreateParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ParentLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ParentLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.createParentLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateParentLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.CreateParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ParentLocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.createParentLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/CreateParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_CreateParentLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateParentLocationRequest,
 *   !proto.elude.proto.ParentLocationResponse>}
 */
const methodDescriptor_GatewayController_UpdateParentLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateParentLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateParentLocationRequest,
  proto_location_location_response_pb.ParentLocationResponse,
  /**
   * @param {!proto.elude.proto.UpdateParentLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ParentLocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ParentLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ParentLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateParentLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateParentLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ParentLocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateParentLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateParentLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.DeleteParentLocationRequest,
 *   !proto.elude.proto.ParentLocationResponse>}
 */
const methodDescriptor_GatewayController_DeleteParentLocation = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/DeleteParentLocation',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.DeleteParentLocationRequest,
  proto_location_location_response_pb.ParentLocationResponse,
  /**
   * @param {!proto.elude.proto.DeleteParentLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ParentLocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.DeleteParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ParentLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ParentLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.deleteParentLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteParentLocation,
      callback);
};


/**
 * @param {!proto.elude.proto.DeleteParentLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ParentLocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.deleteParentLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/DeleteParentLocation',
      request,
      metadata || {},
      methodDescriptor_GatewayController_DeleteParentLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.elude.proto.UpdateParentLocationWithImageUnaryRequest,
 *   !proto.elude.proto.ParentLocationResponse>}
 */
const methodDescriptor_GatewayController_UpdateParentLocationWithImageUnary = new grpc.web.MethodDescriptor(
  '/elude.proto.GatewayController/UpdateParentLocationWithImageUnary',
  grpc.web.MethodType.UNARY,
  proto_location_location_request_pb.UpdateParentLocationWithImageUnaryRequest,
  proto_location_location_response_pb.ParentLocationResponse,
  /**
   * @param {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_location_location_response_pb.ParentLocationResponse.deserializeBinary
);


/**
 * @param {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.elude.proto.ParentLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.elude.proto.ParentLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.elude.proto.GatewayControllerClient.prototype.updateParentLocationWithImageUnary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateParentLocationWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateParentLocationWithImageUnary,
      callback);
};


/**
 * @param {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.elude.proto.ParentLocationResponse>}
 *     Promise that resolves to the response
 */
proto.elude.proto.GatewayControllerPromiseClient.prototype.updateParentLocationWithImageUnary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/elude.proto.GatewayController/UpdateParentLocationWithImageUnary',
      request,
      metadata || {},
      methodDescriptor_GatewayController_UpdateParentLocationWithImageUnary);
};


module.exports = proto.elude.proto;

