// source: proto/booking/booking_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_pagination_pb = require('../../proto/commons/pagination_pb.js');
goog.object.extend(proto, proto_commons_pagination_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_options_pb = require('../../proto/commons/options_pb.js');
goog.object.extend(proto, proto_commons_options_pb);
var proto_models_traveller_pb = require('../../proto/models/traveller_pb.js');
goog.object.extend(proto, proto_models_traveller_pb);
var proto_models_sticker_pb = require('../../proto/models/sticker_pb.js');
goog.object.extend(proto, proto_models_sticker_pb);
var proto_commons_filter_options_pb = require('../../proto/commons/filter_options_pb.js');
goog.object.extend(proto, proto_commons_filter_options_pb);
var proto_models_amenity_pb = require('../../proto/models/amenity_pb.js');
goog.object.extend(proto, proto_models_amenity_pb);
var proto_commons_image_data_pb = require('../../proto/commons/image_data_pb.js');
goog.object.extend(proto, proto_commons_image_data_pb);
var proto_models_gds_pb = require('../../proto/models/gds_pb.js');
goog.object.extend(proto, proto_models_gds_pb);
var proto_models_meal_plan_pb = require('../../proto/models/meal_plan_pb.js');
goog.object.extend(proto, proto_models_meal_plan_pb);
var proto_models_room_view_type_pb = require('../../proto/models/room_view_type_pb.js');
goog.object.extend(proto, proto_models_room_view_type_pb);
var proto_models_flight_type_pb = require('../../proto/models/flight_type_pb.js');
goog.object.extend(proto, proto_models_flight_type_pb);
var proto_models_booking_pb = require('../../proto/models/booking_pb.js');
goog.object.extend(proto, proto_models_booking_pb);
goog.exportSymbol('proto.elude.proto.ChangeBookingStatusRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateAmenityRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateFlightTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateGdsRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateMealPlanRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateRoomViewTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateStickerRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateTravellerRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteAmenityRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteFlightTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteGdsRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteMealPlanRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteRoomViewTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteStickerRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteTravellerRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAllGdsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAmenitiesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAmenityRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetBookingByEludeReferenceRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetBookingRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetBookingsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFlightTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFlightTypesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetGdsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetMealPlanRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetMealPlansRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetRoomViewTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetRoomViewTypesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetStickerRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetStickersForUserRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetStickersRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetTravelStatsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetTravellerRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetTravellersRequest', null, global);
goog.exportSymbol('proto.elude.proto.SendEmailsRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAmenityRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAmenityWithImageRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAmenityWithImageRequest.DataCase', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAmenityWithImageUnaryRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateBookingRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateFlightTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateGdsRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateMealPlanRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateRoomViewTypeRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateStickerRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateStickerWithImageRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateStickerWithImageRequest.DataCase', null, global);
goog.exportSymbol('proto.elude.proto.UpdateStickerWithImageUnaryRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateTravellerRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetBookingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetBookingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetBookingsRequest.displayName = 'proto.elude.proto.GetBookingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetBookingRequest.displayName = 'proto.elude.proto.GetBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateBookingRequest.displayName = 'proto.elude.proto.UpdateBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ChangeBookingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.ChangeBookingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ChangeBookingStatusRequest.displayName = 'proto.elude.proto.ChangeBookingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetBookingByEludeReferenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetBookingByEludeReferenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetBookingByEludeReferenceRequest.displayName = 'proto.elude.proto.GetBookingByEludeReferenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetTravelStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetTravelStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetTravelStatsRequest.displayName = 'proto.elude.proto.GetTravelStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.SendEmailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.SendEmailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.SendEmailsRequest.displayName = 'proto.elude.proto.SendEmailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetTravellersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetTravellersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetTravellersRequest.displayName = 'proto.elude.proto.GetTravellersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetTravellerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetTravellerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetTravellerRequest.displayName = 'proto.elude.proto.GetTravellerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateTravellerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateTravellerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateTravellerRequest.displayName = 'proto.elude.proto.CreateTravellerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateTravellerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateTravellerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateTravellerRequest.displayName = 'proto.elude.proto.UpdateTravellerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteTravellerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteTravellerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteTravellerRequest.displayName = 'proto.elude.proto.DeleteTravellerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetStickerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetStickerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetStickerRequest.displayName = 'proto.elude.proto.GetStickerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetStickersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetStickersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetStickersRequest.displayName = 'proto.elude.proto.GetStickersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetStickersForUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetStickersForUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetStickersForUserRequest.displayName = 'proto.elude.proto.GetStickersForUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateStickerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateStickerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateStickerRequest.displayName = 'proto.elude.proto.CreateStickerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateStickerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateStickerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateStickerRequest.displayName = 'proto.elude.proto.UpdateStickerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteStickerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteStickerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteStickerRequest.displayName = 'proto.elude.proto.DeleteStickerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateStickerWithImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.elude.proto.UpdateStickerWithImageRequest.oneofGroups_);
};
goog.inherits(proto.elude.proto.UpdateStickerWithImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateStickerWithImageRequest.displayName = 'proto.elude.proto.UpdateStickerWithImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateStickerWithImageUnaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateStickerWithImageUnaryRequest.displayName = 'proto.elude.proto.UpdateStickerWithImageUnaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAmenityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAmenityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAmenityRequest.displayName = 'proto.elude.proto.GetAmenityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAmenitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAmenitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAmenitiesRequest.displayName = 'proto.elude.proto.GetAmenitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateAmenityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateAmenityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateAmenityRequest.displayName = 'proto.elude.proto.CreateAmenityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAmenityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAmenityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAmenityRequest.displayName = 'proto.elude.proto.UpdateAmenityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteAmenityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteAmenityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteAmenityRequest.displayName = 'proto.elude.proto.DeleteAmenityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAmenityWithImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.elude.proto.UpdateAmenityWithImageRequest.oneofGroups_);
};
goog.inherits(proto.elude.proto.UpdateAmenityWithImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAmenityWithImageRequest.displayName = 'proto.elude.proto.UpdateAmenityWithImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAmenityWithImageUnaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAmenityWithImageUnaryRequest.displayName = 'proto.elude.proto.UpdateAmenityWithImageUnaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetGdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetGdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetGdsRequest.displayName = 'proto.elude.proto.GetGdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAllGdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAllGdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAllGdsRequest.displayName = 'proto.elude.proto.GetAllGdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateGdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateGdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateGdsRequest.displayName = 'proto.elude.proto.CreateGdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateGdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateGdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateGdsRequest.displayName = 'proto.elude.proto.UpdateGdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteGdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteGdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteGdsRequest.displayName = 'proto.elude.proto.DeleteGdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetMealPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetMealPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetMealPlanRequest.displayName = 'proto.elude.proto.GetMealPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetMealPlansRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetMealPlansRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetMealPlansRequest.displayName = 'proto.elude.proto.GetMealPlansRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateMealPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateMealPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateMealPlanRequest.displayName = 'proto.elude.proto.CreateMealPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateMealPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateMealPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateMealPlanRequest.displayName = 'proto.elude.proto.UpdateMealPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteMealPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteMealPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteMealPlanRequest.displayName = 'proto.elude.proto.DeleteMealPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetRoomViewTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetRoomViewTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetRoomViewTypeRequest.displayName = 'proto.elude.proto.GetRoomViewTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetRoomViewTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetRoomViewTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetRoomViewTypesRequest.displayName = 'proto.elude.proto.GetRoomViewTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateRoomViewTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateRoomViewTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateRoomViewTypeRequest.displayName = 'proto.elude.proto.CreateRoomViewTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateRoomViewTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateRoomViewTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateRoomViewTypeRequest.displayName = 'proto.elude.proto.UpdateRoomViewTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteRoomViewTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteRoomViewTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteRoomViewTypeRequest.displayName = 'proto.elude.proto.DeleteRoomViewTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFlightTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFlightTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFlightTypeRequest.displayName = 'proto.elude.proto.GetFlightTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFlightTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFlightTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFlightTypesRequest.displayName = 'proto.elude.proto.GetFlightTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateFlightTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateFlightTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateFlightTypeRequest.displayName = 'proto.elude.proto.CreateFlightTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateFlightTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateFlightTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateFlightTypeRequest.displayName = 'proto.elude.proto.UpdateFlightTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteFlightTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteFlightTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteFlightTypeRequest.displayName = 'proto.elude.proto.DeleteFlightTypeRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetBookingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetBookingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetBookingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetBookingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetBookingsRequest}
 */
proto.elude.proto.GetBookingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetBookingsRequest;
  return proto.elude.proto.GetBookingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetBookingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetBookingsRequest}
 */
proto.elude.proto.GetBookingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 5:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    case 6:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetBookingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetBookingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetBookingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetBookingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetBookingsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
*/
proto.elude.proto.GetBookingsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetBookingsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
*/
proto.elude.proto.GetBookingsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetBookingsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
*/
proto.elude.proto.GetBookingsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 user_id = 4;
 * @return {number}
 */
proto.elude.proto.GetBookingsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional FilterOptions filter_options = 5;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetBookingsRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 5));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
*/
proto.elude.proto.GetBookingsRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingsRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Pagination pagination = 6;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetBookingsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 6));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
*/
proto.elude.proto.GetBookingsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string search = 7;
 * @return {string}
 */
proto.elude.proto.GetBookingsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetBookingsRequest} returns this
 */
proto.elude.proto.GetBookingsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetBookingRequest}
 */
proto.elude.proto.GetBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetBookingRequest;
  return proto.elude.proto.GetBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetBookingRequest}
 */
proto.elude.proto.GetBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetBookingRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetBookingRequest} returns this
*/
proto.elude.proto.GetBookingRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingRequest} returns this
 */
proto.elude.proto.GetBookingRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetBookingRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetBookingRequest} returns this
*/
proto.elude.proto.GetBookingRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingRequest} returns this
 */
proto.elude.proto.GetBookingRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetBookingRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetBookingRequest} returns this
*/
proto.elude.proto.GetBookingRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingRequest} returns this
 */
proto.elude.proto.GetBookingRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetBookingRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetBookingRequest} returns this
 */
proto.elude.proto.GetBookingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    booking: (f = msg.getBooking()) && proto_models_booking_pb.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateBookingRequest}
 */
proto.elude.proto.UpdateBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateBookingRequest;
  return proto.elude.proto.UpdateBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateBookingRequest}
 */
proto.elude.proto.UpdateBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_models_booking_pb.Booking;
      reader.readMessage(value,proto_models_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_booking_pb.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateBookingRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
*/
proto.elude.proto.UpdateBookingRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
 */
proto.elude.proto.UpdateBookingRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateBookingRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateBookingRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
*/
proto.elude.proto.UpdateBookingRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
 */
proto.elude.proto.UpdateBookingRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateBookingRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateBookingRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
*/
proto.elude.proto.UpdateBookingRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
 */
proto.elude.proto.UpdateBookingRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateBookingRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Booking booking = 4;
 * @return {?proto.elude.proto.Booking}
 */
proto.elude.proto.UpdateBookingRequest.prototype.getBooking = function() {
  return /** @type{?proto.elude.proto.Booking} */ (
    jspb.Message.getWrapperField(this, proto_models_booking_pb.Booking, 4));
};


/**
 * @param {?proto.elude.proto.Booking|undefined} value
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
*/
proto.elude.proto.UpdateBookingRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateBookingRequest} returns this
 */
proto.elude.proto.UpdateBookingRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateBookingRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ChangeBookingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ChangeBookingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ChangeBookingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bookingStatusId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ChangeBookingStatusRequest}
 */
proto.elude.proto.ChangeBookingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ChangeBookingStatusRequest;
  return proto.elude.proto.ChangeBookingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ChangeBookingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ChangeBookingStatusRequest}
 */
proto.elude.proto.ChangeBookingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ChangeBookingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ChangeBookingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ChangeBookingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBookingStatusId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
*/
proto.elude.proto.ChangeBookingStatusRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
*/
proto.elude.proto.ChangeBookingStatusRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
*/
proto.elude.proto.ChangeBookingStatusRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 booking_status_id = 5;
 * @return {number}
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.getBookingStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ChangeBookingStatusRequest} returns this
 */
proto.elude.proto.ChangeBookingStatusRequest.prototype.setBookingStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetBookingByEludeReferenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetBookingByEludeReferenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    eludeReference: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetBookingByEludeReferenceRequest;
  return proto.elude.proto.GetBookingByEludeReferenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetBookingByEludeReferenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEludeReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetBookingByEludeReferenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetBookingByEludeReferenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getEludeReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
*/
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
*/
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
*/
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string elude_reference = 4;
 * @return {string}
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.getEludeReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetBookingByEludeReferenceRequest} returns this
 */
proto.elude.proto.GetBookingByEludeReferenceRequest.prototype.setEludeReference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetTravelStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetTravelStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTravelStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetTravelStatsRequest}
 */
proto.elude.proto.GetTravelStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetTravelStatsRequest;
  return proto.elude.proto.GetTravelStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetTravelStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetTravelStatsRequest}
 */
proto.elude.proto.GetTravelStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetTravelStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetTravelStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTravelStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
*/
proto.elude.proto.GetTravelStatsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
 */
proto.elude.proto.GetTravelStatsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
*/
proto.elude.proto.GetTravelStatsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
 */
proto.elude.proto.GetTravelStatsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
*/
proto.elude.proto.GetTravelStatsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
 */
proto.elude.proto.GetTravelStatsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 user_id = 4;
 * @return {number}
 */
proto.elude.proto.GetTravelStatsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetTravelStatsRequest} returns this
 */
proto.elude.proto.GetTravelStatsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.SendEmailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.SendEmailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.SendEmailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SendEmailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.SendEmailsRequest}
 */
proto.elude.proto.SendEmailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.SendEmailsRequest;
  return proto.elude.proto.SendEmailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.SendEmailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.SendEmailsRequest}
 */
proto.elude.proto.SendEmailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.SendEmailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.SendEmailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.SendEmailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SendEmailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.SendEmailsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
*/
proto.elude.proto.SendEmailsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
 */
proto.elude.proto.SendEmailsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SendEmailsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.SendEmailsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
*/
proto.elude.proto.SendEmailsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
 */
proto.elude.proto.SendEmailsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SendEmailsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.SendEmailsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
*/
proto.elude.proto.SendEmailsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
 */
proto.elude.proto.SendEmailsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SendEmailsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.SendEmailsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SendEmailsRequest} returns this
 */
proto.elude.proto.SendEmailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetTravellersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetTravellersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetTravellersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTravellersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetTravellersRequest}
 */
proto.elude.proto.GetTravellersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetTravellersRequest;
  return proto.elude.proto.GetTravellersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetTravellersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetTravellersRequest}
 */
proto.elude.proto.GetTravellersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 5:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetTravellersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetTravellersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetTravellersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTravellersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetTravellersRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
*/
proto.elude.proto.GetTravellersRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellersRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetTravellersRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
*/
proto.elude.proto.GetTravellersRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellersRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 userId = 3;
 * @return {number}
 */
proto.elude.proto.GetTravellersRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetTravellersRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
*/
proto.elude.proto.GetTravellersRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellersRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Pagination pagination = 5;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetTravellersRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 5));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
*/
proto.elude.proto.GetTravellersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetTravellersRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
*/
proto.elude.proto.GetTravellersRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellersRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string search = 7;
 * @return {string}
 */
proto.elude.proto.GetTravellersRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetTravellersRequest} returns this
 */
proto.elude.proto.GetTravellersRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetTravellerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetTravellerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetTravellerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTravellerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetTravellerRequest}
 */
proto.elude.proto.GetTravellerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetTravellerRequest;
  return proto.elude.proto.GetTravellerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetTravellerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetTravellerRequest}
 */
proto.elude.proto.GetTravellerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetTravellerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetTravellerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetTravellerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTravellerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetTravellerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
*/
proto.elude.proto.GetTravellerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
 */
proto.elude.proto.GetTravellerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetTravellerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
*/
proto.elude.proto.GetTravellerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
 */
proto.elude.proto.GetTravellerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.GetTravellerRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
 */
proto.elude.proto.GetTravellerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetTravellerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
*/
proto.elude.proto.GetTravellerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTravellerRequest} returns this
 */
proto.elude.proto.GetTravellerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTravellerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateTravellerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateTravellerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateTravellerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateTravellerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    traveller: (f = msg.getTraveller()) && proto_models_traveller_pb.Traveller.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateTravellerRequest}
 */
proto.elude.proto.CreateTravellerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateTravellerRequest;
  return proto.elude.proto.CreateTravellerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateTravellerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateTravellerRequest}
 */
proto.elude.proto.CreateTravellerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_traveller_pb.Traveller;
      reader.readMessage(value,proto_models_traveller_pb.Traveller.deserializeBinaryFromReader);
      msg.setTraveller(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateTravellerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateTravellerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateTravellerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateTravellerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getTraveller();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_traveller_pb.Traveller.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateTravellerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
*/
proto.elude.proto.CreateTravellerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
 */
proto.elude.proto.CreateTravellerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateTravellerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateTravellerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
*/
proto.elude.proto.CreateTravellerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
 */
proto.elude.proto.CreateTravellerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateTravellerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Traveller traveller = 3;
 * @return {?proto.elude.proto.Traveller}
 */
proto.elude.proto.CreateTravellerRequest.prototype.getTraveller = function() {
  return /** @type{?proto.elude.proto.Traveller} */ (
    jspb.Message.getWrapperField(this, proto_models_traveller_pb.Traveller, 3));
};


/**
 * @param {?proto.elude.proto.Traveller|undefined} value
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
*/
proto.elude.proto.CreateTravellerRequest.prototype.setTraveller = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
 */
proto.elude.proto.CreateTravellerRequest.prototype.clearTraveller = function() {
  return this.setTraveller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateTravellerRequest.prototype.hasTraveller = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateTravellerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
*/
proto.elude.proto.CreateTravellerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateTravellerRequest} returns this
 */
proto.elude.proto.CreateTravellerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateTravellerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateTravellerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateTravellerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateTravellerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    traveller: (f = msg.getTraveller()) && proto_models_traveller_pb.Traveller.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateTravellerRequest}
 */
proto.elude.proto.UpdateTravellerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateTravellerRequest;
  return proto.elude.proto.UpdateTravellerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateTravellerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateTravellerRequest}
 */
proto.elude.proto.UpdateTravellerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_traveller_pb.Traveller;
      reader.readMessage(value,proto_models_traveller_pb.Traveller.deserializeBinaryFromReader);
      msg.setTraveller(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateTravellerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateTravellerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateTravellerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getTraveller();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_traveller_pb.Traveller.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
*/
proto.elude.proto.UpdateTravellerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
 */
proto.elude.proto.UpdateTravellerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
*/
proto.elude.proto.UpdateTravellerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
 */
proto.elude.proto.UpdateTravellerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Traveller traveller = 3;
 * @return {?proto.elude.proto.Traveller}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.getTraveller = function() {
  return /** @type{?proto.elude.proto.Traveller} */ (
    jspb.Message.getWrapperField(this, proto_models_traveller_pb.Traveller, 3));
};


/**
 * @param {?proto.elude.proto.Traveller|undefined} value
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
*/
proto.elude.proto.UpdateTravellerRequest.prototype.setTraveller = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
 */
proto.elude.proto.UpdateTravellerRequest.prototype.clearTraveller = function() {
  return this.setTraveller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.hasTraveller = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
*/
proto.elude.proto.UpdateTravellerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateTravellerRequest} returns this
 */
proto.elude.proto.UpdateTravellerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateTravellerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteTravellerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteTravellerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteTravellerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteTravellerRequest}
 */
proto.elude.proto.DeleteTravellerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteTravellerRequest;
  return proto.elude.proto.DeleteTravellerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteTravellerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteTravellerRequest}
 */
proto.elude.proto.DeleteTravellerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteTravellerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteTravellerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteTravellerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
*/
proto.elude.proto.DeleteTravellerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
 */
proto.elude.proto.DeleteTravellerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
*/
proto.elude.proto.DeleteTravellerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
 */
proto.elude.proto.DeleteTravellerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
 */
proto.elude.proto.DeleteTravellerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
*/
proto.elude.proto.DeleteTravellerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteTravellerRequest} returns this
 */
proto.elude.proto.DeleteTravellerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteTravellerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetStickerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetStickerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetStickerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetStickerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetStickerRequest}
 */
proto.elude.proto.GetStickerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetStickerRequest;
  return proto.elude.proto.GetStickerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetStickerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetStickerRequest}
 */
proto.elude.proto.GetStickerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetStickerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetStickerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetStickerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetStickerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetStickerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetStickerRequest} returns this
*/
proto.elude.proto.GetStickerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickerRequest} returns this
 */
proto.elude.proto.GetStickerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetStickerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetStickerRequest} returns this
*/
proto.elude.proto.GetStickerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickerRequest} returns this
 */
proto.elude.proto.GetStickerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetStickerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetStickerRequest} returns this
*/
proto.elude.proto.GetStickerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickerRequest} returns this
 */
proto.elude.proto.GetStickerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetStickerRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetStickerRequest} returns this
 */
proto.elude.proto.GetStickerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetStickersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetStickersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetStickersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetStickersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetStickersRequest}
 */
proto.elude.proto.GetStickersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetStickersRequest;
  return proto.elude.proto.GetStickersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetStickersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetStickersRequest}
 */
proto.elude.proto.GetStickersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetStickersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetStickersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetStickersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetStickersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetStickersRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetStickersRequest} returns this
*/
proto.elude.proto.GetStickersRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersRequest} returns this
 */
proto.elude.proto.GetStickersRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetStickersRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetStickersRequest} returns this
*/
proto.elude.proto.GetStickersRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersRequest} returns this
 */
proto.elude.proto.GetStickersRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetStickersRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetStickersRequest} returns this
*/
proto.elude.proto.GetStickersRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersRequest} returns this
 */
proto.elude.proto.GetStickersRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetStickersRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetStickersRequest} returns this
*/
proto.elude.proto.GetStickersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersRequest} returns this
 */
proto.elude.proto.GetStickersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetStickersRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetStickersRequest} returns this
 */
proto.elude.proto.GetStickersRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetStickersRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetStickersRequest} returns this
*/
proto.elude.proto.GetStickersRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersRequest} returns this
 */
proto.elude.proto.GetStickersRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetStickersForUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetStickersForUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetStickersForUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetStickersForUserRequest}
 */
proto.elude.proto.GetStickersForUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetStickersForUserRequest;
  return proto.elude.proto.GetStickersForUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetStickersForUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetStickersForUserRequest}
 */
proto.elude.proto.GetStickersForUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetStickersForUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetStickersForUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetStickersForUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
*/
proto.elude.proto.GetStickersForUserRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
 */
proto.elude.proto.GetStickersForUserRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
*/
proto.elude.proto.GetStickersForUserRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
 */
proto.elude.proto.GetStickersForUserRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
*/
proto.elude.proto.GetStickersForUserRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
 */
proto.elude.proto.GetStickersForUserRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 user_id = 4;
 * @return {number}
 */
proto.elude.proto.GetStickersForUserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetStickersForUserRequest} returns this
 */
proto.elude.proto.GetStickersForUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateStickerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateStickerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateStickerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateStickerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    sticker: (f = msg.getSticker()) && proto_models_sticker_pb.Sticker.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateStickerRequest}
 */
proto.elude.proto.CreateStickerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateStickerRequest;
  return proto.elude.proto.CreateStickerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateStickerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateStickerRequest}
 */
proto.elude.proto.CreateStickerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_sticker_pb.Sticker;
      reader.readMessage(value,proto_models_sticker_pb.Sticker.deserializeBinaryFromReader);
      msg.setSticker(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateStickerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateStickerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateStickerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateStickerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getSticker();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_sticker_pb.Sticker.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateStickerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
*/
proto.elude.proto.CreateStickerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
 */
proto.elude.proto.CreateStickerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateStickerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateStickerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
*/
proto.elude.proto.CreateStickerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
 */
proto.elude.proto.CreateStickerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateStickerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Sticker sticker = 3;
 * @return {?proto.elude.proto.Sticker}
 */
proto.elude.proto.CreateStickerRequest.prototype.getSticker = function() {
  return /** @type{?proto.elude.proto.Sticker} */ (
    jspb.Message.getWrapperField(this, proto_models_sticker_pb.Sticker, 3));
};


/**
 * @param {?proto.elude.proto.Sticker|undefined} value
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
*/
proto.elude.proto.CreateStickerRequest.prototype.setSticker = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
 */
proto.elude.proto.CreateStickerRequest.prototype.clearSticker = function() {
  return this.setSticker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateStickerRequest.prototype.hasSticker = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateStickerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
*/
proto.elude.proto.CreateStickerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateStickerRequest} returns this
 */
proto.elude.proto.CreateStickerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateStickerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateStickerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateStickerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateStickerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    sticker: (f = msg.getSticker()) && proto_models_sticker_pb.Sticker.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateStickerRequest}
 */
proto.elude.proto.UpdateStickerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateStickerRequest;
  return proto.elude.proto.UpdateStickerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateStickerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateStickerRequest}
 */
proto.elude.proto.UpdateStickerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_sticker_pb.Sticker;
      reader.readMessage(value,proto_models_sticker_pb.Sticker.deserializeBinaryFromReader);
      msg.setSticker(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateStickerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateStickerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateStickerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getSticker();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_sticker_pb.Sticker.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateStickerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
*/
proto.elude.proto.UpdateStickerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
 */
proto.elude.proto.UpdateStickerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateStickerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
*/
proto.elude.proto.UpdateStickerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
 */
proto.elude.proto.UpdateStickerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Sticker sticker = 3;
 * @return {?proto.elude.proto.Sticker}
 */
proto.elude.proto.UpdateStickerRequest.prototype.getSticker = function() {
  return /** @type{?proto.elude.proto.Sticker} */ (
    jspb.Message.getWrapperField(this, proto_models_sticker_pb.Sticker, 3));
};


/**
 * @param {?proto.elude.proto.Sticker|undefined} value
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
*/
proto.elude.proto.UpdateStickerRequest.prototype.setSticker = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
 */
proto.elude.proto.UpdateStickerRequest.prototype.clearSticker = function() {
  return this.setSticker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerRequest.prototype.hasSticker = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateStickerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
*/
proto.elude.proto.UpdateStickerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerRequest} returns this
 */
proto.elude.proto.UpdateStickerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteStickerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteStickerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteStickerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteStickerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteStickerRequest}
 */
proto.elude.proto.DeleteStickerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteStickerRequest;
  return proto.elude.proto.DeleteStickerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteStickerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteStickerRequest}
 */
proto.elude.proto.DeleteStickerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteStickerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteStickerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteStickerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteStickerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteStickerRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
*/
proto.elude.proto.DeleteStickerRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
 */
proto.elude.proto.DeleteStickerRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteStickerRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteStickerRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
*/
proto.elude.proto.DeleteStickerRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
 */
proto.elude.proto.DeleteStickerRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteStickerRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteStickerRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
 */
proto.elude.proto.DeleteStickerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteStickerRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
*/
proto.elude.proto.DeleteStickerRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteStickerRequest} returns this
 */
proto.elude.proto.DeleteStickerRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteStickerRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.elude.proto.UpdateStickerWithImageRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.elude.proto.UpdateStickerWithImageRequest.DataCase = {
  DATA_NOT_SET: 0,
  UPDATEREQ: 1,
  CHUNK_DATA: 2
};

/**
 * @return {proto.elude.proto.UpdateStickerWithImageRequest.DataCase}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.getDataCase = function() {
  return /** @type {proto.elude.proto.UpdateStickerWithImageRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.elude.proto.UpdateStickerWithImageRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateStickerWithImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateStickerWithImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerWithImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateStickerWithImageData.toObject(includeInstance, f),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateStickerWithImageRequest}
 */
proto.elude.proto.UpdateStickerWithImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateStickerWithImageRequest;
  return proto.elude.proto.UpdateStickerWithImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateStickerWithImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateStickerWithImageRequest}
 */
proto.elude.proto.UpdateStickerWithImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateStickerWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateStickerWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateStickerWithImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateStickerWithImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerWithImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateStickerWithImageData.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateStickerWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateStickerWithImageData}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateStickerWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateStickerWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateStickerWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageRequest} returns this
*/
proto.elude.proto.UpdateStickerWithImageRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.elude.proto.UpdateStickerWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageRequest} returns this
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateStickerWithImageRequest} returns this
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateStickerWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageRequest} returns this
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.clearChunkData = function() {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateStickerWithImageRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageRequest.prototype.hasChunkData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateStickerWithImageUnaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateStickerWithImageData.toObject(includeInstance, f),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateStickerWithImageUnaryRequest}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateStickerWithImageUnaryRequest;
  return proto.elude.proto.UpdateStickerWithImageUnaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateStickerWithImageUnaryRequest}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateStickerWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateStickerWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateStickerWithImageUnaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateStickerWithImageData.serializeBinaryToWriter
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateStickerWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateStickerWithImageData}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateStickerWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateStickerWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateStickerWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} returns this
*/
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateStickerWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateStickerWithImageUnaryRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAmenityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAmenityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAmenityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAmenityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAmenityRequest}
 */
proto.elude.proto.GetAmenityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAmenityRequest;
  return proto.elude.proto.GetAmenityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAmenityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAmenityRequest}
 */
proto.elude.proto.GetAmenityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAmenityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAmenityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAmenityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAmenityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAmenityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
*/
proto.elude.proto.GetAmenityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
 */
proto.elude.proto.GetAmenityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAmenityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
*/
proto.elude.proto.GetAmenityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
 */
proto.elude.proto.GetAmenityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAmenityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
*/
proto.elude.proto.GetAmenityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
 */
proto.elude.proto.GetAmenityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetAmenityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetAmenityRequest} returns this
 */
proto.elude.proto.GetAmenityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAmenitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAmenitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAmenitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAmenitiesRequest}
 */
proto.elude.proto.GetAmenitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAmenitiesRequest;
  return proto.elude.proto.GetAmenitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAmenitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAmenitiesRequest}
 */
proto.elude.proto.GetAmenitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAmenitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAmenitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAmenitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
*/
proto.elude.proto.GetAmenitiesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
 */
proto.elude.proto.GetAmenitiesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
*/
proto.elude.proto.GetAmenitiesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
 */
proto.elude.proto.GetAmenitiesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
*/
proto.elude.proto.GetAmenitiesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
 */
proto.elude.proto.GetAmenitiesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
*/
proto.elude.proto.GetAmenitiesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
 */
proto.elude.proto.GetAmenitiesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
 */
proto.elude.proto.GetAmenitiesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
*/
proto.elude.proto.GetAmenitiesRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAmenitiesRequest} returns this
 */
proto.elude.proto.GetAmenitiesRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAmenitiesRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateAmenityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateAmenityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateAmenityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateAmenityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    amenity: (f = msg.getAmenity()) && proto_models_amenity_pb.Amenity.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateAmenityRequest}
 */
proto.elude.proto.CreateAmenityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateAmenityRequest;
  return proto.elude.proto.CreateAmenityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateAmenityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateAmenityRequest}
 */
proto.elude.proto.CreateAmenityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_amenity_pb.Amenity;
      reader.readMessage(value,proto_models_amenity_pb.Amenity.deserializeBinaryFromReader);
      msg.setAmenity(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateAmenityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateAmenityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateAmenityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateAmenityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmenity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_amenity_pb.Amenity.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateAmenityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
*/
proto.elude.proto.CreateAmenityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
 */
proto.elude.proto.CreateAmenityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAmenityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateAmenityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
*/
proto.elude.proto.CreateAmenityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
 */
proto.elude.proto.CreateAmenityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAmenityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Amenity amenity = 3;
 * @return {?proto.elude.proto.Amenity}
 */
proto.elude.proto.CreateAmenityRequest.prototype.getAmenity = function() {
  return /** @type{?proto.elude.proto.Amenity} */ (
    jspb.Message.getWrapperField(this, proto_models_amenity_pb.Amenity, 3));
};


/**
 * @param {?proto.elude.proto.Amenity|undefined} value
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
*/
proto.elude.proto.CreateAmenityRequest.prototype.setAmenity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
 */
proto.elude.proto.CreateAmenityRequest.prototype.clearAmenity = function() {
  return this.setAmenity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAmenityRequest.prototype.hasAmenity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateAmenityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
*/
proto.elude.proto.CreateAmenityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAmenityRequest} returns this
 */
proto.elude.proto.CreateAmenityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAmenityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAmenityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAmenityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    amenity: (f = msg.getAmenity()) && proto_models_amenity_pb.Amenity.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAmenityRequest}
 */
proto.elude.proto.UpdateAmenityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAmenityRequest;
  return proto.elude.proto.UpdateAmenityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAmenityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAmenityRequest}
 */
proto.elude.proto.UpdateAmenityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_amenity_pb.Amenity;
      reader.readMessage(value,proto_models_amenity_pb.Amenity.deserializeBinaryFromReader);
      msg.setAmenity(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAmenityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAmenityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmenity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_amenity_pb.Amenity.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
*/
proto.elude.proto.UpdateAmenityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
 */
proto.elude.proto.UpdateAmenityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
*/
proto.elude.proto.UpdateAmenityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
 */
proto.elude.proto.UpdateAmenityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Amenity amenity = 3;
 * @return {?proto.elude.proto.Amenity}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.getAmenity = function() {
  return /** @type{?proto.elude.proto.Amenity} */ (
    jspb.Message.getWrapperField(this, proto_models_amenity_pb.Amenity, 3));
};


/**
 * @param {?proto.elude.proto.Amenity|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
*/
proto.elude.proto.UpdateAmenityRequest.prototype.setAmenity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
 */
proto.elude.proto.UpdateAmenityRequest.prototype.clearAmenity = function() {
  return this.setAmenity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.hasAmenity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
*/
proto.elude.proto.UpdateAmenityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityRequest} returns this
 */
proto.elude.proto.UpdateAmenityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteAmenityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteAmenityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteAmenityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteAmenityRequest}
 */
proto.elude.proto.DeleteAmenityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteAmenityRequest;
  return proto.elude.proto.DeleteAmenityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteAmenityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteAmenityRequest}
 */
proto.elude.proto.DeleteAmenityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteAmenityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteAmenityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteAmenityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
*/
proto.elude.proto.DeleteAmenityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
 */
proto.elude.proto.DeleteAmenityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
*/
proto.elude.proto.DeleteAmenityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
 */
proto.elude.proto.DeleteAmenityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
 */
proto.elude.proto.DeleteAmenityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
*/
proto.elude.proto.DeleteAmenityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAmenityRequest} returns this
 */
proto.elude.proto.DeleteAmenityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAmenityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.elude.proto.UpdateAmenityWithImageRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.DataCase = {
  DATA_NOT_SET: 0,
  UPDATEREQ: 1,
  CHUNK_DATA: 2
};

/**
 * @return {proto.elude.proto.UpdateAmenityWithImageRequest.DataCase}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.getDataCase = function() {
  return /** @type {proto.elude.proto.UpdateAmenityWithImageRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.elude.proto.UpdateAmenityWithImageRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAmenityWithImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAmenityWithImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityWithImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateAmenityWithImageData.toObject(includeInstance, f),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAmenityWithImageRequest}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAmenityWithImageRequest;
  return proto.elude.proto.UpdateAmenityWithImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAmenityWithImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAmenityWithImageRequest}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateAmenityWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateAmenityWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAmenityWithImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAmenityWithImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityWithImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateAmenityWithImageData.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateAmenityWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateAmenityWithImageData}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateAmenityWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateAmenityWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateAmenityWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageRequest} returns this
*/
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.elude.proto.UpdateAmenityWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageRequest} returns this
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageRequest} returns this
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateAmenityWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageRequest} returns this
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.clearChunkData = function() {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateAmenityWithImageRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageRequest.prototype.hasChunkData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAmenityWithImageUnaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateAmenityWithImageData.toObject(includeInstance, f),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAmenityWithImageUnaryRequest;
  return proto.elude.proto.UpdateAmenityWithImageUnaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateAmenityWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateAmenityWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAmenityWithImageUnaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateAmenityWithImageData.serializeBinaryToWriter
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateAmenityWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateAmenityWithImageData}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateAmenityWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateAmenityWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateAmenityWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} returns this
*/
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateAmenityWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateAmenityWithImageUnaryRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetGdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetGdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetGdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetGdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetGdsRequest}
 */
proto.elude.proto.GetGdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetGdsRequest;
  return proto.elude.proto.GetGdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetGdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetGdsRequest}
 */
proto.elude.proto.GetGdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetGdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetGdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetGdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetGdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetGdsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetGdsRequest} returns this
*/
proto.elude.proto.GetGdsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetGdsRequest} returns this
 */
proto.elude.proto.GetGdsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetGdsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetGdsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetGdsRequest} returns this
*/
proto.elude.proto.GetGdsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetGdsRequest} returns this
 */
proto.elude.proto.GetGdsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetGdsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetGdsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetGdsRequest} returns this
*/
proto.elude.proto.GetGdsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetGdsRequest} returns this
 */
proto.elude.proto.GetGdsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetGdsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetGdsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetGdsRequest} returns this
 */
proto.elude.proto.GetGdsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAllGdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAllGdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAllGdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAllGdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAllGdsRequest}
 */
proto.elude.proto.GetAllGdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAllGdsRequest;
  return proto.elude.proto.GetAllGdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAllGdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAllGdsRequest}
 */
proto.elude.proto.GetAllGdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAllGdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAllGdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAllGdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAllGdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAllGdsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
*/
proto.elude.proto.GetAllGdsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
 */
proto.elude.proto.GetAllGdsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAllGdsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAllGdsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
*/
proto.elude.proto.GetAllGdsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
 */
proto.elude.proto.GetAllGdsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAllGdsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAllGdsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
*/
proto.elude.proto.GetAllGdsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
 */
proto.elude.proto.GetAllGdsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAllGdsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetAllGdsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
*/
proto.elude.proto.GetAllGdsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
 */
proto.elude.proto.GetAllGdsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAllGdsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetAllGdsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetAllGdsRequest} returns this
 */
proto.elude.proto.GetAllGdsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateGdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateGdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateGdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateGdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    gds: (f = msg.getGds()) && proto_models_gds_pb.Gds.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateGdsRequest}
 */
proto.elude.proto.CreateGdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateGdsRequest;
  return proto.elude.proto.CreateGdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateGdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateGdsRequest}
 */
proto.elude.proto.CreateGdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_gds_pb.Gds;
      reader.readMessage(value,proto_models_gds_pb.Gds.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateGdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateGdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateGdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateGdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_gds_pb.Gds.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateGdsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
*/
proto.elude.proto.CreateGdsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
 */
proto.elude.proto.CreateGdsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateGdsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateGdsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
*/
proto.elude.proto.CreateGdsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
 */
proto.elude.proto.CreateGdsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateGdsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Gds gds = 3;
 * @return {?proto.elude.proto.Gds}
 */
proto.elude.proto.CreateGdsRequest.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.Gds} */ (
    jspb.Message.getWrapperField(this, proto_models_gds_pb.Gds, 3));
};


/**
 * @param {?proto.elude.proto.Gds|undefined} value
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
*/
proto.elude.proto.CreateGdsRequest.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
 */
proto.elude.proto.CreateGdsRequest.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateGdsRequest.prototype.hasGds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateGdsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
*/
proto.elude.proto.CreateGdsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateGdsRequest} returns this
 */
proto.elude.proto.CreateGdsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateGdsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateGdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateGdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateGdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateGdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    gds: (f = msg.getGds()) && proto_models_gds_pb.Gds.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateGdsRequest}
 */
proto.elude.proto.UpdateGdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateGdsRequest;
  return proto.elude.proto.UpdateGdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateGdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateGdsRequest}
 */
proto.elude.proto.UpdateGdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_gds_pb.Gds;
      reader.readMessage(value,proto_models_gds_pb.Gds.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateGdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateGdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateGdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateGdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_gds_pb.Gds.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateGdsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
*/
proto.elude.proto.UpdateGdsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
 */
proto.elude.proto.UpdateGdsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateGdsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateGdsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
*/
proto.elude.proto.UpdateGdsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
 */
proto.elude.proto.UpdateGdsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateGdsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Gds gds = 3;
 * @return {?proto.elude.proto.Gds}
 */
proto.elude.proto.UpdateGdsRequest.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.Gds} */ (
    jspb.Message.getWrapperField(this, proto_models_gds_pb.Gds, 3));
};


/**
 * @param {?proto.elude.proto.Gds|undefined} value
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
*/
proto.elude.proto.UpdateGdsRequest.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
 */
proto.elude.proto.UpdateGdsRequest.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateGdsRequest.prototype.hasGds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateGdsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
*/
proto.elude.proto.UpdateGdsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateGdsRequest} returns this
 */
proto.elude.proto.UpdateGdsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateGdsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteGdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteGdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteGdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteGdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteGdsRequest}
 */
proto.elude.proto.DeleteGdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteGdsRequest;
  return proto.elude.proto.DeleteGdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteGdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteGdsRequest}
 */
proto.elude.proto.DeleteGdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteGdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteGdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteGdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteGdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteGdsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
*/
proto.elude.proto.DeleteGdsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
 */
proto.elude.proto.DeleteGdsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteGdsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteGdsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
*/
proto.elude.proto.DeleteGdsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
 */
proto.elude.proto.DeleteGdsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteGdsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteGdsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
 */
proto.elude.proto.DeleteGdsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteGdsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
*/
proto.elude.proto.DeleteGdsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteGdsRequest} returns this
 */
proto.elude.proto.DeleteGdsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteGdsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetMealPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetMealPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetMealPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMealPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetMealPlanRequest}
 */
proto.elude.proto.GetMealPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetMealPlanRequest;
  return proto.elude.proto.GetMealPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetMealPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetMealPlanRequest}
 */
proto.elude.proto.GetMealPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetMealPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetMealPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetMealPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMealPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetMealPlanRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
*/
proto.elude.proto.GetMealPlanRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
 */
proto.elude.proto.GetMealPlanRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlanRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetMealPlanRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
*/
proto.elude.proto.GetMealPlanRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
 */
proto.elude.proto.GetMealPlanRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlanRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetMealPlanRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
*/
proto.elude.proto.GetMealPlanRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
 */
proto.elude.proto.GetMealPlanRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlanRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetMealPlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetMealPlanRequest} returns this
 */
proto.elude.proto.GetMealPlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetMealPlansRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetMealPlansRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetMealPlansRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMealPlansRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetMealPlansRequest}
 */
proto.elude.proto.GetMealPlansRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetMealPlansRequest;
  return proto.elude.proto.GetMealPlansRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetMealPlansRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetMealPlansRequest}
 */
proto.elude.proto.GetMealPlansRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetMealPlansRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetMealPlansRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetMealPlansRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMealPlansRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetMealPlansRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
*/
proto.elude.proto.GetMealPlansRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
 */
proto.elude.proto.GetMealPlansRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlansRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetMealPlansRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
*/
proto.elude.proto.GetMealPlansRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
 */
proto.elude.proto.GetMealPlansRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlansRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetMealPlansRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
*/
proto.elude.proto.GetMealPlansRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
 */
proto.elude.proto.GetMealPlansRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlansRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetMealPlansRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
*/
proto.elude.proto.GetMealPlansRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
 */
proto.elude.proto.GetMealPlansRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMealPlansRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetMealPlansRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetMealPlansRequest} returns this
 */
proto.elude.proto.GetMealPlansRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateMealPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateMealPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateMealPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    mealPlan: (f = msg.getMealPlan()) && proto_models_meal_plan_pb.MealPlan.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateMealPlanRequest}
 */
proto.elude.proto.CreateMealPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateMealPlanRequest;
  return proto.elude.proto.CreateMealPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateMealPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateMealPlanRequest}
 */
proto.elude.proto.CreateMealPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_meal_plan_pb.MealPlan;
      reader.readMessage(value,proto_models_meal_plan_pb.MealPlan.deserializeBinaryFromReader);
      msg.setMealPlan(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateMealPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateMealPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateMealPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getMealPlan();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_meal_plan_pb.MealPlan.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
*/
proto.elude.proto.CreateMealPlanRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
 */
proto.elude.proto.CreateMealPlanRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
*/
proto.elude.proto.CreateMealPlanRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
 */
proto.elude.proto.CreateMealPlanRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MealPlan meal_plan = 3;
 * @return {?proto.elude.proto.MealPlan}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.getMealPlan = function() {
  return /** @type{?proto.elude.proto.MealPlan} */ (
    jspb.Message.getWrapperField(this, proto_models_meal_plan_pb.MealPlan, 3));
};


/**
 * @param {?proto.elude.proto.MealPlan|undefined} value
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
*/
proto.elude.proto.CreateMealPlanRequest.prototype.setMealPlan = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
 */
proto.elude.proto.CreateMealPlanRequest.prototype.clearMealPlan = function() {
  return this.setMealPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.hasMealPlan = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
*/
proto.elude.proto.CreateMealPlanRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateMealPlanRequest} returns this
 */
proto.elude.proto.CreateMealPlanRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateMealPlanRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateMealPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateMealPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateMealPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    mealPlan: (f = msg.getMealPlan()) && proto_models_meal_plan_pb.MealPlan.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateMealPlanRequest}
 */
proto.elude.proto.UpdateMealPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateMealPlanRequest;
  return proto.elude.proto.UpdateMealPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateMealPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateMealPlanRequest}
 */
proto.elude.proto.UpdateMealPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_meal_plan_pb.MealPlan;
      reader.readMessage(value,proto_models_meal_plan_pb.MealPlan.deserializeBinaryFromReader);
      msg.setMealPlan(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateMealPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateMealPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateMealPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getMealPlan();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_meal_plan_pb.MealPlan.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
*/
proto.elude.proto.UpdateMealPlanRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
*/
proto.elude.proto.UpdateMealPlanRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MealPlan meal_plan = 3;
 * @return {?proto.elude.proto.MealPlan}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.getMealPlan = function() {
  return /** @type{?proto.elude.proto.MealPlan} */ (
    jspb.Message.getWrapperField(this, proto_models_meal_plan_pb.MealPlan, 3));
};


/**
 * @param {?proto.elude.proto.MealPlan|undefined} value
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
*/
proto.elude.proto.UpdateMealPlanRequest.prototype.setMealPlan = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.clearMealPlan = function() {
  return this.setMealPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.hasMealPlan = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
*/
proto.elude.proto.UpdateMealPlanRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateMealPlanRequest} returns this
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateMealPlanRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteMealPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteMealPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteMealPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteMealPlanRequest}
 */
proto.elude.proto.DeleteMealPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteMealPlanRequest;
  return proto.elude.proto.DeleteMealPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteMealPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteMealPlanRequest}
 */
proto.elude.proto.DeleteMealPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteMealPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteMealPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteMealPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
*/
proto.elude.proto.DeleteMealPlanRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
*/
proto.elude.proto.DeleteMealPlanRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
*/
proto.elude.proto.DeleteMealPlanRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteMealPlanRequest} returns this
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteMealPlanRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetRoomViewTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetRoomViewTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRoomViewTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetRoomViewTypeRequest}
 */
proto.elude.proto.GetRoomViewTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetRoomViewTypeRequest;
  return proto.elude.proto.GetRoomViewTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetRoomViewTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetRoomViewTypeRequest}
 */
proto.elude.proto.GetRoomViewTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetRoomViewTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetRoomViewTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRoomViewTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
*/
proto.elude.proto.GetRoomViewTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
*/
proto.elude.proto.GetRoomViewTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
*/
proto.elude.proto.GetRoomViewTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetRoomViewTypeRequest} returns this
 */
proto.elude.proto.GetRoomViewTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetRoomViewTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetRoomViewTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRoomViewTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetRoomViewTypesRequest}
 */
proto.elude.proto.GetRoomViewTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetRoomViewTypesRequest;
  return proto.elude.proto.GetRoomViewTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetRoomViewTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetRoomViewTypesRequest}
 */
proto.elude.proto.GetRoomViewTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetRoomViewTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetRoomViewTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRoomViewTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
*/
proto.elude.proto.GetRoomViewTypesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
*/
proto.elude.proto.GetRoomViewTypesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
*/
proto.elude.proto.GetRoomViewTypesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
*/
proto.elude.proto.GetRoomViewTypesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetRoomViewTypesRequest} returns this
 */
proto.elude.proto.GetRoomViewTypesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateRoomViewTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateRoomViewTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateRoomViewTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    roomViewType: (f = msg.getRoomViewType()) && proto_models_room_view_type_pb.RoomViewType.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest}
 */
proto.elude.proto.CreateRoomViewTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateRoomViewTypeRequest;
  return proto.elude.proto.CreateRoomViewTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateRoomViewTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest}
 */
proto.elude.proto.CreateRoomViewTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_room_view_type_pb.RoomViewType;
      reader.readMessage(value,proto_models_room_view_type_pb.RoomViewType.deserializeBinaryFromReader);
      msg.setRoomViewType(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateRoomViewTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateRoomViewTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateRoomViewTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getRoomViewType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_room_view_type_pb.RoomViewType.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
*/
proto.elude.proto.CreateRoomViewTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
*/
proto.elude.proto.CreateRoomViewTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoomViewType room_view_type = 3;
 * @return {?proto.elude.proto.RoomViewType}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.getRoomViewType = function() {
  return /** @type{?proto.elude.proto.RoomViewType} */ (
    jspb.Message.getWrapperField(this, proto_models_room_view_type_pb.RoomViewType, 3));
};


/**
 * @param {?proto.elude.proto.RoomViewType|undefined} value
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
*/
proto.elude.proto.CreateRoomViewTypeRequest.prototype.setRoomViewType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.clearRoomViewType = function() {
  return this.setRoomViewType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.hasRoomViewType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
*/
proto.elude.proto.CreateRoomViewTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRoomViewTypeRequest} returns this
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRoomViewTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateRoomViewTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateRoomViewTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateRoomViewTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    roomViewType: (f = msg.getRoomViewType()) && proto_models_room_view_type_pb.RoomViewType.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateRoomViewTypeRequest;
  return proto.elude.proto.UpdateRoomViewTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateRoomViewTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_room_view_type_pb.RoomViewType;
      reader.readMessage(value,proto_models_room_view_type_pb.RoomViewType.deserializeBinaryFromReader);
      msg.setRoomViewType(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateRoomViewTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateRoomViewTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateRoomViewTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getRoomViewType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_room_view_type_pb.RoomViewType.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
*/
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
*/
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoomViewType room_view_type = 3;
 * @return {?proto.elude.proto.RoomViewType}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.getRoomViewType = function() {
  return /** @type{?proto.elude.proto.RoomViewType} */ (
    jspb.Message.getWrapperField(this, proto_models_room_view_type_pb.RoomViewType, 3));
};


/**
 * @param {?proto.elude.proto.RoomViewType|undefined} value
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
*/
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.setRoomViewType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.clearRoomViewType = function() {
  return this.setRoomViewType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.hasRoomViewType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
*/
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRoomViewTypeRequest} returns this
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRoomViewTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteRoomViewTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteRoomViewTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteRoomViewTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteRoomViewTypeRequest;
  return proto.elude.proto.DeleteRoomViewTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteRoomViewTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteRoomViewTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteRoomViewTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteRoomViewTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
*/
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
*/
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
*/
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteRoomViewTypeRequest} returns this
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteRoomViewTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFlightTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFlightTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFlightTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFlightTypeRequest}
 */
proto.elude.proto.GetFlightTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFlightTypeRequest;
  return proto.elude.proto.GetFlightTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFlightTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFlightTypeRequest}
 */
proto.elude.proto.GetFlightTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFlightTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFlightTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFlightTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
*/
proto.elude.proto.GetFlightTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
 */
proto.elude.proto.GetFlightTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
*/
proto.elude.proto.GetFlightTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
 */
proto.elude.proto.GetFlightTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
*/
proto.elude.proto.GetFlightTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
 */
proto.elude.proto.GetFlightTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetFlightTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetFlightTypeRequest} returns this
 */
proto.elude.proto.GetFlightTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFlightTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFlightTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFlightTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFlightTypesRequest}
 */
proto.elude.proto.GetFlightTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFlightTypesRequest;
  return proto.elude.proto.GetFlightTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFlightTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFlightTypesRequest}
 */
proto.elude.proto.GetFlightTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFlightTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFlightTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFlightTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
*/
proto.elude.proto.GetFlightTypesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
 */
proto.elude.proto.GetFlightTypesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
*/
proto.elude.proto.GetFlightTypesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
 */
proto.elude.proto.GetFlightTypesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
*/
proto.elude.proto.GetFlightTypesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
 */
proto.elude.proto.GetFlightTypesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
*/
proto.elude.proto.GetFlightTypesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
 */
proto.elude.proto.GetFlightTypesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetFlightTypesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetFlightTypesRequest} returns this
 */
proto.elude.proto.GetFlightTypesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateFlightTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateFlightTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateFlightTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    flightType: (f = msg.getFlightType()) && proto_models_flight_type_pb.FlightType.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateFlightTypeRequest}
 */
proto.elude.proto.CreateFlightTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateFlightTypeRequest;
  return proto.elude.proto.CreateFlightTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateFlightTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateFlightTypeRequest}
 */
proto.elude.proto.CreateFlightTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_flight_type_pb.FlightType;
      reader.readMessage(value,proto_models_flight_type_pb.FlightType.deserializeBinaryFromReader);
      msg.setFlightType(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateFlightTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateFlightTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateFlightTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_flight_type_pb.FlightType.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
*/
proto.elude.proto.CreateFlightTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
*/
proto.elude.proto.CreateFlightTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FlightType flight_type = 3;
 * @return {?proto.elude.proto.FlightType}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.getFlightType = function() {
  return /** @type{?proto.elude.proto.FlightType} */ (
    jspb.Message.getWrapperField(this, proto_models_flight_type_pb.FlightType, 3));
};


/**
 * @param {?proto.elude.proto.FlightType|undefined} value
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
*/
proto.elude.proto.CreateFlightTypeRequest.prototype.setFlightType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.clearFlightType = function() {
  return this.setFlightType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.hasFlightType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
*/
proto.elude.proto.CreateFlightTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFlightTypeRequest} returns this
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFlightTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateFlightTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateFlightTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateFlightTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    flightType: (f = msg.getFlightType()) && proto_models_flight_type_pb.FlightType.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateFlightTypeRequest}
 */
proto.elude.proto.UpdateFlightTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateFlightTypeRequest;
  return proto.elude.proto.UpdateFlightTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateFlightTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateFlightTypeRequest}
 */
proto.elude.proto.UpdateFlightTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_flight_type_pb.FlightType;
      reader.readMessage(value,proto_models_flight_type_pb.FlightType.deserializeBinaryFromReader);
      msg.setFlightType(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateFlightTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateFlightTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateFlightTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_flight_type_pb.FlightType.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
*/
proto.elude.proto.UpdateFlightTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
*/
proto.elude.proto.UpdateFlightTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FlightType flight_type = 3;
 * @return {?proto.elude.proto.FlightType}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.getFlightType = function() {
  return /** @type{?proto.elude.proto.FlightType} */ (
    jspb.Message.getWrapperField(this, proto_models_flight_type_pb.FlightType, 3));
};


/**
 * @param {?proto.elude.proto.FlightType|undefined} value
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
*/
proto.elude.proto.UpdateFlightTypeRequest.prototype.setFlightType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.clearFlightType = function() {
  return this.setFlightType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.hasFlightType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
*/
proto.elude.proto.UpdateFlightTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFlightTypeRequest} returns this
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFlightTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteFlightTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteFlightTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteFlightTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteFlightTypeRequest}
 */
proto.elude.proto.DeleteFlightTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteFlightTypeRequest;
  return proto.elude.proto.DeleteFlightTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteFlightTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteFlightTypeRequest}
 */
proto.elude.proto.DeleteFlightTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteFlightTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteFlightTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteFlightTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
*/
proto.elude.proto.DeleteFlightTypeRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
*/
proto.elude.proto.DeleteFlightTypeRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
*/
proto.elude.proto.DeleteFlightTypeRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteFlightTypeRequest} returns this
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteFlightTypeRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.elude.proto);
