import {
  AddSocialRequest,
  ChangePasswordRequest,
  GetUserRequest,
  UpdateUserRequest,
} from '../../../util/gen/proto/auth/auth_request_pb'
import { GetTravelStatsRequest } from '../../../util/gen/proto/booking/booking_request_pb'
import {
  AuthProviderEnum,
  PaymentProvidersEnum,
} from '../../../util/gen/proto/commons/enum_pb'
import { GDSPaymentProvider } from '../../../util/gen/proto/commons/flow_common_pb'
import { Pagination } from '../../../util/gen/proto/commons/pagination_pb'
import { UpdateUserPreferencesRequest } from '../../../util/gen/proto/location/location_request_pb'
import { User } from '../../../util/gen/proto/models/user_pb'
import {
  AddPaymentMethodRequest,
  DeletePaymentMethodRequest,
  GetPaymentMethodsRequest,
} from '../../../util/gen/proto/payment/payment_request_pb'
import { AppleAuth } from './auth-providers/AppleAuth'
import { FacebookAuth } from './auth-providers/FacebookAuth'
import { GoogleAuth } from './auth-providers/GoogleAuth'
import { TwitterAuth } from './auth-providers/TwitterAuth'
import { Api } from './base/Api'
import { Grpc, hydrateGrpcModel } from './base/Grpc'
import { useOptions } from './base/Grpc.utils'

export const UserService = {
  getUser: ({ userId }) =>
    Grpc.snt
      .getUser(new GetUserRequest().setId(userId))
      .then(res => res.toObject()),
  updateUser: user => {
    const userModel = hydrateGrpcModel(new User(), user)
    return Grpc.snt
      .updateUser(
        new UpdateUserRequest().setUser(userModel),
        useOptions({ useToasts: true }),
      )
      .then(res => res.toObject())
  },
  updateUserPreferences: ids => {
    return Grpc.snt
      .updateUserPreferences(
        new UpdateUserPreferencesRequest().setPreferenceIdsList(ids),
      )
      .then(res => res.toObject())
  },
  changePassword: ({
    currentPassword,
    passwordConfirmation,
    password,
    userId,
  }) =>
    Grpc.snt
      .changePassword(
        new ChangePasswordRequest()
          .setId(userId)
          .setCurrentPassword(currentPassword)
          .setPassword(password)
          .setPasswordConfirmation(passwordConfirmation),
        useOptions({ useToasts: true }),
      )
      .then(res => res.toObject()),
  addGoogleSocial: () =>
    GoogleAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.addSocial(
          new AddSocialRequest()
            .setToken(credential.idToken)
            .setProvider(AuthProviderEnum.GOOGLE),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  addFacebookSocial: () =>
    FacebookAuth.signIn().then(({ credential }) =>
      Grpc.snt.addSocial(
        new AddSocialRequest()
          .setToken(credential.accessToken)
          .setProvider(AuthProviderEnum.FACEBOOK),
        useOptions({ useToasts: true }),
      ),
    ),
  addTwitterSocial: () =>
    TwitterAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.addSocial(
          new AddSocialRequest()
            .setToken(credential.accessToken)
            .setTokenSecret(credential.secret)
            .setProvider(AuthProviderEnum.TWITTER),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  addAppleSocial: () =>
    AppleAuth.signIn()
      .then(({ credential }) =>
        Grpc.snt.addSocial(
          new AddSocialRequest()
            .setToken(credential.idToken)
            .setProvider(AuthProviderEnum.APPLE),
          useOptions({ useToasts: true }),
        ),
      )
      .then(res => res.toObject()),
  addPaypal: (id, data) =>
    Api.post(`users/${id}/add_payment_method/paypal`, data),
  addStripe: ({ token }) =>
    Grpc.snt
      .addPaymentMethod(
        new AddPaymentMethodRequest()
          .setToken(token)
          .setIsDefault(1)
          .setProvider(
            new GDSPaymentProvider().setName(PaymentProvidersEnum.STRIPE),
          ),
        useOptions({ useToasts: true }),
      )
      .then(res => res.toObject()),
  deletePaymentMethod: ({ paymentMethodId }) =>
    Grpc.snt
      .deletePaymentMethod(
        new DeletePaymentMethodRequest().setId(paymentMethodId),
        useOptions({ useToasts: true }),
      )
      .then(res => res.toObject()),
  getPaymentMethods: ({ userId }) =>
    Grpc.snt
      .getPaymentMethods(
        new GetPaymentMethodsRequest()
          .setUserid(userId)
          .setPagination(new Pagination().setOverridePagination(true)),
      )
      .then(res => res.toObject()),
  getStats: ({ userId }) =>
    Grpc.snt
      .getTravelStats(new GetTravelStatsRequest().setUserId(userId))
      .then(res => res.toObject()),
}
