import { SearchTypeEnum } from '../../../util/gen/proto/commons/enum_pb'

const LOCATION_TYPE = {
  AIRPORT: 'airport',
  FLIGHT: 'flight',
  COMBO: 'combo',
  HOTEL: 'hotel',
}
const LOCATION_PAGINATION = {
  airport: false,
  flight: false,
  combo: true,
  hotel: true,
}
const LOCATION_ENUM = {
  [SearchTypeEnum.AIRPORT_SEARCH]: LOCATION_TYPE.AIRPORT,
  [SearchTypeEnum.COMBINED_SEARCH]: LOCATION_TYPE.COMBO,
  [SearchTypeEnum.FLIGHT_SEARCH]: LOCATION_TYPE.FLIGHT,
  [SearchTypeEnum.LOCATION_SEARCH]: LOCATION_TYPE.HOTEL,
}
export const LOCATION = {
  TYPE: LOCATION_TYPE,
  PAGINATION: LOCATION_PAGINATION,
  ENUM: LOCATION_ENUM,
}
