export const onboardingRoutes = {
  path: '/onboarding',
  component: () => import('@/views/onboarding/Onboarding.vue'),
  meta: { title: 'Onboarding', userOnly: true },
  children: [
    {
      path: '/',
      component: () => import('@/views/onboarding/Onboarding10Intro.vue'),
      meta: { progress: false, skippable: true },
    },
    {
      path: 'name',
      component: () => import('@/views/onboarding/Onboarding11Name.vue'),
      meta: { progress: 12.5, skippable: true },
    },
    {
      path: 'city',
      component: () => import('@/views/onboarding/Onboarding12City.vue'),
      meta: { progress: 25, skippable: true },
    },
    {
      path: 'info',
      component: () => import('@/views/onboarding/Onboarding20Info.vue'),
      meta: { progress: 37.5, skippable: true },
    },
    {
      path: 'food',
      component: () => import('@/views/onboarding/Onboarding21Food.vue'),
      meta: { progress: 50, skippable: true },
    },
    {
      path: 'places',
      component: () => import('@/views/onboarding/Onboarding22Places.vue'),
      meta: { progress: 62.5, skippable: true },
    },
    {
      path: 'outdoorsy',
      component: () => import('@/views/onboarding/Onboarding23Outdoorsy.vue'),
      meta: { progress: 75, skippable: true },
    },
    {
      path: 'mingle',
      component: () => import('@/views/onboarding/Onboarding24Mingle.vue'),
      meta: { progress: 87.5, skippable: true },
    },
    {
      path: 'connectivity',
      component: () =>
        import('@/views/onboarding/Onboarding25Connectivity.vue'),
      meta: { progress: 100, skippable: true },
    },
    {
      path: 'outro',
      component: () => import('@/views/onboarding/Onboarding30Outro.vue'),
      meta: { progress: false, skippable: false },
    },
    // {
    //   path: 'activity',
    //   component: () => import('@/views/onboarding/OnboardingActivity.vue'),
    //   meta: { progress: 50, skippable: true },
    // },
    // {
    //   path: 'would-you',
    //   component: () => import('@/views/onboarding/OnboardingWouldYou.vue'),
    //   meta: { progress: 50, skippable: true },
    // },
    // {
    //   path: 'friends',
    //   component: () => import('@/views/onboarding/OnboardingFriends.vue'),
    //   meta: { progress: 50, skippable: true },
    // },
    // {
    //   path: 'music',
    //   component: () => import('@/views/onboarding/OnboardingMusic.vue'),
    //   meta: { progress: 50, skippable: true },
    // },
  ],
}
