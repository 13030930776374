// source: proto/models/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_title_pb = require('../../proto/models/title_pb.js');
goog.object.extend(proto, proto_models_title_pb);
var proto_models_country_pb = require('../../proto/models/country_pb.js');
goog.object.extend(proto, proto_models_country_pb);
var proto_models_auth_provider_pb = require('../../proto/models/auth_provider_pb.js');
goog.object.extend(proto, proto_models_auth_provider_pb);
var proto_models_media_pb = require('../../proto/models/media_pb.js');
goog.object.extend(proto, proto_models_media_pb);
var proto_models_payment_provider_pb = require('../../proto/models/payment_provider_pb.js');
goog.object.extend(proto, proto_models_payment_provider_pb);
var proto_models_role_pb = require('../../proto/models/role_pb.js');
goog.object.extend(proto, proto_models_role_pb);
var proto_models_location_pb = require('../../proto/models/location_pb.js');
goog.object.extend(proto, proto_models_location_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_models_preference_pb = require('../../proto/models/preference_pb.js');
goog.object.extend(proto, proto_models_preference_pb);
goog.exportSymbol('proto.elude.proto.PaymentMethod', null, global);
goog.exportSymbol('proto.elude.proto.User', null, global);
goog.exportSymbol('proto.elude.proto.UserSocial', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.User.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.User.displayName = 'proto.elude.proto.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UserSocial = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UserSocial, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UserSocial.displayName = 'proto.elude.proto.UserSocial';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PaymentMethod.displayName = 'proto.elude.proto.PaymentMethod';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.User.repeatedFields_ = [25,26,27,34];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.User.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    password: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    onboardingData: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cityId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    titleId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    phoneCountryId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    profilePictureId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    authProviderId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    city: (f = msg.getCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && proto_models_title_pb.Title.toObject(includeInstance, f),
    phoneCountry: (f = msg.getPhoneCountry()) && proto_models_country_pb.Country.toObject(includeInstance, f),
    authProvider: (f = msg.getAuthProvider()) && proto_models_auth_provider_pb.AuthProvider.toObject(includeInstance, f),
    profilePicture: (f = msg.getProfilePicture()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    verifiedAt: (f = msg.getVerifiedAt()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    verificationToken: jspb.Message.getFieldWithDefault(msg, 22, ""),
    recoveryToken: jspb.Message.getFieldWithDefault(msg, 23, ""),
    travelerType: jspb.Message.getFieldWithDefault(msg, 24, ""),
    socialsList: jspb.Message.toObjectList(msg.getSocialsList(),
    proto.elude.proto.UserSocial.toObject, includeInstance),
    paymentMethodsList: jspb.Message.toObjectList(msg.getPaymentMethodsList(),
    proto.elude.proto.PaymentMethod.toObject, includeInstance),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto_models_role_pb.Role.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    preferredAirport: (f = msg.getPreferredAirport()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    airportId: jspb.Message.getFieldWithDefault(msg, 30, 0),
    preferredAirportLocation: (f = msg.getPreferredAirportLocation()) && proto_models_location_pb.Location.toObject(includeInstance, f),
    provider: jspb.Message.getFieldWithDefault(msg, 32, 0),
    verificationSentAt: (f = msg.getVerificationSentAt()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    preferencesList: jspb.Message.toObjectList(msg.getPreferencesList(),
    proto_models_preference_pb.Preference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.User}
 */
proto.elude.proto.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.User;
  return proto.elude.proto.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.User}
 */
proto.elude.proto.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateOfBirth(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnboardingData(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTitleId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhoneCountryId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfilePictureId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAuthProviderId(value);
      break;
    case 16:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 17:
      var value = new proto_models_title_pb.Title;
      reader.readMessage(value,proto_models_title_pb.Title.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 18:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.setPhoneCountry(value);
      break;
    case 19:
      var value = new proto_models_auth_provider_pb.AuthProvider;
      reader.readMessage(value,proto_models_auth_provider_pb.AuthProvider.deserializeBinaryFromReader);
      msg.setAuthProvider(value);
      break;
    case 20:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setProfilePicture(value);
      break;
    case 21:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVerifiedAt(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationToken(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecoveryToken(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setTravelerType(value);
      break;
    case 25:
      var value = new proto.elude.proto.UserSocial;
      reader.readMessage(value,proto.elude.proto.UserSocial.deserializeBinaryFromReader);
      msg.addSocials(value);
      break;
    case 26:
      var value = new proto.elude.proto.PaymentMethod;
      reader.readMessage(value,proto.elude.proto.PaymentMethod.deserializeBinaryFromReader);
      msg.addPaymentMethods(value);
      break;
    case 27:
      var value = new proto_models_role_pb.Role;
      reader.readMessage(value,proto_models_role_pb.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 28:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 29:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setPreferredAirport(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirportId(value);
      break;
    case 31:
      var value = new proto_models_location_pb.Location;
      reader.readMessage(value,proto_models_location_pb.Location.deserializeBinaryFromReader);
      msg.setPreferredAirportLocation(value);
      break;
    case 32:
      var value = /** @type {!proto.elude.proto.AuthProviderEnum} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 33:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVerificationSentAt(value);
      break;
    case 34:
      var value = new proto_models_preference_pb.Preference;
      reader.readMessage(value,proto_models_preference_pb.Preference.deserializeBinaryFromReader);
      msg.addPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOnboardingData();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCityId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTitleId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPhoneCountryId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getProfilePictureId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getAuthProviderId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_models_title_pb.Title.serializeBinaryToWriter
    );
  }
  f = message.getPhoneCountry();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getAuthProvider();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_models_auth_provider_pb.AuthProvider.serializeBinaryToWriter
    );
  }
  f = message.getProfilePicture();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedAt();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVerificationToken();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getRecoveryToken();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getTravelerType();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSocialsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.elude.proto.UserSocial.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.elude.proto.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto_models_role_pb.Role.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreferredAirport();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getAirportId();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getPreferredAirportLocation();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_models_location_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getVerificationSentAt();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      34,
      f,
      proto_models_preference_pb.Preference.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.elude.proto.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.elude.proto.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_of_birth = 4;
 * @return {string}
 */
proto.elude.proto.User.prototype.getDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.elude.proto.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.elude.proto.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string password = 7;
 * @return {string}
 */
proto.elude.proto.User.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 8;
 * @return {string}
 */
proto.elude.proto.User.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_admin = 9;
 * @return {boolean}
 */
proto.elude.proto.User.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string onboarding_data = 10;
 * @return {string}
 */
proto.elude.proto.User.prototype.getOnboardingData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setOnboardingData = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 city_id = 11;
 * @return {number}
 */
proto.elude.proto.User.prototype.getCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 title_id = 12;
 * @return {number}
 */
proto.elude.proto.User.prototype.getTitleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setTitleId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 phone_country_id = 13;
 * @return {number}
 */
proto.elude.proto.User.prototype.getPhoneCountryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setPhoneCountryId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 profile_picture_id = 14;
 * @return {number}
 */
proto.elude.proto.User.prototype.getProfilePictureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setProfilePictureId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 auth_provider_id = 15;
 * @return {number}
 */
proto.elude.proto.User.prototype.getAuthProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setAuthProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional City city = 16;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.User.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 16));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasCity = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Title title = 17;
 * @return {?proto.elude.proto.Title}
 */
proto.elude.proto.User.prototype.getTitle = function() {
  return /** @type{?proto.elude.proto.Title} */ (
    jspb.Message.getWrapperField(this, proto_models_title_pb.Title, 17));
};


/**
 * @param {?proto.elude.proto.Title|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Country phone_country = 18;
 * @return {?proto.elude.proto.Country}
 */
proto.elude.proto.User.prototype.getPhoneCountry = function() {
  return /** @type{?proto.elude.proto.Country} */ (
    jspb.Message.getWrapperField(this, proto_models_country_pb.Country, 18));
};


/**
 * @param {?proto.elude.proto.Country|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setPhoneCountry = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearPhoneCountry = function() {
  return this.setPhoneCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasPhoneCountry = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional AuthProvider auth_provider = 19;
 * @return {?proto.elude.proto.AuthProvider}
 */
proto.elude.proto.User.prototype.getAuthProvider = function() {
  return /** @type{?proto.elude.proto.AuthProvider} */ (
    jspb.Message.getWrapperField(this, proto_models_auth_provider_pb.AuthProvider, 19));
};


/**
 * @param {?proto.elude.proto.AuthProvider|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setAuthProvider = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearAuthProvider = function() {
  return this.setAuthProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasAuthProvider = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Media profile_picture = 20;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.User.prototype.getProfilePicture = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 20));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setProfilePicture = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearProfilePicture = function() {
  return this.setProfilePicture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasProfilePicture = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Timestamp verified_at = 21;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.User.prototype.getVerifiedAt = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setVerifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearVerifiedAt = function() {
  return this.setVerifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasVerifiedAt = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string verification_token = 22;
 * @return {string}
 */
proto.elude.proto.User.prototype.getVerificationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setVerificationToken = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string recovery_token = 23;
 * @return {string}
 */
proto.elude.proto.User.prototype.getRecoveryToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setRecoveryToken = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string traveler_type = 24;
 * @return {string}
 */
proto.elude.proto.User.prototype.getTravelerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setTravelerType = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated UserSocial socials = 25;
 * @return {!Array<!proto.elude.proto.UserSocial>}
 */
proto.elude.proto.User.prototype.getSocialsList = function() {
  return /** @type{!Array<!proto.elude.proto.UserSocial>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.UserSocial, 25));
};


/**
 * @param {!Array<!proto.elude.proto.UserSocial>} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setSocialsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.elude.proto.UserSocial=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.UserSocial}
 */
proto.elude.proto.User.prototype.addSocials = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.elude.proto.UserSocial, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearSocialsList = function() {
  return this.setSocialsList([]);
};


/**
 * repeated PaymentMethod payment_methods = 26;
 * @return {!Array<!proto.elude.proto.PaymentMethod>}
 */
proto.elude.proto.User.prototype.getPaymentMethodsList = function() {
  return /** @type{!Array<!proto.elude.proto.PaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.PaymentMethod, 26));
};


/**
 * @param {!Array<!proto.elude.proto.PaymentMethod>} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setPaymentMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.elude.proto.PaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.User.prototype.addPaymentMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.elude.proto.PaymentMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearPaymentMethodsList = function() {
  return this.setPaymentMethodsList([]);
};


/**
 * repeated Role roles = 27;
 * @return {!Array<!proto.elude.proto.Role>}
 */
proto.elude.proto.User.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.elude.proto.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_role_pb.Role, 27));
};


/**
 * @param {!Array<!proto.elude.proto.Role>} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.elude.proto.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Role}
 */
proto.elude.proto.User.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.elude.proto.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional Timestamp created_at = 28;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.User.prototype.getCreatedAt = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 28));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional Airport preferred_airport = 29;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.User.prototype.getPreferredAirport = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 29));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setPreferredAirport = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearPreferredAirport = function() {
  return this.setPreferredAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasPreferredAirport = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional int32 airport_id = 30;
 * @return {number}
 */
proto.elude.proto.User.prototype.getAirportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setAirportId = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional Location preferred_airport_location = 31;
 * @return {?proto.elude.proto.Location}
 */
proto.elude.proto.User.prototype.getPreferredAirportLocation = function() {
  return /** @type{?proto.elude.proto.Location} */ (
    jspb.Message.getWrapperField(this, proto_models_location_pb.Location, 31));
};


/**
 * @param {?proto.elude.proto.Location|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setPreferredAirportLocation = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearPreferredAirportLocation = function() {
  return this.setPreferredAirportLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasPreferredAirportLocation = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional AuthProviderEnum provider = 32;
 * @return {!proto.elude.proto.AuthProviderEnum}
 */
proto.elude.proto.User.prototype.getProvider = function() {
  return /** @type {!proto.elude.proto.AuthProviderEnum} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.elude.proto.AuthProviderEnum} value
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * optional Timestamp verification_sent_at = 33;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.User.prototype.getVerificationSentAt = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 33));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setVerificationSentAt = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearVerificationSentAt = function() {
  return this.setVerificationSentAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.User.prototype.hasVerificationSentAt = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * repeated Preference preferences = 34;
 * @return {!Array<!proto.elude.proto.Preference>}
 */
proto.elude.proto.User.prototype.getPreferencesList = function() {
  return /** @type{!Array<!proto.elude.proto.Preference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_preference_pb.Preference, 34));
};


/**
 * @param {!Array<!proto.elude.proto.Preference>} value
 * @return {!proto.elude.proto.User} returns this
*/
proto.elude.proto.User.prototype.setPreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 34, value);
};


/**
 * @param {!proto.elude.proto.Preference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Preference}
 */
proto.elude.proto.User.prototype.addPreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 34, opt_value, proto.elude.proto.Preference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.User} returns this
 */
proto.elude.proto.User.prototype.clearPreferencesList = function() {
  return this.setPreferencesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UserSocial.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UserSocial.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UserSocial} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UserSocial.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    authProviderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    authProvider: (f = msg.getAuthProvider()) && proto_models_auth_provider_pb.AuthProvider.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    user: (f = msg.getUser()) && proto.elude.proto.User.toObject(includeInstance, f),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fallbackField: jspb.Message.getFieldWithDefault(msg, 7, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UserSocial}
 */
proto.elude.proto.UserSocial.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UserSocial;
  return proto.elude.proto.UserSocial.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UserSocial} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UserSocial}
 */
proto.elude.proto.UserSocial.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAuthProviderId(value);
      break;
    case 3:
      var value = new proto_models_auth_provider_pb.AuthProvider;
      reader.readMessage(value,proto_models_auth_provider_pb.AuthProvider.deserializeBinaryFromReader);
      msg.setAuthProvider(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 5:
      var value = new proto.elude.proto.User;
      reader.readMessage(value,proto.elude.proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFallbackField(value);
      break;
    case 8:
      var value = /** @type {!proto.elude.proto.AuthProviderEnum} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UserSocial.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UserSocial.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UserSocial} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UserSocial.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuthProviderId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAuthProvider();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_auth_provider_pb.AuthProvider.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.elude.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFallbackField();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.UserSocial.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 auth_provider_id = 2;
 * @return {number}
 */
proto.elude.proto.UserSocial.prototype.getAuthProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.setAuthProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AuthProvider auth_provider = 3;
 * @return {?proto.elude.proto.AuthProvider}
 */
proto.elude.proto.UserSocial.prototype.getAuthProvider = function() {
  return /** @type{?proto.elude.proto.AuthProvider} */ (
    jspb.Message.getWrapperField(this, proto_models_auth_provider_pb.AuthProvider, 3));
};


/**
 * @param {?proto.elude.proto.AuthProvider|undefined} value
 * @return {!proto.elude.proto.UserSocial} returns this
*/
proto.elude.proto.UserSocial.prototype.setAuthProvider = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.clearAuthProvider = function() {
  return this.setAuthProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UserSocial.prototype.hasAuthProvider = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 user_id = 4;
 * @return {number}
 */
proto.elude.proto.UserSocial.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional User user = 5;
 * @return {?proto.elude.proto.User}
 */
proto.elude.proto.UserSocial.prototype.getUser = function() {
  return /** @type{?proto.elude.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.User, 5));
};


/**
 * @param {?proto.elude.proto.User|undefined} value
 * @return {!proto.elude.proto.UserSocial} returns this
*/
proto.elude.proto.UserSocial.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UserSocial.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string unique_identifier = 6;
 * @return {string}
 */
proto.elude.proto.UserSocial.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fallback_field = 7;
 * @return {string}
 */
proto.elude.proto.UserSocial.prototype.getFallbackField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.setFallbackField = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AuthProviderEnum provider = 8;
 * @return {!proto.elude.proto.AuthProviderEnum}
 */
proto.elude.proto.UserSocial.prototype.getProvider = function() {
  return /** @type {!proto.elude.proto.AuthProviderEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.elude.proto.AuthProviderEnum} value
 * @return {!proto.elude.proto.UserSocial} returns this
 */
proto.elude.proto.UserSocial.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastFour: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isDefault: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brand: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    paymentProviderId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    user: (f = msg.getUser()) && proto.elude.proto.User.toObject(includeInstance, f),
    paymentProvider: (f = msg.getPaymentProvider()) && proto_models_payment_provider_pb.PaymentProvider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PaymentMethod;
  return proto.elude.proto.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFour(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsDefault(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentProviderId(value);
      break;
    case 9:
      var value = new proto.elude.proto.User;
      reader.readMessage(value,proto.elude.proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 10:
      var value = new proto_models_payment_provider_pb.PaymentProvider;
      reader.readMessage(value,proto_models_payment_provider_pb.PaymentProvider.deserializeBinaryFromReader);
      msg.setPaymentProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLastFour();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsDefault();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPaymentProviderId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.elude.proto.User.serializeBinaryToWriter
    );
  }
  f = message.getPaymentProvider();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_models_payment_provider_pb.PaymentProvider.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.PaymentMethod.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string last_four = 2;
 * @return {string}
 */
proto.elude.proto.PaymentMethod.prototype.getLastFour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setLastFour = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.elude.proto.PaymentMethod.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 is_default = 4;
 * @return {number}
 */
proto.elude.proto.PaymentMethod.prototype.getIsDefault = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string brand = 5;
 * @return {string}
 */
proto.elude.proto.PaymentMethod.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.elude.proto.PaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 user_id = 7;
 * @return {number}
 */
proto.elude.proto.PaymentMethod.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 payment_provider_id = 8;
 * @return {number}
 */
proto.elude.proto.PaymentMethod.prototype.getPaymentProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.setPaymentProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional User user = 9;
 * @return {?proto.elude.proto.User}
 */
proto.elude.proto.PaymentMethod.prototype.getUser = function() {
  return /** @type{?proto.elude.proto.User} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.User, 9));
};


/**
 * @param {?proto.elude.proto.User|undefined} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
*/
proto.elude.proto.PaymentMethod.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethod.prototype.hasUser = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PaymentProvider payment_provider = 10;
 * @return {?proto.elude.proto.PaymentProvider}
 */
proto.elude.proto.PaymentMethod.prototype.getPaymentProvider = function() {
  return /** @type{?proto.elude.proto.PaymentProvider} */ (
    jspb.Message.getWrapperField(this, proto_models_payment_provider_pb.PaymentProvider, 10));
};


/**
 * @param {?proto.elude.proto.PaymentProvider|undefined} value
 * @return {!proto.elude.proto.PaymentMethod} returns this
*/
proto.elude.proto.PaymentMethod.prototype.setPaymentProvider = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethod} returns this
 */
proto.elude.proto.PaymentMethod.prototype.clearPaymentProvider = function() {
  return this.setPaymentProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethod.prototype.hasPaymentProvider = function() {
  return jspb.Message.getField(this, 10) != null;
};


goog.object.extend(exports, proto.elude.proto);
