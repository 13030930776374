<template>
  <h2 class="x-class">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'XTitle',
}
</script>

<style scoped lang="scss">
.x-class {
  font-family: GTSuper;
  font-size: 3.125rem;
}
</style>
