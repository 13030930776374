<template>
  <grid :gtc="gtc" :gap="gap" style="justify-content: flex-start">
    <icon v-for="x in Number(count)" :key="x" :icon="icon" :color="color" />
  </grid>
</template>

<script>
export default {
  name: 'IconList',
  props: {
    icon: {
      type: String,
      required: true,
    },
    count: {
      type: [Number, String],
      required: true,
    },
    gap: {
      type: String,
      default: '0.5',
    },
    color: {
      type: String,
    },
  },
  computed: {
    gtc() {
      return `repeat(${this.count}, auto)`
    },
  },
}
</script>

<style scoped lang="scss"></style>
