<template>
  <div>
    <transition-group
      ref="carousel"
      name="slide"
      appear
      tag="div"
      class="x-carousel"
      :class="carouselClass"
      :style="carouselStyles"
      @scroll.native="scrollHandler"
      @mousedown.native="mousedownHandler"
      @mouseup.native="mousedown = false"
      @mouseleave.native="mousedown = false"
      @mousemove.native="mousemoveHandler"
    >
      <component
        :is="item.is"
        v-for="item in carouselData"
        :key="item.id"
        ref="cards"
        v-bind="item"
        :data="item.data"
        :class="[...carouselCardClass, ...item.class, 'carousel-card']"
        :style="{ ...carouselCardStyles, ...item.style }"
        @click="clickHandler(item.id)"
      />
    </transition-group>
    <flex v-if="indicator" class="x-indicator" style="height: 10px">
      <icon
        v-for="(item, index) in carouselData"
        :key="item.id"
        icon="Dot"
        style="
          transition-property: all;
          transition-timing-function: ease-in-out;
          transition-duration: 0.2s;
        "
        :color="activeIndex === index ? 'black' : 'grey'"
        :class="
          (index === 0 || data.length - 1 === index) &&
          activeIndex !== index &&
          'x-dot-small'
        "
      />
    </flex>
  </div>
</template>

<script>
import { utils } from '../../other/utils/utils.js'

export default {
  name: 'Carousel',
  props: {
    data: {
      type: Array,
      default: () =>
        [...Array(10)].map(() => ({
          is: 'div',
          style: 'background: var(--color-dark); padding: 5rem;',
        })),
      required: true,
    },
    indicator: Boolean,
    gap: {
      type: [String, Number],
      default: '1',
    },
    selectable: Boolean,
    preservePadding: Boolean,
    center: Boolean,
    snapY: Boolean,
    startIndex: Number || String,
  },
  data() {
    return {
      carousel: undefined,
      activeIndex: this.startIndex !== undefined ? Number(this.startIndex) : 0,
      cardWidth: undefined,
      parentStyles: undefined,
      cardClickAt: 0,
      mousedown: false,
      mousedownAt: 0,
      mousedownScroll: 0,
      gapInPx: utils.units.remToPx(this.gap),
      cssVars: {
        marginGap: `${Number(this.gap) / 2}rem`,
      },
    }
  },
  mounted() {
    window.addEventListener('resize', this.calcCardWidth)
    this.carousel = this.$refs.carousel.$el
    this.$nextTick(() => {
      this.parentStyles = window.getComputedStyle(this.$el.parentElement)
      this.clickScroller(this.activeIndex)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcCardWidth)
  },
  watch: {
    data: {
      immediate: true,
      handler(x) {
        x.length &&
          this.$nextTick(() => (this.cardWidth = this.getCard()?.clientWidth))
      },
    },
    activeIndex: {
      immediate: true,
      handler(x) {
        this.$emit('active', x)
      },
    },
    startIndex(x) {
      this.clickScroller(x)
    },
  },
  methods: {
    getCard() {
      return this.$refs.cards[0]?.$el ?? this.$refs.cards[0]
    },
    calcCardWidth: utils.throttle(function () {
      this.cardWidth = this.getCard()?.clientWidth
    }, 500),
    clickHandler(index) {
      if (Date.now() - this.mousedownAt > 180) return
      this.$emit('click', index)
      if (index === this.activeIndex) this.$emit('confirm', index)
      this.activeIndex = index
      this.cardClickAt = Date.now()
      this.clickScroller(index)
    },
    clickScroller(index) {
      const child = [...this.carousel.children][index].getBoundingClientRect()
      const crsl = this.carousel.getBoundingClientRect()
      const pos = child.left - crsl.left + child.width / 2
      const target = crsl.width / 2
      this.snapY &&
        window.scrollBy({
          top: child.top - this.gapInPx,
          behavior: 'smooth',
        })
      if (typeof this.carousel?.scrollBy === 'function')
        this.carousel.scrollBy({
          left: pos - target,
          behavior: 'smooth',
        })
    },
    scrollHandler: utils.throttle(function () {
      if (Date.now() - this.cardClickAt < 500) return
      const scrollMax = this.carousel.scrollWidth - this.carousel.clientWidth
      const index =
        Math.ceil(this.carousel.scrollLeft / (scrollMax / this.data.length)) - 1
      this.activeIndex = index < 0 ? 0 : index
    }, 75),
    mousedownHandler() {
      this.mousedown = true
      this.mousedownAt = Date.now()
      this.mousedownScroll = this.carousel.scrollLeft + event.clientX
    },
    mousemoveHandler() {
      if (!this.mousedown) return
      this.$refs.carousel.$el.scrollLeft = this.mousedownScroll - event.clientX
    },
  },
  computed: {
    carouselData() {
      return this.data.map((x, index) => ({ id: index, ...x }))
    },
    carouselStyles() {
      const styles = {}
      styles['--gap'] = utils.css.calcUnits('rem')(this.gap)
      styles['--card-width'] = this.cardWidth / 2 + 'px'
      if (!this.mousedown)
        styles.scrollSnapType = this.center ? 'x mandatory' : 'x proximity'
      if (!this.preservePadding) {
        styles.marginLeft = `-${this.parentStyles?.getPropertyValue(
          'padding-left',
        )}`
        styles.marginRight = `-${this.parentStyles?.getPropertyValue(
          'padding-right',
        )}`
      }
      return styles
    },
    carouselClass() {
      const classes = []
      if (!this.selectable) classes.push('x-not-selectable')
      if (this.center) classes.push('x-center')
      return classes
    },
    carouselCardStyles() {
      const styles = {}
      if (!this.mousedown)
        styles.scrollSnapAlign = this.center ? 'center' : 'nearest'
      if (this.indicator) styles.marginBottom = '1rem'
      return styles
    },
    carouselCardClass() {
      const classes = []
      return classes
    },
  },
}
</script>

<style scoped lang="scss">
.x-carousel {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  & > * {
    flex: 0 0 auto;
  }
  & > * {
    margin-left: var(--margin-gap);
    margin-right: var(--margin-gap);
  }
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::before {
    margin-right: var(--margin-gap);
  }
  &::after {
    margin-left: var(--margin-gap);
  }
  &::before,
  &::after {
    content: '';
    flex: 0 0 2px;
  }
}
.x-center {
  &::before,
  &::after {
    content: '';
    flex: 0 0 calc(50% - var(--card-width) - var(--gap));
  }
}
.x-not-selectable * {
  user-select: none;
}
.x-dot-small {
  width: 4px;
}
.x-indicator {
  justify-content: center;
  align-items: center;
  & > * + * {
    margin-left: 0.7rem;
  }
}
</style>
