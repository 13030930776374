// source: proto/models/city.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_models_media_pb = require('../../proto/models/media_pb.js');
goog.object.extend(proto, proto_models_media_pb);
var proto_models_country_pb = require('../../proto/models/country_pb.js');
goog.object.extend(proto, proto_models_country_pb);
var proto_models_region_pb = require('../../proto/models/region_pb.js');
goog.object.extend(proto, proto_models_region_pb);
var proto_models_gds_pb = require('../../proto/models/gds_pb.js');
goog.object.extend(proto, proto_models_gds_pb);
var proto_models_property_chain_pb = require('../../proto/models/property_chain_pb.js');
goog.object.extend(proto, proto_models_property_chain_pb);
var proto_models_property_type_pb = require('../../proto/models/property_type_pb.js');
goog.object.extend(proto, proto_models_property_type_pb);
var proto_commons_weather_pb = require('../../proto/commons/weather_pb.js');
goog.object.extend(proto, proto_commons_weather_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.elude.proto.Airport', null, global);
goog.exportSymbol('proto.elude.proto.City', null, global);
goog.exportSymbol('proto.elude.proto.Property', null, global);
goog.exportSymbol('proto.elude.proto.PropertyGds', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.City = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.City.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.City, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.City.displayName = 'proto.elude.proto.City';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Airport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Airport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Airport.displayName = 'proto.elude.proto.Airport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Property = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.Property.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Property.displayName = 'proto.elude.proto.Property';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PropertyGds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PropertyGds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PropertyGds.displayName = 'proto.elude.proto.PropertyGds';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.City.repeatedFields_ = [14,18,20,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.City.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.City.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.City} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.City.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    iataCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isConnected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_long: jspb.Message.getFieldWithDefault(msg, 7, ""),
    countryId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    country: (f = msg.getCountry()) && proto_models_country_pb.Country.toObject(includeInstance, f),
    imageId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    media: (f = msg.getMedia()) && proto_models_media_pb.Media.toObject(includeInstance, f),
    regionId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    region: (f = msg.getRegion()) && proto_models_region_pb.Region.toObject(includeInstance, f),
    airportsList: jspb.Message.toObjectList(msg.getAirportsList(),
    proto.elude.proto.Airport.toObject, includeInstance),
    weather: (f = msg.getWeather()) && proto_commons_weather_pb.Weather.toObject(includeInstance, f),
    nearestConnectedCity: (f = msg.getNearestConnectedCity()) && proto.elude.proto.City.toObject(includeInstance, f),
    distanceMi: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.elude.proto.Property.toObject, includeInstance),
    isRecommended: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    connectedLocationIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    shardId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    connectedLocationsList: jspb.Message.toObjectList(msg.getConnectedLocationsList(),
    proto.elude.proto.City.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.City}
 */
proto.elude.proto.City.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.City;
  return proto.elude.proto.City.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.City} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.City}
 */
proto.elude.proto.City.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIataCode(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConnected(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLong(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountryId(value);
      break;
    case 9:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageId(value);
      break;
    case 11:
      var value = new proto_models_media_pb.Media;
      reader.readMessage(value,proto_models_media_pb.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRegionId(value);
      break;
    case 13:
      var value = new proto_models_region_pb.Region;
      reader.readMessage(value,proto_models_region_pb.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 14:
      var value = new proto.elude.proto.Airport;
      reader.readMessage(value,proto.elude.proto.Airport.deserializeBinaryFromReader);
      msg.addAirports(value);
      break;
    case 15:
      var value = new proto_commons_weather_pb.Weather;
      reader.readMessage(value,proto_commons_weather_pb.Weather.deserializeBinaryFromReader);
      msg.setWeather(value);
      break;
    case 16:
      var value = new proto.elude.proto.City;
      reader.readMessage(value,proto.elude.proto.City.deserializeBinaryFromReader);
      msg.setNearestConnectedCity(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceMi(value);
      break;
    case 18:
      var value = new proto.elude.proto.Property;
      reader.readMessage(value,proto.elude.proto.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecommended(value);
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConnectedLocationIds(values[i]);
      }
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setShardId(value);
      break;
    case 22:
      var value = new proto.elude.proto.City;
      reader.readMessage(value,proto.elude.proto.City.deserializeBinaryFromReader);
      msg.addConnectedLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.City.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.City.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.City} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.City.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIataCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsConnected();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLong();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountryId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getImageId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_models_media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getRegionId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_models_region_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getAirportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.elude.proto.Airport.serializeBinaryToWriter
    );
  }
  f = message.getWeather();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_commons_weather_pb.Weather.serializeBinaryToWriter
    );
  }
  f = message.getNearestConnectedCity();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.elude.proto.City.serializeBinaryToWriter
    );
  }
  f = message.getDistanceMi();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.elude.proto.Property.serializeBinaryToWriter
    );
  }
  f = message.getIsRecommended();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getConnectedLocationIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      20,
      f
    );
  }
  f = message.getShardId();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getConnectedLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.elude.proto.City.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.City.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.elude.proto.City.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.elude.proto.City.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string iata_code = 4;
 * @return {string}
 */
proto.elude.proto.City.prototype.getIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_connected = 5;
 * @return {boolean}
 */
proto.elude.proto.City.prototype.getIsConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setIsConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string lat = 6;
 * @return {string}
 */
proto.elude.proto.City.prototype.getLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setLat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string long = 7;
 * @return {string}
 */
proto.elude.proto.City.prototype.getLong = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setLong = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 country_id = 8;
 * @return {number}
 */
proto.elude.proto.City.prototype.getCountryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setCountryId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Country country = 9;
 * @return {?proto.elude.proto.Country}
 */
proto.elude.proto.City.prototype.getCountry = function() {
  return /** @type{?proto.elude.proto.Country} */ (
    jspb.Message.getWrapperField(this, proto_models_country_pb.Country, 9));
};


/**
 * @param {?proto.elude.proto.Country|undefined} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.City.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 image_id = 10;
 * @return {number}
 */
proto.elude.proto.City.prototype.getImageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setImageId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Media media = 11;
 * @return {?proto.elude.proto.Media}
 */
proto.elude.proto.City.prototype.getMedia = function() {
  return /** @type{?proto.elude.proto.Media} */ (
    jspb.Message.getWrapperField(this, proto_models_media_pb.Media, 11));
};


/**
 * @param {?proto.elude.proto.Media|undefined} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.City.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 region_id = 12;
 * @return {number}
 */
proto.elude.proto.City.prototype.getRegionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional Region region = 13;
 * @return {?proto.elude.proto.Region}
 */
proto.elude.proto.City.prototype.getRegion = function() {
  return /** @type{?proto.elude.proto.Region} */ (
    jspb.Message.getWrapperField(this, proto_models_region_pb.Region, 13));
};


/**
 * @param {?proto.elude.proto.Region|undefined} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.City.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated Airport airports = 14;
 * @return {!Array<!proto.elude.proto.Airport>}
 */
proto.elude.proto.City.prototype.getAirportsList = function() {
  return /** @type{!Array<!proto.elude.proto.Airport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.Airport, 14));
};


/**
 * @param {!Array<!proto.elude.proto.Airport>} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setAirportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.elude.proto.Airport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Airport}
 */
proto.elude.proto.City.prototype.addAirports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.elude.proto.Airport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearAirportsList = function() {
  return this.setAirportsList([]);
};


/**
 * optional Weather weather = 15;
 * @return {?proto.elude.proto.Weather}
 */
proto.elude.proto.City.prototype.getWeather = function() {
  return /** @type{?proto.elude.proto.Weather} */ (
    jspb.Message.getWrapperField(this, proto_commons_weather_pb.Weather, 15));
};


/**
 * @param {?proto.elude.proto.Weather|undefined} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setWeather = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearWeather = function() {
  return this.setWeather(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.City.prototype.hasWeather = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional City nearest_connected_city = 16;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.City.prototype.getNearestConnectedCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.City, 16));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setNearestConnectedCity = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearNearestConnectedCity = function() {
  return this.setNearestConnectedCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.City.prototype.hasNearestConnectedCity = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional float distance_mi = 17;
 * @return {number}
 */
proto.elude.proto.City.prototype.getDistanceMi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setDistanceMi = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * repeated Property properties = 18;
 * @return {!Array<!proto.elude.proto.Property>}
 */
proto.elude.proto.City.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.elude.proto.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.Property, 18));
};


/**
 * @param {!Array<!proto.elude.proto.Property>} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.elude.proto.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Property}
 */
proto.elude.proto.City.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.elude.proto.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * optional bool is_recommended = 19;
 * @return {boolean}
 */
proto.elude.proto.City.prototype.getIsRecommended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setIsRecommended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * repeated int32 connected_location_ids = 20;
 * @return {!Array<number>}
 */
proto.elude.proto.City.prototype.getConnectedLocationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setConnectedLocationIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.addConnectedLocationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearConnectedLocationIdsList = function() {
  return this.setConnectedLocationIdsList([]);
};


/**
 * optional int32 shard_id = 21;
 * @return {number}
 */
proto.elude.proto.City.prototype.getShardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.setShardId = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * repeated City connected_locations = 22;
 * @return {!Array<!proto.elude.proto.City>}
 */
proto.elude.proto.City.prototype.getConnectedLocationsList = function() {
  return /** @type{!Array<!proto.elude.proto.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.City, 22));
};


/**
 * @param {!Array<!proto.elude.proto.City>} value
 * @return {!proto.elude.proto.City} returns this
*/
proto.elude.proto.City.prototype.setConnectedLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.elude.proto.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.City}
 */
proto.elude.proto.City.prototype.addConnectedLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.elude.proto.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.City} returns this
 */
proto.elude.proto.City.prototype.clearConnectedLocationsList = function() {
  return this.setConnectedLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Airport.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Airport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Airport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Airport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iataCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    elevation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gpsCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pb_long: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    cityId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    city: (f = msg.getCity()) && proto.elude.proto.City.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Airport}
 */
proto.elude.proto.Airport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Airport;
  return proto.elude.proto.Airport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Airport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Airport}
 */
proto.elude.proto.Airport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIataCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setElevation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGpsCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLong(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityId(value);
      break;
    case 12:
      var value = new proto.elude.proto.City;
      reader.readMessage(value,proto.elude.proto.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Airport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Airport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Airport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Airport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIataCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getElevation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGpsCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLong();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCityId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.elude.proto.City.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Airport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iata_code = 3;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string identity = 4;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string elevation = 5;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getElevation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setElevation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gps_code = 6;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getGpsCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setGpsCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string lat = 7;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setLat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string long = 8;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getLong = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setLong = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.elude.proto.Airport.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_active = 10;
 * @return {boolean}
 */
proto.elude.proto.Airport.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 city_id = 11;
 * @return {number}
 */
proto.elude.proto.Airport.prototype.getCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.setCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional City city = 12;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Airport.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.City, 12));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Airport} returns this
*/
proto.elude.proto.Airport.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Airport} returns this
 */
proto.elude.proto.Airport.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Airport.prototype.hasCity = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.Property.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Property.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Property.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Property} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Property.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    iataCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    city: (f = msg.getCity()) && proto.elude.proto.City.toObject(includeInstance, f),
    propertyTypeId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    propertyType: (f = msg.getPropertyType()) && proto_models_property_type_pb.PropertyType.toObject(includeInstance, f),
    propertyChainId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    propertyChain: (f = msg.getPropertyChain()) && proto_models_property_chain_pb.PropertyChain.toObject(includeInstance, f),
    propertyGdsList: jspb.Message.toObjectList(msg.getPropertyGdsList(),
    proto.elude.proto.PropertyGds.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Property}
 */
proto.elude.proto.Property.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Property;
  return proto.elude.proto.Property.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Property}
 */
proto.elude.proto.Property.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIataCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRating(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityId(value);
      break;
    case 8:
      var value = new proto.elude.proto.City;
      reader.readMessage(value,proto.elude.proto.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPropertyTypeId(value);
      break;
    case 10:
      var value = new proto_models_property_type_pb.PropertyType;
      reader.readMessage(value,proto_models_property_type_pb.PropertyType.deserializeBinaryFromReader);
      msg.setPropertyType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPropertyChainId(value);
      break;
    case 12:
      var value = new proto_models_property_chain_pb.PropertyChain;
      reader.readMessage(value,proto_models_property_chain_pb.PropertyChain.deserializeBinaryFromReader);
      msg.setPropertyChain(value);
      break;
    case 13:
      var value = new proto.elude.proto.PropertyGds;
      reader.readMessage(value,proto.elude.proto.PropertyGds.deserializeBinaryFromReader);
      msg.addPropertyGds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Property.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Property.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIataCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRating();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCityId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.elude.proto.City.serializeBinaryToWriter
    );
  }
  f = message.getPropertyTypeId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPropertyType();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_models_property_type_pb.PropertyType.serializeBinaryToWriter
    );
  }
  f = message.getPropertyChainId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPropertyChain();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_models_property_chain_pb.PropertyChain.serializeBinaryToWriter
    );
  }
  f = message.getPropertyGdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.elude.proto.PropertyGds.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Property.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.elude.proto.Property.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.elude.proto.Property.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string iata_code = 4;
 * @return {string}
 */
proto.elude.proto.Property.prototype.getIataCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setIataCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string postal_code = 5;
 * @return {string}
 */
proto.elude.proto.Property.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string rating = 6;
 * @return {string}
 */
proto.elude.proto.Property.prototype.getRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setRating = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 city_id = 7;
 * @return {number}
 */
proto.elude.proto.Property.prototype.getCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional City city = 8;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Property.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.City, 8));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Property} returns this
*/
proto.elude.proto.Property.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Property.prototype.hasCity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 property_type_id = 9;
 * @return {number}
 */
proto.elude.proto.Property.prototype.getPropertyTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setPropertyTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional PropertyType property_type = 10;
 * @return {?proto.elude.proto.PropertyType}
 */
proto.elude.proto.Property.prototype.getPropertyType = function() {
  return /** @type{?proto.elude.proto.PropertyType} */ (
    jspb.Message.getWrapperField(this, proto_models_property_type_pb.PropertyType, 10));
};


/**
 * @param {?proto.elude.proto.PropertyType|undefined} value
 * @return {!proto.elude.proto.Property} returns this
*/
proto.elude.proto.Property.prototype.setPropertyType = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.clearPropertyType = function() {
  return this.setPropertyType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Property.prototype.hasPropertyType = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 property_chain_id = 11;
 * @return {number}
 */
proto.elude.proto.Property.prototype.getPropertyChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.setPropertyChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional PropertyChain property_chain = 12;
 * @return {?proto.elude.proto.PropertyChain}
 */
proto.elude.proto.Property.prototype.getPropertyChain = function() {
  return /** @type{?proto.elude.proto.PropertyChain} */ (
    jspb.Message.getWrapperField(this, proto_models_property_chain_pb.PropertyChain, 12));
};


/**
 * @param {?proto.elude.proto.PropertyChain|undefined} value
 * @return {!proto.elude.proto.Property} returns this
*/
proto.elude.proto.Property.prototype.setPropertyChain = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.clearPropertyChain = function() {
  return this.setPropertyChain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Property.prototype.hasPropertyChain = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated PropertyGds property_gds = 13;
 * @return {!Array<!proto.elude.proto.PropertyGds>}
 */
proto.elude.proto.Property.prototype.getPropertyGdsList = function() {
  return /** @type{!Array<!proto.elude.proto.PropertyGds>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.PropertyGds, 13));
};


/**
 * @param {!Array<!proto.elude.proto.PropertyGds>} value
 * @return {!proto.elude.proto.Property} returns this
*/
proto.elude.proto.Property.prototype.setPropertyGdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.elude.proto.PropertyGds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PropertyGds}
 */
proto.elude.proto.Property.prototype.addPropertyGds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.elude.proto.PropertyGds, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.Property} returns this
 */
proto.elude.proto.Property.prototype.clearPropertyGdsList = function() {
  return this.setPropertyGdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PropertyGds.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PropertyGds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PropertyGds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PropertyGds.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    propertyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    property: (f = msg.getProperty()) && proto.elude.proto.Property.toObject(includeInstance, f),
    gdsId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gds: (f = msg.getGds()) && proto_models_gds_pb.Gds.toObject(includeInstance, f),
    identifier: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PropertyGds}
 */
proto.elude.proto.PropertyGds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PropertyGds;
  return proto.elude.proto.PropertyGds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PropertyGds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PropertyGds}
 */
proto.elude.proto.PropertyGds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPropertyId(value);
      break;
    case 3:
      var value = new proto.elude.proto.Property;
      reader.readMessage(value,proto.elude.proto.Property.deserializeBinaryFromReader);
      msg.setProperty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGdsId(value);
      break;
    case 5:
      var value = new proto_models_gds_pb.Gds;
      reader.readMessage(value,proto_models_gds_pb.Gds.deserializeBinaryFromReader);
      msg.setGds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PropertyGds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PropertyGds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PropertyGds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PropertyGds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPropertyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.elude.proto.Property.serializeBinaryToWriter
    );
  }
  f = message.getGdsId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGds();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_models_gds_pb.Gds.serializeBinaryToWriter
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.PropertyGds.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PropertyGds} returns this
 */
proto.elude.proto.PropertyGds.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 property_id = 2;
 * @return {number}
 */
proto.elude.proto.PropertyGds.prototype.getPropertyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PropertyGds} returns this
 */
proto.elude.proto.PropertyGds.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Property property = 3;
 * @return {?proto.elude.proto.Property}
 */
proto.elude.proto.PropertyGds.prototype.getProperty = function() {
  return /** @type{?proto.elude.proto.Property} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Property, 3));
};


/**
 * @param {?proto.elude.proto.Property|undefined} value
 * @return {!proto.elude.proto.PropertyGds} returns this
*/
proto.elude.proto.PropertyGds.prototype.setProperty = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PropertyGds} returns this
 */
proto.elude.proto.PropertyGds.prototype.clearProperty = function() {
  return this.setProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PropertyGds.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 gds_id = 4;
 * @return {number}
 */
proto.elude.proto.PropertyGds.prototype.getGdsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PropertyGds} returns this
 */
proto.elude.proto.PropertyGds.prototype.setGdsId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Gds gds = 5;
 * @return {?proto.elude.proto.Gds}
 */
proto.elude.proto.PropertyGds.prototype.getGds = function() {
  return /** @type{?proto.elude.proto.Gds} */ (
    jspb.Message.getWrapperField(this, proto_models_gds_pb.Gds, 5));
};


/**
 * @param {?proto.elude.proto.Gds|undefined} value
 * @return {!proto.elude.proto.PropertyGds} returns this
*/
proto.elude.proto.PropertyGds.prototype.setGds = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PropertyGds} returns this
 */
proto.elude.proto.PropertyGds.prototype.clearGds = function() {
  return this.setGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PropertyGds.prototype.hasGds = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string identifier = 6;
 * @return {string}
 */
proto.elude.proto.PropertyGds.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PropertyGds} returns this
 */
proto.elude.proto.PropertyGds.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


goog.object.extend(exports, proto.elude.proto);
