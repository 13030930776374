import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export const createFirebaseAuth = (providerInstance, providerClass) => ({
  signIn: () =>
    signInWithPopup(auth, providerInstance)
      .then(result => {
        const data = {
          user: result.user,
          credential: providerClass.credentialFromResult(result),
        }
        return data
      })
      .catch(error => {
        const errorData = {
          errorCode: error.code,
          errorMessage: error.message,
          email: error.email,
          credential: providerClass.credentialFromError(error),
        }
        throw errorData
      }),
})
