<template>
  <PosTos type="pos"></PosTos>
</template>

<script>
import PosTos from '../../components/common/PosTos.vue'

export default {
  name: 'Privacy',
  components: { PosTos },
}
</script>
