const clientId = process.env.VUE_APP_APPLE_CLIENT_ID
const redirectUri = window.location.origin + '/login'

const config = {
  clientId: clientId,
  scope: 'email',
  redirectURI: redirectUri,
  usePopup: true,
}

const install = () => {
  const script = document.createElement('script')
  script.src =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  script.async = true
  script.onload = () => {
    AppleAuth.init()
  }
  document.body.appendChild(script)
}

export const AppleAuth = {
  instance: null,
  isAuthorized: false,
  isInit: false,

  init: () => {
    window.AppleID.auth.init(config)
    AppleAuth.instance = window.AppleID
  },

  signIn: () =>
    new Promise((resolve, reject) => {
      if (!AppleAuth.instance) {
        reject(new Error('AppleAuth instance not initiated'))
        return
      }
      AppleAuth.instance.auth.signIn()
      document.addEventListener('AppleIDSignInOnSuccess', data => {
        resolve({ credential: { idToken: data.detail.authorization.id_token } })
      })
      document.addEventListener('AppleIDSignInOnFailure', error => {
        reject({ msg: error })
      })
    }),
}

if (clientId) install()

// FIREBASE REFACTOR ("firebase": "^9.8.1")

// import { OAuthProvider } from 'firebase/auth'
// import { createFirebaseAuth } from '../firebase/Firebase'

// const provider = new OAuthProvider('apple.com')
// provider.addScope('email')
// provider.addScope('name')

// export const AppleAuth = createFirebaseAuth(provider, OAuthProvider)
