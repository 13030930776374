import { Pagination } from '../../../util/gen/proto/commons/pagination_pb'
import {
  GetCitiesRequest,
  GetCityRequest,
  GetMultipleCitiesRequest,
} from '../../../util/gen/proto/location/location_request_pb'
import { Grpc } from './base/Grpc'

const pagination = new Pagination().setPageSize(30)

export const CityService = {
  getMultiple: (iataCodes = []) =>
    Grpc.snt
      .getMultipleCities(
        new GetMultipleCitiesRequest().setIataCodesList(iataCodes),
      )
      .then(res => res.toObject()),
  getCities: () =>
    Grpc.snt
      .getCities(new GetCitiesRequest().setPagination(pagination))
      .then(res => res.toObject()),
  searchCities: search =>
    Grpc.snt
      .getCities(
        new GetCitiesRequest().setSearch(search).setPagination(pagination),
      )
      .then(res => res.toObject()),
  getDepartureCities: () =>
    Grpc.snt
      .getDepartureCities(
        new GetCitiesRequest().setPagination(new Pagination().setPageSize(100)),
      )
      .then(res => res.toObject()),
  searchDepartureCities: search =>
    Grpc.snt
      .getDepartureCities(
        new GetCitiesRequest().setSearch(search).setPagination(pagination),
      )
      .then(res => res.toObject()),
  getWeatherByCity: ({ cityId }) =>
    Grpc.snt
      .getCityWithWeather(new GetCityRequest().setId(cityId))
      .then(res => res.toObject()),
}
