import { AuthProviderEnum } from '../../../util/gen/proto/commons/enum_pb'
import IconApple from '../components-rf/icons/socials/IconApple.vue'
import IconFacebook from '../components-rf/icons/socials/IconFacebook.vue'
import IconGoogle from '../components-rf/icons/socials/IconGoogle.vue'
import IconTwitter from '../components-rf/icons/socials/IconTwitter.vue'
import { AuthService } from '../services/AuthService'
import { UserService } from '../services/UserService'

const disabledProviders = [AuthProviderEnum.FACEBOOK]

export const SOCIAL_PROVIDERS = {
  apple: {
    id: AuthProviderEnum.APPLE,
    key: 'APPLE',
    icon: IconApple,
    name: 'Apple',
    trackingId: 'apple',
    login: AuthService.appleLogin,
    addSocial: UserService.addAppleSocial,
  },
  faceook: {
    id: AuthProviderEnum.FACEBOOK,
    key: 'FACEBOOK',
    icon: IconFacebook,
    name: 'Facebook',
    trackingId: 'facebook',
    login: AuthService.facebookLogin,
    addSocial: UserService.addFacebookSocial,
  },
  google: {
    id: AuthProviderEnum.GOOGLE,
    key: 'GOOGLE',
    icon: IconGoogle,
    name: 'Google',
    trackingId: 'google',
    login: AuthService.googleLogin,
    addSocial: UserService.addGoogleSocial,
  },
  twitter: {
    id: AuthProviderEnum.TWITTER,
    key: 'TWITTER',
    icon: IconTwitter,
    name: 'Twitter',
    trackingId: 'twitter',
    login: AuthService.twitterLogin,
    addSocial: UserService.addTwitterSocial,
  },
}

export const AUTH_PROVIDERS = Object.values(SOCIAL_PROVIDERS).filter(
  ({ id }) => !disabledProviders.includes(id),
)

const providersValid = Object.values(SOCIAL_PROVIDERS).every(
  ({ id, key }) => AuthProviderEnum[key] === id,
)

if (!providersValid)
  console.warn(
    'AUTH PROVIDERS ARE NOT MAPPED PROPERLY! Check "AUTH_PROVIDERS.js".',
  )
