import { TitleService } from '../../services/TitleService'

export const TitleModule = () => ({
  state: {
    titles: [],
  },
  getters: {
    getTitle: s => titleId =>
      s.titles.find(title => title.id == titleId)?.title,
    getFullName: () => entity => {
      const {
        firstName,
        lastName,
        title: { title },
      } = entity
      const titleName = !title || title === 'Other' ? '' : title
      return [titleName, firstName, lastName].filter(el => !!el).join(' ')
    },
  },
  actions: {
    getTitles: ({ commit }) =>
      TitleService.getTitles().then(res => commit('setTitles', res.titlesList)),
  },
})
