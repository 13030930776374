// source: proto/location/location_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_pagination_pb = require('../../proto/commons/pagination_pb.js');
goog.object.extend(proto, proto_commons_pagination_pb);
var proto_commons_weather_pb = require('../../proto/commons/weather_pb.js');
goog.object.extend(proto, proto_commons_weather_pb);
var proto_commons_image_data_pb = require('../../proto/commons/image_data_pb.js');
goog.object.extend(proto, proto_commons_image_data_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_options_pb = require('../../proto/commons/options_pb.js');
goog.object.extend(proto, proto_commons_options_pb);
var proto_commons_filter_options_pb = require('../../proto/commons/filter_options_pb.js');
goog.object.extend(proto, proto_commons_filter_options_pb);
var proto_commons_coordinates_pb = require('../../proto/commons/coordinates_pb.js');
goog.object.extend(proto, proto_commons_coordinates_pb);
var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_commons_flow_common_pb = require('../../proto/commons/flow_common_pb.js');
goog.object.extend(proto, proto_commons_flow_common_pb);
var proto_models_media_pb = require('../../proto/models/media_pb.js');
goog.object.extend(proto, proto_models_media_pb);
var proto_models_country_pb = require('../../proto/models/country_pb.js');
goog.object.extend(proto, proto_models_country_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_airline_pb = require('../../proto/models/airline_pb.js');
goog.object.extend(proto, proto_models_airline_pb);
var proto_models_featured_location_pb = require('../../proto/models/featured_location_pb.js');
goog.object.extend(proto, proto_models_featured_location_pb);
var proto_models_connected_city_pb = require('../../proto/models/connected_city_pb.js');
goog.object.extend(proto, proto_models_connected_city_pb);
var proto_models_region_pb = require('../../proto/models/region_pb.js');
goog.object.extend(proto, proto_models_region_pb);
var proto_air_hotel_reservation_search_request_pb = require('../../proto/air_hotel_reservation_search/request_pb.js');
goog.object.extend(proto, proto_air_hotel_reservation_search_request_pb);
var proto_models_shard_pb = require('../../proto/models/shard_pb.js');
goog.object.extend(proto, proto_models_shard_pb);
var proto_models_parent_location_pb = require('../../proto/models/parent_location_pb.js');
goog.object.extend(proto, proto_models_parent_location_pb);
goog.exportSymbol('proto.elude.proto.CreateAirlineRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateAirportRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateConnectedCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateCountryRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateFeaturedLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateParentLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateRegionRequest', null, global);
goog.exportSymbol('proto.elude.proto.CreateShardRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteAirlineRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteAirportRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteConnectedCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteCountryRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteFeaturedLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteParentLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteRegionRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteShardRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAffordableDestinationsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAirlineRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAirlinesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAirportRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAirportsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetAverageCostRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetCitiesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetCityByCoordinatesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetCityByIPRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetConnectedCitiesForDepartureCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetConnectedCitiesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetConnectedCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetCountriesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetCountryRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetDestinationCitiesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFeaturedLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFeaturedLocationsByLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFeaturedLocationsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetLocationByCoordinatesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetLocationsByIdentifiersRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetLocationsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetMultipleAirlinesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetMultipleAirportsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetMultipleCitiesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetMultipleCountriesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetParentLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetParentLocationsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetPreferencesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetRecommendedCitiesRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetRegionRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetRegionsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetSearchLocationsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetShardRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetShardsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetTopDestinationsRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetWeatherByCoordinatesRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAirlineRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAirlineWithImageRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAirlineWithImageRequest.DataCase', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAirlineWithImageUnaryRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateAirportRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateCityWithImageRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateCityWithImageRequest.DataCase', null, global);
goog.exportSymbol('proto.elude.proto.UpdateCityWithImageUnaryRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateConnectedCityRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateCountryRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateFeaturedLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateParentLocationRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateParentLocationWithImageRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateParentLocationWithImageRequest.DataCase', null, global);
goog.exportSymbol('proto.elude.proto.UpdateParentLocationWithImageUnaryRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateRegionRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateShardRequest', null, global);
goog.exportSymbol('proto.elude.proto.UpdateUserPreferencesRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetCityRequest.displayName = 'proto.elude.proto.GetCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetCitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetCitiesRequest.displayName = 'proto.elude.proto.GetCitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateCityRequest.displayName = 'proto.elude.proto.CreateCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateCityRequest.displayName = 'proto.elude.proto.UpdateCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteCityRequest.displayName = 'proto.elude.proto.DeleteCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetCityByIPRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetCityByIPRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetCityByIPRequest.displayName = 'proto.elude.proto.GetCityByIPRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetCityByCoordinatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetCityByCoordinatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetCityByCoordinatesRequest.displayName = 'proto.elude.proto.GetCityByCoordinatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetMultipleCitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetMultipleCitiesRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetMultipleCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetMultipleCitiesRequest.displayName = 'proto.elude.proto.GetMultipleCitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateCityWithImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.elude.proto.UpdateCityWithImageRequest.oneofGroups_);
};
goog.inherits(proto.elude.proto.UpdateCityWithImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateCityWithImageRequest.displayName = 'proto.elude.proto.UpdateCityWithImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateCityWithImageUnaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateCityWithImageUnaryRequest.displayName = 'proto.elude.proto.UpdateCityWithImageUnaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetCountryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetCountryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetCountryRequest.displayName = 'proto.elude.proto.GetCountryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetCountriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetCountriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetCountriesRequest.displayName = 'proto.elude.proto.GetCountriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateCountryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateCountryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateCountryRequest.displayName = 'proto.elude.proto.CreateCountryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateCountryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateCountryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateCountryRequest.displayName = 'proto.elude.proto.UpdateCountryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteCountryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteCountryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteCountryRequest.displayName = 'proto.elude.proto.DeleteCountryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetMultipleCountriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetMultipleCountriesRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetMultipleCountriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetMultipleCountriesRequest.displayName = 'proto.elude.proto.GetMultipleCountriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAverageCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAverageCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAverageCostRequest.displayName = 'proto.elude.proto.GetAverageCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetDestinationCitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetDestinationCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetDestinationCitiesRequest.displayName = 'proto.elude.proto.GetDestinationCitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetTopDestinationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetTopDestinationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetTopDestinationsRequest.displayName = 'proto.elude.proto.GetTopDestinationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAffordableDestinationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAffordableDestinationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAffordableDestinationsRequest.displayName = 'proto.elude.proto.GetAffordableDestinationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAirportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAirportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAirportRequest.displayName = 'proto.elude.proto.GetAirportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAirportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAirportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAirportsRequest.displayName = 'proto.elude.proto.GetAirportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateAirportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateAirportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateAirportRequest.displayName = 'proto.elude.proto.CreateAirportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAirportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAirportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAirportRequest.displayName = 'proto.elude.proto.UpdateAirportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteAirportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteAirportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteAirportRequest.displayName = 'proto.elude.proto.DeleteAirportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetMultipleAirportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetMultipleAirportsRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetMultipleAirportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetMultipleAirportsRequest.displayName = 'proto.elude.proto.GetMultipleAirportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAirlineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAirlineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAirlineRequest.displayName = 'proto.elude.proto.GetAirlineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetAirlinesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetAirlinesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetAirlinesRequest.displayName = 'proto.elude.proto.GetAirlinesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateAirlineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateAirlineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateAirlineRequest.displayName = 'proto.elude.proto.CreateAirlineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAirlineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAirlineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAirlineRequest.displayName = 'proto.elude.proto.UpdateAirlineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteAirlineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteAirlineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteAirlineRequest.displayName = 'proto.elude.proto.DeleteAirlineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetMultipleAirlinesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetMultipleAirlinesRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetMultipleAirlinesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetMultipleAirlinesRequest.displayName = 'proto.elude.proto.GetMultipleAirlinesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAirlineWithImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.elude.proto.UpdateAirlineWithImageRequest.oneofGroups_);
};
goog.inherits(proto.elude.proto.UpdateAirlineWithImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAirlineWithImageRequest.displayName = 'proto.elude.proto.UpdateAirlineWithImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateAirlineWithImageUnaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateAirlineWithImageUnaryRequest.displayName = 'proto.elude.proto.UpdateAirlineWithImageUnaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFeaturedLocationsByLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFeaturedLocationsByLocationRequest.displayName = 'proto.elude.proto.GetFeaturedLocationsByLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.displayName = 'proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.displayName = 'proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFeaturedLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFeaturedLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFeaturedLocationRequest.displayName = 'proto.elude.proto.GetFeaturedLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetFeaturedLocationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetFeaturedLocationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetFeaturedLocationsRequest.displayName = 'proto.elude.proto.GetFeaturedLocationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateFeaturedLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateFeaturedLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateFeaturedLocationRequest.displayName = 'proto.elude.proto.CreateFeaturedLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateFeaturedLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateFeaturedLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateFeaturedLocationRequest.displayName = 'proto.elude.proto.UpdateFeaturedLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteFeaturedLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteFeaturedLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteFeaturedLocationRequest.displayName = 'proto.elude.proto.DeleteFeaturedLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetConnectedCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetConnectedCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetConnectedCityRequest.displayName = 'proto.elude.proto.GetConnectedCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetConnectedCitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetConnectedCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetConnectedCitiesRequest.displayName = 'proto.elude.proto.GetConnectedCitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateConnectedCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateConnectedCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateConnectedCityRequest.displayName = 'proto.elude.proto.CreateConnectedCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateConnectedCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateConnectedCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateConnectedCityRequest.displayName = 'proto.elude.proto.UpdateConnectedCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteConnectedCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteConnectedCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteConnectedCityRequest.displayName = 'proto.elude.proto.DeleteConnectedCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetConnectedCitiesForDepartureCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.displayName = 'proto.elude.proto.GetConnectedCitiesForDepartureCityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetWeatherByCoordinatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetWeatherByCoordinatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetWeatherByCoordinatesRequest.displayName = 'proto.elude.proto.GetWeatherByCoordinatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetRegionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetRegionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetRegionRequest.displayName = 'proto.elude.proto.GetRegionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetRegionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetRegionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetRegionsRequest.displayName = 'proto.elude.proto.GetRegionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateRegionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateRegionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateRegionRequest.displayName = 'proto.elude.proto.CreateRegionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateRegionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateRegionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateRegionRequest.displayName = 'proto.elude.proto.UpdateRegionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteRegionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteRegionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteRegionRequest.displayName = 'proto.elude.proto.DeleteRegionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetLocationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetLocationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetLocationsRequest.displayName = 'proto.elude.proto.GetLocationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetLocationByCoordinatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetLocationByCoordinatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetLocationByCoordinatesRequest.displayName = 'proto.elude.proto.GetLocationByCoordinatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetLocationRequest.displayName = 'proto.elude.proto.GetLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetLocationsByIdentifiersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetLocationsByIdentifiersRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetLocationsByIdentifiersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetLocationsByIdentifiersRequest.displayName = 'proto.elude.proto.GetLocationsByIdentifiersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetSearchLocationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.GetSearchLocationsRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.GetSearchLocationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetSearchLocationsRequest.displayName = 'proto.elude.proto.GetSearchLocationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetPreferencesRequest.displayName = 'proto.elude.proto.GetPreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateUserPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.UpdateUserPreferencesRequest.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.UpdateUserPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateUserPreferencesRequest.displayName = 'proto.elude.proto.UpdateUserPreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetRecommendedCitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetRecommendedCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetRecommendedCitiesRequest.displayName = 'proto.elude.proto.GetRecommendedCitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetShardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetShardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetShardRequest.displayName = 'proto.elude.proto.GetShardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetShardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetShardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetShardsRequest.displayName = 'proto.elude.proto.GetShardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateShardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateShardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateShardRequest.displayName = 'proto.elude.proto.CreateShardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateShardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateShardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateShardRequest.displayName = 'proto.elude.proto.UpdateShardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteShardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteShardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteShardRequest.displayName = 'proto.elude.proto.DeleteShardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetParentLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetParentLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetParentLocationRequest.displayName = 'proto.elude.proto.GetParentLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetParentLocationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetParentLocationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetParentLocationsRequest.displayName = 'proto.elude.proto.GetParentLocationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateParentLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateParentLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateParentLocationRequest.displayName = 'proto.elude.proto.CreateParentLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateParentLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateParentLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateParentLocationRequest.displayName = 'proto.elude.proto.UpdateParentLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteParentLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteParentLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteParentLocationRequest.displayName = 'proto.elude.proto.DeleteParentLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.UpdateParentLocationWithImageUnaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.displayName = 'proto.elude.proto.UpdateParentLocationWithImageUnaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.UpdateParentLocationWithImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.elude.proto.UpdateParentLocationWithImageRequest.oneofGroups_);
};
goog.inherits(proto.elude.proto.UpdateParentLocationWithImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.UpdateParentLocationWithImageRequest.displayName = 'proto.elude.proto.UpdateParentLocationWithImageRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetCityRequest}
 */
proto.elude.proto.GetCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetCityRequest;
  return proto.elude.proto.GetCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetCityRequest}
 */
proto.elude.proto.GetCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetCityRequest} returns this
*/
proto.elude.proto.GetCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityRequest} returns this
 */
proto.elude.proto.GetCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetCityRequest} returns this
*/
proto.elude.proto.GetCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityRequest} returns this
 */
proto.elude.proto.GetCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetCityRequest} returns this
*/
proto.elude.proto.GetCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityRequest} returns this
 */
proto.elude.proto.GetCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetCityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetCityRequest} returns this
 */
proto.elude.proto.GetCityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetCitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetCitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetCitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetCitiesRequest}
 */
proto.elude.proto.GetCitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetCitiesRequest;
  return proto.elude.proto.GetCitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetCitiesRequest}
 */
proto.elude.proto.GetCitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetCitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetCitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetCitiesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
*/
proto.elude.proto.GetCitiesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
 */
proto.elude.proto.GetCitiesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCitiesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetCitiesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
*/
proto.elude.proto.GetCitiesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
 */
proto.elude.proto.GetCitiesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCitiesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetCitiesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
*/
proto.elude.proto.GetCitiesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
 */
proto.elude.proto.GetCitiesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCitiesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetCitiesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
*/
proto.elude.proto.GetCitiesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
 */
proto.elude.proto.GetCitiesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCitiesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetCitiesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
 */
proto.elude.proto.GetCitiesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetCitiesRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
*/
proto.elude.proto.GetCitiesRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCitiesRequest} returns this
 */
proto.elude.proto.GetCitiesRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCitiesRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    city: (f = msg.getCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateCityRequest}
 */
proto.elude.proto.CreateCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateCityRequest;
  return proto.elude.proto.CreateCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateCityRequest}
 */
proto.elude.proto.CreateCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateCityRequest} returns this
*/
proto.elude.proto.CreateCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCityRequest} returns this
 */
proto.elude.proto.CreateCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateCityRequest} returns this
*/
proto.elude.proto.CreateCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCityRequest} returns this
 */
proto.elude.proto.CreateCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional City city = 3;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.CreateCityRequest.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 3));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.CreateCityRequest} returns this
*/
proto.elude.proto.CreateCityRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCityRequest} returns this
 */
proto.elude.proto.CreateCityRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCityRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateCityRequest} returns this
*/
proto.elude.proto.CreateCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCityRequest} returns this
 */
proto.elude.proto.CreateCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    city: (f = msg.getCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateCityRequest}
 */
proto.elude.proto.UpdateCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateCityRequest;
  return proto.elude.proto.UpdateCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateCityRequest}
 */
proto.elude.proto.UpdateCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
*/
proto.elude.proto.UpdateCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
 */
proto.elude.proto.UpdateCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
*/
proto.elude.proto.UpdateCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
 */
proto.elude.proto.UpdateCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional City city = 3;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.UpdateCityRequest.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 3));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
*/
proto.elude.proto.UpdateCityRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
 */
proto.elude.proto.UpdateCityRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
*/
proto.elude.proto.UpdateCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityRequest} returns this
 */
proto.elude.proto.UpdateCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteCityRequest}
 */
proto.elude.proto.DeleteCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteCityRequest;
  return proto.elude.proto.DeleteCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteCityRequest}
 */
proto.elude.proto.DeleteCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
*/
proto.elude.proto.DeleteCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
 */
proto.elude.proto.DeleteCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
*/
proto.elude.proto.DeleteCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
 */
proto.elude.proto.DeleteCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteCityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
 */
proto.elude.proto.DeleteCityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
*/
proto.elude.proto.DeleteCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteCityRequest} returns this
 */
proto.elude.proto.DeleteCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetCityByIPRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetCityByIPRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetCityByIPRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCityByIPRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    ip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetCityByIPRequest}
 */
proto.elude.proto.GetCityByIPRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetCityByIPRequest;
  return proto.elude.proto.GetCityByIPRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetCityByIPRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetCityByIPRequest}
 */
proto.elude.proto.GetCityByIPRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetCityByIPRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetCityByIPRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetCityByIPRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCityByIPRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetCityByIPRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
*/
proto.elude.proto.GetCityByIPRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
 */
proto.elude.proto.GetCityByIPRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByIPRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetCityByIPRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
*/
proto.elude.proto.GetCityByIPRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
 */
proto.elude.proto.GetCityByIPRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByIPRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ip = 3;
 * @return {string}
 */
proto.elude.proto.GetCityByIPRequest.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
 */
proto.elude.proto.GetCityByIPRequest.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetCityByIPRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
*/
proto.elude.proto.GetCityByIPRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByIPRequest} returns this
 */
proto.elude.proto.GetCityByIPRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByIPRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetCityByCoordinatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetCityByCoordinatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCityByCoordinatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto_commons_coordinates_pb.Coordinates.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest}
 */
proto.elude.proto.GetCityByCoordinatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetCityByCoordinatesRequest;
  return proto.elude.proto.GetCityByCoordinatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetCityByCoordinatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest}
 */
proto.elude.proto.GetCityByCoordinatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_coordinates_pb.Coordinates;
      reader.readMessage(value,proto_commons_coordinates_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetCityByCoordinatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetCityByCoordinatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCityByCoordinatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_coordinates_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
*/
proto.elude.proto.GetCityByCoordinatesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
*/
proto.elude.proto.GetCityByCoordinatesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Coordinates coordinates = 3;
 * @return {?proto.elude.proto.Coordinates}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.elude.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto_commons_coordinates_pb.Coordinates, 3));
};


/**
 * @param {?proto.elude.proto.Coordinates|undefined} value
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
*/
proto.elude.proto.GetCityByCoordinatesRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
*/
proto.elude.proto.GetCityByCoordinatesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCityByCoordinatesRequest} returns this
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCityByCoordinatesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetMultipleCitiesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetMultipleCitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleCitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    iataCodesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetMultipleCitiesRequest}
 */
proto.elude.proto.GetMultipleCitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetMultipleCitiesRequest;
  return proto.elude.proto.GetMultipleCitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetMultipleCitiesRequest}
 */
proto.elude.proto.GetMultipleCitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIataCodes(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetMultipleCitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetMultipleCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleCitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getIataCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
*/
proto.elude.proto.GetMultipleCitiesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
*/
proto.elude.proto.GetMultipleCitiesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string iata_codes = 3;
 * @return {!Array<string>}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.getIataCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.setIataCodesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.addIataCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.clearIataCodesList = function() {
  return this.setIataCodesList([]);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
*/
proto.elude.proto.GetMultipleCitiesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleCitiesRequest} returns this
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleCitiesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.elude.proto.UpdateCityWithImageRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.elude.proto.UpdateCityWithImageRequest.DataCase = {
  DATA_NOT_SET: 0,
  UPDATEREQ: 1,
  CHUNK_DATA: 2
};

/**
 * @return {proto.elude.proto.UpdateCityWithImageRequest.DataCase}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.getDataCase = function() {
  return /** @type {proto.elude.proto.UpdateCityWithImageRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.elude.proto.UpdateCityWithImageRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateCityWithImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateCityWithImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityWithImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateCityWithImageData.toObject(includeInstance, f),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateCityWithImageRequest}
 */
proto.elude.proto.UpdateCityWithImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateCityWithImageRequest;
  return proto.elude.proto.UpdateCityWithImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateCityWithImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateCityWithImageRequest}
 */
proto.elude.proto.UpdateCityWithImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateCityWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateCityWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateCityWithImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateCityWithImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityWithImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateCityWithImageData.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateCityWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateCityWithImageData}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateCityWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateCityWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateCityWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageRequest} returns this
*/
proto.elude.proto.UpdateCityWithImageRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.elude.proto.UpdateCityWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageRequest} returns this
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateCityWithImageRequest} returns this
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateCityWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageRequest} returns this
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.clearChunkData = function() {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateCityWithImageRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageRequest.prototype.hasChunkData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateCityWithImageUnaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateCityWithImageUnaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateCityWithImageData.toObject(includeInstance, f),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateCityWithImageUnaryRequest}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateCityWithImageUnaryRequest;
  return proto.elude.proto.UpdateCityWithImageUnaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateCityWithImageUnaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateCityWithImageUnaryRequest}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateCityWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateCityWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateCityWithImageUnaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateCityWithImageUnaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateCityWithImageData.serializeBinaryToWriter
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateCityWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateCityWithImageData}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateCityWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateCityWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateCityWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateCityWithImageUnaryRequest} returns this
*/
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCityWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateCityWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateCityWithImageUnaryRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetCountryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetCountryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetCountryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCountryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetCountryRequest}
 */
proto.elude.proto.GetCountryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetCountryRequest;
  return proto.elude.proto.GetCountryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetCountryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetCountryRequest}
 */
proto.elude.proto.GetCountryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetCountryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetCountryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetCountryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCountryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetCountryRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetCountryRequest} returns this
*/
proto.elude.proto.GetCountryRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountryRequest} returns this
 */
proto.elude.proto.GetCountryRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountryRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetCountryRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetCountryRequest} returns this
*/
proto.elude.proto.GetCountryRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountryRequest} returns this
 */
proto.elude.proto.GetCountryRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountryRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetCountryRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetCountryRequest} returns this
*/
proto.elude.proto.GetCountryRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountryRequest} returns this
 */
proto.elude.proto.GetCountryRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountryRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetCountryRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetCountryRequest} returns this
 */
proto.elude.proto.GetCountryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetCountriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetCountriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetCountriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCountriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetCountriesRequest}
 */
proto.elude.proto.GetCountriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetCountriesRequest;
  return proto.elude.proto.GetCountriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetCountriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetCountriesRequest}
 */
proto.elude.proto.GetCountriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetCountriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetCountriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetCountriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetCountriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetCountriesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
*/
proto.elude.proto.GetCountriesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
 */
proto.elude.proto.GetCountriesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountriesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetCountriesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
*/
proto.elude.proto.GetCountriesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
 */
proto.elude.proto.GetCountriesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountriesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetCountriesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
*/
proto.elude.proto.GetCountriesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
 */
proto.elude.proto.GetCountriesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountriesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetCountriesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
*/
proto.elude.proto.GetCountriesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
 */
proto.elude.proto.GetCountriesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountriesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetCountriesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
 */
proto.elude.proto.GetCountriesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetCountriesRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
*/
proto.elude.proto.GetCountriesRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetCountriesRequest} returns this
 */
proto.elude.proto.GetCountriesRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetCountriesRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateCountryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateCountryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateCountryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateCountryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    country: (f = msg.getCountry()) && proto_models_country_pb.Country.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateCountryRequest}
 */
proto.elude.proto.CreateCountryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateCountryRequest;
  return proto.elude.proto.CreateCountryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateCountryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateCountryRequest}
 */
proto.elude.proto.CreateCountryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateCountryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateCountryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateCountryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateCountryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateCountryRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
*/
proto.elude.proto.CreateCountryRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
 */
proto.elude.proto.CreateCountryRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCountryRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateCountryRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
*/
proto.elude.proto.CreateCountryRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
 */
proto.elude.proto.CreateCountryRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCountryRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Country country = 3;
 * @return {?proto.elude.proto.Country}
 */
proto.elude.proto.CreateCountryRequest.prototype.getCountry = function() {
  return /** @type{?proto.elude.proto.Country} */ (
    jspb.Message.getWrapperField(this, proto_models_country_pb.Country, 3));
};


/**
 * @param {?proto.elude.proto.Country|undefined} value
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
*/
proto.elude.proto.CreateCountryRequest.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
 */
proto.elude.proto.CreateCountryRequest.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCountryRequest.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateCountryRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
*/
proto.elude.proto.CreateCountryRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateCountryRequest} returns this
 */
proto.elude.proto.CreateCountryRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateCountryRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateCountryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateCountryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateCountryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCountryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    country: (f = msg.getCountry()) && proto_models_country_pb.Country.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateCountryRequest}
 */
proto.elude.proto.UpdateCountryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateCountryRequest;
  return proto.elude.proto.UpdateCountryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateCountryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateCountryRequest}
 */
proto.elude.proto.UpdateCountryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_country_pb.Country;
      reader.readMessage(value,proto_models_country_pb.Country.deserializeBinaryFromReader);
      msg.setCountry(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateCountryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateCountryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateCountryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateCountryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_country_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateCountryRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
*/
proto.elude.proto.UpdateCountryRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
 */
proto.elude.proto.UpdateCountryRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCountryRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateCountryRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
*/
proto.elude.proto.UpdateCountryRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
 */
proto.elude.proto.UpdateCountryRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCountryRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Country country = 3;
 * @return {?proto.elude.proto.Country}
 */
proto.elude.proto.UpdateCountryRequest.prototype.getCountry = function() {
  return /** @type{?proto.elude.proto.Country} */ (
    jspb.Message.getWrapperField(this, proto_models_country_pb.Country, 3));
};


/**
 * @param {?proto.elude.proto.Country|undefined} value
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
*/
proto.elude.proto.UpdateCountryRequest.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
 */
proto.elude.proto.UpdateCountryRequest.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCountryRequest.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateCountryRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
*/
proto.elude.proto.UpdateCountryRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateCountryRequest} returns this
 */
proto.elude.proto.UpdateCountryRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateCountryRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteCountryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteCountryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteCountryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteCountryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteCountryRequest}
 */
proto.elude.proto.DeleteCountryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteCountryRequest;
  return proto.elude.proto.DeleteCountryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteCountryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteCountryRequest}
 */
proto.elude.proto.DeleteCountryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteCountryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteCountryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteCountryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteCountryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteCountryRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
*/
proto.elude.proto.DeleteCountryRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
 */
proto.elude.proto.DeleteCountryRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteCountryRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteCountryRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
*/
proto.elude.proto.DeleteCountryRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
 */
proto.elude.proto.DeleteCountryRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteCountryRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteCountryRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
 */
proto.elude.proto.DeleteCountryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteCountryRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
*/
proto.elude.proto.DeleteCountryRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteCountryRequest} returns this
 */
proto.elude.proto.DeleteCountryRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteCountryRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetMultipleCountriesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetMultipleCountriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetMultipleCountriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleCountriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    isoCodesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetMultipleCountriesRequest}
 */
proto.elude.proto.GetMultipleCountriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetMultipleCountriesRequest;
  return proto.elude.proto.GetMultipleCountriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetMultipleCountriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetMultipleCountriesRequest}
 */
proto.elude.proto.GetMultipleCountriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIsoCodes(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetMultipleCountriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetMultipleCountriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleCountriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsoCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
*/
proto.elude.proto.GetMultipleCountriesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
*/
proto.elude.proto.GetMultipleCountriesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string iso_codes = 3;
 * @return {!Array<string>}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.getIsoCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.setIsoCodesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.addIsoCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.clearIsoCodesList = function() {
  return this.setIsoCodesList([]);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
*/
proto.elude.proto.GetMultipleCountriesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleCountriesRequest} returns this
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleCountriesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAverageCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAverageCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAverageCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAverageCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAverageCostRequest}
 */
proto.elude.proto.GetAverageCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAverageCostRequest;
  return proto.elude.proto.GetAverageCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAverageCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAverageCostRequest}
 */
proto.elude.proto.GetAverageCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAverageCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAverageCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAverageCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAverageCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAverageCostRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAverageCostRequest} returns this
*/
proto.elude.proto.GetAverageCostRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAverageCostRequest} returns this
 */
proto.elude.proto.GetAverageCostRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAverageCostRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAverageCostRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAverageCostRequest} returns this
*/
proto.elude.proto.GetAverageCostRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAverageCostRequest} returns this
 */
proto.elude.proto.GetAverageCostRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAverageCostRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAverageCostRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAverageCostRequest} returns this
*/
proto.elude.proto.GetAverageCostRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAverageCostRequest} returns this
 */
proto.elude.proto.GetAverageCostRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAverageCostRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetDestinationCitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetDestinationCitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetDestinationCitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetDestinationCitiesRequest}
 */
proto.elude.proto.GetDestinationCitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetDestinationCitiesRequest;
  return proto.elude.proto.GetDestinationCitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetDestinationCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetDestinationCitiesRequest}
 */
proto.elude.proto.GetDestinationCitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetDestinationCitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetDestinationCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetDestinationCitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
*/
proto.elude.proto.GetDestinationCitiesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
*/
proto.elude.proto.GetDestinationCitiesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
*/
proto.elude.proto.GetDestinationCitiesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
*/
proto.elude.proto.GetDestinationCitiesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetDestinationCitiesRequest} returns this
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetDestinationCitiesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetTopDestinationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetTopDestinationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTopDestinationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetTopDestinationsRequest}
 */
proto.elude.proto.GetTopDestinationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetTopDestinationsRequest;
  return proto.elude.proto.GetTopDestinationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetTopDestinationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetTopDestinationsRequest}
 */
proto.elude.proto.GetTopDestinationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetTopDestinationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetTopDestinationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetTopDestinationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetTopDestinationsRequest} returns this
*/
proto.elude.proto.GetTopDestinationsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTopDestinationsRequest} returns this
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetTopDestinationsRequest} returns this
*/
proto.elude.proto.GetTopDestinationsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTopDestinationsRequest} returns this
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetTopDestinationsRequest} returns this
*/
proto.elude.proto.GetTopDestinationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetTopDestinationsRequest} returns this
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetTopDestinationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAffordableDestinationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAffordableDestinationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAffordableDestinationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest}
 */
proto.elude.proto.GetAffordableDestinationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAffordableDestinationsRequest;
  return proto.elude.proto.GetAffordableDestinationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAffordableDestinationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest}
 */
proto.elude.proto.GetAffordableDestinationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAffordableDestinationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAffordableDestinationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAffordableDestinationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest} returns this
*/
proto.elude.proto.GetAffordableDestinationsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest} returns this
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest} returns this
*/
proto.elude.proto.GetAffordableDestinationsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest} returns this
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest} returns this
*/
proto.elude.proto.GetAffordableDestinationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAffordableDestinationsRequest} returns this
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAffordableDestinationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAirportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAirportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAirportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAirportRequest}
 */
proto.elude.proto.GetAirportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAirportRequest;
  return proto.elude.proto.GetAirportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAirportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAirportRequest}
 */
proto.elude.proto.GetAirportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAirportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAirportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAirportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAirportRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAirportRequest} returns this
*/
proto.elude.proto.GetAirportRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportRequest} returns this
 */
proto.elude.proto.GetAirportRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAirportRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAirportRequest} returns this
*/
proto.elude.proto.GetAirportRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportRequest} returns this
 */
proto.elude.proto.GetAirportRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAirportRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAirportRequest} returns this
*/
proto.elude.proto.GetAirportRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportRequest} returns this
 */
proto.elude.proto.GetAirportRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetAirportRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetAirportRequest} returns this
 */
proto.elude.proto.GetAirportRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAirportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAirportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAirportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAirportsRequest}
 */
proto.elude.proto.GetAirportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAirportsRequest;
  return proto.elude.proto.GetAirportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAirportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAirportsRequest}
 */
proto.elude.proto.GetAirportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAirportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAirportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAirportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAirportsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
*/
proto.elude.proto.GetAirportsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
 */
proto.elude.proto.GetAirportsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAirportsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
*/
proto.elude.proto.GetAirportsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
 */
proto.elude.proto.GetAirportsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAirportsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
*/
proto.elude.proto.GetAirportsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
 */
proto.elude.proto.GetAirportsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetAirportsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
*/
proto.elude.proto.GetAirportsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
 */
proto.elude.proto.GetAirportsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetAirportsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
 */
proto.elude.proto.GetAirportsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetAirportsRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
*/
proto.elude.proto.GetAirportsRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirportsRequest} returns this
 */
proto.elude.proto.GetAirportsRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirportsRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateAirportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateAirportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateAirportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateAirportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airport: (f = msg.getAirport()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateAirportRequest}
 */
proto.elude.proto.CreateAirportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateAirportRequest;
  return proto.elude.proto.CreateAirportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateAirportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateAirportRequest}
 */
proto.elude.proto.CreateAirportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setAirport(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateAirportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateAirportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateAirportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateAirportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirport();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateAirportRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
*/
proto.elude.proto.CreateAirportRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
 */
proto.elude.proto.CreateAirportRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirportRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateAirportRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
*/
proto.elude.proto.CreateAirportRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
 */
proto.elude.proto.CreateAirportRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirportRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Airport airport = 3;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.CreateAirportRequest.prototype.getAirport = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 3));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
*/
proto.elude.proto.CreateAirportRequest.prototype.setAirport = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
 */
proto.elude.proto.CreateAirportRequest.prototype.clearAirport = function() {
  return this.setAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirportRequest.prototype.hasAirport = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateAirportRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
*/
proto.elude.proto.CreateAirportRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirportRequest} returns this
 */
proto.elude.proto.CreateAirportRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirportRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAirportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAirportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAirportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airport: (f = msg.getAirport()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAirportRequest}
 */
proto.elude.proto.UpdateAirportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAirportRequest;
  return proto.elude.proto.UpdateAirportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAirportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAirportRequest}
 */
proto.elude.proto.UpdateAirportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setAirport(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAirportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAirportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirport();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateAirportRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
*/
proto.elude.proto.UpdateAirportRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
 */
proto.elude.proto.UpdateAirportRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirportRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateAirportRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
*/
proto.elude.proto.UpdateAirportRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
 */
proto.elude.proto.UpdateAirportRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirportRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Airport airport = 3;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.UpdateAirportRequest.prototype.getAirport = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 3));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
*/
proto.elude.proto.UpdateAirportRequest.prototype.setAirport = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
 */
proto.elude.proto.UpdateAirportRequest.prototype.clearAirport = function() {
  return this.setAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirportRequest.prototype.hasAirport = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateAirportRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
*/
proto.elude.proto.UpdateAirportRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirportRequest} returns this
 */
proto.elude.proto.UpdateAirportRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirportRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteAirportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteAirportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteAirportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteAirportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteAirportRequest}
 */
proto.elude.proto.DeleteAirportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteAirportRequest;
  return proto.elude.proto.DeleteAirportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteAirportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteAirportRequest}
 */
proto.elude.proto.DeleteAirportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteAirportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteAirportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteAirportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteAirportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteAirportRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
*/
proto.elude.proto.DeleteAirportRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
 */
proto.elude.proto.DeleteAirportRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAirportRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteAirportRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
*/
proto.elude.proto.DeleteAirportRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
 */
proto.elude.proto.DeleteAirportRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAirportRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteAirportRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
 */
proto.elude.proto.DeleteAirportRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteAirportRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
*/
proto.elude.proto.DeleteAirportRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAirportRequest} returns this
 */
proto.elude.proto.DeleteAirportRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAirportRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetMultipleAirportsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetMultipleAirportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetMultipleAirportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleAirportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    iataCodesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetMultipleAirportsRequest}
 */
proto.elude.proto.GetMultipleAirportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetMultipleAirportsRequest;
  return proto.elude.proto.GetMultipleAirportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetMultipleAirportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetMultipleAirportsRequest}
 */
proto.elude.proto.GetMultipleAirportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIataCodes(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetMultipleAirportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetMultipleAirportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleAirportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getIataCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
*/
proto.elude.proto.GetMultipleAirportsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
*/
proto.elude.proto.GetMultipleAirportsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string iata_codes = 3;
 * @return {!Array<string>}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.getIataCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.setIataCodesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.addIataCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.clearIataCodesList = function() {
  return this.setIataCodesList([]);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
*/
proto.elude.proto.GetMultipleAirportsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleAirportsRequest} returns this
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleAirportsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAirlineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAirlineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAirlineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirlineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAirlineRequest}
 */
proto.elude.proto.GetAirlineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAirlineRequest;
  return proto.elude.proto.GetAirlineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAirlineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAirlineRequest}
 */
proto.elude.proto.GetAirlineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAirlineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAirlineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAirlineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirlineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAirlineRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
*/
proto.elude.proto.GetAirlineRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
 */
proto.elude.proto.GetAirlineRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlineRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAirlineRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
*/
proto.elude.proto.GetAirlineRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
 */
proto.elude.proto.GetAirlineRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlineRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAirlineRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
*/
proto.elude.proto.GetAirlineRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
 */
proto.elude.proto.GetAirlineRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlineRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetAirlineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetAirlineRequest} returns this
 */
proto.elude.proto.GetAirlineRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetAirlinesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetAirlinesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetAirlinesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirlinesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetAirlinesRequest}
 */
proto.elude.proto.GetAirlinesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetAirlinesRequest;
  return proto.elude.proto.GetAirlinesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetAirlinesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetAirlinesRequest}
 */
proto.elude.proto.GetAirlinesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetAirlinesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetAirlinesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetAirlinesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetAirlinesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetAirlinesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
*/
proto.elude.proto.GetAirlinesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
 */
proto.elude.proto.GetAirlinesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlinesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetAirlinesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
*/
proto.elude.proto.GetAirlinesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
 */
proto.elude.proto.GetAirlinesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlinesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetAirlinesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
*/
proto.elude.proto.GetAirlinesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
 */
proto.elude.proto.GetAirlinesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlinesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetAirlinesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
*/
proto.elude.proto.GetAirlinesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
 */
proto.elude.proto.GetAirlinesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlinesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetAirlinesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
 */
proto.elude.proto.GetAirlinesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetAirlinesRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
*/
proto.elude.proto.GetAirlinesRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetAirlinesRequest} returns this
 */
proto.elude.proto.GetAirlinesRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetAirlinesRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateAirlineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateAirlineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateAirlineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateAirlineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateAirlineRequest}
 */
proto.elude.proto.CreateAirlineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateAirlineRequest;
  return proto.elude.proto.CreateAirlineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateAirlineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateAirlineRequest}
 */
proto.elude.proto.CreateAirlineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateAirlineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateAirlineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateAirlineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateAirlineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateAirlineRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
*/
proto.elude.proto.CreateAirlineRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
 */
proto.elude.proto.CreateAirlineRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirlineRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateAirlineRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
*/
proto.elude.proto.CreateAirlineRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
 */
proto.elude.proto.CreateAirlineRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirlineRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Airline airline = 3;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.CreateAirlineRequest.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 3));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
*/
proto.elude.proto.CreateAirlineRequest.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
 */
proto.elude.proto.CreateAirlineRequest.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirlineRequest.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateAirlineRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
*/
proto.elude.proto.CreateAirlineRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateAirlineRequest} returns this
 */
proto.elude.proto.CreateAirlineRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateAirlineRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAirlineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAirlineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAirlineRequest}
 */
proto.elude.proto.UpdateAirlineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAirlineRequest;
  return proto.elude.proto.UpdateAirlineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAirlineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAirlineRequest}
 */
proto.elude.proto.UpdateAirlineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAirlineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAirlineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
*/
proto.elude.proto.UpdateAirlineRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
 */
proto.elude.proto.UpdateAirlineRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
*/
proto.elude.proto.UpdateAirlineRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
 */
proto.elude.proto.UpdateAirlineRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Airline airline = 3;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 3));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
*/
proto.elude.proto.UpdateAirlineRequest.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
 */
proto.elude.proto.UpdateAirlineRequest.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
*/
proto.elude.proto.UpdateAirlineRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineRequest} returns this
 */
proto.elude.proto.UpdateAirlineRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteAirlineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteAirlineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteAirlineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteAirlineRequest}
 */
proto.elude.proto.DeleteAirlineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteAirlineRequest;
  return proto.elude.proto.DeleteAirlineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteAirlineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteAirlineRequest}
 */
proto.elude.proto.DeleteAirlineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteAirlineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteAirlineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteAirlineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
*/
proto.elude.proto.DeleteAirlineRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
 */
proto.elude.proto.DeleteAirlineRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
*/
proto.elude.proto.DeleteAirlineRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
 */
proto.elude.proto.DeleteAirlineRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
 */
proto.elude.proto.DeleteAirlineRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
*/
proto.elude.proto.DeleteAirlineRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteAirlineRequest} returns this
 */
proto.elude.proto.DeleteAirlineRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteAirlineRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetMultipleAirlinesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetMultipleAirlinesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetMultipleAirlinesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleAirlinesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    iataCodesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest}
 */
proto.elude.proto.GetMultipleAirlinesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetMultipleAirlinesRequest;
  return proto.elude.proto.GetMultipleAirlinesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetMultipleAirlinesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest}
 */
proto.elude.proto.GetMultipleAirlinesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIataCodes(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetMultipleAirlinesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetMultipleAirlinesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetMultipleAirlinesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getIataCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
*/
proto.elude.proto.GetMultipleAirlinesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
*/
proto.elude.proto.GetMultipleAirlinesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string iata_codes = 3;
 * @return {!Array<string>}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.getIataCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.setIataCodesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.addIataCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.clearIataCodesList = function() {
  return this.setIataCodesList([]);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
*/
proto.elude.proto.GetMultipleAirlinesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetMultipleAirlinesRequest} returns this
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetMultipleAirlinesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.elude.proto.UpdateAirlineWithImageRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.DataCase = {
  DATA_NOT_SET: 0,
  UPDATEREQ: 1,
  CHUNK_DATA: 2
};

/**
 * @return {proto.elude.proto.UpdateAirlineWithImageRequest.DataCase}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.getDataCase = function() {
  return /** @type {proto.elude.proto.UpdateAirlineWithImageRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.elude.proto.UpdateAirlineWithImageRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAirlineWithImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAirlineWithImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineWithImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateAirlineWithImageData.toObject(includeInstance, f),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAirlineWithImageRequest}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAirlineWithImageRequest;
  return proto.elude.proto.UpdateAirlineWithImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAirlineWithImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAirlineWithImageRequest}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateAirlineWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateAirlineWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAirlineWithImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAirlineWithImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineWithImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateAirlineWithImageData.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateAirlineWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateAirlineWithImageData}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateAirlineWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateAirlineWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateAirlineWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageRequest} returns this
*/
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.elude.proto.UpdateAirlineWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageRequest} returns this
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageRequest} returns this
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateAirlineWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageRequest} returns this
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.clearChunkData = function() {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateAirlineWithImageRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageRequest.prototype.hasChunkData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateAirlineWithImageUnaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateAirlineWithImageData.toObject(includeInstance, f),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateAirlineWithImageUnaryRequest;
  return proto.elude.proto.UpdateAirlineWithImageUnaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateAirlineWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateAirlineWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateAirlineWithImageUnaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateAirlineWithImageData.serializeBinaryToWriter
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateAirlineWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateAirlineWithImageData}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateAirlineWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateAirlineWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateAirlineWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} returns this
*/
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateAirlineWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateAirlineWithImageUnaryRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFeaturedLocationsByLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto_commons_flow_common_pb.LocationIdentifier.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    overrideConnectedCity: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFeaturedLocationsByLocationRequest;
  return proto.elude.proto.GetFeaturedLocationsByLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_flow_common_pb.LocationIdentifier;
      reader.readMessage(value,proto_commons_flow_common_pb.LocationIdentifier.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideConnectedCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFeaturedLocationsByLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_flow_common_pb.LocationIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getOverrideConnectedCity();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LocationIdentifier location = 3;
 * @return {?proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.getLocation = function() {
  return /** @type{?proto.elude.proto.LocationIdentifier} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.LocationIdentifier, 3));
};


/**
 * @param {?proto.elude.proto.LocationIdentifier|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool override_connected_city = 5;
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.getOverrideConnectedCity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByLocationRequest.prototype.setOverrideConnectedCity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto_commons_coordinates_pb.Coordinates.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    overrideConnectedCity: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest;
  return proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_coordinates_pb.Coordinates;
      reader.readMessage(value,proto_commons_coordinates_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideConnectedCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_coordinates_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getOverrideConnectedCity();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Coordinates coordinates = 3;
 * @return {?proto.elude.proto.Coordinates}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.elude.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto_commons_coordinates_pb.Coordinates, 3));
};


/**
 * @param {?proto.elude.proto.Coordinates|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool override_connected_city = 5;
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.getOverrideConnectedCity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsByCoordinatesRequest.prototype.setOverrideConnectedCity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest;
  return proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsWithoutDepartureLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFeaturedLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFeaturedLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFeaturedLocationRequest}
 */
proto.elude.proto.GetFeaturedLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFeaturedLocationRequest;
  return proto.elude.proto.GetFeaturedLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFeaturedLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFeaturedLocationRequest}
 */
proto.elude.proto.GetFeaturedLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFeaturedLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFeaturedLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetFeaturedLocationRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetFeaturedLocationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetFeaturedLocationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest}
 */
proto.elude.proto.GetFeaturedLocationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetFeaturedLocationsRequest;
  return proto.elude.proto.GetFeaturedLocationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetFeaturedLocationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest}
 */
proto.elude.proto.GetFeaturedLocationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetFeaturedLocationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetFeaturedLocationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetFeaturedLocationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
*/
proto.elude.proto.GetFeaturedLocationsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetFeaturedLocationsRequest} returns this
 */
proto.elude.proto.GetFeaturedLocationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateFeaturedLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateFeaturedLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateFeaturedLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    featuredLocation: (f = msg.getFeaturedLocation()) && proto_models_featured_location_pb.FeaturedLocation.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest}
 */
proto.elude.proto.CreateFeaturedLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateFeaturedLocationRequest;
  return proto.elude.proto.CreateFeaturedLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateFeaturedLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest}
 */
proto.elude.proto.CreateFeaturedLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_featured_location_pb.FeaturedLocation;
      reader.readMessage(value,proto_models_featured_location_pb.FeaturedLocation.deserializeBinaryFromReader);
      msg.setFeaturedLocation(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateFeaturedLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateFeaturedLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateFeaturedLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_featured_location_pb.FeaturedLocation.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
*/
proto.elude.proto.CreateFeaturedLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
*/
proto.elude.proto.CreateFeaturedLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FeaturedLocation featured_location = 3;
 * @return {?proto.elude.proto.FeaturedLocation}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.getFeaturedLocation = function() {
  return /** @type{?proto.elude.proto.FeaturedLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_featured_location_pb.FeaturedLocation, 3));
};


/**
 * @param {?proto.elude.proto.FeaturedLocation|undefined} value
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
*/
proto.elude.proto.CreateFeaturedLocationRequest.prototype.setFeaturedLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.clearFeaturedLocation = function() {
  return this.setFeaturedLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.hasFeaturedLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
*/
proto.elude.proto.CreateFeaturedLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateFeaturedLocationRequest} returns this
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateFeaturedLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateFeaturedLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateFeaturedLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateFeaturedLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    featuredLocation: (f = msg.getFeaturedLocation()) && proto_models_featured_location_pb.FeaturedLocation.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateFeaturedLocationRequest;
  return proto.elude.proto.UpdateFeaturedLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateFeaturedLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_featured_location_pb.FeaturedLocation;
      reader.readMessage(value,proto_models_featured_location_pb.FeaturedLocation.deserializeBinaryFromReader);
      msg.setFeaturedLocation(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateFeaturedLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateFeaturedLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateFeaturedLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_featured_location_pb.FeaturedLocation.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
*/
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
*/
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FeaturedLocation featured_location = 3;
 * @return {?proto.elude.proto.FeaturedLocation}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.getFeaturedLocation = function() {
  return /** @type{?proto.elude.proto.FeaturedLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_featured_location_pb.FeaturedLocation, 3));
};


/**
 * @param {?proto.elude.proto.FeaturedLocation|undefined} value
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
*/
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.setFeaturedLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.clearFeaturedLocation = function() {
  return this.setFeaturedLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.hasFeaturedLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
*/
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateFeaturedLocationRequest} returns this
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateFeaturedLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteFeaturedLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteFeaturedLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteFeaturedLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteFeaturedLocationRequest;
  return proto.elude.proto.DeleteFeaturedLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteFeaturedLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteFeaturedLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteFeaturedLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteFeaturedLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
*/
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
*/
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
*/
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteFeaturedLocationRequest} returns this
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteFeaturedLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetConnectedCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetConnectedCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetConnectedCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetConnectedCityRequest}
 */
proto.elude.proto.GetConnectedCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetConnectedCityRequest;
  return proto.elude.proto.GetConnectedCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetConnectedCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetConnectedCityRequest}
 */
proto.elude.proto.GetConnectedCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetConnectedCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetConnectedCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetConnectedCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
*/
proto.elude.proto.GetConnectedCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
 */
proto.elude.proto.GetConnectedCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
*/
proto.elude.proto.GetConnectedCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
 */
proto.elude.proto.GetConnectedCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
*/
proto.elude.proto.GetConnectedCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
 */
proto.elude.proto.GetConnectedCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetConnectedCityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetConnectedCityRequest} returns this
 */
proto.elude.proto.GetConnectedCityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetConnectedCitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetConnectedCitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetConnectedCitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetConnectedCitiesRequest}
 */
proto.elude.proto.GetConnectedCitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetConnectedCitiesRequest;
  return proto.elude.proto.GetConnectedCitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetConnectedCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetConnectedCitiesRequest}
 */
proto.elude.proto.GetConnectedCitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetConnectedCitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetConnectedCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetConnectedCitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetConnectedCitiesRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateConnectedCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateConnectedCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateConnectedCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    connectedCity: (f = msg.getConnectedCity()) && proto_models_connected_city_pb.ConnectedCity.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateConnectedCityRequest}
 */
proto.elude.proto.CreateConnectedCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateConnectedCityRequest;
  return proto.elude.proto.CreateConnectedCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateConnectedCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateConnectedCityRequest}
 */
proto.elude.proto.CreateConnectedCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_connected_city_pb.ConnectedCity;
      reader.readMessage(value,proto_models_connected_city_pb.ConnectedCity.deserializeBinaryFromReader);
      msg.setConnectedCity(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateConnectedCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateConnectedCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateConnectedCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getConnectedCity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_connected_city_pb.ConnectedCity.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
*/
proto.elude.proto.CreateConnectedCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
*/
proto.elude.proto.CreateConnectedCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConnectedCity connected_city = 3;
 * @return {?proto.elude.proto.ConnectedCity}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.getConnectedCity = function() {
  return /** @type{?proto.elude.proto.ConnectedCity} */ (
    jspb.Message.getWrapperField(this, proto_models_connected_city_pb.ConnectedCity, 3));
};


/**
 * @param {?proto.elude.proto.ConnectedCity|undefined} value
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
*/
proto.elude.proto.CreateConnectedCityRequest.prototype.setConnectedCity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.clearConnectedCity = function() {
  return this.setConnectedCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.hasConnectedCity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
*/
proto.elude.proto.CreateConnectedCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateConnectedCityRequest} returns this
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateConnectedCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateConnectedCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateConnectedCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateConnectedCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    connectedCity: (f = msg.getConnectedCity()) && proto_models_connected_city_pb.ConnectedCity.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateConnectedCityRequest}
 */
proto.elude.proto.UpdateConnectedCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateConnectedCityRequest;
  return proto.elude.proto.UpdateConnectedCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateConnectedCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateConnectedCityRequest}
 */
proto.elude.proto.UpdateConnectedCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_connected_city_pb.ConnectedCity;
      reader.readMessage(value,proto_models_connected_city_pb.ConnectedCity.deserializeBinaryFromReader);
      msg.setConnectedCity(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateConnectedCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateConnectedCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateConnectedCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getConnectedCity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_connected_city_pb.ConnectedCity.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
*/
proto.elude.proto.UpdateConnectedCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
*/
proto.elude.proto.UpdateConnectedCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConnectedCity connected_city = 3;
 * @return {?proto.elude.proto.ConnectedCity}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.getConnectedCity = function() {
  return /** @type{?proto.elude.proto.ConnectedCity} */ (
    jspb.Message.getWrapperField(this, proto_models_connected_city_pb.ConnectedCity, 3));
};


/**
 * @param {?proto.elude.proto.ConnectedCity|undefined} value
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
*/
proto.elude.proto.UpdateConnectedCityRequest.prototype.setConnectedCity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.clearConnectedCity = function() {
  return this.setConnectedCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.hasConnectedCity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
*/
proto.elude.proto.UpdateConnectedCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateConnectedCityRequest} returns this
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateConnectedCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteConnectedCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteConnectedCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteConnectedCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteConnectedCityRequest}
 */
proto.elude.proto.DeleteConnectedCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteConnectedCityRequest;
  return proto.elude.proto.DeleteConnectedCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteConnectedCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteConnectedCityRequest}
 */
proto.elude.proto.DeleteConnectedCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteConnectedCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteConnectedCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteConnectedCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
*/
proto.elude.proto.DeleteConnectedCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
*/
proto.elude.proto.DeleteConnectedCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
*/
proto.elude.proto.DeleteConnectedCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteConnectedCityRequest} returns this
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteConnectedCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    departureCityId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetConnectedCitiesForDepartureCityRequest;
  return proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartureCityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getDepartureCityId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
*/
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 departure_city_id = 5;
 * @return {number}
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.getDepartureCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetConnectedCitiesForDepartureCityRequest} returns this
 */
proto.elude.proto.GetConnectedCitiesForDepartureCityRequest.prototype.setDepartureCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetWeatherByCoordinatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetWeatherByCoordinatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto_commons_coordinates_pb.Coordinates.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetWeatherByCoordinatesRequest;
  return proto.elude.proto.GetWeatherByCoordinatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetWeatherByCoordinatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_coordinates_pb.Coordinates;
      reader.readMessage(value,proto_commons_coordinates_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetWeatherByCoordinatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetWeatherByCoordinatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_coordinates_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Coordinates coordinates = 3;
 * @return {?proto.elude.proto.Coordinates}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.elude.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto_commons_coordinates_pb.Coordinates, 3));
};


/**
 * @param {?proto.elude.proto.Coordinates|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
*/
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetWeatherByCoordinatesRequest} returns this
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetWeatherByCoordinatesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetRegionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetRegionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetRegionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRegionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetRegionRequest}
 */
proto.elude.proto.GetRegionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetRegionRequest;
  return proto.elude.proto.GetRegionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetRegionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetRegionRequest}
 */
proto.elude.proto.GetRegionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetRegionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetRegionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetRegionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRegionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetRegionRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetRegionRequest} returns this
*/
proto.elude.proto.GetRegionRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionRequest} returns this
 */
proto.elude.proto.GetRegionRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetRegionRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetRegionRequest} returns this
*/
proto.elude.proto.GetRegionRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionRequest} returns this
 */
proto.elude.proto.GetRegionRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetRegionRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetRegionRequest} returns this
*/
proto.elude.proto.GetRegionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionRequest} returns this
 */
proto.elude.proto.GetRegionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetRegionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetRegionRequest} returns this
 */
proto.elude.proto.GetRegionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetRegionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetRegionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetRegionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRegionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetRegionsRequest}
 */
proto.elude.proto.GetRegionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetRegionsRequest;
  return proto.elude.proto.GetRegionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetRegionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetRegionsRequest}
 */
proto.elude.proto.GetRegionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetRegionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetRegionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetRegionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRegionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetRegionsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
*/
proto.elude.proto.GetRegionsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
 */
proto.elude.proto.GetRegionsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetRegionsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
*/
proto.elude.proto.GetRegionsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
 */
proto.elude.proto.GetRegionsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetRegionsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
*/
proto.elude.proto.GetRegionsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
 */
proto.elude.proto.GetRegionsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetRegionsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
*/
proto.elude.proto.GetRegionsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
 */
proto.elude.proto.GetRegionsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetRegionsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
 */
proto.elude.proto.GetRegionsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetRegionsRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
*/
proto.elude.proto.GetRegionsRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRegionsRequest} returns this
 */
proto.elude.proto.GetRegionsRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRegionsRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateRegionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateRegionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateRegionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateRegionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    region: (f = msg.getRegion()) && proto_models_region_pb.Region.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateRegionRequest}
 */
proto.elude.proto.CreateRegionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateRegionRequest;
  return proto.elude.proto.CreateRegionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateRegionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateRegionRequest}
 */
proto.elude.proto.CreateRegionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_region_pb.Region;
      reader.readMessage(value,proto_models_region_pb.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateRegionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateRegionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateRegionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateRegionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_region_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateRegionRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
*/
proto.elude.proto.CreateRegionRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
 */
proto.elude.proto.CreateRegionRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRegionRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateRegionRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
*/
proto.elude.proto.CreateRegionRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
 */
proto.elude.proto.CreateRegionRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRegionRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Region region = 3;
 * @return {?proto.elude.proto.Region}
 */
proto.elude.proto.CreateRegionRequest.prototype.getRegion = function() {
  return /** @type{?proto.elude.proto.Region} */ (
    jspb.Message.getWrapperField(this, proto_models_region_pb.Region, 3));
};


/**
 * @param {?proto.elude.proto.Region|undefined} value
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
*/
proto.elude.proto.CreateRegionRequest.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
 */
proto.elude.proto.CreateRegionRequest.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRegionRequest.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateRegionRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
*/
proto.elude.proto.CreateRegionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateRegionRequest} returns this
 */
proto.elude.proto.CreateRegionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateRegionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateRegionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateRegionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateRegionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateRegionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    region: (f = msg.getRegion()) && proto_models_region_pb.Region.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateRegionRequest}
 */
proto.elude.proto.UpdateRegionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateRegionRequest;
  return proto.elude.proto.UpdateRegionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateRegionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateRegionRequest}
 */
proto.elude.proto.UpdateRegionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_region_pb.Region;
      reader.readMessage(value,proto_models_region_pb.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateRegionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateRegionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateRegionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateRegionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_region_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateRegionRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
*/
proto.elude.proto.UpdateRegionRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
 */
proto.elude.proto.UpdateRegionRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRegionRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateRegionRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
*/
proto.elude.proto.UpdateRegionRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
 */
proto.elude.proto.UpdateRegionRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRegionRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Region region = 3;
 * @return {?proto.elude.proto.Region}
 */
proto.elude.proto.UpdateRegionRequest.prototype.getRegion = function() {
  return /** @type{?proto.elude.proto.Region} */ (
    jspb.Message.getWrapperField(this, proto_models_region_pb.Region, 3));
};


/**
 * @param {?proto.elude.proto.Region|undefined} value
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
*/
proto.elude.proto.UpdateRegionRequest.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
 */
proto.elude.proto.UpdateRegionRequest.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRegionRequest.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateRegionRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
*/
proto.elude.proto.UpdateRegionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateRegionRequest} returns this
 */
proto.elude.proto.UpdateRegionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateRegionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteRegionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteRegionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteRegionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteRegionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteRegionRequest}
 */
proto.elude.proto.DeleteRegionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteRegionRequest;
  return proto.elude.proto.DeleteRegionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteRegionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteRegionRequest}
 */
proto.elude.proto.DeleteRegionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteRegionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteRegionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteRegionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteRegionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteRegionRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
*/
proto.elude.proto.DeleteRegionRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
 */
proto.elude.proto.DeleteRegionRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteRegionRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteRegionRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
*/
proto.elude.proto.DeleteRegionRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
 */
proto.elude.proto.DeleteRegionRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteRegionRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteRegionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
 */
proto.elude.proto.DeleteRegionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteRegionRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
*/
proto.elude.proto.DeleteRegionRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteRegionRequest} returns this
 */
proto.elude.proto.DeleteRegionRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteRegionRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetLocationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetLocationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetLocationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 4, ""),
    searchType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto_commons_coordinates_pb.Coordinates.toObject(includeInstance, f),
    homeAirportId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetLocationsRequest}
 */
proto.elude.proto.GetLocationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetLocationsRequest;
  return proto.elude.proto.GetLocationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetLocationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetLocationsRequest}
 */
proto.elude.proto.GetLocationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {!proto.elude.proto.SearchTypeEnum} */ (reader.readEnum());
      msg.setSearchType(value);
      break;
    case 6:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 7:
      var value = new proto_commons_coordinates_pb.Coordinates;
      reader.readMessage(value,proto_commons_coordinates_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHomeAirportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetLocationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetLocationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetLocationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSearchType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_coordinates_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getHomeAirportId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetLocationsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
*/
proto.elude.proto.GetLocationsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetLocationsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
*/
proto.elude.proto.GetLocationsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetLocationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
*/
proto.elude.proto.GetLocationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string search = 4;
 * @return {string}
 */
proto.elude.proto.GetLocationsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SearchTypeEnum search_type = 5;
 * @return {!proto.elude.proto.SearchTypeEnum}
 */
proto.elude.proto.GetLocationsRequest.prototype.getSearchType = function() {
  return /** @type {!proto.elude.proto.SearchTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.elude.proto.SearchTypeEnum} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.setSearchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Pagination pagination = 6;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetLocationsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 6));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
*/
proto.elude.proto.GetLocationsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Coordinates coordinates = 7;
 * @return {?proto.elude.proto.Coordinates}
 */
proto.elude.proto.GetLocationsRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.elude.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto_commons_coordinates_pb.Coordinates, 7));
};


/**
 * @param {?proto.elude.proto.Coordinates|undefined} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
*/
proto.elude.proto.GetLocationsRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 home_airport_id = 8;
 * @return {number}
 */
proto.elude.proto.GetLocationsRequest.prototype.getHomeAirportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetLocationsRequest} returns this
 */
proto.elude.proto.GetLocationsRequest.prototype.setHomeAirportId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetLocationByCoordinatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetLocationByCoordinatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationByCoordinatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto_commons_coordinates_pb.Coordinates.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetLocationByCoordinatesRequest;
  return proto.elude.proto.GetLocationByCoordinatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetLocationByCoordinatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_coordinates_pb.Coordinates;
      reader.readMessage(value,proto_commons_coordinates_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetLocationByCoordinatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetLocationByCoordinatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationByCoordinatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_coordinates_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
*/
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
*/
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Coordinates coordinates = 3;
 * @return {?proto.elude.proto.Coordinates}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.elude.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto_commons_coordinates_pb.Coordinates, 3));
};


/**
 * @param {?proto.elude.proto.Coordinates|undefined} value
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
*/
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
*/
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationByCoordinatesRequest} returns this
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationByCoordinatesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetLocationRequest}
 */
proto.elude.proto.GetLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetLocationRequest;
  return proto.elude.proto.GetLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetLocationRequest}
 */
proto.elude.proto.GetLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetLocationRequest} returns this
*/
proto.elude.proto.GetLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationRequest} returns this
 */
proto.elude.proto.GetLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetLocationRequest} returns this
*/
proto.elude.proto.GetLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationRequest} returns this
 */
proto.elude.proto.GetLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetLocationRequest} returns this
*/
proto.elude.proto.GetLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationRequest} returns this
 */
proto.elude.proto.GetLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetLocationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetLocationRequest} returns this
 */
proto.elude.proto.GetLocationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetLocationsByIdentifiersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetLocationsByIdentifiersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    locationIdentifiersList: jspb.Message.toObjectList(msg.getLocationIdentifiersList(),
    proto_commons_flow_common_pb.LocationIdentifier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetLocationsByIdentifiersRequest;
  return proto.elude.proto.GetLocationsByIdentifiersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetLocationsByIdentifiersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.LocationIdentifier;
      reader.readMessage(value,proto_commons_flow_common_pb.LocationIdentifier.deserializeBinaryFromReader);
      msg.addLocationIdentifiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetLocationsByIdentifiersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetLocationsByIdentifiersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getLocationIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_commons_flow_common_pb.LocationIdentifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LocationIdentifier location_identifiers = 4;
 * @return {!Array<!proto.elude.proto.LocationIdentifier>}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.getLocationIdentifiersList = function() {
  return /** @type{!Array<!proto.elude.proto.LocationIdentifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_commons_flow_common_pb.LocationIdentifier, 4));
};


/**
 * @param {!Array<!proto.elude.proto.LocationIdentifier>} value
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
*/
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.setLocationIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.elude.proto.LocationIdentifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.LocationIdentifier}
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.addLocationIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.elude.proto.LocationIdentifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetLocationsByIdentifiersRequest} returns this
 */
proto.elude.proto.GetLocationsByIdentifiersRequest.prototype.clearLocationIdentifiersList = function() {
  return this.setLocationIdentifiersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.GetSearchLocationsRequest.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetSearchLocationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetSearchLocationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSearchLocationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && proto_air_hotel_reservation_search_request_pb.ItineraryRequest.toObject(includeInstance, f),
    cacheCityIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    cacheLocationIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetSearchLocationsRequest}
 */
proto.elude.proto.GetSearchLocationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetSearchLocationsRequest;
  return proto.elude.proto.GetSearchLocationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetSearchLocationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetSearchLocationsRequest}
 */
proto.elude.proto.GetSearchLocationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_air_hotel_reservation_search_request_pb.ItineraryRequest;
      reader.readMessage(value,proto_air_hotel_reservation_search_request_pb.ItineraryRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCacheCityIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCacheLocationIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetSearchLocationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetSearchLocationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSearchLocationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_air_hotel_reservation_search_request_pb.ItineraryRequest.serializeBinaryToWriter
    );
  }
  f = message.getCacheCityIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getCacheLocationIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
*/
proto.elude.proto.GetSearchLocationsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
*/
proto.elude.proto.GetSearchLocationsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
*/
proto.elude.proto.GetSearchLocationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ItineraryRequest request = 4;
 * @return {?proto.elude.proto.ItineraryRequest}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.getRequest = function() {
  return /** @type{?proto.elude.proto.ItineraryRequest} */ (
    jspb.Message.getWrapperField(this, proto_air_hotel_reservation_search_request_pb.ItineraryRequest, 4));
};


/**
 * @param {?proto.elude.proto.ItineraryRequest|undefined} value
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
*/
proto.elude.proto.GetSearchLocationsRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated int32 cache_city_ids = 5;
 * @return {!Array<number>}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.getCacheCityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.setCacheCityIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.addCacheCityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.clearCacheCityIdsList = function() {
  return this.setCacheCityIdsList([]);
};


/**
 * repeated int32 cache_location_ids = 6;
 * @return {!Array<number>}
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.getCacheLocationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.setCacheLocationIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.addCacheLocationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.GetSearchLocationsRequest} returns this
 */
proto.elude.proto.GetSearchLocationsRequest.prototype.clearCacheLocationIdsList = function() {
  return this.setCacheLocationIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetPreferencesRequest}
 */
proto.elude.proto.GetPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetPreferencesRequest;
  return proto.elude.proto.GetPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetPreferencesRequest}
 */
proto.elude.proto.GetPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetPreferencesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetPreferencesRequest} returns this
*/
proto.elude.proto.GetPreferencesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPreferencesRequest} returns this
 */
proto.elude.proto.GetPreferencesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPreferencesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetPreferencesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetPreferencesRequest} returns this
*/
proto.elude.proto.GetPreferencesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPreferencesRequest} returns this
 */
proto.elude.proto.GetPreferencesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPreferencesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetPreferencesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetPreferencesRequest} returns this
*/
proto.elude.proto.GetPreferencesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetPreferencesRequest} returns this
 */
proto.elude.proto.GetPreferencesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetPreferencesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.UpdateUserPreferencesRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateUserPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateUserPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateUserPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    preferenceIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest}
 */
proto.elude.proto.UpdateUserPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateUserPreferencesRequest;
  return proto.elude.proto.UpdateUserPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateUserPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest}
 */
proto.elude.proto.UpdateUserPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPreferenceIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateUserPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateUserPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateUserPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPreferenceIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
*/
proto.elude.proto.UpdateUserPreferencesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
*/
proto.elude.proto.UpdateUserPreferencesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
*/
proto.elude.proto.UpdateUserPreferencesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated int32 preference_ids = 4;
 * @return {!Array<number>}
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.getPreferenceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.setPreferenceIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.addPreferenceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.UpdateUserPreferencesRequest} returns this
 */
proto.elude.proto.UpdateUserPreferencesRequest.prototype.clearPreferenceIdsList = function() {
  return this.setPreferenceIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetRecommendedCitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetRecommendedCitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRecommendedCitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest}
 */
proto.elude.proto.GetRecommendedCitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetRecommendedCitiesRequest;
  return proto.elude.proto.GetRecommendedCitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetRecommendedCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest}
 */
proto.elude.proto.GetRecommendedCitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetRecommendedCitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetRecommendedCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetRecommendedCitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest} returns this
*/
proto.elude.proto.GetRecommendedCitiesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest} returns this
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest} returns this
*/
proto.elude.proto.GetRecommendedCitiesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest} returns this
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest} returns this
*/
proto.elude.proto.GetRecommendedCitiesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetRecommendedCitiesRequest} returns this
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetRecommendedCitiesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetShardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetShardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetShardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetShardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetShardRequest}
 */
proto.elude.proto.GetShardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetShardRequest;
  return proto.elude.proto.GetShardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetShardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetShardRequest}
 */
proto.elude.proto.GetShardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetShardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetShardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetShardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetShardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetShardRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetShardRequest} returns this
*/
proto.elude.proto.GetShardRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardRequest} returns this
 */
proto.elude.proto.GetShardRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetShardRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetShardRequest} returns this
*/
proto.elude.proto.GetShardRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardRequest} returns this
 */
proto.elude.proto.GetShardRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetShardRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetShardRequest} returns this
*/
proto.elude.proto.GetShardRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardRequest} returns this
 */
proto.elude.proto.GetShardRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetShardRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetShardRequest} returns this
 */
proto.elude.proto.GetShardRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetShardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetShardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetShardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetShardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetShardsRequest}
 */
proto.elude.proto.GetShardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetShardsRequest;
  return proto.elude.proto.GetShardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetShardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetShardsRequest}
 */
proto.elude.proto.GetShardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetShardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetShardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetShardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetShardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetShardsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetShardsRequest} returns this
*/
proto.elude.proto.GetShardsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardsRequest} returns this
 */
proto.elude.proto.GetShardsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetShardsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetShardsRequest} returns this
*/
proto.elude.proto.GetShardsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardsRequest} returns this
 */
proto.elude.proto.GetShardsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetShardsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetShardsRequest} returns this
*/
proto.elude.proto.GetShardsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardsRequest} returns this
 */
proto.elude.proto.GetShardsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetShardsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetShardsRequest} returns this
*/
proto.elude.proto.GetShardsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardsRequest} returns this
 */
proto.elude.proto.GetShardsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetShardsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetShardsRequest} returns this
 */
proto.elude.proto.GetShardsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetShardsRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetShardsRequest} returns this
*/
proto.elude.proto.GetShardsRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetShardsRequest} returns this
 */
proto.elude.proto.GetShardsRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetShardsRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateShardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateShardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateShardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateShardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    shard: (f = msg.getShard()) && proto_models_shard_pb.Shard.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateShardRequest}
 */
proto.elude.proto.CreateShardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateShardRequest;
  return proto.elude.proto.CreateShardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateShardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateShardRequest}
 */
proto.elude.proto.CreateShardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_shard_pb.Shard;
      reader.readMessage(value,proto_models_shard_pb.Shard.deserializeBinaryFromReader);
      msg.setShard(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateShardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateShardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateShardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateShardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getShard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_shard_pb.Shard.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateShardRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateShardRequest} returns this
*/
proto.elude.proto.CreateShardRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateShardRequest} returns this
 */
proto.elude.proto.CreateShardRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateShardRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateShardRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateShardRequest} returns this
*/
proto.elude.proto.CreateShardRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateShardRequest} returns this
 */
proto.elude.proto.CreateShardRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateShardRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Shard shard = 3;
 * @return {?proto.elude.proto.Shard}
 */
proto.elude.proto.CreateShardRequest.prototype.getShard = function() {
  return /** @type{?proto.elude.proto.Shard} */ (
    jspb.Message.getWrapperField(this, proto_models_shard_pb.Shard, 3));
};


/**
 * @param {?proto.elude.proto.Shard|undefined} value
 * @return {!proto.elude.proto.CreateShardRequest} returns this
*/
proto.elude.proto.CreateShardRequest.prototype.setShard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateShardRequest} returns this
 */
proto.elude.proto.CreateShardRequest.prototype.clearShard = function() {
  return this.setShard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateShardRequest.prototype.hasShard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateShardRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateShardRequest} returns this
*/
proto.elude.proto.CreateShardRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateShardRequest} returns this
 */
proto.elude.proto.CreateShardRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateShardRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateShardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateShardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateShardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateShardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    shard: (f = msg.getShard()) && proto_models_shard_pb.Shard.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateShardRequest}
 */
proto.elude.proto.UpdateShardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateShardRequest;
  return proto.elude.proto.UpdateShardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateShardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateShardRequest}
 */
proto.elude.proto.UpdateShardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_shard_pb.Shard;
      reader.readMessage(value,proto_models_shard_pb.Shard.deserializeBinaryFromReader);
      msg.setShard(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateShardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateShardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateShardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateShardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getShard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_shard_pb.Shard.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateShardRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
*/
proto.elude.proto.UpdateShardRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
 */
proto.elude.proto.UpdateShardRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateShardRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateShardRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
*/
proto.elude.proto.UpdateShardRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
 */
proto.elude.proto.UpdateShardRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateShardRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Shard shard = 3;
 * @return {?proto.elude.proto.Shard}
 */
proto.elude.proto.UpdateShardRequest.prototype.getShard = function() {
  return /** @type{?proto.elude.proto.Shard} */ (
    jspb.Message.getWrapperField(this, proto_models_shard_pb.Shard, 3));
};


/**
 * @param {?proto.elude.proto.Shard|undefined} value
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
*/
proto.elude.proto.UpdateShardRequest.prototype.setShard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
 */
proto.elude.proto.UpdateShardRequest.prototype.clearShard = function() {
  return this.setShard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateShardRequest.prototype.hasShard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateShardRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
*/
proto.elude.proto.UpdateShardRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateShardRequest} returns this
 */
proto.elude.proto.UpdateShardRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateShardRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteShardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteShardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteShardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteShardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteShardRequest}
 */
proto.elude.proto.DeleteShardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteShardRequest;
  return proto.elude.proto.DeleteShardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteShardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteShardRequest}
 */
proto.elude.proto.DeleteShardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteShardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteShardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteShardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteShardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteShardRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
*/
proto.elude.proto.DeleteShardRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
 */
proto.elude.proto.DeleteShardRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteShardRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteShardRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
*/
proto.elude.proto.DeleteShardRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
 */
proto.elude.proto.DeleteShardRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteShardRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteShardRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
 */
proto.elude.proto.DeleteShardRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteShardRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
*/
proto.elude.proto.DeleteShardRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteShardRequest} returns this
 */
proto.elude.proto.DeleteShardRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteShardRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetParentLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetParentLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetParentLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetParentLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetParentLocationRequest}
 */
proto.elude.proto.GetParentLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetParentLocationRequest;
  return proto.elude.proto.GetParentLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetParentLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetParentLocationRequest}
 */
proto.elude.proto.GetParentLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetParentLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetParentLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetParentLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetParentLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetParentLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
*/
proto.elude.proto.GetParentLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
 */
proto.elude.proto.GetParentLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetParentLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
*/
proto.elude.proto.GetParentLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
 */
proto.elude.proto.GetParentLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetParentLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
*/
proto.elude.proto.GetParentLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
 */
proto.elude.proto.GetParentLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.elude.proto.GetParentLocationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetParentLocationRequest} returns this
 */
proto.elude.proto.GetParentLocationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetParentLocationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetParentLocationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetParentLocationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    search: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetParentLocationsRequest}
 */
proto.elude.proto.GetParentLocationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetParentLocationsRequest;
  return proto.elude.proto.GetParentLocationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetParentLocationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetParentLocationsRequest}
 */
proto.elude.proto.GetParentLocationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 4:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 6:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetParentLocationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetParentLocationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetParentLocationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
*/
proto.elude.proto.GetParentLocationsRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
 */
proto.elude.proto.GetParentLocationsRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
*/
proto.elude.proto.GetParentLocationsRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
 */
proto.elude.proto.GetParentLocationsRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Options options = 3;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 3));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
*/
proto.elude.proto.GetParentLocationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
 */
proto.elude.proto.GetParentLocationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Pagination pagination = 4;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 4));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
*/
proto.elude.proto.GetParentLocationsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
 */
proto.elude.proto.GetParentLocationsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string search = 5;
 * @return {string}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
 */
proto.elude.proto.GetParentLocationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FilterOptions filter_options = 6;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 6));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
*/
proto.elude.proto.GetParentLocationsRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetParentLocationsRequest} returns this
 */
proto.elude.proto.GetParentLocationsRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetParentLocationsRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateParentLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateParentLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateParentLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    parentLocation: (f = msg.getParentLocation()) && proto_models_parent_location_pb.ParentLocation.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateParentLocationRequest}
 */
proto.elude.proto.CreateParentLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateParentLocationRequest;
  return proto.elude.proto.CreateParentLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateParentLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateParentLocationRequest}
 */
proto.elude.proto.CreateParentLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_parent_location_pb.ParentLocation;
      reader.readMessage(value,proto_models_parent_location_pb.ParentLocation.deserializeBinaryFromReader);
      msg.setParentLocation(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateParentLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateParentLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateParentLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_parent_location_pb.ParentLocation.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
*/
proto.elude.proto.CreateParentLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
 */
proto.elude.proto.CreateParentLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
*/
proto.elude.proto.CreateParentLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
 */
proto.elude.proto.CreateParentLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ParentLocation parent_location = 3;
 * @return {?proto.elude.proto.ParentLocation}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.getParentLocation = function() {
  return /** @type{?proto.elude.proto.ParentLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_parent_location_pb.ParentLocation, 3));
};


/**
 * @param {?proto.elude.proto.ParentLocation|undefined} value
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
*/
proto.elude.proto.CreateParentLocationRequest.prototype.setParentLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
 */
proto.elude.proto.CreateParentLocationRequest.prototype.clearParentLocation = function() {
  return this.setParentLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.hasParentLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
*/
proto.elude.proto.CreateParentLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateParentLocationRequest} returns this
 */
proto.elude.proto.CreateParentLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateParentLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateParentLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateParentLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    parentLocation: (f = msg.getParentLocation()) && proto_models_parent_location_pb.ParentLocation.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateParentLocationRequest}
 */
proto.elude.proto.UpdateParentLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateParentLocationRequest;
  return proto.elude.proto.UpdateParentLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateParentLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateParentLocationRequest}
 */
proto.elude.proto.UpdateParentLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_models_parent_location_pb.ParentLocation;
      reader.readMessage(value,proto_models_parent_location_pb.ParentLocation.deserializeBinaryFromReader);
      msg.setParentLocation(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateParentLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateParentLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getParentLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_models_parent_location_pb.ParentLocation.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
*/
proto.elude.proto.UpdateParentLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
*/
proto.elude.proto.UpdateParentLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ParentLocation parent_location = 3;
 * @return {?proto.elude.proto.ParentLocation}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.getParentLocation = function() {
  return /** @type{?proto.elude.proto.ParentLocation} */ (
    jspb.Message.getWrapperField(this, proto_models_parent_location_pb.ParentLocation, 3));
};


/**
 * @param {?proto.elude.proto.ParentLocation|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
*/
proto.elude.proto.UpdateParentLocationRequest.prototype.setParentLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.clearParentLocation = function() {
  return this.setParentLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.hasParentLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
*/
proto.elude.proto.UpdateParentLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationRequest} returns this
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteParentLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteParentLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteParentLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteParentLocationRequest}
 */
proto.elude.proto.DeleteParentLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteParentLocationRequest;
  return proto.elude.proto.DeleteParentLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteParentLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteParentLocationRequest}
 */
proto.elude.proto.DeleteParentLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteParentLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteParentLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteParentLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
*/
proto.elude.proto.DeleteParentLocationRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
*/
proto.elude.proto.DeleteParentLocationRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
*/
proto.elude.proto.DeleteParentLocationRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteParentLocationRequest} returns this
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteParentLocationRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateParentLocationWithImageData.toObject(includeInstance, f),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateParentLocationWithImageUnaryRequest;
  return proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateParentLocationWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateParentLocationWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateParentLocationWithImageData.serializeBinaryToWriter
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateParentLocationWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateParentLocationWithImageData}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateParentLocationWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateParentLocationWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateParentLocationWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes image = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes image = 2;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageUnaryRequest} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageUnaryRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.DataCase = {
  DATA_NOT_SET: 0,
  UPDATEREQ: 1,
  CHUNK_DATA: 2
};

/**
 * @return {proto.elude.proto.UpdateParentLocationWithImageRequest.DataCase}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.getDataCase = function() {
  return /** @type {proto.elude.proto.UpdateParentLocationWithImageRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.elude.proto.UpdateParentLocationWithImageRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.UpdateParentLocationWithImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.UpdateParentLocationWithImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatereq: (f = msg.getUpdatereq()) && proto_commons_image_data_pb.UpdateParentLocationWithImageData.toObject(includeInstance, f),
    chunkData: msg.getChunkData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageRequest}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.UpdateParentLocationWithImageRequest;
  return proto.elude.proto.UpdateParentLocationWithImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.UpdateParentLocationWithImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageRequest}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_image_data_pb.UpdateParentLocationWithImageData;
      reader.readMessage(value,proto_commons_image_data_pb.UpdateParentLocationWithImageData.deserializeBinaryFromReader);
      msg.setUpdatereq(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunkData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.UpdateParentLocationWithImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.UpdateParentLocationWithImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatereq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_image_data_pb.UpdateParentLocationWithImageData.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional UpdateParentLocationWithImageData updateReq = 1;
 * @return {?proto.elude.proto.UpdateParentLocationWithImageData}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.getUpdatereq = function() {
  return /** @type{?proto.elude.proto.UpdateParentLocationWithImageData} */ (
    jspb.Message.getWrapperField(this, proto_commons_image_data_pb.UpdateParentLocationWithImageData, 1));
};


/**
 * @param {?proto.elude.proto.UpdateParentLocationWithImageData|undefined} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageRequest} returns this
*/
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.setUpdatereq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.elude.proto.UpdateParentLocationWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageRequest} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.clearUpdatereq = function() {
  return this.setUpdatereq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.hasUpdatereq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes chunk_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.getChunkData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes chunk_data = 2;
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {string}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.getChunkData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunkData()));
};


/**
 * optional bytes chunk_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunkData()`
 * @return {!Uint8Array}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.getChunkData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunkData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.elude.proto.UpdateParentLocationWithImageRequest} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.setChunkData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateParentLocationWithImageRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.elude.proto.UpdateParentLocationWithImageRequest} returns this
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.clearChunkData = function() {
  return jspb.Message.setOneofField(this, 2, proto.elude.proto.UpdateParentLocationWithImageRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.UpdateParentLocationWithImageRequest.prototype.hasChunkData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.elude.proto);
