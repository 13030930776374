<template>
  <flex
    class="g-icon-component"
    :class="classes"
    :style="styles"
    @click="button && $emit('click')"
  >
    <component :is="component" />
  </flex>
</template>

<script>
import American from './icons/American.vue'
import ApplePay from './icons/ApplePay.vue'
import AppleWallet from './icons/AppleWallet.vue'
import Arrive from './icons/Arrive.vue'
import ArrowBackBlack from './icons/ArrowBackBlack.vue'
import ArrowBackCircle from './icons/ArrowBackCircle.vue'
import ArrowForwardSmall from './icons/ArrowForwardSmall.vue'
import ArrowForwardWhite from './icons/ArrowForwardWhite.vue'
import CalendarSmall from './icons/CalendarSmall.vue'
import Clock from './icons/Clock.vue'
import Close from './icons/Close.vue'
import CloseButtonCrossSign from './icons/CloseButtonCrossSign.vue'
import CreditCard from './icons/CreditCard.vue'
import Depart from './icons/Depart.vue'
import Dot from './icons/Dot.vue'
import DropdownLeft from './icons/DropdownLeft.vue'
import DropdownRight from './icons/DropdownRight.vue'
import Edit from './icons/Edit.vue'
import Globe from './icons/Globe.vue'
import Hotel from './icons/Hotel.vue'
import HotelBed from './icons/HotelBed.vue'
import HotelBreakfast from './icons/HotelBreakfast.vue'
import HotelCableTV from './icons/HotelCableTV.vue'
import HotelHotTub from './icons/HotelHotTub.vue'
import HotelLaundry from './icons/HotelLaundry.vue'
import HotelParking from './icons/HotelParking.vue'
import HotelSize from './icons/HotelSize.vue'
import HotelWifi from './icons/HotelWifi.vue'
import LocationPin from './icons/LocationPin.vue'
import Logout from './icons/Logout.vue'
import Mail from './icons/Mail.vue'
import Map from './icons/Map.vue'
import MasterCard from './icons/MasterCard.vue'
import Passengers from './icons/Passengers.vue'
import PayPal from './icons/PayPal.vue'
import Phone from './icons/Phone.vue'
import PlaneArrive from './icons/PlaneArrive.vue'
import PlaneDepart from './icons/PlaneDepart.vue'
import PlaneOutline from './icons/PlaneOutline.vue'
import PlaneRed from './icons/PlaneRed.vue'
import PlaneRedReturning from './icons/PlaneRedReturning.vue'
import ShapeMinus from './icons/ShapeMinus.vue'
import ShapePlus from './icons/ShapePlus.vue'
import Share from './icons/Share.vue'
import Sound from './icons/Sound.vue'
import Star from './icons/Star.vue'
import StarSmall from './icons/StarSmall.vue'
import Trash from './icons/Trash.vue'
import Visa from './icons/Visa.vue'
import Weather from './icons/Weather.vue'
import { utils } from '../../other/utils/utils.js'

const icons = {
  American,
  ApplePay,
  AppleWallet,
  Arrive,
  ArrowBackBlack,
  ArrowBackCircle,
  ArrowForwardSmall,
  ArrowForwardWhite,
  CalendarSmall,
  Clock,
  Close,
  CloseButtonCrossSign,
  CreditCard,
  Depart,
  Dot,
  DropdownLeft,
  DropdownRight,
  Edit,
  Globe,
  Hotel,
  HotelBed,
  HotelBreakfast,
  HotelCableTV,
  HotelHotTub,
  HotelLaundry,
  HotelParking,
  HotelSize,
  HotelWifi,
  LocationPin,
  Logout,
  Mail,
  Map,
  MasterCard,
  Passengers,
  PayPal,
  Phone,
  PlaneArrive,
  PlaneDepart,
  PlaneOutline,
  PlaneRed,
  PlaneRedReturning,
  ShapeMinus,
  ShapePlus,
  Share,
  Sound,
  Star,
  StarSmall,
  Trash,
  Visa,
  Weather,
}

const getIcon = ico => icons[ico]
const pickColorOrVar = value =>
  value && value[0] === '#' ? value : utils.css.getVar(`color-${value}`)

export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
      validator: value => getIcon(value),
    },
    button: Boolean,
    color: String,
    hover: {
      type: String,
      default: 'ternary',
    },
    inline: Boolean,
  },
  watch: {
    color(x) {
      this.cssVars.baseColor = pickColorOrVar(x)
    },
    hover(x) {
      this.cssVars.hoverColor = pickColorOrVar(x)
    },
  },
  data() {
    return {
      cssVars: {
        baseColor: pickColorOrVar(this.color),
        hoverColor: pickColorOrVar(this.hover),
      },
    }
  },
  computed: {
    component() {
      return getIcon(this.icon)
    },
    classes() {
      const classes = []
      if (this.color) classes.push('g-icon-component-color')
      if (this.hover && this.button) classes.push('g-icon-component-hover')
      return classes
    },
    styles() {
      const styles = {}
      if (this.inline) styles.display = 'inline'
      if (this.button) styles.cursor = 'pointer'
      return styles
    },
  },
}
</script>

<style lang="scss">
.g-icon-component {
  align-items: center;
  & > svg > * {
    transition: var(--transition-default);
  }
  &-color > svg > *[stroke] {
    stroke: var(--base-color);
  }
  &-color > svg > *[fill] {
    fill: var(--base-color);
  }
  &-hover:hover > svg > *[stroke] {
    stroke: var(--hover-color);
  }
  &-hover:hover > svg > *[fill] {
    fill: var(--hover-color);
  }
}
</style>
