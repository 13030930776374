// source: proto/saved_search/saved_search_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_options_pb = require('../../proto/commons/options_pb.js');
goog.object.extend(proto, proto_commons_options_pb);
var proto_commons_pagination_pb = require('../../proto/commons/pagination_pb.js');
goog.object.extend(proto, proto_commons_pagination_pb);
var proto_commons_filter_options_pb = require('../../proto/commons/filter_options_pb.js');
goog.object.extend(proto, proto_commons_filter_options_pb);
var proto_commons_saved_search_pb = require('../../proto/commons/saved_search_pb.js');
goog.object.extend(proto, proto_commons_saved_search_pb);
goog.exportSymbol('proto.elude.proto.CreateSavedSearchRequest', null, global);
goog.exportSymbol('proto.elude.proto.DeleteSavedSearchRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetSavedSearchRequest', null, global);
goog.exportSymbol('proto.elude.proto.GetSavedSearchesRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetSavedSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetSavedSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetSavedSearchRequest.displayName = 'proto.elude.proto.GetSavedSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.GetSavedSearchesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.GetSavedSearchesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.GetSavedSearchesRequest.displayName = 'proto.elude.proto.GetSavedSearchesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateSavedSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateSavedSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateSavedSearchRequest.displayName = 'proto.elude.proto.CreateSavedSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.DeleteSavedSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.DeleteSavedSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.DeleteSavedSearchRequest.displayName = 'proto.elude.proto.DeleteSavedSearchRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetSavedSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetSavedSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSavedSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetSavedSearchRequest}
 */
proto.elude.proto.GetSavedSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetSavedSearchRequest;
  return proto.elude.proto.GetSavedSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetSavedSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetSavedSearchRequest}
 */
proto.elude.proto.GetSavedSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetSavedSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetSavedSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSavedSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
*/
proto.elude.proto.GetSavedSearchRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
 */
proto.elude.proto.GetSavedSearchRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
 */
proto.elude.proto.GetSavedSearchRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SessionInfo session_info = 3;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 3));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
*/
proto.elude.proto.GetSavedSearchRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
 */
proto.elude.proto.GetSavedSearchRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
*/
proto.elude.proto.GetSavedSearchRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchRequest} returns this
 */
proto.elude.proto.GetSavedSearchRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.GetSavedSearchesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.GetSavedSearchesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSavedSearchesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    filterOptions: (f = msg.getFilterOptions()) && proto_commons_filter_options_pb.FilterOptions.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.GetSavedSearchesRequest}
 */
proto.elude.proto.GetSavedSearchesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.GetSavedSearchesRequest;
  return proto.elude.proto.GetSavedSearchesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.GetSavedSearchesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.GetSavedSearchesRequest}
 */
proto.elude.proto.GetSavedSearchesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new proto_commons_filter_options_pb.FilterOptions;
      reader.readMessage(value,proto_commons_filter_options_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilterOptions(value);
      break;
    case 5:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.GetSavedSearchesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.GetSavedSearchesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.GetSavedSearchesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilterOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_filter_options_pb.FilterOptions.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
*/
proto.elude.proto.GetSavedSearchesRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
*/
proto.elude.proto.GetSavedSearchesRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
*/
proto.elude.proto.GetSavedSearchesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FilterOptions filter_options = 4;
 * @return {?proto.elude.proto.FilterOptions}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.getFilterOptions = function() {
  return /** @type{?proto.elude.proto.FilterOptions} */ (
    jspb.Message.getWrapperField(this, proto_commons_filter_options_pb.FilterOptions, 4));
};


/**
 * @param {?proto.elude.proto.FilterOptions|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
*/
proto.elude.proto.GetSavedSearchesRequest.prototype.setFilterOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.clearFilterOptions = function() {
  return this.setFilterOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.hasFilterOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Options options = 5;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 5));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
*/
proto.elude.proto.GetSavedSearchesRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.GetSavedSearchesRequest} returns this
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.GetSavedSearchesRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateSavedSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateSavedSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateSavedSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    savedSearch: (f = msg.getSavedSearch()) && proto_commons_saved_search_pb.SavedSearch.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateSavedSearchRequest}
 */
proto.elude.proto.CreateSavedSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateSavedSearchRequest;
  return proto.elude.proto.CreateSavedSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateSavedSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateSavedSearchRequest}
 */
proto.elude.proto.CreateSavedSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_saved_search_pb.SavedSearch;
      reader.readMessage(value,proto_commons_saved_search_pb.SavedSearch.deserializeBinaryFromReader);
      msg.setSavedSearch(value);
      break;
    case 3:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateSavedSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateSavedSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateSavedSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSavedSearch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_saved_search_pb.SavedSearch.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
*/
proto.elude.proto.CreateSavedSearchRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SavedSearch saved_search = 2;
 * @return {?proto.elude.proto.SavedSearch}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.getSavedSearch = function() {
  return /** @type{?proto.elude.proto.SavedSearch} */ (
    jspb.Message.getWrapperField(this, proto_commons_saved_search_pb.SavedSearch, 2));
};


/**
 * @param {?proto.elude.proto.SavedSearch|undefined} value
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
*/
proto.elude.proto.CreateSavedSearchRequest.prototype.setSavedSearch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.clearSavedSearch = function() {
  return this.setSavedSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.hasSavedSearch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SessionInfo session_info = 3;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 3));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
*/
proto.elude.proto.CreateSavedSearchRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
*/
proto.elude.proto.CreateSavedSearchRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateSavedSearchRequest} returns this
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateSavedSearchRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.DeleteSavedSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.DeleteSavedSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteSavedSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto_commons_options_pb.Options.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.DeleteSavedSearchRequest}
 */
proto.elude.proto.DeleteSavedSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.DeleteSavedSearchRequest;
  return proto.elude.proto.DeleteSavedSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.DeleteSavedSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.DeleteSavedSearchRequest}
 */
proto.elude.proto.DeleteSavedSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 4:
      var value = new proto_commons_options_pb.Options;
      reader.readMessage(value,proto_commons_options_pb.Options.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.DeleteSavedSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.DeleteSavedSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.DeleteSavedSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_options_pb.Options.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
*/
proto.elude.proto.DeleteSavedSearchRequest.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SessionInfo session_info = 3;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 3));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
*/
proto.elude.proto.DeleteSavedSearchRequest.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Options options = 4;
 * @return {?proto.elude.proto.Options}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.getOptions = function() {
  return /** @type{?proto.elude.proto.Options} */ (
    jspb.Message.getWrapperField(this, proto_commons_options_pb.Options, 4));
};


/**
 * @param {?proto.elude.proto.Options|undefined} value
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
*/
proto.elude.proto.DeleteSavedSearchRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.DeleteSavedSearchRequest} returns this
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.DeleteSavedSearchRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.elude.proto);
