<template>
  <button class="back-button" :data-dark="dark" @click="clickListener">
    <icon icon="ArrowBackCircle" :color="dark ? 'dark' : 'white'" />
  </button>
</template>

<script>
export default {
  name: 'XBackButton',
  props: {
    dark: Boolean,
  },
  computed: {
    clickListener() {
      return () =>
        this.$listeners?.click ? this.$emit('click') : this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.back-button {
  border-radius: var(--border-radius-rounded);
}
.back-button[data-dark='true']:hover {
  background: var(--color-transparent-grey);
}
.back-button[data-dark='false']:hover {
  background: var(--color-transparent-white);
}
</style>
