// source: proto/models/promotion_code.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
var proto_models_user_pb = require('../../proto/models/user_pb.js');
goog.object.extend(proto, proto_models_user_pb);
goog.exportSymbol('proto.elude.proto.PromotionCode', null, global);
goog.exportSymbol('proto.elude.proto.PromotionCodeRedemption', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PromotionCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.PromotionCode.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.PromotionCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PromotionCode.displayName = 'proto.elude.proto.PromotionCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PromotionCodeRedemption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PromotionCodeRedemption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PromotionCodeRedemption.displayName = 'proto.elude.proto.PromotionCodeRedemption';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.PromotionCode.repeatedFields_ = [4,13,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PromotionCode.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PromotionCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PromotionCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxRedemptions: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expiresAt: (f = msg.getExpiresAt()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timesRedeemed: jspb.Message.getFieldWithDefault(msg, 8, 0),
    percentOff: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    amountOff: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 11, ""),
    promotionCodeRedemptionsList: jspb.Message.toObjectList(msg.getPromotionCodeRedemptionsList(),
    proto.elude.proto.PromotionCodeRedemption.toObject, includeInstance),
    startAt: (f = msg.getStartAt()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startAtDisplayValue: jspb.Message.getFieldWithDefault(msg, 15, ""),
    expiresAtDisplayValue: jspb.Message.getFieldWithDefault(msg, 16, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_models_user_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PromotionCode}
 */
proto.elude.proto.PromotionCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PromotionCode;
  return proto.elude.proto.PromotionCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PromotionCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PromotionCode}
 */
proto.elude.proto.PromotionCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserIds(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxRedemptions(value);
      break;
    case 7:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimesRedeemed(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentOff(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmountOff(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 13:
      var value = new proto.elude.proto.PromotionCodeRedemption;
      reader.readMessage(value,proto.elude.proto.PromotionCodeRedemption.deserializeBinaryFromReader);
      msg.addPromotionCodeRedemptions(value);
      break;
    case 14:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartAtDisplayValue(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiresAtDisplayValue(value);
      break;
    case 17:
      var value = new proto_models_user_pb.User;
      reader.readMessage(value,proto_models_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PromotionCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PromotionCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PromotionCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxRedemptions();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimesRedeemed();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPercentOff();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getAmountOff();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPromotionCodeRedemptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.elude.proto.PromotionCodeRedemption.serializeBinaryToWriter
    );
  }
  f = message.getStartAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartAtDisplayValue();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExpiresAtDisplayValue();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto_models_user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.PromotionCode.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.elude.proto.PromotionCode.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.elude.proto.PromotionCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 user_ids = 4;
 * @return {!Array<number>}
 */
proto.elude.proto.PromotionCode.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.elude.proto.PromotionCode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 max_redemptions = 6;
 * @return {number}
 */
proto.elude.proto.PromotionCode.prototype.getMaxRedemptions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setMaxRedemptions = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Timestamp expires_at = 7;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.PromotionCode.prototype.getExpiresAt = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.PromotionCode} returns this
*/
proto.elude.proto.PromotionCode.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCode.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 times_redeemed = 8;
 * @return {number}
 */
proto.elude.proto.PromotionCode.prototype.getTimesRedeemed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setTimesRedeemed = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional float percent_off = 9;
 * @return {number}
 */
proto.elude.proto.PromotionCode.prototype.getPercentOff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setPercentOff = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float amount_off = 10;
 * @return {number}
 */
proto.elude.proto.PromotionCode.prototype.getAmountOff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setAmountOff = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string currency = 11;
 * @return {string}
 */
proto.elude.proto.PromotionCode.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated PromotionCodeRedemption promotion_code_redemptions = 13;
 * @return {!Array<!proto.elude.proto.PromotionCodeRedemption>}
 */
proto.elude.proto.PromotionCode.prototype.getPromotionCodeRedemptionsList = function() {
  return /** @type{!Array<!proto.elude.proto.PromotionCodeRedemption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.PromotionCodeRedemption, 13));
};


/**
 * @param {!Array<!proto.elude.proto.PromotionCodeRedemption>} value
 * @return {!proto.elude.proto.PromotionCode} returns this
*/
proto.elude.proto.PromotionCode.prototype.setPromotionCodeRedemptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.elude.proto.PromotionCodeRedemption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PromotionCodeRedemption}
 */
proto.elude.proto.PromotionCode.prototype.addPromotionCodeRedemptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.elude.proto.PromotionCodeRedemption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.clearPromotionCodeRedemptionsList = function() {
  return this.setPromotionCodeRedemptionsList([]);
};


/**
 * optional Timestamp start_at = 14;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.PromotionCode.prototype.getStartAt = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.PromotionCode} returns this
*/
proto.elude.proto.PromotionCode.prototype.setStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.clearStartAt = function() {
  return this.setStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCode.prototype.hasStartAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string start_at_display_value = 15;
 * @return {string}
 */
proto.elude.proto.PromotionCode.prototype.getStartAtDisplayValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setStartAtDisplayValue = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string expires_at_display_value = 16;
 * @return {string}
 */
proto.elude.proto.PromotionCode.prototype.getExpiresAtDisplayValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.setExpiresAtDisplayValue = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated User users = 17;
 * @return {!Array<!proto.elude.proto.User>}
 */
proto.elude.proto.PromotionCode.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.elude.proto.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_user_pb.User, 17));
};


/**
 * @param {!Array<!proto.elude.proto.User>} value
 * @return {!proto.elude.proto.PromotionCode} returns this
*/
proto.elude.proto.PromotionCode.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.elude.proto.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.User}
 */
proto.elude.proto.PromotionCode.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.elude.proto.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.PromotionCode} returns this
 */
proto.elude.proto.PromotionCode.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PromotionCodeRedemption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PromotionCodeRedemption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCodeRedemption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    promotionCodeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    promotionCode: (f = msg.getPromotionCode()) && proto.elude.proto.PromotionCode.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto_models_user_pb.User.toObject(includeInstance, f),
    timesRedeemedByUser: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PromotionCodeRedemption}
 */
proto.elude.proto.PromotionCodeRedemption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PromotionCodeRedemption;
  return proto.elude.proto.PromotionCodeRedemption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PromotionCodeRedemption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PromotionCodeRedemption}
 */
proto.elude.proto.PromotionCodeRedemption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPromotionCodeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.elude.proto.PromotionCode;
      reader.readMessage(value,proto.elude.proto.PromotionCode.deserializeBinaryFromReader);
      msg.setPromotionCode(value);
      break;
    case 5:
      var value = new proto_models_user_pb.User;
      reader.readMessage(value,proto_models_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimesRedeemedByUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PromotionCodeRedemption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PromotionCodeRedemption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCodeRedemption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPromotionCodeId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPromotionCode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.elude.proto.PromotionCode.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_models_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getTimesRedeemedByUser();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
 */
proto.elude.proto.PromotionCodeRedemption.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 promotion_code_id = 2;
 * @return {number}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.getPromotionCodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
 */
proto.elude.proto.PromotionCodeRedemption.prototype.setPromotionCodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
 */
proto.elude.proto.PromotionCodeRedemption.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional PromotionCode promotion_code = 4;
 * @return {?proto.elude.proto.PromotionCode}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.getPromotionCode = function() {
  return /** @type{?proto.elude.proto.PromotionCode} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.PromotionCode, 4));
};


/**
 * @param {?proto.elude.proto.PromotionCode|undefined} value
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
*/
proto.elude.proto.PromotionCodeRedemption.prototype.setPromotionCode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
 */
proto.elude.proto.PromotionCodeRedemption.prototype.clearPromotionCode = function() {
  return this.setPromotionCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.hasPromotionCode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User user = 5;
 * @return {?proto.elude.proto.User}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.getUser = function() {
  return /** @type{?proto.elude.proto.User} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.User, 5));
};


/**
 * @param {?proto.elude.proto.User|undefined} value
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
*/
proto.elude.proto.PromotionCodeRedemption.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
 */
proto.elude.proto.PromotionCodeRedemption.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 times_redeemed_by_user = 6;
 * @return {number}
 */
proto.elude.proto.PromotionCodeRedemption.prototype.getTimesRedeemedByUser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.PromotionCodeRedemption} returns this
 */
proto.elude.proto.PromotionCodeRedemption.prototype.setTimesRedeemedByUser = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


goog.object.extend(exports, proto.elude.proto);
