export const ToastModule = () => ({
  state: {
    toasts: [],
    timeout: undefined,
    identifier: 1,
  },
  mutations: {
    pushToast: (state, { type, message }) => {
      state.toasts.push({ id: state.identifier, type, message })
      state.identifier = state.identifier + 1
    },
    clear: state => {
      state.toasts = []
      state.timeout = undefined
    },
  },
  actions: {
    success({ dispatch }, message) {
      dispatch('send', { type: 'success', message })
    },
    error({ dispatch }, message) {
      dispatch('send', { type: 'error', message })
    },
    send({ state, dispatch, commit }, { type, message }) {
      commit('pushToast', { type, message })
      if (!state.timeout) dispatch('remove')
    },
    remove({ state, commit, dispatch }) {
      if (state.timeout) clearInterval(state.timeout)
      state.timeout = setTimeout(() => {
        commit('shiftToasts')
        state.timeout = undefined
        if (state.toasts.length !== 0) dispatch('remove')
      }, 2000)
    },
  },
})
