import {
  AbandonTripRequest,
  GetAbandonTripSearchRequest,
  HotelDetailsRequest,
  ItineraryRequest,
  ReservationRequest,
  RevalidateItineraryRequest,
} from '../../../util/gen/proto/air_hotel_reservation_search/request_pb'
import {
  AbandonedStageEnum,
  BookingClassEnum,
  BudgetTierEnum,
} from '../../../util/gen/proto/commons/enum_pb'
import {
  AirRequirements,
  Budget,
  Date,
  Dates,
  GDSBackend,
  HotelRequirements,
  LocationIdentifier,
  LocationsRequirements,
  Travellers,
} from '../../../util/gen/proto/commons/flow_common_pb'
import { Options } from '../../../util/gen/proto/commons/options_pb'
import { SessionInfo } from '../../../util/gen/proto/commons/session_info_pb'
import { ValidatePromotionCodeRequest } from '../../../util/gen/proto/payment/payment_request_pb'
import { SEARCH } from '../constants/SEARCH'
import { Grpc } from './base/Grpc'

const parseYMD = dateString => {
  const YMDArray = dateString.split('-').map(x => Number(x))
  return {
    year: YMDArray[0],
    month: YMDArray[1],
    day: YMDArray[2],
  }
}

const grpcOptions = new Options().setTimeoutMs(500000).setMaxSizeBytes(5000)
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const createDate = date => {
  const { year, month, day } = parseYMD(date)
  return new Date()
    .setDay(day)
    .setMonth(month)
    .setYear(year)
    .setTimezone(timezone)
}

const createItineraryParameters = ({
  searchType,
  tier,
  budget,
  departure,
  destination,
  departureDate,
  returnDate,
  adults,
  children,
  infants,
  preferences,
}) => {
  const metadata = SEARCH.meta[searchType]

  const _dates = new Dates()
  _dates
    .setDeparture(createDate(departureDate))
    .setReturn(createDate(returnDate))

  const _travelers = new Travellers()
  _travelers.setAdults(adults).setChildren(children).setInfants(infants)

  const _locations = new LocationsRequirements()
  if (departure) {
    const [id, type] = departure.split('-')
    _locations.setDeparture(
      new LocationIdentifier().setId(+id).setLocationType(+type),
    )
  }
  if (destination) {
    const [id, type] = destination.split('-')
    _locations.setDestinationsList([
      new LocationIdentifier().setId(+id).setLocationType(+type),
    ])
  }

  const _preferences = []
  if (preferences?.length) _preferences.push(...preferences)

  const _budget = new Budget()
  _budget
    .setMinPrice(budget)
    .setMaxPrice(budget)
    .setAbsoluteMaxPrice(budget)
    .setCurrency('USD')

  const _hotel = new HotelRequirements()
  _hotel.setMinStars(1).setAmenities(true).setDisable(!metadata.hasHotels)

  const _air = new AirRequirements()
  _air
    .setBookingClass(BookingClassEnum.ECONOMY)
    .setDisable(!metadata.hasFlights)

  const _tier = tier

  return {
    dates: _dates,
    budget: _budget,
    budgetTier: _tier,
    travellers: _travelers,
    airRequirements: _air,
    hotelRequirements: _hotel,
    locationsRequirements: _locations,
    preferences: _preferences,
  }
}

export const SearchService = {
  runSearch: ({
    searchType,
    tier,
    budget,
    departure,
    destination,
    departureDate,
    returnDate,
    adults,
    children,
    infants,
    rerun,
    preferences,
  }) => {
    const params = createItineraryParameters({
      searchType,
      tier,
      budget,
      departure,
      destination,
      departureDate,
      returnDate,
      adults,
      children,
      infants,
      preferences,
    })

    return Grpc.snt.runSearch(
      new ItineraryRequest()
        .setOptions(grpcOptions)
        .setNumResults(5)
        .setDates(params.dates)
        .setBudget(params.budget)
        .setBudgetTier(BudgetTierEnum.NONE)
        .setTravellers(params.travellers)
        .setAirRequirements(params.airRequirements)
        .setHotelRequirements(params.hotelRequirements)
        .setLocationsRequirements(params.locationsRequirements)
        .setPreferenceIdsList(params.preferences)
        .setRerun(!!rerun),
    )
  },
  abandonSearch: ({
    searchType,
    tripId,
    budget,
    departure,
    destination,
    departureDate,
    returnDate,
    adults,
    children,
    infants,
    flightId,
    flightGds,
    hotelId,
    hotelGds,
    abandonedStage,
  }) => {
    const params = createItineraryParameters({
      searchType,
      budget,
      departure,
      destination,
      departureDate,
      returnDate,
      adults,
      children,
      infants,
    })

    const itineraryRequest = new ItineraryRequest()
      .setOptions(grpcOptions)
      .setNumResults(5)
      .setDates(params.dates)
      .setBudget(params.budget)
      .setBudgetTier(BudgetTierEnum.NONE)
      .setTravellers(params.travellers)
      .setAirRequirements(params.airRequirements)
      .setHotelRequirements(params.hotelRequirements)
      .setLocationsRequirements(params.locationsRequirements)
      .setBudget(new Budget())

    const abandonTripRequest = new GetAbandonTripSearchRequest()
      .setAbandonedStage(abandonedStage)
      .setSessionInfo(new SessionInfo().setSessionId(tripId))
      .setItineraryRequest(itineraryRequest)
    if (hotelId)
      abandonTripRequest
        .setHotelId(hotelId)
        .setHotelGds(new GDSBackend().setName(hotelGds))
    if (flightId)
      abandonTripRequest
        .setFlightId(flightId)
        .setFlightGds(new GDSBackend().setName(flightGds))

    return Grpc.snt.getAbandonTripSearch(abandonTripRequest)
  },
  getHotelDetails: ({ hotelId, gdsName, tripId }) =>
    Grpc.snt
      .getHotelDetails(
        new HotelDetailsRequest()
          .setHotelId(hotelId)
          .setGds(new GDSBackend().setName(gdsName))
          .setSessionInfo(new SessionInfo().setSessionId(tripId)),
      )
      .then(res => res.toObject()),
  revalidateItinerary: ({
    tripId,
    flightId,
    flightGds,
    hotelId,
    hotelGds,
    roomId,
    travelerIds,
  }) =>
    Grpc.snt
      .revalidateItinerary(
        new RevalidateItineraryRequest()
          .setOptions(grpcOptions)
          .setSessionInfo(new SessionInfo().setSessionId(tripId))
          .setFlightId(flightId)
          .setFlightGds(new GDSBackend().setName(flightGds))
          .setHotelId(hotelId)
          .setHotelGds(new GDSBackend().setName(hotelGds))
          .setRoomId(roomId)
          .setTravellerIdsList(travelerIds),
      )
      .then(res => res.toObject()),
  validatePromoCode: ({
    tripId,
    flightId,
    flightGds,
    hotelId,
    hotelGds,
    roomId,
    travelerIdsList,
    promoCode,
  }) => {
    const request = new ValidatePromotionCodeRequest()
      .setSessionInfo(new SessionInfo().setSessionId(tripId))
      .setTravellerIdsList(travelerIdsList)
      .setPromotionCode(promoCode)

    if (flightId)
      request
        .setFlightId(flightId)
        .setFlightGds(new GDSBackend().setName(flightGds))
    if (hotelId && roomId)
      request
        .setHotelId(hotelId)
        .setHotelGds(new GDSBackend().setName(hotelGds))
        .setRoomId(roomId)

    return Grpc.snt.validatePromotionCode(request).then(res => res.toObject())
  },
  createReservation: ({
    tripId,
    hotelId,
    roomId,
    flightId,
    paymentMethodId,
    travelerIds,
    hotelGds,
    flightGds,
    promoCodeId,
  }) => {
    const request = new ReservationRequest()
      .setOptions(grpcOptions)
      .setSessionInfo(new SessionInfo().setSessionId(tripId))
      .setFlightId(flightId)
      .setFlightGds(new GDSBackend().setName(flightGds))
      .setHotelId(hotelId)
      .setRoomId(roomId)
      .setHotelGds(new GDSBackend().setName(hotelGds))
      .setTravellerIdsList(travelerIds)
      .setPaymentMethodId(paymentMethodId)
    if (promoCodeId) request.setPromotionCodeId(promoCodeId)

    return Grpc.snt.streamCreateReservation(request)
  },
  abandonTrip: ({
    searchType,
    tripId,
    tier,
    budget,
    departure,
    destination,
    departureDate,
    returnDate,
    adults,
    children,
    infants,
    flightId,
    hotelId,
    roomId,
  }) => {
    const params = createItineraryParameters({
      searchType,
      tier,
      budget,
      departure,
      destination,
      departureDate,
      returnDate,
      adults,
      children,
      infants,
    })

    const mappedSearchType = {
      [SEARCH.TYPE.COMBO]: SEARCH.TYPE.COMBO,
      [SEARCH.TYPE.PACKAGE]: SEARCH.TYPE.COMBO,
      [SEARCH.TYPE.FLIGHT]: SEARCH.TYPE.FLIGHT,
      [SEARCH.TYPE.HOTEL]: SEARCH.TYPE.HOTEL,
    }

    return Grpc.snt.abandonTrip(
      new AbandonTripRequest()
        .setSearchType(mappedSearchType[searchType])
        .setAbandonedStage(AbandonedStageEnum.SUMMARY)
        .setSessionInfo(new SessionInfo().setSessionId(`${tripId}`))
        .setFlightId(flightId)
        .setHotelId(hotelId)
        .setRoomId(roomId)
        .setDates(params.dates)
        .setBudget(budget)
        .setTravellers(params.travellers)
        .setAirRequirements(params.airRequirements)
        .setHotelRequirements(params.hotelRequirements)
        .setLocationsRequirements(params.locationsRequirements),
    )
  },
}
