<template>
  <div class="toast-wrapper">
    <transition-group name="my-list" tag="div">
      <div
        v-for="toast in toasts"
        :key="toast.id"
        class="toast-element"
        :class="getClass(toast.type)"
      >
        {{ toast.message }}
      </div>
    </transition-group>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Toast',
  computed: {
    toasts() {
      return this.$store.state.ToastModule.toasts
    },
  },
  methods: {
    getClass: type => [`toast-${type}`],
  },
})
</script>

<style scoped lang="scss">
.toast-wrapper {
  position: fixed;
  width: 200px;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
}
.toast-element {
  color: var(--color-dark);
  box-shadow: var(--shadow-m);
  overflow-wrap: break-word;
  padding: 1rem 0.5rem;
  width: 100%;
  text-align: center;
  border-radius: 0.2rem;
  transition: all 0.6s ease-in-out;
  margin-bottom: 1rem;
}
.toast-success {
  border: 1px solid var(--color-success);
  background: adjust-color(get('color.success'), $lightness: 30%);
}
.toast-error {
  border: 1px solid var(--color-error);
  background: adjust-color(get('color.error'), $lightness: 30%);
}
.my-list-enter-active,
.my-list-leave-active {
  transition: all ease-in-out 0.3s;
  position: absolute;
}
.my-list-enter {
  opacity: 0;
  transform: translateY(10px);
}
.my-list-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
