<template>
  <transition name="slide" appear>
    <flex class="align-items">
      <icon
        v-if="!hideArrow"
        id="x-back-button"
        class="absolute"
        icon="ArrowBackBlack"
        button
        @click="clickListener"
      />
      <h2 class="x-header-title fs-24">
        <slot></slot>
      </h2>
    </flex>
  </transition>
</template>

<script>
export default {
  name: 'XBackHeader',
  props: {
    hideArrow: Boolean,
  },
  computed: {
    clickListener() {
      return () =>
        this.$listeners?.back ? this.$emit('back') : this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.x-header-title {
  font-family: GTSuper;
  margin-left: auto;
  margin-right: auto;
}
</style>
