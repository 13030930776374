<script>
import Flexbox from './Flexbox.mixin.vue'

export default {
  name: 'Flex',
  mixins: [Flexbox],
  props: {
    column: Boolean,
    wrap: Boolean,
  },
  computed: {
    styles() {
      const styles = {}
      styles.display = 'flex'
      if (this.column) styles.flexDirection = 'column'
      if (this.wrap) styles.flexWrap = 'wrap'
      return styles
    },
  },
}
</script>

<style scoped lang="scss"></style>
