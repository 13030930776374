const toSnakeCase = str =>
  (str ?? '')
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(el => el.toLowerCase())
    .join('_')
const toKebabCase = str =>
  (str ?? '')
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(el => el.toLowerCase())
    .join('-')
const toCamelCase = str =>
  (str ?? '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, char) => char.toUpperCase())

const toPascalCase = str => {
  if (!str) return ''
  const camel = str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, char) => char.toUpperCase())
  return camel.charAt(0).toUpperCase() + camel.slice(1)
}

String.prototype.toSnakeCase = function () {
  return toSnakeCase(this)
}
String.prototype.toKebabCase = function () {
  return toKebabCase(this)
}
String.prototype.toCamelCase = function () {
  return toCamelCase(this)
}
String.prototype.toPascalCase = function () {
  return toPascalCase(this)
}
