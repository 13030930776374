<template>
  <div
    style="
      display: grid;
      grid-template-rows: auto 1fr;
      height: 100%;
      justify-content: center;
    "
  >
    <transition name="float" appear>
      <h1 class="x-title">Elude</h1>
    </transition>
    <transition name="fade" appear>
      <x-button
        id="explore-button"
        type="main"
        class="x-explore"
        style="transition-delay: 150ms"
        @click="onExplore"
        >{{ $t('landing.explore') }}</x-button
      >
    </transition>
    <transition name="fade" appear>
      <div
        class="landing-page-buttons-grid"
        style="
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          transition-delay: 200ms;
          margin-top: 2rem;
        "
      >
        <button
          id="login-button"
          class="x-action-button button-expand"
          style="text-tranform: uppercase"
          @click="onLogin"
        >
          {{ $t('landing.login') }}
        </button>
        <div class="x-action-divider"></div>
        <button
          id="registration-button"
          class="x-action-button button-expand"
          style="text-tranform: uppercase"
          @click="onSignup"
        >
          {{ $t('landing.signup') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { appAnalytics } from '../../services/analytics/analytics.js'

export default {
  name: 'LandingPage',
  mounted() {
    appAnalytics.page({ title: 'welcome_screen_view' })
  },
  methods: {
    onExplore() {
      appAnalytics.track('welcome_screen_btn_explore')
      this.$router.push('/search')
    },
    onLogin() {
      appAnalytics.track('welcome_screen_btn_login')
      this.$router.push('/login')
    },
    onSignup() {
      appAnalytics.track('welcome_screen_btn_signup')
      this.$router.push('/signup')
    },
  },
}
</script>

<style lang="scss" scoped>
.x-title {
  font-size: 7rem;
  font-family: GTSuper;
  text-align: center;
}
.x-explore {
  margin-top: auto;
}
.x-action-button {
  border-radius: var(--border-radius-m);
  letter-spacing: 0.13em;
}
.x-action-divider {
  width: 1px;
  background: var(--color-dark);
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.x-modal-buttons {
  margin-top: 1rem;
  justify-items: center;
  & button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    color: #aaa;
    &:hover {
      color: #888;
    }
  }
}
.landing-page-buttons-grid {
  border-top: 1px solid black;
  padding-top: 2rem;
}
</style>
