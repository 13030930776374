import { SearchTypeEnum } from '../../../util/gen/proto/commons/enum_pb'
import { LocationIdentifier } from '../../../util/gen/proto/commons/flow_common_pb'
import { Pagination } from '../../../util/gen/proto/commons/pagination_pb'
import {
  GetFeaturedLocationsByCoordinatesRequest,
  GetFeaturedLocationsByLocationRequest,
  GetFeaturedLocationsWithoutDepartureLocationRequest,
  GetLocationByCoordinatesRequest,
  GetLocationRequest,
  GetLocationsRequest,
} from '../../../util/gen/proto/location/location_request_pb'
import { LOCATION } from '../constants/LOCATION'
import { Grpc } from './base/Grpc'
import { useOptions } from './base/Grpc.utils'
import { getCoordinates } from './CoordinatesService'

export const parseLocation = location => {
  const region = location.metadata?.regionName
  const country = location.metadata?.countryName
  const hasLocation =
    location.id !== undefined && location.locationType !== undefined
  const id = hasLocation ? `${location.id}-${location.locationType}` : undefined
  return {
    ...location,
    id,
    identifier: location.id,
    display: {
      name: location.displayName,
      description: location.displayDescription,
      city: location.metadata?.cityName ?? location.displayName,
      country: location.metadata?.countryName ?? location.displayDescription,
      regionCountry:
        region && country
          ? `${region}, ${country}`
          : location.displayDescription,
    },
    sublocationsList: location.sublocationsList?.map(parseLocation) ?? [],
  }
}

const createLocationRequest = searchTypeEnum => name => {
  const type = LOCATION.ENUM[searchTypeEnum]
  const isPaginated = LOCATION.PAGINATION[type]
  const locationRequest = new GetLocationsRequest()
  locationRequest.setSearchType(searchTypeEnum)
  if (isPaginated)
    locationRequest.setPagination(new Pagination().setPageSize(100))
  if (name) locationRequest.setSearch(name)
  return Grpc.snt
    .getLocations(locationRequest, useOptions({ useToasts: true }))
    .then(res => res.toObject())
    .then(res => ({
      ...res,
      locationsList: res.locationsList.map(parseLocation),
    }))
}

const parseNearestLocationResponse = res => {
  const data = res.toObject()
  return {
    ...data,
    location: parseLocation(data.location),
  }
}
const parseFeaturedLocationsResponse = res => {
  const data = res.toObject()
  return {
    ...data,
    featuredLocationsList: data.featuredLocationsList.map(featuredLocation => ({
      ...featuredLocation,
      arrivalLocation: parseLocation(featuredLocation.arrivalLocation ?? {}),
      departureLocation: parseLocation(
        featuredLocation.departureLocation ?? {},
      ),
    })),
  }
}

export const LocationService = {
  getLocations: {
    flight: createLocationRequest(SearchTypeEnum.FLIGHT_SEARCH),
    hotel: createLocationRequest(SearchTypeEnum.LOCATION_SEARCH),
    combo: createLocationRequest(SearchTypeEnum.COMBINED_SEARCH),
    airport: createLocationRequest(SearchTypeEnum.AIRPORT_SEARCH),
  },
  getNearestLocationByLocation: ({ id }) =>
    Grpc.snt
      .getNearestLocation(new GetLocationRequest().setId(id))
      .then(parseNearestLocationResponse),
  getNearestLocationByCoordinates: () =>
    getCoordinates()
      .then(coordinates =>
        Grpc.snt.getNearestLocationByCoordinates(
          new GetLocationByCoordinatesRequest().setCoordinates(coordinates),
        ),
      )
      .then(parseNearestLocationResponse),
  getFeaturedLocationsByLocation: ({ locationId }) =>
    Grpc.snt
      .getFeaturedLocationsByLocation(
        new GetFeaturedLocationsByLocationRequest().setLocation(
          new LocationIdentifier().setId(locationId),
        ),
      )
      .then(parseFeaturedLocationsResponse),
  getFeaturedLocationsByCoordinates: ({ coordinates }) =>
    Grpc.snt
      .getFeaturedLocationsByCoordinates(
        new GetFeaturedLocationsByCoordinatesRequest().setCoordinates(
          coordinates,
        ),
      )
      .then(parseFeaturedLocationsResponse),
  getFeaturedLocationsWithoutDepartureLocation: () =>
    Grpc.snt
      .getFeaturedLocationsWithoutDepartureLocation(
        new GetFeaturedLocationsWithoutDepartureLocationRequest(),
      )
      .then(parseFeaturedLocationsResponse),
}
export function displayUserLocation(location) {
  if (!location) return ''
  return location.metadata.airportName
  // return location.metadata?.cityName && location.metadata.cityIataCode
  //   ? `${location.metadata?.cityName}, ${location.metadata.cityIataCode}`
  //   : location.displayName
}
