import 'firebase/analytics'
import { Settings as LuxonSettings } from 'luxon'
import 'swiper/swiper-bundle.css'
import Vue from 'vue'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueI18n from 'vue-i18n'
import vOutsideEvents from 'vue-outside-events'
import * as VueGoogleMaps from 'vue2-google-maps'
import './main.components'
import { CSSVarsPlugin } from './other/plugins/CSSVarsPlugin'
import { FormsPlugin } from './other/plugins/FormsPlugin'
import en from './other/translations/en.json'
import './other/utils/fix-date'
import { formatPrice } from './other/utils/formatPrice'
import { toast } from './other/utils/helpers/toast'
import { utils } from './other/utils/utils'
import { Api } from './services/base/Api'

// INIT
Api.init(process.env.VUE_APP_REST_API_URL)

// PLUGINS
Vue.directive('focus', { inserted: element => element.focus() })
Vue.use(vOutsideEvents)
Vue.use(VueI18n)
Vue.use(CSSVarsPlugin)
Vue.use(FormsPlugin)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
  },
})
// COMPONENTS
Vue.component('datetime', Datetime)

// PROTOTYPE
const vue = {
  sleep: ms => new Promise(res => setTimeout(res, ms)),
  toast,
  schema: utils.schema,
  image: path => process.env.VUE_APP_BASE_URL + path,
  placeholderImage: (x = 2560, y = 1440) =>
    `https://picsum.photos/${x}${y ? '/' + y : ''}`,
  airlineImage: path =>
    path
      ? process.env.VUE_APP_BASE_URL + `/storage/img/airlines/${path}.png`
      : '',
  formatPrice,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
}
Object.entries(vue).forEach(([key, fn]) => (Vue.prototype[`$${key}`] = fn))

// CONFIG
// window.ondragstart = () => false
export const vueConfig = {
  i18n: new VueI18n({
    locale: 'en',
    messages: { en },
  }),
}

LuxonSettings.defaultLocale = 'en-US'

if (process.env.NODE_ENV !== 'test') {
  // SELF-XSS Warning
  const warningTitleCSS =
    'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;'
  const warningDescCSS = 'font-size: 18px;'
  // eslint-disable-next-line no-console
  const log = console.log
  log('%cStop!', warningTitleCSS)
  log(
    '%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a feature, it is a scam which might leave you exposed to account theft.',
    warningDescCSS,
  )
  log(
    '%cSee https://en.wikipedia.org/wiki/Self-XSS for more information.',
    warningDescCSS,
  )
}
