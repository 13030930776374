import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { secureLocalStorage } from '../other/utils/secureLocalStorage'
import { AirModule } from './modules/AirModule'
import { AuthModule } from './modules/AuthModule'
import { BookingModule } from './modules/BookingModule'
import { CityModule } from './modules/CityModule'
import { CountryModule } from './modules/CountryModule'
import { LocationModule } from './modules/LocationModule'
import { OnboardingModule } from './modules/OnboardingModule'
import { PersistModule } from './modules/PersistModule'
import { PreferenceModule } from './modules/PreferenceModule'
import { RecoveryModule } from './modules/RecoveryModule'
import { SearchForm } from './modules/SearchForm'
import { SearchReservation } from './modules/SearchReservation'
import { SearchState } from './modules/SearchState'
import { SearchStream } from './modules/SearchStream'
import { StickerModule } from './modules/StickerModule'
import { TitleModule } from './modules/TitleModule'
import { ToastModule } from './modules/ToastModule'
import { TravelerModule } from './modules/TravelerModule'
import { UserModule } from './modules/UserModule'
import { WeatherModule } from './modules/WeatherModule'
import { buildStore } from './store.factory'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  storage: secureLocalStorage,
  reducer: state => ({
    AuthModule: state.AuthModule,
    UserModule: state.UserModule,
    PersistModule: state.PersistModule,
    OnboardingModule: state.OnboardingModule,
    SearchForm: state.SearchForm,
  }),
})

export const getBuiltStore = () =>
  buildStore({
    modules: {
      AirModule,
      AuthModule,
      BookingModule,
      CityModule,
      CountryModule,
      LocationModule,
      OnboardingModule,
      PersistModule,
      PreferenceModule,
      RecoveryModule,
      SearchForm,
      SearchReservation,
      SearchState,
      SearchStream,
      StickerModule,
      TitleModule,
      ToastModule,
      TravelerModule,
      UserModule,
      WeatherModule,
    },
    mutations: {
      clearState: () => clearState(),
    },
    actions: {
      onOpen({ commit, dispatch }) {
        dispatch('AuthModule/initSession')
        commit('ToastModule/clear')
        dispatch('CountryModule/getCountries')
        dispatch('PreferenceModule/getPreferences')
      },
      onClose() {},
      onLogin({ dispatch }) {
        dispatch('UserModule/getUser')
        dispatch('UserModule/getStats')
        dispatch('TitleModule/getTitles')
        dispatch('StickerModule/getStickers')
        dispatch('TravelerModule/getTravelers')
        dispatch('LocationModule/getLocations')
      },
      onLogout() {},
    },
  })

const initStore = () =>
  new Vuex.Store({
    ...getBuiltStore(),
    plugins: [vuexLocal.plugin],
  })

export const store = (function () {
  try {
    return initStore()
  } catch (e) {
    if (e.message === 'Malformed UTF-8 data') {
      localStorage.clear()
      return initStore()
    }
  }
})()

const clearState = () =>
  store.replaceState(new Vuex.Store(getBuiltStore()).state)
