// source: proto/payment/payment_response.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_commons_status_pb = require('../../proto/commons/status_pb.js');
goog.object.extend(proto, proto_commons_status_pb);
var proto_commons_pagination_pb = require('../../proto/commons/pagination_pb.js');
goog.object.extend(proto, proto_commons_pagination_pb);
var proto_commons_auth_pb = require('../../proto/commons/auth_pb.js');
goog.object.extend(proto, proto_commons_auth_pb);
var proto_commons_session_info_pb = require('../../proto/commons/session_info_pb.js');
goog.object.extend(proto, proto_commons_session_info_pb);
var proto_commons_flow_common_pb = require('../../proto/commons/flow_common_pb.js');
goog.object.extend(proto, proto_commons_flow_common_pb);
var proto_models_user_pb = require('../../proto/models/user_pb.js');
goog.object.extend(proto, proto_models_user_pb);
var proto_models_payment_provider_pb = require('../../proto/models/payment_provider_pb.js');
goog.object.extend(proto, proto_models_payment_provider_pb);
var proto_models_promotion_code_pb = require('../../proto/models/promotion_code_pb.js');
goog.object.extend(proto, proto_models_promotion_code_pb);
goog.exportSymbol('proto.elude.proto.AddPaymentMethodResponse', null, global);
goog.exportSymbol('proto.elude.proto.CaptureChargeResponse', null, global);
goog.exportSymbol('proto.elude.proto.CreateChargeResponse', null, global);
goog.exportSymbol('proto.elude.proto.MakePaymentResponse', null, global);
goog.exportSymbol('proto.elude.proto.PaymentMethodResponse', null, global);
goog.exportSymbol('proto.elude.proto.PaymentMethodsResponse', null, global);
goog.exportSymbol('proto.elude.proto.PaymentProviderResponse', null, global);
goog.exportSymbol('proto.elude.proto.PaymentProvidersResponse', null, global);
goog.exportSymbol('proto.elude.proto.PromotionCodeResponse', null, global);
goog.exportSymbol('proto.elude.proto.PromotionCodesResponse', null, global);
goog.exportSymbol('proto.elude.proto.RefundChargeResponse', null, global);
goog.exportSymbol('proto.elude.proto.ValidatePromotionCodeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.MakePaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.MakePaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.MakePaymentResponse.displayName = 'proto.elude.proto.MakePaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.AddPaymentMethodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.AddPaymentMethodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.AddPaymentMethodResponse.displayName = 'proto.elude.proto.AddPaymentMethodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CreateChargeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CreateChargeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CreateChargeResponse.displayName = 'proto.elude.proto.CreateChargeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.CaptureChargeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.CaptureChargeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.CaptureChargeResponse.displayName = 'proto.elude.proto.CaptureChargeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.RefundChargeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.RefundChargeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.RefundChargeResponse.displayName = 'proto.elude.proto.RefundChargeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PaymentMethodsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.PaymentMethodsResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.PaymentMethodsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PaymentMethodsResponse.displayName = 'proto.elude.proto.PaymentMethodsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PaymentMethodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PaymentMethodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PaymentMethodResponse.displayName = 'proto.elude.proto.PaymentMethodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PaymentProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.PaymentProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.PaymentProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PaymentProvidersResponse.displayName = 'proto.elude.proto.PaymentProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PaymentProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PaymentProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PaymentProviderResponse.displayName = 'proto.elude.proto.PaymentProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PromotionCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.PromotionCodesResponse.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.PromotionCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PromotionCodesResponse.displayName = 'proto.elude.proto.PromotionCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.PromotionCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.PromotionCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.PromotionCodeResponse.displayName = 'proto.elude.proto.PromotionCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.ValidatePromotionCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.ValidatePromotionCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.ValidatePromotionCodeResponse.displayName = 'proto.elude.proto.ValidatePromotionCodeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.MakePaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.MakePaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.MakePaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.MakePaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.MakePaymentResponse}
 */
proto.elude.proto.MakePaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.MakePaymentResponse;
  return proto.elude.proto.MakePaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.MakePaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.MakePaymentResponse}
 */
proto.elude.proto.MakePaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.MakePaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.MakePaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.MakePaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.MakePaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.MakePaymentResponse.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.MakePaymentResponse} returns this
*/
proto.elude.proto.MakePaymentResponse.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.MakePaymentResponse} returns this
 */
proto.elude.proto.MakePaymentResponse.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.MakePaymentResponse.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.MakePaymentResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.MakePaymentResponse} returns this
*/
proto.elude.proto.MakePaymentResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.MakePaymentResponse} returns this
 */
proto.elude.proto.MakePaymentResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.MakePaymentResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.MakePaymentResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.MakePaymentResponse} returns this
*/
proto.elude.proto.MakePaymentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.MakePaymentResponse} returns this
 */
proto.elude.proto.MakePaymentResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.MakePaymentResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.AddPaymentMethodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.AddPaymentMethodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AddPaymentMethodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto_models_user_pb.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.AddPaymentMethodResponse}
 */
proto.elude.proto.AddPaymentMethodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.AddPaymentMethodResponse;
  return proto.elude.proto.AddPaymentMethodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.AddPaymentMethodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.AddPaymentMethodResponse}
 */
proto.elude.proto.AddPaymentMethodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_models_user_pb.PaymentMethod;
      reader.readMessage(value,proto_models_user_pb.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.AddPaymentMethodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.AddPaymentMethodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.AddPaymentMethodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_user_pb.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
*/
proto.elude.proto.AddPaymentMethodResponse.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
*/
proto.elude.proto.AddPaymentMethodResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
*/
proto.elude.proto.AddPaymentMethodResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PaymentMethod payment_method = 4;
 * @return {?proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.getPaymentMethod = function() {
  return /** @type{?proto.elude.proto.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.PaymentMethod, 4));
};


/**
 * @param {?proto.elude.proto.PaymentMethod|undefined} value
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
*/
proto.elude.proto.AddPaymentMethodResponse.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.AddPaymentMethodResponse} returns this
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.AddPaymentMethodResponse.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CreateChargeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CreateChargeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CreateChargeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateChargeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    chargeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    provider: (f = msg.getProvider()) && proto_commons_flow_common_pb.GDSPaymentProvider.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto_models_user_pb.PaymentMethod.toObject(includeInstance, f),
    discountedTotalAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    promotionCode: (f = msg.getPromotionCode()) && proto_models_promotion_code_pb.PromotionCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CreateChargeResponse}
 */
proto.elude.proto.CreateChargeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CreateChargeResponse;
  return proto.elude.proto.CreateChargeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CreateChargeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CreateChargeResponse}
 */
proto.elude.proto.CreateChargeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChargeId(value);
      break;
    case 5:
      var value = new proto_commons_flow_common_pb.GDSPaymentProvider;
      reader.readMessage(value,proto_commons_flow_common_pb.GDSPaymentProvider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    case 6:
      var value = new proto_models_user_pb.PaymentMethod;
      reader.readMessage(value,proto_models_user_pb.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountedTotalAmount(value);
      break;
    case 8:
      var value = new proto_models_promotion_code_pb.PromotionCode;
      reader.readMessage(value,proto_models_promotion_code_pb.PromotionCode.deserializeBinaryFromReader);
      msg.setPromotionCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CreateChargeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CreateChargeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CreateChargeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CreateChargeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getChargeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_commons_flow_common_pb.GDSPaymentProvider.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_models_user_pb.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getDiscountedTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPromotionCode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_models_promotion_code_pb.PromotionCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CreateChargeResponse.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
*/
proto.elude.proto.CreateChargeResponse.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateChargeResponse.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CreateChargeResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
*/
proto.elude.proto.CreateChargeResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateChargeResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CreateChargeResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
*/
proto.elude.proto.CreateChargeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateChargeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string charge_id = 4;
 * @return {string}
 */
proto.elude.proto.CreateChargeResponse.prototype.getChargeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.setChargeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GDSPaymentProvider provider = 5;
 * @return {?proto.elude.proto.GDSPaymentProvider}
 */
proto.elude.proto.CreateChargeResponse.prototype.getProvider = function() {
  return /** @type{?proto.elude.proto.GDSPaymentProvider} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.GDSPaymentProvider, 5));
};


/**
 * @param {?proto.elude.proto.GDSPaymentProvider|undefined} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
*/
proto.elude.proto.CreateChargeResponse.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateChargeResponse.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PaymentMethod payment_method = 6;
 * @return {?proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.CreateChargeResponse.prototype.getPaymentMethod = function() {
  return /** @type{?proto.elude.proto.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.PaymentMethod, 6));
};


/**
 * @param {?proto.elude.proto.PaymentMethod|undefined} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
*/
proto.elude.proto.CreateChargeResponse.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateChargeResponse.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 discounted_total_amount = 7;
 * @return {number}
 */
proto.elude.proto.CreateChargeResponse.prototype.getDiscountedTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.setDiscountedTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional PromotionCode promotion_code = 8;
 * @return {?proto.elude.proto.PromotionCode}
 */
proto.elude.proto.CreateChargeResponse.prototype.getPromotionCode = function() {
  return /** @type{?proto.elude.proto.PromotionCode} */ (
    jspb.Message.getWrapperField(this, proto_models_promotion_code_pb.PromotionCode, 8));
};


/**
 * @param {?proto.elude.proto.PromotionCode|undefined} value
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
*/
proto.elude.proto.CreateChargeResponse.prototype.setPromotionCode = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CreateChargeResponse} returns this
 */
proto.elude.proto.CreateChargeResponse.prototype.clearPromotionCode = function() {
  return this.setPromotionCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CreateChargeResponse.prototype.hasPromotionCode = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.CaptureChargeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.CaptureChargeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.CaptureChargeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CaptureChargeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    chargeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    promotionCode: (f = msg.getPromotionCode()) && proto_models_promotion_code_pb.PromotionCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.CaptureChargeResponse}
 */
proto.elude.proto.CaptureChargeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.CaptureChargeResponse;
  return proto.elude.proto.CaptureChargeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.CaptureChargeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.CaptureChargeResponse}
 */
proto.elude.proto.CaptureChargeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChargeId(value);
      break;
    case 5:
      var value = new proto_models_promotion_code_pb.PromotionCode;
      reader.readMessage(value,proto_models_promotion_code_pb.PromotionCode.deserializeBinaryFromReader);
      msg.setPromotionCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.CaptureChargeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.CaptureChargeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.CaptureChargeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.CaptureChargeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getChargeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPromotionCode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_models_promotion_code_pb.PromotionCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.CaptureChargeResponse.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
*/
proto.elude.proto.CaptureChargeResponse.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
 */
proto.elude.proto.CaptureChargeResponse.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CaptureChargeResponse.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.CaptureChargeResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
*/
proto.elude.proto.CaptureChargeResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
 */
proto.elude.proto.CaptureChargeResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CaptureChargeResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.CaptureChargeResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
*/
proto.elude.proto.CaptureChargeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
 */
proto.elude.proto.CaptureChargeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CaptureChargeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string charge_id = 4;
 * @return {string}
 */
proto.elude.proto.CaptureChargeResponse.prototype.getChargeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
 */
proto.elude.proto.CaptureChargeResponse.prototype.setChargeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PromotionCode promotion_code = 5;
 * @return {?proto.elude.proto.PromotionCode}
 */
proto.elude.proto.CaptureChargeResponse.prototype.getPromotionCode = function() {
  return /** @type{?proto.elude.proto.PromotionCode} */ (
    jspb.Message.getWrapperField(this, proto_models_promotion_code_pb.PromotionCode, 5));
};


/**
 * @param {?proto.elude.proto.PromotionCode|undefined} value
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
*/
proto.elude.proto.CaptureChargeResponse.prototype.setPromotionCode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.CaptureChargeResponse} returns this
 */
proto.elude.proto.CaptureChargeResponse.prototype.clearPromotionCode = function() {
  return this.setPromotionCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.CaptureChargeResponse.prototype.hasPromotionCode = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.RefundChargeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.RefundChargeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.RefundChargeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RefundChargeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auth: (f = msg.getAuth()) && proto_commons_auth_pb.Auth.toObject(includeInstance, f),
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    refundId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.RefundChargeResponse}
 */
proto.elude.proto.RefundChargeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.RefundChargeResponse;
  return proto.elude.proto.RefundChargeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.RefundChargeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.RefundChargeResponse}
 */
proto.elude.proto.RefundChargeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_auth_pb.Auth;
      reader.readMessage(value,proto_commons_auth_pb.Auth.deserializeBinaryFromReader);
      msg.setAuth(value);
      break;
    case 2:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.RefundChargeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.RefundChargeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.RefundChargeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.RefundChargeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuth();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_auth_pb.Auth.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getRefundId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Auth auth = 1;
 * @return {?proto.elude.proto.Auth}
 */
proto.elude.proto.RefundChargeResponse.prototype.getAuth = function() {
  return /** @type{?proto.elude.proto.Auth} */ (
    jspb.Message.getWrapperField(this, proto_commons_auth_pb.Auth, 1));
};


/**
 * @param {?proto.elude.proto.Auth|undefined} value
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
*/
proto.elude.proto.RefundChargeResponse.prototype.setAuth = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
 */
proto.elude.proto.RefundChargeResponse.prototype.clearAuth = function() {
  return this.setAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RefundChargeResponse.prototype.hasAuth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionInfo session_info = 2;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.RefundChargeResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 2));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
*/
proto.elude.proto.RefundChargeResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
 */
proto.elude.proto.RefundChargeResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RefundChargeResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.RefundChargeResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
*/
proto.elude.proto.RefundChargeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
 */
proto.elude.proto.RefundChargeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.RefundChargeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string refund_id = 4;
 * @return {string}
 */
proto.elude.proto.RefundChargeResponse.prototype.getRefundId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.RefundChargeResponse} returns this
 */
proto.elude.proto.RefundChargeResponse.prototype.setRefundId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.PaymentMethodsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PaymentMethodsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PaymentMethodsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentMethodsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    paymentMethodsList: jspb.Message.toObjectList(msg.getPaymentMethodsList(),
    proto_models_user_pb.PaymentMethod.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PaymentMethodsResponse}
 */
proto.elude.proto.PaymentMethodsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PaymentMethodsResponse;
  return proto.elude.proto.PaymentMethodsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PaymentMethodsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PaymentMethodsResponse}
 */
proto.elude.proto.PaymentMethodsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_user_pb.PaymentMethod;
      reader.readMessage(value,proto_models_user_pb.PaymentMethod.deserializeBinaryFromReader);
      msg.addPaymentMethods(value);
      break;
    case 3:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PaymentMethodsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PaymentMethodsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentMethodsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_user_pb.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
*/
proto.elude.proto.PaymentMethodsResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
 */
proto.elude.proto.PaymentMethodsResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PaymentMethod payment_methods = 2;
 * @return {!Array<!proto.elude.proto.PaymentMethod>}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.getPaymentMethodsList = function() {
  return /** @type{!Array<!proto.elude.proto.PaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_user_pb.PaymentMethod, 2));
};


/**
 * @param {!Array<!proto.elude.proto.PaymentMethod>} value
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
*/
proto.elude.proto.PaymentMethodsResponse.prototype.setPaymentMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.PaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.addPaymentMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.PaymentMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
 */
proto.elude.proto.PaymentMethodsResponse.prototype.clearPaymentMethodsList = function() {
  return this.setPaymentMethodsList([]);
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
*/
proto.elude.proto.PaymentMethodsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
 */
proto.elude.proto.PaymentMethodsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
*/
proto.elude.proto.PaymentMethodsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethodsResponse} returns this
 */
proto.elude.proto.PaymentMethodsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethodsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PaymentMethodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PaymentMethodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PaymentMethodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentMethodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto_models_user_pb.PaymentMethod.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PaymentMethodResponse}
 */
proto.elude.proto.PaymentMethodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PaymentMethodResponse;
  return proto.elude.proto.PaymentMethodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PaymentMethodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PaymentMethodResponse}
 */
proto.elude.proto.PaymentMethodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_user_pb.PaymentMethod;
      reader.readMessage(value,proto_models_user_pb.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PaymentMethodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PaymentMethodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PaymentMethodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentMethodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_user_pb.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.PaymentMethodResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.PaymentMethodResponse} returns this
*/
proto.elude.proto.PaymentMethodResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethodResponse} returns this
 */
proto.elude.proto.PaymentMethodResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethodResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaymentMethod payment_method = 2;
 * @return {?proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.PaymentMethodResponse.prototype.getPaymentMethod = function() {
  return /** @type{?proto.elude.proto.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.PaymentMethod, 2));
};


/**
 * @param {?proto.elude.proto.PaymentMethod|undefined} value
 * @return {!proto.elude.proto.PaymentMethodResponse} returns this
*/
proto.elude.proto.PaymentMethodResponse.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethodResponse} returns this
 */
proto.elude.proto.PaymentMethodResponse.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethodResponse.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.PaymentMethodResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.PaymentMethodResponse} returns this
*/
proto.elude.proto.PaymentMethodResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentMethodResponse} returns this
 */
proto.elude.proto.PaymentMethodResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentMethodResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.PaymentProvidersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PaymentProvidersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PaymentProvidersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentProvidersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    paymentProvidersList: jspb.Message.toObjectList(msg.getPaymentProvidersList(),
    proto_models_payment_provider_pb.PaymentProvider.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PaymentProvidersResponse}
 */
proto.elude.proto.PaymentProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PaymentProvidersResponse;
  return proto.elude.proto.PaymentProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PaymentProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PaymentProvidersResponse}
 */
proto.elude.proto.PaymentProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_payment_provider_pb.PaymentProvider;
      reader.readMessage(value,proto_models_payment_provider_pb.PaymentProvider.deserializeBinaryFromReader);
      msg.addPaymentProviders(value);
      break;
    case 3:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PaymentProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PaymentProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaymentProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_payment_provider_pb.PaymentProvider.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
*/
proto.elude.proto.PaymentProvidersResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
 */
proto.elude.proto.PaymentProvidersResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PaymentProvider payment_providers = 2;
 * @return {!Array<!proto.elude.proto.PaymentProvider>}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.getPaymentProvidersList = function() {
  return /** @type{!Array<!proto.elude.proto.PaymentProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_payment_provider_pb.PaymentProvider, 2));
};


/**
 * @param {!Array<!proto.elude.proto.PaymentProvider>} value
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
*/
proto.elude.proto.PaymentProvidersResponse.prototype.setPaymentProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.PaymentProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PaymentProvider}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.addPaymentProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.PaymentProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
 */
proto.elude.proto.PaymentProvidersResponse.prototype.clearPaymentProvidersList = function() {
  return this.setPaymentProvidersList([]);
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
*/
proto.elude.proto.PaymentProvidersResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
 */
proto.elude.proto.PaymentProvidersResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
*/
proto.elude.proto.PaymentProvidersResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentProvidersResponse} returns this
 */
proto.elude.proto.PaymentProvidersResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentProvidersResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PaymentProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PaymentProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PaymentProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    paymentProvider: (f = msg.getPaymentProvider()) && proto_models_payment_provider_pb.PaymentProvider.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PaymentProviderResponse}
 */
proto.elude.proto.PaymentProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PaymentProviderResponse;
  return proto.elude.proto.PaymentProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PaymentProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PaymentProviderResponse}
 */
proto.elude.proto.PaymentProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_payment_provider_pb.PaymentProvider;
      reader.readMessage(value,proto_models_payment_provider_pb.PaymentProvider.deserializeBinaryFromReader);
      msg.setPaymentProvider(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PaymentProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PaymentProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PaymentProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PaymentProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPaymentProvider();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_payment_provider_pb.PaymentProvider.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.PaymentProviderResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.PaymentProviderResponse} returns this
*/
proto.elude.proto.PaymentProviderResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentProviderResponse} returns this
 */
proto.elude.proto.PaymentProviderResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentProviderResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaymentProvider payment_provider = 2;
 * @return {?proto.elude.proto.PaymentProvider}
 */
proto.elude.proto.PaymentProviderResponse.prototype.getPaymentProvider = function() {
  return /** @type{?proto.elude.proto.PaymentProvider} */ (
    jspb.Message.getWrapperField(this, proto_models_payment_provider_pb.PaymentProvider, 2));
};


/**
 * @param {?proto.elude.proto.PaymentProvider|undefined} value
 * @return {!proto.elude.proto.PaymentProviderResponse} returns this
*/
proto.elude.proto.PaymentProviderResponse.prototype.setPaymentProvider = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentProviderResponse} returns this
 */
proto.elude.proto.PaymentProviderResponse.prototype.clearPaymentProvider = function() {
  return this.setPaymentProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentProviderResponse.prototype.hasPaymentProvider = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.PaymentProviderResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.PaymentProviderResponse} returns this
*/
proto.elude.proto.PaymentProviderResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PaymentProviderResponse} returns this
 */
proto.elude.proto.PaymentProviderResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PaymentProviderResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.PromotionCodesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PromotionCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PromotionCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PromotionCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    promotionCodesList: jspb.Message.toObjectList(msg.getPromotionCodesList(),
    proto_models_promotion_code_pb.PromotionCode.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && proto_commons_pagination_pb.Pagination.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PromotionCodesResponse}
 */
proto.elude.proto.PromotionCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PromotionCodesResponse;
  return proto.elude.proto.PromotionCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PromotionCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PromotionCodesResponse}
 */
proto.elude.proto.PromotionCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_promotion_code_pb.PromotionCode;
      reader.readMessage(value,proto_models_promotion_code_pb.PromotionCode.deserializeBinaryFromReader);
      msg.addPromotionCodes(value);
      break;
    case 3:
      var value = new proto_commons_pagination_pb.Pagination;
      reader.readMessage(value,proto_commons_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PromotionCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PromotionCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PromotionCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPromotionCodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_models_promotion_code_pb.PromotionCode.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.PromotionCodesResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
*/
proto.elude.proto.PromotionCodesResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
 */
proto.elude.proto.PromotionCodesResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodesResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PromotionCode promotion_codes = 2;
 * @return {!Array<!proto.elude.proto.PromotionCode>}
 */
proto.elude.proto.PromotionCodesResponse.prototype.getPromotionCodesList = function() {
  return /** @type{!Array<!proto.elude.proto.PromotionCode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_promotion_code_pb.PromotionCode, 2));
};


/**
 * @param {!Array<!proto.elude.proto.PromotionCode>} value
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
*/
proto.elude.proto.PromotionCodesResponse.prototype.setPromotionCodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.elude.proto.PromotionCode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.PromotionCode}
 */
proto.elude.proto.PromotionCodesResponse.prototype.addPromotionCodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.elude.proto.PromotionCode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
 */
proto.elude.proto.PromotionCodesResponse.prototype.clearPromotionCodesList = function() {
  return this.setPromotionCodesList([]);
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.elude.proto.Pagination}
 */
proto.elude.proto.PromotionCodesResponse.prototype.getPagination = function() {
  return /** @type{?proto.elude.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_commons_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.elude.proto.Pagination|undefined} value
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
*/
proto.elude.proto.PromotionCodesResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
 */
proto.elude.proto.PromotionCodesResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodesResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.PromotionCodesResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 4));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
*/
proto.elude.proto.PromotionCodesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodesResponse} returns this
 */
proto.elude.proto.PromotionCodesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.PromotionCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.PromotionCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.PromotionCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    promotionCode: (f = msg.getPromotionCode()) && proto_models_promotion_code_pb.PromotionCode.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.PromotionCodeResponse}
 */
proto.elude.proto.PromotionCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.PromotionCodeResponse;
  return proto.elude.proto.PromotionCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.PromotionCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.PromotionCodeResponse}
 */
proto.elude.proto.PromotionCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_promotion_code_pb.PromotionCode;
      reader.readMessage(value,proto_models_promotion_code_pb.PromotionCode.deserializeBinaryFromReader);
      msg.setPromotionCode(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.PromotionCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.PromotionCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.PromotionCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.PromotionCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPromotionCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_promotion_code_pb.PromotionCode.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.PromotionCodeResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.PromotionCodeResponse} returns this
*/
proto.elude.proto.PromotionCodeResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodeResponse} returns this
 */
proto.elude.proto.PromotionCodeResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodeResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PromotionCode promotion_code = 2;
 * @return {?proto.elude.proto.PromotionCode}
 */
proto.elude.proto.PromotionCodeResponse.prototype.getPromotionCode = function() {
  return /** @type{?proto.elude.proto.PromotionCode} */ (
    jspb.Message.getWrapperField(this, proto_models_promotion_code_pb.PromotionCode, 2));
};


/**
 * @param {?proto.elude.proto.PromotionCode|undefined} value
 * @return {!proto.elude.proto.PromotionCodeResponse} returns this
*/
proto.elude.proto.PromotionCodeResponse.prototype.setPromotionCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodeResponse} returns this
 */
proto.elude.proto.PromotionCodeResponse.prototype.clearPromotionCode = function() {
  return this.setPromotionCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodeResponse.prototype.hasPromotionCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.PromotionCodeResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.PromotionCodeResponse} returns this
*/
proto.elude.proto.PromotionCodeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.PromotionCodeResponse} returns this
 */
proto.elude.proto.PromotionCodeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.PromotionCodeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.ValidatePromotionCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.ValidatePromotionCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ValidatePromotionCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto_commons_session_info_pb.SessionInfo.toObject(includeInstance, f),
    promotionCode: (f = msg.getPromotionCode()) && proto_models_promotion_code_pb.PromotionCode.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto_commons_status_pb.Status.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto_commons_flow_common_pb.Price.toObject(includeInstance, f),
    discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse}
 */
proto.elude.proto.ValidatePromotionCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.ValidatePromotionCodeResponse;
  return proto.elude.proto.ValidatePromotionCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.ValidatePromotionCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse}
 */
proto.elude.proto.ValidatePromotionCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_commons_session_info_pb.SessionInfo;
      reader.readMessage(value,proto_commons_session_info_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 2:
      var value = new proto_models_promotion_code_pb.PromotionCode;
      reader.readMessage(value,proto_models_promotion_code_pb.PromotionCode.deserializeBinaryFromReader);
      msg.setPromotionCode(value);
      break;
    case 3:
      var value = new proto_commons_status_pb.Status;
      reader.readMessage(value,proto_commons_status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_commons_flow_common_pb.Price;
      reader.readMessage(value,proto_commons_flow_common_pb.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.ValidatePromotionCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.ValidatePromotionCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.ValidatePromotionCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_commons_session_info_pb.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getPromotionCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_models_promotion_code_pb.PromotionCode.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_commons_status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_commons_flow_common_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional SessionInfo session_info = 1;
 * @return {?proto.elude.proto.SessionInfo}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.getSessionInfo = function() {
  return /** @type{?proto.elude.proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto_commons_session_info_pb.SessionInfo, 1));
};


/**
 * @param {?proto.elude.proto.SessionInfo|undefined} value
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
*/
proto.elude.proto.ValidatePromotionCodeResponse.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PromotionCode promotion_code = 2;
 * @return {?proto.elude.proto.PromotionCode}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.getPromotionCode = function() {
  return /** @type{?proto.elude.proto.PromotionCode} */ (
    jspb.Message.getWrapperField(this, proto_models_promotion_code_pb.PromotionCode, 2));
};


/**
 * @param {?proto.elude.proto.PromotionCode|undefined} value
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
*/
proto.elude.proto.ValidatePromotionCodeResponse.prototype.setPromotionCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.clearPromotionCode = function() {
  return this.setPromotionCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.hasPromotionCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {?proto.elude.proto.Status}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.getStatus = function() {
  return /** @type{?proto.elude.proto.Status} */ (
    jspb.Message.getWrapperField(this, proto_commons_status_pb.Status, 3));
};


/**
 * @param {?proto.elude.proto.Status|undefined} value
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
*/
proto.elude.proto.ValidatePromotionCodeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Price price = 4;
 * @return {?proto.elude.proto.Price}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.getPrice = function() {
  return /** @type{?proto.elude.proto.Price} */ (
    jspb.Message.getWrapperField(this, proto_commons_flow_common_pb.Price, 4));
};


/**
 * @param {?proto.elude.proto.Price|undefined} value
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
*/
proto.elude.proto.ValidatePromotionCodeResponse.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float discount = 5;
 * @return {number}
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.ValidatePromotionCodeResponse} returns this
 */
proto.elude.proto.ValidatePromotionCodeResponse.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


goog.object.extend(exports, proto.elude.proto);
