import { TravelerService } from '../../services/TravelerService'

const isAdult = ({ travelerType }) => travelerType === 'Adult'
const isChild = ({ travelerType }) => travelerType === 'Child'
const isInfant = ({ travelerType }) => travelerType === 'Infant'

export const TravelerModule = () => ({
  state: {
    travelers: [],
    titles: [],
  },
  getters: {
    travelers: s => ({
      adults: s.travelers.filter(traveler => isAdult(traveler)),
      children: s.travelers.filter(traveler => isChild(traveler)),
      infants: s.travelers.filter(traveler => isInfant(traveler)),
    }),
  },
  actions: {
    getTravelers: ({ commit, rootState }) =>
      TravelerService.getTravelers({
        userId: rootState.UserModule.user.id,
      }).then(res => commit('setTravelers', res.travellersList)),
    createTraveler: ({ commit, rootState }, traveler) =>
      TravelerService.createTraveler({
        ...traveler,
        userId: rootState.UserModule.user.id,
      }).then(res => {
        commit('addTravelers', res.traveller)
        return res.traveller
      }),
    updateTraveler: ({ commit }, partialTraveler) =>
      TravelerService.updateTraveler(partialTraveler).then(res => {
        commit('addTravelers', res.traveller)
        return res.traveller
      }),
    deleteTraveler: ({ commit }, { id }) =>
      TravelerService.deleteTraveler({ id }).then(() =>
        commit('removeTravelers', id),
      ),
  },
})
