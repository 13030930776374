import { Coordinates } from '../../../util/gen/proto/commons/coordinates_pb'

const coordinates = {
  data: undefined,
  get: () =>
    new Coordinates()
      .setLatitude(`${coordinates.data.latitude}`)
      .setLongitude(`${coordinates.data.longitude}`),
}

export const getCoordinates = () =>
  new Promise((resolve, reject) => {
    if (coordinates.data) resolve(coordinates.get())
    else
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          coordinates.data = coords
          resolve(coordinates.get())
        },
        error => reject(error),
      )
  })
