// source: proto/models/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_airline_pb = require('../../proto/models/airline_pb.js');
goog.object.extend(proto, proto_models_airline_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
goog.exportSymbol('proto.elude.proto.Flight', null, global);
goog.exportSymbol('proto.elude.proto.Segment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Flight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.Flight.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.Flight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Flight.displayName = 'proto.elude.proto.Flight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Segment.displayName = 'proto.elude.proto.Segment';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.Flight.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Flight.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Flight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Flight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Flight.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flightTypeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    departureCityId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    arrivalCityId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.elude.proto.Segment.toObject, includeInstance),
    departureCity: (f = msg.getDepartureCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    arrivalCity: (f = msg.getArrivalCity()) && proto_models_city_pb.City.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Flight}
 */
proto.elude.proto.Flight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Flight;
  return proto.elude.proto.Flight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Flight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Flight}
 */
proto.elude.proto.Flight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightTypeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartureCityId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArrivalCityId(value);
      break;
    case 6:
      var value = new proto.elude.proto.Segment;
      reader.readMessage(value,proto.elude.proto.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 7:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setDepartureCity(value);
      break;
    case 8:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setArrivalCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Flight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Flight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Flight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Flight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightTypeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDepartureCityId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getArrivalCityId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.elude.proto.Segment.serializeBinaryToWriter
    );
  }
  f = message.getDepartureCity();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getArrivalCity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Flight.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string duration = 2;
 * @return {string}
 */
proto.elude.proto.Flight.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 flight_type_id = 3;
 * @return {number}
 */
proto.elude.proto.Flight.prototype.getFlightTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.setFlightTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 departure_city_id = 4;
 * @return {number}
 */
proto.elude.proto.Flight.prototype.getDepartureCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.setDepartureCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 arrival_city_id = 5;
 * @return {number}
 */
proto.elude.proto.Flight.prototype.getArrivalCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.setArrivalCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Segment segments = 6;
 * @return {!Array<!proto.elude.proto.Segment>}
 */
proto.elude.proto.Flight.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.elude.proto.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.Segment, 6));
};


/**
 * @param {!Array<!proto.elude.proto.Segment>} value
 * @return {!proto.elude.proto.Flight} returns this
*/
proto.elude.proto.Flight.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.elude.proto.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Segment}
 */
proto.elude.proto.Flight.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.elude.proto.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * optional City departure_city = 7;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Flight.prototype.getDepartureCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 7));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Flight} returns this
*/
proto.elude.proto.Flight.prototype.setDepartureCity = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.clearDepartureCity = function() {
  return this.setDepartureCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Flight.prototype.hasDepartureCity = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional City arrival_city = 8;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Flight.prototype.getArrivalCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 8));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Flight} returns this
*/
proto.elude.proto.Flight.prototype.setArrivalCity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Flight} returns this
 */
proto.elude.proto.Flight.prototype.clearArrivalCity = function() {
  return this.setArrivalCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Flight.prototype.hasArrivalCity = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 4, ""),
    departureTerminal: jspb.Message.getFieldWithDefault(msg, 5, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    departureDateTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    arrivalDateTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    departureAirportId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    arrivalAirportId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    departureAirport: (f = msg.getDepartureAirport()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    arrivalAirport: (f = msg.getArrivalAirport()) && proto_models_city_pb.Airport.toObject(includeInstance, f),
    flight: (f = msg.getFlight()) && proto.elude.proto.Flight.toObject(includeInstance, f),
    airlineId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    departureDateTimeTimestamp: (f = msg.getDepartureDateTimeTimestamp()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    arrivalDateTimeTimestamp: (f = msg.getArrivalDateTimeTimestamp()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Segment}
 */
proto.elude.proto.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Segment;
  return proto.elude.proto.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Segment}
 */
proto.elude.proto.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartureTerminal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartureDateTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalDateTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartureAirportId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArrivalAirportId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightId(value);
      break;
    case 12:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setDepartureAirport(value);
      break;
    case 13:
      var value = new proto_models_city_pb.Airport;
      reader.readMessage(value,proto_models_city_pb.Airport.deserializeBinaryFromReader);
      msg.setArrivalAirport(value);
      break;
    case 14:
      var value = new proto.elude.proto.Flight;
      reader.readMessage(value,proto.elude.proto.Flight.deserializeBinaryFromReader);
      msg.setFlight(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirlineId(value);
      break;
    case 16:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 17:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDepartureDateTimeTimestamp(value);
      break;
    case 18:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setArrivalDateTimeTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDuration();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDepartureTerminal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDepartureDateTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getArrivalDateTime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDepartureAirportId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getArrivalAirportId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getFlightId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDepartureAirport();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getArrivalAirport();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_models_city_pb.Airport.serializeBinaryToWriter
    );
  }
  f = message.getFlight();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.elude.proto.Flight.serializeBinaryToWriter
    );
  }
  f = message.getAirlineId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getDepartureDateTimeTimestamp();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArrivalDateTimeTimestamp();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Segment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string duration = 2;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string gate = 3;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getGate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setGate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string arrival_terminal = 4;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string departure_terminal = 5;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getDepartureTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setDepartureTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string flight_number = 6;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string departure_date_time = 7;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getDepartureDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setDepartureDateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string arrival_date_time = 8;
 * @return {string}
 */
proto.elude.proto.Segment.prototype.getArrivalDateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setArrivalDateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 departure_airport_id = 9;
 * @return {number}
 */
proto.elude.proto.Segment.prototype.getDepartureAirportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setDepartureAirportId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 arrival_airport_id = 10;
 * @return {number}
 */
proto.elude.proto.Segment.prototype.getArrivalAirportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setArrivalAirportId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 flight_id = 11;
 * @return {number}
 */
proto.elude.proto.Segment.prototype.getFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional Airport departure_airport = 12;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.Segment.prototype.getDepartureAirport = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 12));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.Segment} returns this
*/
proto.elude.proto.Segment.prototype.setDepartureAirport = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.clearDepartureAirport = function() {
  return this.setDepartureAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Segment.prototype.hasDepartureAirport = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Airport arrival_airport = 13;
 * @return {?proto.elude.proto.Airport}
 */
proto.elude.proto.Segment.prototype.getArrivalAirport = function() {
  return /** @type{?proto.elude.proto.Airport} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.Airport, 13));
};


/**
 * @param {?proto.elude.proto.Airport|undefined} value
 * @return {!proto.elude.proto.Segment} returns this
*/
proto.elude.proto.Segment.prototype.setArrivalAirport = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.clearArrivalAirport = function() {
  return this.setArrivalAirport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Segment.prototype.hasArrivalAirport = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Flight flight = 14;
 * @return {?proto.elude.proto.Flight}
 */
proto.elude.proto.Segment.prototype.getFlight = function() {
  return /** @type{?proto.elude.proto.Flight} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Flight, 14));
};


/**
 * @param {?proto.elude.proto.Flight|undefined} value
 * @return {!proto.elude.proto.Segment} returns this
*/
proto.elude.proto.Segment.prototype.setFlight = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.clearFlight = function() {
  return this.setFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Segment.prototype.hasFlight = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int32 airline_id = 15;
 * @return {number}
 */
proto.elude.proto.Segment.prototype.getAirlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.setAirlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional Airline airline = 16;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.Segment.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 16));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.Segment} returns this
*/
proto.elude.proto.Segment.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Segment.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Timestamp departure_date_time_timestamp = 17;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Segment.prototype.getDepartureDateTimeTimestamp = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Segment} returns this
*/
proto.elude.proto.Segment.prototype.setDepartureDateTimeTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.clearDepartureDateTimeTimestamp = function() {
  return this.setDepartureDateTimeTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Segment.prototype.hasDepartureDateTimeTimestamp = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Timestamp arrival_date_time_timestamp = 18;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Segment.prototype.getArrivalDateTimeTimestamp = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Segment} returns this
*/
proto.elude.proto.Segment.prototype.setArrivalDateTimeTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Segment} returns this
 */
proto.elude.proto.Segment.prototype.clearArrivalDateTimeTimestamp = function() {
  return this.setArrivalDateTimeTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Segment.prototype.hasArrivalDateTimeTimestamp = function() {
  return jspb.Message.getField(this, 18) != null;
};


goog.object.extend(exports, proto.elude.proto);
