export const OnboardingModule = () => ({
  state: {
    finished: false,
    firstName: '',
    airportId: undefined,
  },
  actions: {
    finish({ state, dispatch }) {
      state.finished = true
      const data = {}
      if (state.firstName) data.firstName = state.firstName
      if (state.airportId) data.airportId = state.airportId
      data.onboardingData = JSON.stringify(state)
      dispatch('UserModule/updateUser', data, { root: true })
    },
  },
})
