<template>
  <button class="x-class" :class="classes" @click="clickListener">
    <icon icon="CloseButtonCrossSign" :color="crossColor" />
  </button>
</template>

<script>
export default {
  name: 'XCloseButton',
  props: {
    type: {
      type: String,
      validation: value => ['white', 'outline'].includes(value),
    },
  },
  computed: {
    classes() {
      return `x-${this.type || 'default'}`
    },
    clickListener() {
      return () =>
        this.$listeners?.click ? this.$emit('click') : this.$router.go(-1)
    },
    crossColor() {
      const color = {
        outline: '#fff',
      }[this.type]
      return color || ''
    },
  },
}
</script>

<style scoped lang="scss">
.x-class {
  border-radius: var(--border-radius-rounded);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-width: 1px;
  font-size: 1.25rem;
  height: 44px;
  width: 44px;
}
.x-default {
  border: 1px solid black;
  color: var(--color-primary);
  &:hover {
    color: darker(get('color.primary'));
    border-color: lighter(get('color.black'));
    background: darker(get('color.light'), 3%);
  }
  &:active {
    color: darker(get('color.primary'), 20%);
    border-color: lighter(get('color.black'));
    background: darker(get('color.light'), 5%);
  }
}
.x-white {
  background: white;
  color: var(--color-primary);
  &:hover {
    color: darker(get('color.primary'));
    background: darker(get('color.white'), 0.7%);
  }
  &:active {
    color: darker(get('color.primary'), 20%);
    background: darker(get('color.white'), 3%);
  }
}
.x-outline {
  color: var(--color-primary);
  border: 1px solid white;
  &:hover {
    background: #ffffff16;
  }
  &:active {
    background: #fff2;
  }
}
</style>
