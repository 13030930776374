const days = numDays => 86400 * 1000 * numDays
const now = Date.now()
const getIsoDate = timestamp => new Date(timestamp).toISOString().split('T')[0]

const MIN_DEPARTURE_OFFSET = 1
const MIN_RETURN_OFFSET = 1
const MAX_DEPARTURE_OFFSET = 365
const MAX_RETURN_OFFSET = 40
const INITIAL_DEPARTURE_OFFSET = 14
const INITIAL_RETURN_OFFSET = 4

const minDeparture = () => {
  const ms = now + days(MIN_DEPARTURE_OFFSET)
  return { ms, date: getIsoDate(ms) }
}
const maxDeparture = () => {
  const ms = now + days(MAX_DEPARTURE_OFFSET)
  return { ms, date: getIsoDate(ms) }
}
const validateDeparture = (form, value) => {
  if (!value) return
  const min = minDeparture(form, value).date
  const max = maxDeparture(form, value).date
  return value >= min && value <= max
}
const placeholderDeparture = () => {
  const ms = now + days(INITIAL_DEPARTURE_OFFSET)
  return getIsoDate(ms)
}
const initialDeparture = (form, value) => {
  if (!value) return placeholderDeparture(form)
  const min = minDeparture(form).date
  const max = maxDeparture(form).date
  if (value >= min && value <= max) return value
  return placeholderDeparture(form)
}

const minReturn = form => {
  const ms = form.departureDate
    ? Date.parse(form.departureDate) + days(MIN_RETURN_OFFSET)
    : now + days(MIN_DEPARTURE_OFFSET + MIN_RETURN_OFFSET)
  return { ms, date: getIsoDate(ms) }
}
const maxReturn = form => {
  const ms = form.departureDate
    ? Date.parse(form.departureDate) + days(MAX_RETURN_OFFSET)
    : now + days(MAX_DEPARTURE_OFFSET + MAX_RETURN_OFFSET)
  return { ms, date: getIsoDate(ms) }
}
const validateReturn = (form, value) => {
  if (!value) return
  const min = minReturn(form).date
  const max = maxReturn(form).date
  return value >= min && value <= max
}
const placeholderReturn = form => {
  const ms = form.departureDate
    ? Date.parse(form.departureDate) + days(INITIAL_RETURN_OFFSET)
    : now + days(INITIAL_DEPARTURE_OFFSET + INITIAL_RETURN_OFFSET)
  return getIsoDate(ms)
}
const initialReturn = (form, value) => {
  if (!value) return placeholderReturn(form)
  const min = minReturn(form).date
  const max = maxReturn(form).date
  if (value >= min && value <= max) return value
  return placeholderReturn(form)
}

export const dateParameters = {
  departure: {
    validator: validateDeparture,
    min: minDeparture,
    max: maxDeparture,
    getInitialValue: initialDeparture,
  },
  return: {
    validator: validateReturn,
    min: minReturn,
    max: maxReturn,
    getInitialValue: initialReturn,
  },
}
