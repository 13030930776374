import { BudgetTierEnum } from '../../../../util/gen/proto/commons/enum_pb'
import { LOCATION } from '../../constants/LOCATION'
import { SEARCH } from '../../constants/SEARCH'
import { dateParameters } from './SearchForm.dates'

const searchTypes = Object.values(SEARCH.TYPE)
const budgetTiers = Object.values(BudgetTierEnum)

export const searchParametersMap = {
  searchType: {
    searchTypes,
    validator: (form, value) => searchTypes.includes(value),
    getInitialValue: () => SEARCH.TYPE.COMBO,
    parseQueryParam: value => value,
  },
  departureDate: {
    searchTypes,
    validator: dateParameters.departure.validator,
    min: dateParameters.departure.min,
    max: dateParameters.departure.max,
    getInitialValue: dateParameters.departure.getInitialValue,
    parseQueryParam: value => value,
  },
  returnDate: {
    searchTypes,
    validator: dateParameters.return.validator,
    min: dateParameters.return.min,
    max: dateParameters.return.max,
    getInitialValue: dateParameters.return.getInitialValue,
    parseQueryParam: value => value,
  },
  adults: {
    searchTypes,
    getInitialValue: () => 1,
    validator: (form, value) => typeof value === 'number' && !!value,
    parseQueryParam: value => Number(value) || 0,
  },
  children: {
    searchTypes,
    getInitialValue: () => 0,
    validator: (form, value) => typeof value === 'number',
    // parseQueryParam: value => Number(value) || 0,
    parseQueryParam: () => 0,
  },
  infants: {
    searchTypes,
    getInitialValue: () => 0,
    validator: (form, value) => typeof value === 'number',
    // parseQueryParam: value => Number(value) || 0,
    parseQueryParam: () => 0,
  },
  departure: {
    searchTypes: [SEARCH.TYPE.PACKAGE, SEARCH.TYPE.COMBO, SEARCH.TYPE.FLIGHT],
    validator: (form, value) =>
      !!value && typeof value === 'string' && value !== form.destination,
    locationType: {
      [SEARCH.TYPE.PACKAGE]: LOCATION.TYPE.FLIGHT,
      [SEARCH.TYPE.COMBO]: LOCATION.TYPE.FLIGHT,
      [SEARCH.TYPE.FLIGHT]: LOCATION.TYPE.FLIGHT,
    },
    getInitialValuePromise: ({ dispatch }) =>
      dispatch('LocationModule/getUserLocation', undefined, {
        root: true,
      }).then(location => location.id),
    useOwnState: true,
    parseQueryParam: value => value,
  },
  departureLocation: {
    // searchTypes: [SEARCH.TYPE.PACKAGE, SEARCH.TYPE.COMBO, SEARCH.TYPE.FLIGHT],
    searchTypes: [],
    ignore: true,
    validator: () => true,
    locationType: {
      [SEARCH.TYPE.PACKAGE]: LOCATION.TYPE.FLIGHT,
      [SEARCH.TYPE.COMBO]: LOCATION.TYPE.FLIGHT,
      [SEARCH.TYPE.FLIGHT]: LOCATION.TYPE.FLIGHT,
    },
    parseQueryParam: () => undefined,
    useOwnState: true,
  },
  destination: {
    searchTypes: [SEARCH.TYPE.COMBO, SEARCH.TYPE.FLIGHT, SEARCH.TYPE.HOTEL],
    validator: (form, value) =>
      !!value && typeof value === 'string' && value !== form.departure,
    locationType: {
      [SEARCH.TYPE.COMBO]: LOCATION.TYPE.COMBO,
      [SEARCH.TYPE.HOTEL]: LOCATION.TYPE.HOTEL,
      [SEARCH.TYPE.FLIGHT]: LOCATION.TYPE.FLIGHT,
    },
    useOwnState: true,
    parseQueryParam: value => value,
  },
  destinationLocation: {
    // searchTypes: [SEARCH.TYPE.COMBO, SEARCH.TYPE.FLIGHT, SEARCH.TYPE.HOTEL],
    searchTypes: [],
    ignore: true,
    validator: () => true,
    locationType: {
      [SEARCH.TYPE.COMBO]: LOCATION.TYPE.COMBO,
      [SEARCH.TYPE.HOTEL]: LOCATION.TYPE.HOTEL,
      [SEARCH.TYPE.FLIGHT]: LOCATION.TYPE.FLIGHT,
    },
    useOwnState: true,
    parseQueryParam: () => undefined,
  },
  budget: {
    searchTypes: [SEARCH.TYPE.PACKAGE],
    getInitialValue: () => 2000,
    validator: (form, value) =>
      typeof value === 'number' && value >= 100 && value <= 15000,
    useOwnState: true,
    useSharedState: true,
    parseQueryParam: value => Number(value),
  },
  tier: {
    searchTypes: [SEARCH.TYPE.COMBO],
    getInitialValue: () => BudgetTierEnum.NONE,
    validator: (form, value) => budgetTiers.includes(value),
    useOwnState: true,
    useSharedState: true,
    parseQueryParam: value => Number(value),
  },
  preferences: {
    searchTypes: [SEARCH.TYPE.PACKAGE],
    getInitialValue: () => [],
    validator: () => true,
    useOwnState: true,
    useSharedState: true,
    parseQueryParam: value => value.map(el => Number(el)),
  },
  rerun: {
    searchTypes: [],
    getInitialValue: () => false,
    validator: (form, value) => typeof value === 'boolean',
    parseQueryParam: value => !!value,
  },
}

const searchParametersPerTypeMap = Object.fromEntries(
  searchTypes.map(searchType => [
    searchType,
    Object.fromEntries(
      Object.entries(searchParametersMap).filter(([, parameter]) =>
        parameter.searchTypes.includes(searchType),
      ),
    ),
  ]),
)

const getSafeParameter = Object.fromEntries(
  Object.entries(searchParametersMap).map(([key, parameter]) => {
    const getter = (form, value) =>
      parameter.validator(form, value)
        ? value
        : parameter.getInitialValue
        ? parameter.getInitialValue(form, value)
        : value
    return [key, getter]
  }),
)

const defaultParams = () => ({
  forceUpdate: Date.now(),
  form: {
    searchType: '',
    tier: BudgetTierEnum.SILVER,
    budget: 2000,
    departure: '',
    departureLocation: undefined,
    destination: '',
    destinationLocation: undefined,
    departureDate: '',
    returnDate: '',
    adults: 1,
    children: 0,
    infants: 0,
    preferences: [],
  },
  cache: {
    [SEARCH.TYPE.PACKAGE]: {},
    [SEARCH.TYPE.COMBO]: {},
    [SEARCH.TYPE.FLIGHT]: {},
    [SEARCH.TYPE.HOTEL]: {},
  },
})

export const SearchForm = () => ({
  state: defaultParams(),
  mutations: {
    setParams(s, formPartial) {
      const form = s.form
      const searchType = formPartial.searchType ?? form.searchType
      const cache = s.cache[searchType]
      Object.entries(formPartial).forEach(([key, value]) => {
        let newValue = value
        const { validator, getInitialValue, useOwnState } =
          searchParametersMap[key] ?? {}
        if (validator && !validator(form, value) && getInitialValue)
          newValue = getInitialValue(form, value)

        form[key] = newValue
        if (useOwnState) cache[key] = newValue
      })
      s.forceUpdate = Date.now()
    },
  },
  getters: {
    getParams: s => {
      s.forceUpdate // required to recalculate getter
      const form = s.form
      const cache = s.cache[form.searchType]
      const activeParameterMap = searchParametersPerTypeMap[form.searchType]
      const params = Object.entries(activeParameterMap).reduce(
        (parameters, [key, { useOwnState, useSharedState }]) => ({
          ...parameters,
          [key]:
            useOwnState && useSharedState
              ? cache[key] ?? form[key]
              : useOwnState
              ? cache[key]
              : form[key],
        }),
        {},
      )
      return {
        all: params,
        search: Object.fromEntries(
          Object.entries(params).filter(
            ([key]) => !activeParameterMap[key].ignore,
          ),
        ),
      }
    },
    activeParameters: (s, g) => {
      const form = g.getParams.all
      const activeParametersMap = searchParametersPerTypeMap[form.searchType]
      if (!activeParametersMap) return {}
      return Object.fromEntries(
        Object.keys(activeParametersMap).map(key => [key, form[key]]),
      )
    },
    validateParameters: () => params => {
      const searchType = getSafeParameter.searchType(params, params.searchType)
      const activeParametersMap = searchParametersPerTypeMap[searchType]
      const requiredParametersValid = Object.entries(activeParametersMap).every(
        ([key, parameter]) => parameter.validator(params, params[key]),
      )
      if (!requiredParametersValid) return false

      const otherParametersValid = Object.entries(params).every(
        ([key, value]) => {
          const activeParameter = activeParametersMap[key]
          if (activeParameter) return true
          const parameter = searchParametersMap[key]
          if (parameter) return parameter.validator(params, value)
          return true
        },
      )
      return otherParametersValid
    },
    parseQueryParameters: () => queryParams =>
      Object.fromEntries(
        Object.entries(queryParams).reduce((parameterEntries, [key, value]) => {
          const parameter = searchParametersMap[key]
          if (!parameter) parameterEntries.push([key, value])
          else if (parameter.parseQueryParam)
            parameterEntries.push([key, parameter.parseQueryParam(value)])
          return parameterEntries
        }, []),
      ),
    searchFormValid: (s, g) => g.validateParameters(g.activeParameters),
  },
  actions: {
    async resolveParameters(ctx, { params, resolveAsync }) {
      const { getters } = ctx
      const validate = getters.validateParameters
      const state = {
        params,
        changed: false,
        valid: validate(params),
        missingJustAsync: false,
      }

      if (state.valid) return state

      const searchParameters = Object.fromEntries(
        Object.entries(params).map(([key, value]) => {
          const parameter = searchParametersMap[key]
          if (!parameter) return [key, value]
          const getParameter = getSafeParameter[key]
          return [key, getParameter(params, value)]
        }),
      )
      state.params = { ...params, ...searchParameters }
      state.changed = true

      const searchType = getSafeParameter.searchType(params, params.searchType)
      const activeParametersMap = searchParametersPerTypeMap[searchType]
      const missingParametersMap = Object.entries(activeParametersMap).filter(
        ([key, parameter]) =>
          !Object.prototype.hasOwnProperty.call(params, key) &&
          !parameter.ignore,
      )

      state.missingJustAsync = missingParametersMap.every(
        ([, parameter]) => parameter.getInitialValuePromise,
      )

      if (state.missingJustAsync) {
        if (!resolveAsync) return state
        const promises = missingParametersMap.map(([, parameter]) =>
          parameter.getInitialValuePromise(ctx, state.params),
        )
        try {
          const results = await Promise.all(promises)
          const resolvedParameters = missingParametersMap.reduce(
            (resolvedParameters, [key], index) => {
              resolvedParameters[key] = results[index]
              return resolvedParameters
            },
            {},
          )
          state.params = { ...state.params, ...resolvedParameters }
          state.valid = validate(state.params)
        } catch {
          return state
        }
      }

      const leftoverParametersMap = []
      const resolvedParameters = missingParametersMap.reduce(
        (resolvedParameters, [key, parameter]) => {
          if (parameter.getInitialValue)
            resolvedParameters[key] = parameter.getInitialValue(state.params)
          else if (parameter.getInitialValuePromise)
            leftoverParametersMap.push([key, parameter])
          return resolvedParameters
        },
        {},
      )

      state.params = { ...state.params, ...resolvedParameters }
      state.missingJustAsync = leftoverParametersMap.every(
        ([, parameter]) => parameter.getInitialValuePromise,
      )
      state.valid = validate(state.params)
      return state
    },
  },
})
