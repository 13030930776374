const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)
const isPlainObject = value => {
  const getTag = value => {
    if (value != null) return toString.call(value)
    if (value === undefined) return '[object Undefined]'
    return '[object Null]'
  }
  const isObjectLike = value => typeof value === 'object' && value !== null
  if (!isObjectLike(value) || getTag(value) !== '[object Object]') return false
  if (Object.getPrototypeOf(value) === null) return true
  let proto = value
  while (Object.getPrototypeOf(proto) !== null)
    proto = Object.getPrototypeOf(proto)
  return Object.getPrototypeOf(value) === proto
}
const resolvePath = (path, state) =>
  path.split('.').reduce((obj, key) => obj[key], state)

const isPrimitive = item =>
  typeof item === 'string' ||
  typeof item === 'number' ||
  typeof item === 'boolean'

export const buildStore = (
  store = {
    plugins: {},
    modules: {},
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  },
) => {
  const modules = Object.entries(store.modules || {}).reduce(
    (a, [moduleName, module]) => ({
      ...a,
      [moduleName]: buildModule(
        typeof module === 'function' ? module() : module,
      ),
    }),
    {},
  )
  const actions = {
    ...store.actions,
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(modname => commit(`${modname}/clearState`))
    },
  }
  return { ...store, modules, actions }
}

const buildModule = (
  module = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  },
) => {
  const mutations = {}
  const getters = {}
  const state = module.state || {}
  mapHelpers(state, mutations, getters)
  return {
    namespaced: true,
    strict: process.env.NODE_ENV !== 'production',
    state,
    getters: { ...getters, ...module.getters },
    mutations: { ...mutations, ...module.mutations },
    actions: module.actions,
  }
}

const mapHelpers = (state, mutations, getters, parentKeys = []) => {
  Object.entries(state).forEach(([key, value]) => {
    const allKeys = [...parentKeys, key]
    const getPointer = s => parentKeys.reduce((obj, key) => obj[key], s)
    const getValue = s => getPointer(s)[key]
    const pascalCase = allKeys.map(x => capitalize(x)).join('')

    // setItem(item)
    mutations[`set${pascalCase}`] = (s, data) => (getPointer(s)[key] = data)
    // clearItem
    mutations[`clear${pascalCase}`] = s => {
      s[key] = value
    }
    // getItem
    getters[`get${pascalCase}`] = s => getPointer(s)[key]

    if (Array.isArray(value)) {
      // addItems
      mutations[`add${pascalCase}`] = (state, items) => {
        const _items = Array.isArray(items) ? items : [items]
        const _array = getValue(state)
        const updatedItems = _array.map(item => {
          const isPrim = isPrimitive(item)
          const updatedItem = _items.find(newItem =>
            isPrim ? item === newItem : item.id === newItem.id,
          )
          return updatedItem ? updatedItem : item
        })
        const newItems = _items
          .filter(newItem => {
            const isPrim = isPrimitive(newItem)
            return !_array.some(item =>
              isPrim ? item === newItem : item.id === newItem.id,
            )
          })
          .reduce((a, newItem) => {
            const isPrim = isPrimitive(newItem)
            const exists = a.some(item =>
              isPrim ? item === newItem : item.id === newItem.id,
            )
            if (!exists) a.push(newItem)
            return a
          }, [])
        const newArray = [...updatedItems, ...newItems]
        if (!newArray.length) return
        getPointer(state)[key] = newArray
      }
      // removeItems
      mutations[`remove${pascalCase}`] = (state, items) => {
        const _items = Array.isArray(items) ? items : [items]
        const _array = getValue(state)
        const rejected = _array.filter(
          saved =>
            !_items.some(item => item.id === saved.id || item === saved.id),
        )
        getPointer(state)[key] = rejected
      }
      // pushItems
      mutations[`push${pascalCase}`] = (state, items) => {
        const _items = Array.isArray(items) ? items : [items]
        getValue(state).push(..._items)
      }
      mutations[`pop${pascalCase}`] = state => getValue(state).pop()
      mutations[`shift${pascalCase}`] = state => getValue(state).shift()
    } else if (isPlainObject(value))
      mapHelpers(value, mutations, getters, allKeys)
  })

  // clearState
  if (parentKeys.length === 0) {
    const initialState = Object.entries(state).map(([k, v]) => [
      k,
      Array.isArray(v) ? [...v] : v,
    ])
    mutations['clearState'] = s =>
      initialState.forEach(([key, value]) => {
        s[key] = value
      })
  }

  // find
  getters['find'] = s => path => matcher => {
    const array = resolvePath(path, s)
    const matchFn = {
      object: item => Object.entries(matcher).every(([k, v]) => item[k] === v),
      function: matcher,
    }[typeof matcher]

    return array.find(item => matchFn(item))
  }

  // aoe
  mutations['aoe'] = (state, data) => {
    const { path, key, payload } = data
    let _key = key ?? 'id'
    if (!path || !payload)
      throw Error(
        `Invalid payload for aoe; should include 'path', 'key', 'payload'`,
      )
    const _array = resolvePath(path, state)

    if (Array.isArray(payload)) {
      const updatedItems = _array.map(item => {
        const updatedItem = payload.find(
          newItem => item[_key] === newItem[_key],
        )
        return updatedItem ? updatedItem : item
      })
      const newItems = payload.filter(
        newItem => !_array.some(item => item[_key] === newItem[_key]),
      )
      _array.length = 0
      _array.push(...updatedItems, ...newItems)
    } else {
      const index = _array.findIndex(item => item[_key] === payload[_key])
      index >= 0 ? _array.splice(index, 1, payload) : _array.push(payload)
    }
  }
}
