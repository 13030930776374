import { SEARCH, sortByPrice } from '../../constants/SEARCH'
import { appAnalytics } from '../../services/analytics/analytics'
import { parseLocation } from '../../services/LocationService'
import { SearchService } from '../../services/SearchService'
import { parseFlights, parseHotels } from './SearchStream.parser'
const root = { root: true }

const defaultState = () => ({
  validTripIds: [],
  validFlightIds: [],
  validHotelIds: [],
  trips: [],
  flights: [],
  hotels: [],
  isRunning: false,
})

export const SearchStream = () => ({
  state: defaultState(),
  mutations: {
    clearState(s) {
      const defaults = defaultState()
      Object.keys(s).forEach(key => (s[key] = defaults[key]))
    },
  },
  getters: {
    trips: (s, g) => {
      const validFlights = g.flights.filter(({ id }) =>
        s.validFlightIds.includes(id),
      )
      const validHotels = g.hotels.filter(({ id }) =>
        s.validHotelIds.includes(id),
      )
      return s.validTripIds.map(id => {
        const trip = s.trips.find(el => el.tripId === id)
        const flights = validFlights.filter(el => el.tripId === id)
        const hotels = validHotels.filter(el => el.tripId === id)

        return { ...trip, flights, hotels }
      })
    },
    flights: s => sortByPrice(s.flights),
    hotels: s => sortByPrice(s.hotels),
  },
  actions: {
    startSearch(
      { dispatch, commit, state },
      { form, onData, onSuccess, onFail, onError, onCancel, onEnd, append },
    ) {
      const { searchType, budget } = form
      appAnalytics.track('search_start', {
        search_type: form.searchType,
        departure_date: form.departureDate,
        return_date: form.returnDate,
        adults: form.adults,
        children: form.children,
        infants: form.infants,
        departure_location: form.departure,
        destination_location: form.destination,
        budget: form.budget,
        budget_tier: form.tier,
        rerun: form.rerun,
      })

      if (!append) {
        commit('clearState')
        commit('SearchState/clearState', undefined, root)
        commit('SearchState/setSearchParams', form, root)
      }

      const status = {
        isSuccess: false,
        promises: [],
        success() {
          if (status.isSuccess) return
          status.isSuccess = true
          onSuccess?.()
          dispatch('onSuccess')
        },
        resolve: async () => {
          if (status.isSuccess) return
          await Promise.allSettled(status.promises)
          if (status.isSuccess) return
          onFail?.()
          dispatch('onFail')
        },
      }

      commit('setIsRunning', true)
      Stream.start(form, {
        onData: data => {
          onData?.(data)
          status.promises.push(
            dispatch('onData', {
              data,
              searchType,
              budget,
            })
              .then(status.success)
              .catch(err => err && console.warn(err)),
          )
        },
        onError: error => {
          status.resolve()
          onError?.()
          dispatch('onError', error)
        },
        onCancel: () => {
          commit('setIsRunning', false)
          appAnalytics.track('search_cancel')
          onCancel?.()
          dispatch('onCancel')
        },
        onEnd: () => {
          status.resolve()
          commit('setIsRunning', false)
          appAnalytics.track('search_results_count', {
            count: state.validTripIds,
          })
          onEnd?.()
          dispatch('onEnd')
        },
      })
    },
    stopSearch: () => Stream.stop(),
    onSuccess: () => {},
    onError: () => {},
    onCancel: () => {},
    onEnd: () => {},
    onFail: () => {},
    async onData({ state, commit, dispatch }, { data, searchType, budget }) {
      if (data.status.statusCode !== 0) return Promise.reject()

      const metadata = SEARCH.meta[searchType]

      const {
        sessionInfo: { sessionId: tripId },
        flightOptionsList,
        hotelsList,
        departureLocation,
        arrivalLocation,
      } = data

      let promises = []

      // trip handler
      const savedTrip = state.trips.find(el => el.tripId === tripId)
      const trip = savedTrip ?? {
        id: tripId,
        tripId,
        departureLocation: parseLocation(departureLocation),
        arrivalLocation: parseLocation(arrivalLocation),
      }
      if (!savedTrip) state.trips.push(trip)

      // flight handler
      if (metadata.hasFlights) {
        const parsedFlights = parseFlights(flightOptionsList, tripId)
        commit('addFlights', parsedFlights)
      }

      // hotel handler
      if (metadata.hasHotels) {
        const parsedHotels = parseHotels(hotelsList, trip)
        commit('addHotels', parsedHotels)
      }

      // post data processors
      await Promise.allSettled(promises)
      return dispatch('processPackageData', {
        searchType,
        budget,
      })
    },
    processPackageData(
      { state, dispatch, getters, commit },
      { searchType, budget },
    ) {
      const { trips } = state
      const { flights, hotels } = getters
      const { validatePackage } = SEARCH.meta[searchType]

      const { tripIds, flightIds, hotelIds } = validatePackage({
        flights,
        hotels,
        trips,
        budget,
      })

      commit('addValidTripIds', tripIds)
      commit('addValidFlightIds', flightIds)
      commit('addValidHotelIds', hotelIds)

      dispatch('SearchState/initTrips', getters.trips, root)

      return tripIds.length ? Promise.resolve() : Promise.reject()
    },
  },
})

const Stream = {
  instance: undefined,
  onCancel: undefined,
  reset: () => (Stream.instance = Stream.onCancel = undefined),
  start(form, { onData, onError, onCancel, onEnd }) {
    Stream.onCancel = onCancel
    const stream = (Stream.instance = SearchService.runSearch(form))

    stream.on('data', data => onData?.(data.toObject()))
    stream.on('error', err => onError?.(err) && Stream.reset())
    stream.on('end', () => onEnd?.() && Stream.reset())
  },
  stop() {
    Stream.onCancel?.()
    Stream.instance?.cancel?.()
    Stream.reset()
  },
}
