import {
  PasswordRecoverRequest,
  PasswordResetRequest,
  VerifyRecoveryTokenRequest,
} from '../../../util/gen/proto/auth/auth_request_pb'
import { Grpc } from './base/Grpc'
import { useOptions } from './base/Grpc.utils'

export const RecoveryService = {
  recoverPassword: ({ email }) =>
    Grpc.snt
      .passwordRecover(
        new PasswordRecoverRequest().setEmail(email),
        useOptions({ useToasts: false }),
      )
      .then(res => res.toObject()),
  resetPassword: ({ email, password, token }) =>
    Grpc.snt
      .passwordReset(
        new PasswordResetRequest()
          .setEmail(email)
          .setPassword(password)
          .setRecoveryToken(token),
        useOptions({ useToasts: false }),
      )
      .then(res => res.toObject()),
  validateToken: ({ token, email }) =>
    Grpc.snt.verifyRecoveryToken(
      new VerifyRecoveryTokenRequest().setRecoveryToken(token).setEmail(email),
      useOptions({ useToasts: false }),
    ),
}
