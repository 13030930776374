import { TEMPERATURE } from '../../constants/WEATHER'
import { router } from '../../router/router'

export const PersistModule = () => ({
  state: {
    wasShownSearchInfo: false,
    isAddingSearchPayment: false,
    isSearchLogin: false,
    temperatureUnit: TEMPERATURE.FAHRENHEIT,
  },
  actions: {
    visitorSearchGuard({ commit, rootState }, action) {
      const isLoggedIn = rootState.AuthModule.isLoggedIn
      if (!isLoggedIn) {
        commit('setIsSearchLogin', true)
        router.push('/login')
      } else action()
    },
    visitorSearchGuardAfterLogin({ commit, state }) {
      if (state.isSearchLogin) {
        commit('setIsSearchLogin', false)
        router.push('/search/summary')
        return true
      }
    },
  },
})
