import LandingPage from '@/views/landing-page/LandingPage.vue'
import POS from '@/views/settings/Privacy.vue'
import TOS from '@/views/settings/TOS.vue'
import { onboardingRoutes } from './routes.onboarding'
import { searchRoutes } from './routes.search'

/**
 * VueRouter routes.
 * @type {import('vue-router').RouteRecord[]}
 */
export const routes = [
  {
    path: '/',
    component: LandingPage,
    meta: { title: 'Elude' },
  },
  onboardingRoutes,
  searchRoutes,
  {
    path: '/login',
    component: () => import('@/views/account/Login.vue'),
    meta: {
      title: 'Login',
      visitorOnly: true,
    },
  },
  {
    path: '/logout',
    component: () => import('@/views/account/Logout.vue'),
    meta: {
      title: 'Logout',
      userOnly: true,
    },
  },
  {
    path: '/signup',
    component: () => import('@/views/account/Signup.vue'),
    meta: {
      title: 'Signup',
      visitorOnly: true,
    },
  },
  {
    path: '/verify-email',
    component: () => import('@/views/account/VerifyEmail.vue'),
    meta: {
      title: 'Verify Email',
      visitorOnly: true,
    },
    beforeEnter(to, from, next) {
      const token = to.query.token
      if (token) return next()
      return next('/')
    },
  },
  {
    path: '/account-recovery',
    component: () => import('@/views/account-recovery/AccountRecovery.vue'),
    meta: {
      title: 'Recover Account',
      visitorOnly: true,
    },
  },
  {
    path: '/account-recovery/token',
    component: () =>
      import('@/views/account-recovery/AccountRecoveryToken.vue'),
    meta: {
      title: 'Recover Account',
      visitorOnly: true,
    },
  },
  {
    path: '/account-recovery/reset',
    component: () =>
      import('@/views/account-recovery/AccountRecoveryReset.vue'),
    meta: {
      title: 'Recover Account',
      visitorOnly: true,
    },
  },
  {
    path: '/home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      showNav: true,
      title: 'Home',
    },
  },
  {
    path: '/bookings',
    component: () => import('@/views/bookings/Bookings.vue'),
    meta: {
      showNav: true,
      userOnly: true,
      title: 'Bookings',
    },
    children: [
      {
        path: 'upcoming',
        component: () => import('@/views/bookings/BookingsUpcoming.vue'),
        meta: {
          showNav: true,
          userOnly: true,
        },
      },
      {
        path: 'previous',
        component: () => import('@/views/bookings/BookingsCompleted.vue'),
        meta: {
          showNav: true,
          userOnly: true,
        },
      },
    ],
  },
  {
    path: '/bookings/details',
    component: () => import('@/views/bookings/BookingDetails.vue'),
    meta: {
      userOnly: true,
      title: 'Booking Details',
    },
    beforeEnter(to, from, next) {
      const validParams = to.query.eluderef
      if (!validParams) return next('/bookings')
      else return next()
    },
    children: [
      {
        path: '',
        name: 'booking-details',
        component: () => import('@/views/bookings/BookingDetailsSummary.vue'),
      },
      {
        path: 'flight/:type',
        name: 'booking-flight-details',
        component: () => import('@/views/bookings/BookingDetailsFlight.vue'),
      },
      {
        path: 'hotel',
        name: 'booking-hotel-details',
        component: () => import('@/views/bookings/BookingDetailsHotel.vue'),
      },
    ],
  },
  {
    path: '/profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      showNav: true,
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/companions',
    component: () => import('@/views/profile/ProfileCompanions.vue'),
    meta: {
      showNav: true,
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/companions/edit/:id',
    component: () => import('@/views/profile/TravelerEdit.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/companions/create',
    component: () => import('@/views/profile/TravelerCreate.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
    beforeEnter(to, from, next) {
      return next('/profile/companions/create/adult')
    },
  },
  {
    path: '/profile/companions/create/:type',
    component: () => import('@/views/profile/TravelerCreate.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
    beforeEnter(to, from, next) {
      return to.params.type !== 'adult'
        ? next('/profile/companions/create/adult')
        : next()
    },
  },
  {
    path: '/profile/payments',
    component: () => import('@/views/profile/ProfilePayments.vue'),
    meta: {
      showNav: true,
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/payments/add-card',
    component: () => import('@/views/profile/ProfilePaymentsAddCard.vue'),
    meta: {
      title: 'Add Payment',
      userOnly: true,
    },
  },
  {
    path: '/profile/payments/add-paypal',
    component: () => import('@/views/profile/ProfilePaymentsAddPaypal.vue'),
    meta: {
      title: 'Add PayPal',
      userOnly: true,
    },
  },
  {
    path: '/profile/map',
    component: () => import('@/views/profile/ProfileMap.vue'),
    meta: {
      title: 'Map',
      userOnly: true,
      fullScreen: true,
    },
  },
  {
    path: '/profile/edit',
    component: () => import('@/views/profile/EditProfile.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
      showNav: true,
    },
  },
  {
    path: '/profile/edit/title',
    component: () => import('@/views/profile/EditProfileTitle.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/first-name',
    component: () => import('@/views/profile/EditProfileFirstName.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/last-name',
    component: () => import('@/views/profile/EditProfileLastName.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/date-of-birth',
    component: () => import('@/views/profile/EditProfileDateOfBirth.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/email',
    component: () => import('@/views/profile/EditProfileEmail.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
    beforeEnter(to, from, next) {
      return next('/profile/edit')
    },
  },
  {
    path: '/profile/edit/phone',
    component: () => import('@/views/profile/EditProfilePhone.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/location',
    component: () => import('@/views/profile/EditProfileLocation.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/password',
    component: () => import('@/views/profile/EditProfilePassword.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/edit/delete-account',
    component: () => import('@/views/profile/EditProfileDeleteAccount.vue'),
    meta: {
      title: 'Profile - Delete Account',
      userOnly: true,
    },
  },

  {
    path: '/profile/stickers',
    component: () => import('@/views/profile/Stickers.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/profile/stickers/details',
    component: () => import('@/views/profile/StickersDetails.vue'),
    meta: {
      title: 'Profile',
      userOnly: true,
    },
  },
  {
    path: '/terms-of-use',
    component: TOS,
    meta: { title: 'Terms of use' },
  },
  {
    path: '/privacy-policy',
    component: POS,
    meta: { title: 'Privacy policy' },
  },
]

if (process.env.NODE_ENV === 'development')
  routes.push({
    path: '/test',
    component: () => import('@/views/Test.vue'),
  })
