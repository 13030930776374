import { AUTH_PROVIDERS } from '../../constants/AUTH_PROVIDERS'
import { hashPassword } from '../../other/utils/hashPassword'
import { AuthService } from '../../services/AuthService'
import { PaypalProvider } from '../../services/payment-providers/PaypalProvider'
import { StripeProvider } from '../../services/payment-providers/StripeProvider'
import { UserService } from '../../services/UserService'

export const UserModule = () => ({
  state: {
    user: undefined,
    paymentMethods: [],
    preferences: [],
    travelStats: { totalTrips: 0, milesTraveled: 0, countriesVisited: 0 },
  },
  getters: {
    userHasLocation: s => !!s.user?.airportId,
    userLocationId: s => s.user?.airportId || undefined,
    userLocation: (s, g, rs, rg) => {
      const locations = rg['LocationModule/locations']
      if (!g.userHasLocation) return
      return locations.find(loc => loc.identifier === s.user.airportId)
    },
  },
  actions: {
    getUser: ({ commit, state }) =>
      UserService.getUser({ userId: state.user.id }).then(({ user }) => {
        commit('setUser', user)
        commit('setPaymentMethods', user.paymentMethodsList)
        commit('setPreferences', user.preferencesList)
      }),
    updateUser: ({ commit, state }, partialUser) => {
      return UserService.updateUser({ ...state.user, ...partialUser }).then(
        res => commit('setUser', res.user),
      )
    },
    updateUserPreferences: (_, ids) => UserService.updateUserPreferences(ids),
    changePassword: async (
      { state },
      { current_password, password, password_confirmation },
    ) => {
      return UserService.changePassword({
        userId: state.user.id,
        currentPassword: await hashPassword(current_password),
        password: await hashPassword(password),
        passwordConfirmation: await hashPassword(password_confirmation),
      })
    },
    paypalSignup: () => PaypalProvider.signup(),
    addStripe: ({ commit }, { cardNumber, holder }) =>
      StripeProvider.getInstance()
        .then(stripe => {
          const payload = [cardNumber, { name: holder }]
          return stripe.createToken(...payload)
        })
        .then(({ token: { id } }) => UserService.addStripe({ token: id }))
        .then(res => {
          commit('addPaymentMethods', res.paymentMethod)
          return res
        })
        .catch(err => {
          throw err
        }),
    addPaypal: ({ state }, data) => {
      const promise = UserService.addPaypal(state.user.id, data)
      return promise
    },
    getPaymentMethods: ({ commit, state }) => {
      const promise = UserService.getPaymentMethods({ userId: state.user.id })
      promise.then(res => commit('setPaymentMethods', res.paymentMethodsList))
      return promise
    },
    deletePaymentMethod: ({ dispatch, state }, paymentMethodId) => {
      const promise = UserService.deletePaymentMethod({
        userId: state.user.id,
        paymentMethodId,
      })
      promise.then(() => dispatch('getPaymentMethods'))
      return promise
    },
    getStats: ({ commit, state }) =>
      UserService.getStats({ userId: state.user.id }).then(res =>
        commit('setTravelStats', res),
      ),
    addSocial: (ctx, authProviderId) =>
      AUTH_PROVIDERS.find(({ id }) => id === authProviderId)?.addSocial(),
    deleteUser: ({ state, dispatch }) =>
      AuthService.deleteUser({ userId: state.user.id }).then(() => {
        dispatch('AuthModule/logout', undefined, { root: true })
      }),
  },
})
