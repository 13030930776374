<template>
  <div class="lds-dual-ring"></div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    size: {
      type: [String, Number],
      default: 18,
    },
  },
  data() {
    return {
      cssVars: {
        size: '18px',
      },
    }
  },
  watch: {
    size: {
      immediate: true,
      handler(size) {
        this.cssVars.size = size + 'px'
      },
    },
  },
}
</script>

<style scoped lang="scss">
.lds-dual-ring {
  display: inline-block;
  width: var(--size);
  height: var(--size);
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0px;
  border-radius: 50%;
  border: calc(var(--size) / 9) solid var(--color-primary);
  border-color: var(--color-primary) transparent var(--color-primary)
    transparent;
  animation: lds-dual-ring 1.6s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
