// source: proto/models/booking.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var proto_models_city_pb = require('../../proto/models/city_pb.js');
goog.object.extend(proto, proto_models_city_pb);
var proto_models_traveller_pb = require('../../proto/models/traveller_pb.js');
goog.object.extend(proto, proto_models_traveller_pb);
var proto_models_gds_pb = require('../../proto/models/gds_pb.js');
goog.object.extend(proto, proto_models_gds_pb);
var proto_models_airline_pb = require('../../proto/models/airline_pb.js');
goog.object.extend(proto, proto_models_airline_pb);
var proto_models_flight_pb = require('../../proto/models/flight_pb.js');
goog.object.extend(proto, proto_models_flight_pb);
var proto_models_user_pb = require('../../proto/models/user_pb.js');
goog.object.extend(proto, proto_models_user_pb);
var proto_models_booking_status_pb = require('../../proto/models/booking_status_pb.js');
goog.object.extend(proto, proto_models_booking_status_pb);
var proto_models_tag_pb = require('../../proto/models/tag_pb.js');
goog.object.extend(proto, proto_models_tag_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
goog.exportSymbol('proto.elude.proto.Booking', null, global);
goog.exportSymbol('proto.elude.proto.BookingTraveller', null, global);
goog.exportSymbol('proto.elude.proto.Hotel', null, global);
goog.exportSymbol('proto.elude.proto.Itinerary', null, global);
goog.exportSymbol('proto.elude.proto.Room', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Booking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.Booking.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.Booking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Booking.displayName = 'proto.elude.proto.Booking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Itinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Itinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Itinerary.displayName = 'proto.elude.proto.Itinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Hotel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.elude.proto.Hotel.repeatedFields_, null);
};
goog.inherits(proto.elude.proto.Hotel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Hotel.displayName = 'proto.elude.proto.Hotel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Room = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Room, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Room.displayName = 'proto.elude.proto.Room';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.BookingTraveller = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.BookingTraveller, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.BookingTraveller.displayName = 'proto.elude.proto.BookingTraveller';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.Booking.repeatedFields_ = [32,37,38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Booking.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Booking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Booking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Booking.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eludeReference: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departureDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    returnDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hotelReservationCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hotelProviderCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flightReservationCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    flightReservationCompanyid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stripeChargeId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    adults: jspb.Message.getFieldWithDefault(msg, 12, 0),
    children: jspb.Message.getFieldWithDefault(msg, 13, 0),
    infants: jspb.Message.getFieldWithDefault(msg, 14, 0),
    price: jspb.Message.getFieldWithDefault(msg, 15, 0),
    basePriceDifference: jspb.Message.getFieldWithDefault(msg, 16, 0),
    agentFee: jspb.Message.getFieldWithDefault(msg, 17, 0),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    baseAmount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    departureCityId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    arrivalCityId: jspb.Message.getFieldWithDefault(msg, 24, 0),
    bookingStatusId: jspb.Message.getFieldWithDefault(msg, 25, 0),
    flightGdsId: jspb.Message.getFieldWithDefault(msg, 26, 0),
    hotelGdsId: jspb.Message.getFieldWithDefault(msg, 27, 0),
    itinerary: (f = msg.getItinerary()) && proto.elude.proto.Itinerary.toObject(includeInstance, f),
    departureCity: (f = msg.getDepartureCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    arrivalCity: (f = msg.getArrivalCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto_models_user_pb.PaymentMethod.toObject(includeInstance, f),
    travellersList: jspb.Message.toObjectList(msg.getTravellersList(),
    proto_models_traveller_pb.Traveller.toObject, includeInstance),
    flightGds: (f = msg.getFlightGds()) && proto_models_gds_pb.Gds.toObject(includeInstance, f),
    hotelGds: (f = msg.getHotelGds()) && proto_models_gds_pb.Gds.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto_models_user_pb.User.toObject(includeInstance, f),
    bookingStatus: (f = msg.getBookingStatus()) && proto_models_booking_status_pb.BookingStatus.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto_models_tag_pb.Tag.toObject, includeInstance),
    bookingTravellersList: jspb.Message.toObjectList(msg.getBookingTravellersList(),
    proto.elude.proto.BookingTraveller.toObject, includeInstance),
    departureDateTimestamp: (f = msg.getDepartureDateTimestamp()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    returnDateTimestamp: (f = msg.getReturnDateTimestamp()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    airlineReservationCode: jspb.Message.getFieldWithDefault(msg, 42, ""),
    stripeBookingUrl: jspb.Message.getFieldWithDefault(msg, 43, ""),
    actionRequired: jspb.Message.getBooleanFieldWithDefault(msg, 44, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Booking}
 */
proto.elude.proto.Booking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Booking;
  return proto.elude.proto.Booking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Booking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Booking}
 */
proto.elude.proto.Booking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEludeReference(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartureDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelReservationCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelProviderCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightReservationCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightReservationCompanyid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeChargeId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdults(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildren(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInfants(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBasePriceDifference(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAgentFee(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAmount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxAmount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBaseAmount(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentMethodId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartureCityId(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArrivalCityId(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingStatusId(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightGdsId(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelGdsId(value);
      break;
    case 28:
      var value = new proto.elude.proto.Itinerary;
      reader.readMessage(value,proto.elude.proto.Itinerary.deserializeBinaryFromReader);
      msg.setItinerary(value);
      break;
    case 29:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setDepartureCity(value);
      break;
    case 30:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setArrivalCity(value);
      break;
    case 31:
      var value = new proto_models_user_pb.PaymentMethod;
      reader.readMessage(value,proto_models_user_pb.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 32:
      var value = new proto_models_traveller_pb.Traveller;
      reader.readMessage(value,proto_models_traveller_pb.Traveller.deserializeBinaryFromReader);
      msg.addTravellers(value);
      break;
    case 33:
      var value = new proto_models_gds_pb.Gds;
      reader.readMessage(value,proto_models_gds_pb.Gds.deserializeBinaryFromReader);
      msg.setFlightGds(value);
      break;
    case 34:
      var value = new proto_models_gds_pb.Gds;
      reader.readMessage(value,proto_models_gds_pb.Gds.deserializeBinaryFromReader);
      msg.setHotelGds(value);
      break;
    case 35:
      var value = new proto_models_user_pb.User;
      reader.readMessage(value,proto_models_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 36:
      var value = new proto_models_booking_status_pb.BookingStatus;
      reader.readMessage(value,proto_models_booking_status_pb.BookingStatus.deserializeBinaryFromReader);
      msg.setBookingStatus(value);
      break;
    case 37:
      var value = new proto_models_tag_pb.Tag;
      reader.readMessage(value,proto_models_tag_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 38:
      var value = new proto.elude.proto.BookingTraveller;
      reader.readMessage(value,proto.elude.proto.BookingTraveller.deserializeBinaryFromReader);
      msg.addBookingTravellers(value);
      break;
    case 39:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDepartureDateTimestamp(value);
      break;
    case 40:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReturnDateTimestamp(value);
      break;
    case 41:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirlineReservationCode(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeBookingUrl(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActionRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Booking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Booking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Booking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Booking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEludeReference();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartureDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReturnDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHotelReservationCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHotelProviderCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlightReservationCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFlightReservationCompanyid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStripeChargeId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAdults();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getChildren();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getInfants();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getBasePriceDifference();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getAgentFee();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getBaseAmount();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getDepartureCityId();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getArrivalCityId();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getBookingStatusId();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getFlightGdsId();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getHotelGdsId();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getItinerary();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.elude.proto.Itinerary.serializeBinaryToWriter
    );
  }
  f = message.getDepartureCity();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getArrivalCity();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_models_user_pb.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getTravellersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto_models_traveller_pb.Traveller.serializeBinaryToWriter
    );
  }
  f = message.getFlightGds();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto_models_gds_pb.Gds.serializeBinaryToWriter
    );
  }
  f = message.getHotelGds();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto_models_gds_pb.Gds.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto_models_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBookingStatus();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto_models_booking_status_pb.BookingStatus.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      37,
      f,
      proto_models_tag_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getBookingTravellersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      38,
      f,
      proto.elude.proto.BookingTraveller.serializeBinaryToWriter
    );
  }
  f = message.getDepartureDateTimestamp();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReturnDateTimestamp();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAirlineReservationCode();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getStripeBookingUrl();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getActionRequired();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string elude_reference = 3;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getEludeReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setEludeReference = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string departure_date = 4;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getDepartureDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setDepartureDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string return_date = 5;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getReturnDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setReturnDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string hotel_reservation_code = 7;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getHotelReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setHotelReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string hotel_provider_code = 8;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getHotelProviderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setHotelProviderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flight_reservation_code = 9;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getFlightReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setFlightReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string flight_reservation_companyId = 10;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getFlightReservationCompanyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setFlightReservationCompanyid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string stripe_charge_id = 11;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getStripeChargeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setStripeChargeId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 adults = 12;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getAdults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setAdults = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 children = 13;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getChildren = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setChildren = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 infants = 14;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getInfants = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setInfants = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 price = 15;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 base_price_difference = 16;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getBasePriceDifference = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setBasePriceDifference = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 agent_fee = 17;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getAgentFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setAgentFee = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 total_amount = 18;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 tax_amount = 19;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 base_amount = 20;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 user_id = 21;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 payment_method_id = 22;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getPaymentMethodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 departure_city_id = 23;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getDepartureCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setDepartureCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 arrival_city_id = 24;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getArrivalCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setArrivalCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 booking_status_id = 25;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getBookingStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setBookingStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 flight_gds_id = 26;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getFlightGdsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setFlightGdsId = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 hotel_gds_id = 27;
 * @return {number}
 */
proto.elude.proto.Booking.prototype.getHotelGdsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setHotelGdsId = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional Itinerary itinerary = 28;
 * @return {?proto.elude.proto.Itinerary}
 */
proto.elude.proto.Booking.prototype.getItinerary = function() {
  return /** @type{?proto.elude.proto.Itinerary} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Itinerary, 28));
};


/**
 * @param {?proto.elude.proto.Itinerary|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setItinerary = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearItinerary = function() {
  return this.setItinerary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasItinerary = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional City departure_city = 29;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Booking.prototype.getDepartureCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 29));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setDepartureCity = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearDepartureCity = function() {
  return this.setDepartureCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasDepartureCity = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional City arrival_city = 30;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Booking.prototype.getArrivalCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 30));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setArrivalCity = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearArrivalCity = function() {
  return this.setArrivalCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasArrivalCity = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional PaymentMethod payment_method = 31;
 * @return {?proto.elude.proto.PaymentMethod}
 */
proto.elude.proto.Booking.prototype.getPaymentMethod = function() {
  return /** @type{?proto.elude.proto.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.PaymentMethod, 31));
};


/**
 * @param {?proto.elude.proto.PaymentMethod|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * repeated Traveller travellers = 32;
 * @return {!Array<!proto.elude.proto.Traveller>}
 */
proto.elude.proto.Booking.prototype.getTravellersList = function() {
  return /** @type{!Array<!proto.elude.proto.Traveller>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_traveller_pb.Traveller, 32));
};


/**
 * @param {!Array<!proto.elude.proto.Traveller>} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setTravellersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.elude.proto.Traveller=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Traveller}
 */
proto.elude.proto.Booking.prototype.addTravellers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.elude.proto.Traveller, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearTravellersList = function() {
  return this.setTravellersList([]);
};


/**
 * optional Gds flight_gds = 33;
 * @return {?proto.elude.proto.Gds}
 */
proto.elude.proto.Booking.prototype.getFlightGds = function() {
  return /** @type{?proto.elude.proto.Gds} */ (
    jspb.Message.getWrapperField(this, proto_models_gds_pb.Gds, 33));
};


/**
 * @param {?proto.elude.proto.Gds|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setFlightGds = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearFlightGds = function() {
  return this.setFlightGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasFlightGds = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional Gds hotel_gds = 34;
 * @return {?proto.elude.proto.Gds}
 */
proto.elude.proto.Booking.prototype.getHotelGds = function() {
  return /** @type{?proto.elude.proto.Gds} */ (
    jspb.Message.getWrapperField(this, proto_models_gds_pb.Gds, 34));
};


/**
 * @param {?proto.elude.proto.Gds|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setHotelGds = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearHotelGds = function() {
  return this.setHotelGds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasHotelGds = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional User user = 35;
 * @return {?proto.elude.proto.User}
 */
proto.elude.proto.Booking.prototype.getUser = function() {
  return /** @type{?proto.elude.proto.User} */ (
    jspb.Message.getWrapperField(this, proto_models_user_pb.User, 35));
};


/**
 * @param {?proto.elude.proto.User|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasUser = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional BookingStatus booking_status = 36;
 * @return {?proto.elude.proto.BookingStatus}
 */
proto.elude.proto.Booking.prototype.getBookingStatus = function() {
  return /** @type{?proto.elude.proto.BookingStatus} */ (
    jspb.Message.getWrapperField(this, proto_models_booking_status_pb.BookingStatus, 36));
};


/**
 * @param {?proto.elude.proto.BookingStatus|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setBookingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearBookingStatus = function() {
  return this.setBookingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasBookingStatus = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * repeated Tag tags = 37;
 * @return {!Array<!proto.elude.proto.Tag>}
 */
proto.elude.proto.Booking.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.elude.proto.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_models_tag_pb.Tag, 37));
};


/**
 * @param {!Array<!proto.elude.proto.Tag>} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 37, value);
};


/**
 * @param {!proto.elude.proto.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Tag}
 */
proto.elude.proto.Booking.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 37, opt_value, proto.elude.proto.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated BookingTraveller booking_travellers = 38;
 * @return {!Array<!proto.elude.proto.BookingTraveller>}
 */
proto.elude.proto.Booking.prototype.getBookingTravellersList = function() {
  return /** @type{!Array<!proto.elude.proto.BookingTraveller>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.BookingTraveller, 38));
};


/**
 * @param {!Array<!proto.elude.proto.BookingTraveller>} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setBookingTravellersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 38, value);
};


/**
 * @param {!proto.elude.proto.BookingTraveller=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.BookingTraveller}
 */
proto.elude.proto.Booking.prototype.addBookingTravellers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, proto.elude.proto.BookingTraveller, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearBookingTravellersList = function() {
  return this.setBookingTravellersList([]);
};


/**
 * optional Timestamp departure_date_timestamp = 39;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Booking.prototype.getDepartureDateTimestamp = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 39));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setDepartureDateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearDepartureDateTimestamp = function() {
  return this.setDepartureDateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasDepartureDateTimestamp = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional Timestamp return_date_timestamp = 40;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Booking.prototype.getReturnDateTimestamp = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setReturnDateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearReturnDateTimestamp = function() {
  return this.setReturnDateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasReturnDateTimestamp = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional Timestamp created_at = 41;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Booking.prototype.getCreatedAt = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Booking} returns this
*/
proto.elude.proto.Booking.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional string airline_reservation_code = 42;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getAirlineReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setAirlineReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string stripe_booking_url = 43;
 * @return {string}
 */
proto.elude.proto.Booking.prototype.getStripeBookingUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setStripeBookingUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional bool action_required = 44;
 * @return {boolean}
 */
proto.elude.proto.Booking.prototype.getActionRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.Booking} returns this
 */
proto.elude.proto.Booking.prototype.setActionRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Itinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Itinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Itinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Itinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    airlineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    departureFlightId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    returnFlightId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    baseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    mainTicketNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hotel: (f = msg.getHotel()) && proto.elude.proto.Hotel.toObject(includeInstance, f),
    departureFlight: (f = msg.getDepartureFlight()) && proto_models_flight_pb.Flight.toObject(includeInstance, f),
    returnFlight: (f = msg.getReturnFlight()) && proto_models_flight_pb.Flight.toObject(includeInstance, f),
    airline: (f = msg.getAirline()) && proto_models_airline_pb.Airline.toObject(includeInstance, f),
    booking: (f = msg.getBooking()) && proto.elude.proto.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Itinerary}
 */
proto.elude.proto.Itinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Itinerary;
  return proto.elude.proto.Itinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Itinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Itinerary}
 */
proto.elude.proto.Itinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAirlineId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartureFlightId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReturnFlightId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTaxAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBaseAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainTicketNumber(value);
      break;
    case 11:
      var value = new proto.elude.proto.Hotel;
      reader.readMessage(value,proto.elude.proto.Hotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 12:
      var value = new proto_models_flight_pb.Flight;
      reader.readMessage(value,proto_models_flight_pb.Flight.deserializeBinaryFromReader);
      msg.setDepartureFlight(value);
      break;
    case 13:
      var value = new proto_models_flight_pb.Flight;
      reader.readMessage(value,proto_models_flight_pb.Flight.deserializeBinaryFromReader);
      msg.setReturnFlight(value);
      break;
    case 14:
      var value = new proto_models_airline_pb.Airline;
      reader.readMessage(value,proto_models_airline_pb.Airline.deserializeBinaryFromReader);
      msg.setAirline(value);
      break;
    case 15:
      var value = new proto.elude.proto.Booking;
      reader.readMessage(value,proto.elude.proto.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Itinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Itinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Itinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Itinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAirlineId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDepartureFlightId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReturnFlightId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getBaseAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getMainTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.elude.proto.Hotel.serializeBinaryToWriter
    );
  }
  f = message.getDepartureFlight();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_models_flight_pb.Flight.serializeBinaryToWriter
    );
  }
  f = message.getReturnFlight();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_models_flight_pb.Flight.serializeBinaryToWriter
    );
  }
  f = message.getAirline();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_models_airline_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.elude.proto.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 airline_id = 2;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getAirlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setAirlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 booking_id = 3;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 departure_flight_id = 4;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getDepartureFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setDepartureFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 return_flight_id = 5;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getReturnFlightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setReturnFlightId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.elude.proto.Itinerary.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float total_amount = 7;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float tax_amount = 8;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float base_amount = 9;
 * @return {number}
 */
proto.elude.proto.Itinerary.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string main_ticket_number = 10;
 * @return {string}
 */
proto.elude.proto.Itinerary.prototype.getMainTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.setMainTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Hotel hotel = 11;
 * @return {?proto.elude.proto.Hotel}
 */
proto.elude.proto.Itinerary.prototype.getHotel = function() {
  return /** @type{?proto.elude.proto.Hotel} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Hotel, 11));
};


/**
 * @param {?proto.elude.proto.Hotel|undefined} value
 * @return {!proto.elude.proto.Itinerary} returns this
*/
proto.elude.proto.Itinerary.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Itinerary.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Flight departure_flight = 12;
 * @return {?proto.elude.proto.Flight}
 */
proto.elude.proto.Itinerary.prototype.getDepartureFlight = function() {
  return /** @type{?proto.elude.proto.Flight} */ (
    jspb.Message.getWrapperField(this, proto_models_flight_pb.Flight, 12));
};


/**
 * @param {?proto.elude.proto.Flight|undefined} value
 * @return {!proto.elude.proto.Itinerary} returns this
*/
proto.elude.proto.Itinerary.prototype.setDepartureFlight = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.clearDepartureFlight = function() {
  return this.setDepartureFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Itinerary.prototype.hasDepartureFlight = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Flight return_flight = 13;
 * @return {?proto.elude.proto.Flight}
 */
proto.elude.proto.Itinerary.prototype.getReturnFlight = function() {
  return /** @type{?proto.elude.proto.Flight} */ (
    jspb.Message.getWrapperField(this, proto_models_flight_pb.Flight, 13));
};


/**
 * @param {?proto.elude.proto.Flight|undefined} value
 * @return {!proto.elude.proto.Itinerary} returns this
*/
proto.elude.proto.Itinerary.prototype.setReturnFlight = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.clearReturnFlight = function() {
  return this.setReturnFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Itinerary.prototype.hasReturnFlight = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Airline airline = 14;
 * @return {?proto.elude.proto.Airline}
 */
proto.elude.proto.Itinerary.prototype.getAirline = function() {
  return /** @type{?proto.elude.proto.Airline} */ (
    jspb.Message.getWrapperField(this, proto_models_airline_pb.Airline, 14));
};


/**
 * @param {?proto.elude.proto.Airline|undefined} value
 * @return {!proto.elude.proto.Itinerary} returns this
*/
proto.elude.proto.Itinerary.prototype.setAirline = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.clearAirline = function() {
  return this.setAirline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Itinerary.prototype.hasAirline = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Booking booking = 15;
 * @return {?proto.elude.proto.Booking}
 */
proto.elude.proto.Itinerary.prototype.getBooking = function() {
  return /** @type{?proto.elude.proto.Booking} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Booking, 15));
};


/**
 * @param {?proto.elude.proto.Booking|undefined} value
 * @return {!proto.elude.proto.Itinerary} returns this
*/
proto.elude.proto.Itinerary.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Itinerary} returns this
 */
proto.elude.proto.Itinerary.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Itinerary.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.elude.proto.Hotel.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Hotel.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Hotel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Hotel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Hotel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    confirmationCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    checkInDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    checkOutDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 8, ""),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    baseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    rating: jspb.Message.getFieldWithDefault(msg, 12, 0),
    stars: jspb.Message.getFieldWithDefault(msg, 13, 0),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cityId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    itinerary: (f = msg.getItinerary()) && proto.elude.proto.Itinerary.toObject(includeInstance, f),
    city: (f = msg.getCity()) && proto_models_city_pb.City.toObject(includeInstance, f),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.elude.proto.Room.toObject, includeInstance),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    checkInDateTimestamp: (f = msg.getCheckInDateTimestamp()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    checkOutDateTimestamp: (f = msg.getCheckOutDateTimestamp()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Hotel}
 */
proto.elude.proto.Hotel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Hotel;
  return proto.elude.proto.Hotel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Hotel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Hotel}
 */
proto.elude.proto.Hotel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckInDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOutDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTaxAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBaseAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStars(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItineraryId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCityId(value);
      break;
    case 16:
      var value = new proto.elude.proto.Itinerary;
      reader.readMessage(value,proto.elude.proto.Itinerary.deserializeBinaryFromReader);
      msg.setItinerary(value);
      break;
    case 17:
      var value = new proto_models_city_pb.City;
      reader.readMessage(value,proto_models_city_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 20:
      var value = new proto.elude.proto.Room;
      reader.readMessage(value,proto.elude.proto.Room.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 22:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckInDateTimestamp(value);
      break;
    case 23:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCheckOutDateTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Hotel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Hotel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Hotel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Hotel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfirmationCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCheckInDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCheckOutDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getBaseAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getStars();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getItineraryId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getCityId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getItinerary();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.elude.proto.Itinerary.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_models_city_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.elude.proto.Room.serializeBinaryToWriter
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCheckInDateTimestamp();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCheckOutDateTimestamp();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string confirmation_code = 4;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getConfirmationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setConfirmationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string check_in_date = 6;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getCheckInDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setCheckInDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string check_out_date = 7;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getCheckOutDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setCheckOutDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string currency = 8;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional float total_amount = 9;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float tax_amount = 10;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float base_amount = 11;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int32 rating = 12;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 stars = 13;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getStars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setStars = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 itinerary_id = 14;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getItineraryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 city_id = 15;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getCityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setCityId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional Itinerary itinerary = 16;
 * @return {?proto.elude.proto.Itinerary}
 */
proto.elude.proto.Hotel.prototype.getItinerary = function() {
  return /** @type{?proto.elude.proto.Itinerary} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Itinerary, 16));
};


/**
 * @param {?proto.elude.proto.Itinerary|undefined} value
 * @return {!proto.elude.proto.Hotel} returns this
*/
proto.elude.proto.Hotel.prototype.setItinerary = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.clearItinerary = function() {
  return this.setItinerary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Hotel.prototype.hasItinerary = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional City city = 17;
 * @return {?proto.elude.proto.City}
 */
proto.elude.proto.Hotel.prototype.getCity = function() {
  return /** @type{?proto.elude.proto.City} */ (
    jspb.Message.getWrapperField(this, proto_models_city_pb.City, 17));
};


/**
 * @param {?proto.elude.proto.City|undefined} value
 * @return {!proto.elude.proto.Hotel} returns this
*/
proto.elude.proto.Hotel.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Hotel.prototype.hasCity = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional float latitude = 18;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float longitude = 19;
 * @return {number}
 */
proto.elude.proto.Hotel.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * repeated Room rooms = 20;
 * @return {!Array<!proto.elude.proto.Room>}
 */
proto.elude.proto.Hotel.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.elude.proto.Room>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.elude.proto.Room, 20));
};


/**
 * @param {!Array<!proto.elude.proto.Room>} value
 * @return {!proto.elude.proto.Hotel} returns this
*/
proto.elude.proto.Hotel.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.elude.proto.Room=} opt_value
 * @param {number=} opt_index
 * @return {!proto.elude.proto.Room}
 */
proto.elude.proto.Hotel.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.elude.proto.Room, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * optional string image_url = 21;
 * @return {string}
 */
proto.elude.proto.Hotel.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional Timestamp check_in_date_timestamp = 22;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Hotel.prototype.getCheckInDateTimestamp = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Hotel} returns this
*/
proto.elude.proto.Hotel.prototype.setCheckInDateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.clearCheckInDateTimestamp = function() {
  return this.setCheckInDateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Hotel.prototype.hasCheckInDateTimestamp = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional Timestamp check_out_date_timestamp = 23;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.Hotel.prototype.getCheckOutDateTimestamp = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.Hotel} returns this
*/
proto.elude.proto.Hotel.prototype.setCheckOutDateTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Hotel} returns this
 */
proto.elude.proto.Hotel.prototype.clearCheckOutDateTimestamp = function() {
  return this.setCheckOutDateTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Hotel.prototype.hasCheckOutDateTimestamp = function() {
  return jspb.Message.getField(this, 23) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Room.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Room.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Room} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Room.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    meal: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hotelId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hotel: (f = msg.getHotel()) && proto.elude.proto.Hotel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Room}
 */
proto.elude.proto.Room.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Room;
  return proto.elude.proto.Room.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Room} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Room}
 */
proto.elude.proto.Room.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMeal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHotelId(value);
      break;
    case 7:
      var value = new proto.elude.proto.Hotel;
      reader.readMessage(value,proto.elude.proto.Hotel.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Room.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Room.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Room} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Room.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMeal();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHotelId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.elude.proto.Hotel.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Room.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.elude.proto.Room.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.elude.proto.Room.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.elude.proto.Room.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool meal = 5;
 * @return {boolean}
 */
proto.elude.proto.Room.prototype.getMeal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.setMeal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 hotel_id = 6;
 * @return {number}
 */
proto.elude.proto.Room.prototype.getHotelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Hotel hotel = 7;
 * @return {?proto.elude.proto.Hotel}
 */
proto.elude.proto.Room.prototype.getHotel = function() {
  return /** @type{?proto.elude.proto.Hotel} */ (
    jspb.Message.getWrapperField(this, proto.elude.proto.Hotel, 7));
};


/**
 * @param {?proto.elude.proto.Hotel|undefined} value
 * @return {!proto.elude.proto.Room} returns this
*/
proto.elude.proto.Room.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Room} returns this
 */
proto.elude.proto.Room.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Room.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.BookingTraveller.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.BookingTraveller.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.BookingTraveller} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.BookingTraveller.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    travellerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    traveller: (f = msg.getTraveller()) && proto_models_traveller_pb.Traveller.toObject(includeInstance, f),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.BookingTraveller}
 */
proto.elude.proto.BookingTraveller.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.BookingTraveller;
  return proto.elude.proto.BookingTraveller.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.BookingTraveller} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.BookingTraveller}
 */
proto.elude.proto.BookingTraveller.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTravellerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookingId(value);
      break;
    case 4:
      var value = new proto_models_traveller_pb.Traveller;
      reader.readMessage(value,proto_models_traveller_pb.Traveller.deserializeBinaryFromReader);
      msg.setTraveller(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.BookingTraveller.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.BookingTraveller.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.BookingTraveller} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.BookingTraveller.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTravellerId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTraveller();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_models_traveller_pb.Traveller.serializeBinaryToWriter
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.BookingTraveller.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.BookingTraveller} returns this
 */
proto.elude.proto.BookingTraveller.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 traveller_id = 2;
 * @return {number}
 */
proto.elude.proto.BookingTraveller.prototype.getTravellerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.BookingTraveller} returns this
 */
proto.elude.proto.BookingTraveller.prototype.setTravellerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 booking_id = 3;
 * @return {number}
 */
proto.elude.proto.BookingTraveller.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.BookingTraveller} returns this
 */
proto.elude.proto.BookingTraveller.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Traveller traveller = 4;
 * @return {?proto.elude.proto.Traveller}
 */
proto.elude.proto.BookingTraveller.prototype.getTraveller = function() {
  return /** @type{?proto.elude.proto.Traveller} */ (
    jspb.Message.getWrapperField(this, proto_models_traveller_pb.Traveller, 4));
};


/**
 * @param {?proto.elude.proto.Traveller|undefined} value
 * @return {!proto.elude.proto.BookingTraveller} returns this
*/
proto.elude.proto.BookingTraveller.prototype.setTraveller = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.BookingTraveller} returns this
 */
proto.elude.proto.BookingTraveller.prototype.clearTraveller = function() {
  return this.setTraveller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.BookingTraveller.prototype.hasTraveller = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string ticket_number = 5;
 * @return {string}
 */
proto.elude.proto.BookingTraveller.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.BookingTraveller} returns this
 */
proto.elude.proto.BookingTraveller.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.elude.proto);
