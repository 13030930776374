import { compose, flatten, map, omit, path, paths, uniq } from 'ramda'
import { sortByPrice } from '../../constants/SEARCH'
import { parseLocation } from '../../services/LocationService'

const getDate = datetime => new Date(datetime).toISOString().split('T')[0]

export const parseHotel = (hotel, trip) => {
  const hotelId = `${trip.id};${hotel.gds.name};${hotel.hotelSearchType};${hotel.id}`

  const rooms = sortByPrice(hotel.roomsList).map(room => ({
    ...room,
    id: `${hotelId};${room.id}`,
    _id: room.id,
    hotelId,
    priceDifference: 0,
    revalidatedAt: 0,
  }))

  const output = {
    ...hotel,
    id: hotelId,
    _id: hotel.id,
    tripId: trip.id,
    location: parseLocation(trip.arrivalLocation),
    rooms,
    price: rooms[0].price,
    checkInDate: getDate(hotel.checkInDatetime.seconds * 1000),
    checkInDatetime: hotel.checkInDatetime.seconds * 1000,
    checkOutDatetime: hotel.checkOutDatetime.seconds * 1000,
  }
  delete output.roomsList

  return output
}

export const parseHotels = (hotels, tripId) =>
  hotels.map(hotel => parseHotel(hotel, tripId))

export const extractAirlinesIataListFromFlights = flights =>
  compose(
    uniq,
    map(path(['airline', 'iataCode'])),
    flatten,
    map(
      paths([
        ['departureFlight', 'flightSegmentsList'],
        ['returnFlight', 'flightSegmentsList'],
      ]),
    ),
  )(flights)

const getOffset = ({ arrivalUtcOffset, departureUtcOffset }) => {
  const arrivalOffset = arrivalUtcOffset.split(':')[0]
  const departureOffset = departureUtcOffset.split(':')[0]
  const result = Number(arrivalOffset) - Number(departureOffset)
  return result >= 0 ? `+${result}` : `${result}`
}

const parseFlightDirection = direction => {
  const first = direction.flightSegmentsList.at(0)
  const last = direction.flightSegmentsList.at(-1)

  const output = {
    ...direction,
    departureDatetime: first.departureDatetime.seconds * 1000,
    departureDate: getDate(first.departureDatetime.seconds * 1000),
    arrivalDatetime: last.arrivalDatetime.seconds * 1000,
    arrivalDate: getDate(last.arrivalDatetime.seconds * 1000),
    departureAirport: first.departureAirports,
    arrivalAirport: last.arrivalAirports,
    duration: direction.duration * 60 * 1000,
    stops: direction.flightSegmentsList.length - 1,
    offset: getOffset({
      departureUtcOffset: first.departureUtcOffset,
      arrivalUtcOffset: last.arrivalUtcOffset,
    }),
    segments: compose(
      map(omit(['departureAirports', 'arrivalAirports'])),
      map(segment => ({
        ...segment,
        duration: segment.duration * 60 * 1000,
        flightCode: segment.airline.iataCode + segment.flightNumber,
        departureDatetime: segment.departureDatetime.seconds * 1000,
        arrivalDatetime: segment.arrivalDatetime.seconds * 1000,
        departureAirport: segment.departureAirports,
        arrivalAirport: segment.arrivalAirports,
        offset: getOffset(segment),
      })),
    )(direction.flightSegmentsList),
  }

  delete output.flightSegmentsList

  return output
}

export const parseFlights = (flights, tripId) =>
  compose(
    map(flight => ({
      ...flight,
      id: `${tripId};${flight.gds.name};${flight.id}`,
      _id: flight.id,
      tripId,
      departureDatetime: flight.departureFlight.departureDatetime,
      returnDatetime: flight.returnFlight.arrivalDatetime,
      priceDifference: 0,
      revalidatedAt: 0,
    })),
    map(flight => {
      const departureFlight = parseFlightDirection(flight.departureFlight)
      const returnFlight = parseFlightDirection(flight.returnFlight)
      return {
        ...flight,
        checkInDate: departureFlight.arrivalDate,
        departureFlight,
        returnFlight,
      }
    }),
  )(flights)

export const mapFlightDirectionAirlines = (direction, airlines) => ({
  ...direction,
  flightSegmentsList: direction.flightSegmentsList.map(segment => ({
    ...segment,
    airline: airlines.find(
      ({ iataCode }) => iataCode === segment.airline.iataCode,
    ),
  })),
})
