<template>
  <nav class="navigation-wrapper">
    <div class="container-xs navigation-list">
      <RouterLink
        v-for="item in list"
        :key="item.path"
        :to="item.path"
        class="navigation-list-item"
        :data-active="item.isActive($route)"
      >
        <component :is="item.icon" />
        <p>{{ item.text }}</p>
      </RouterLink>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import IconNavigationExploreVue from '../../components-rf/icons/navbar/IconNavigationExplore.vue'
import IconNavigationProfileVue from '../../components-rf/icons/navbar/IconNavigationProfile.vue'
import IconNavigationSearchVue from '../../components-rf/icons/navbar/IconNavigationSearch.vue'
import IconNavigationTripsVue from '../../components-rf/icons/navbar/IconNavigationTrips.vue'

const list = [
  {
    text: 'Explore',
    icon: IconNavigationExploreVue,
    isActive: route => route.path.startsWith('/home'),
    path: '/home',
  },
  {
    text: 'My Trips',
    icon: IconNavigationTripsVue,
    isActive: route => route.path.startsWith('/bookings'),
    path: '/bookings',
  },
  {
    text: 'Search',
    icon: IconNavigationSearchVue,
    isActive: route => route.path.startsWith('/search'),
    path: '/search',
  },
  {
    text: 'Account',
    icon: IconNavigationProfileVue,
    isActive: route => route.path.startsWith('/profile'),
    path: '/profile',
  },
]

export default {
  name: 'Navigation',
  computed: mapState('AuthModule', ['isLoggedIn']),
  data() {
    return { list }
  },
}
</script>

<style scoped lang="scss">
.navigation-wrapper {
  background-color: var(--color-light);
  height: 50px;
}
.navigation-list {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navigation-list-item {
  display: grid;
  gap: 4px;
  min-width: 50px;
  height: 100%;
  justify-items: center;
  align-content: center;
  & > p {
    font-family: Montserrat;
    font-size: 12px;
  }
  color: #727272;
  &[data-active='true'] {
    color: var(--color-primary);
  }
}
</style>
