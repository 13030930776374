<template>
  <transition :name="noFadeIn ? 'modal-fade-out' : 'modal-fade'">
    <div v-show="isShown" class="x-wrap center" :style="wrapStyle">
      <div
        ref="modal"
        v-click-outside="() => !nonClosable && !noClickAway && hide()"
        tabindex="-1"
        class="x-modal"
        :class="xClass"
        :style="modalStyle"
      >
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
// TODO - details-summary modal
import { utils } from '../../other/utils/utils.js'

const defaults = {
  background: '#fff',
  overlay: '#00000088',
}

export default {
  name: 'Modal',
  created() {
    if (!this.nonClosable)
      document.addEventListener('keydown', this.handleEscape)
  },
  beforeDestroy() {
    if (!this.nonClosable)
      window.removeEventListener('keydown', this.handleEscape)
    utils.body.style.overflowY = 'auto'
  },
  props: {
    xClass: [String, Object, Array],
    xStyle: [String, Object],
    overlay: [Boolean, String],
    nonClosable: Boolean,
    noFadeIn: Boolean,
    noClickAway: Boolean,
    bg: {
      type: [Boolean, String],
      default: true,
    },
  },
  data() {
    return {
      isShown: false,
      openEl: [],
    }
  },
  methods: {
    show() {
      if (this.isShown) return
      const target = window.event?.target
      if (target) this.openEl.includes(target) || this.openEl.push(target)
      this.$emit('show')
      this.isShown = true
      this.$nextTick(() => {
        this.$refs.modal?.focus?.()
      })
    },
    hide() {
      if (!this.isShown || this.openEl.includes(window.event?.target)) return
      this.$emit('hide')
      this.isShown = false
    },
    handleEscape() {
      if (window.event.keyCode === 27) {
        this.$emit('close')
        this.hide()
      }
    },
  },
  computed: {
    wrapStyle() {
      const styles = {}
      utils.body.style.overflowY = this.isShown ? 'hidden' : 'auto'
      styles.overflowY = this.isShown ? 'auto' : 'hidden'
      if (this.overlay) {
        styles.background =
          this.overlay === true
            ? defaults.overlay
            : this.overlay === 'true'
            ? defaults.overlay
            : this.overlay[0] === '#'
            ? this.overlay
            : utils.css.getVar(`color-${this.overlay}`)
      }
      return styles
    },
    modalStyle() {
      const styles = {}
      if (this.bg && this.bg !== 'false') {
        styles.background =
          this.bg === true
            ? defaults.background
            : this.bg === 'true'
            ? defaults.background
            : this.bg[0] === '#'
            ? this.bg
            : utils.css.getVar(`color-${this.bg}`)
      }
      const o = {
        ...utils.css.parseStyles(styles),
        ...utils.css.parseStyles(this.xStyle),
      }
      return o
    },
  },
}
</script>

<style scoped lang="scss">
.x-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  z-index: 1000;
  overflow: auto;
}
.x-modal {
  outline: none;
  margin: auto;
}
.modal-fade {
  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active,
  &-leave-active {
    transition: all 0.6s cubic-bezier(0.8, 0.4, 0.2, 0.8);
  }
}
.modal-fade-out {
  &-leave-to {
    opacity: 0;
  }
  &-leave-active {
    transition: all 0.6s cubic-bezier(0.8, 0.4, 0.2, 0.8);
  }
}
.fade-out-leave-active {
  transition: all 0.8s cubic-bezier(0.8, 0.4, 0.2, 0.8);
}
.fade-out-leave-to {
  opacity: 0;
}
</style>
