<template>
  <flex
    tag="button"
    class="x-base"
    :class="classes"
    :disabled="disabled || isLoading"
    @click="$emit('click')"
  >
    <AppLoader v-if="isLoading" :size="loaderSize" />
    <slot v-else></slot>
    <DropdownRight
      v-if="arrow && !isLoading"
      style="margin-left: auto; padding-left: 10px"
      height="100%"
      width="18px"
    />
  </flex>
</template>

<script>
import AppLoader from '../../../components-rf/AppLoader.vue'
import DropdownRight from '../../utils/icons/DropdownRight.vue'

export default {
  name: 'XButton',
  components: { AppLoader, DropdownRight },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value =>
        ['main', 'large', 'medium', 'default', 'outline'].includes(value),
    },
    arrow: Boolean,
    color: {
      type: String,
      default: 'primary',
    },
    isLoading: Boolean,
    disabled: Boolean,
  },
  computed: {
    classes() {
      return [`x-${this.type}`, `button-${this.color}`]
    },
    loaderSize() {
      const defaultSize = {
        main: 28,
        large: 24,
        medium: 18,
      }[this.type]
      return defaultSize || 16
    },
  },
}
</script>

<style scoped lang="scss">
.x-base {
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: LuciferSans;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  color: white;
  letter-spacing: 0.1em;
  &:hover {
    box-shadow: var(--shadow-m);
  }
}
.x-outline {
  @extend .x-base-small;
  font-size: 16px;
  border-radius: 9px;
  height: 44px;
  background: #fff0 !important;
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary);
}
.x-base-big {
  min-width: 300px;
  border-radius: 9px;
  @include s {
    min-width: 353px;
  }
}
.x-base-small {
  min-width: 275px;
}
.x-main {
  @extend .x-base-big;
  height: 56px;
  font-size: 18px;
}
.x-large {
  @extend .x-base-big;
  height: 60px;
  font-size: 18px;
}
.x-medium {
  @extend .x-base-small;
  font-size: 16px;
  @include s {
    font-size: 18px;
  }
  border-radius: 9px;
  height: 50px;
}
.x-default {
  @extend .x-base-small;
  font-size: 16px;
  border-radius: 17px;
  height: 44px;
}
</style>
