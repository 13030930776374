import { GetCountriesRequest } from '../../../util/gen/proto/location/location_request_pb'
import { Pagination } from '../../../util/gen/proto/commons/pagination_pb'
import { Grpc } from './base/Grpc'

const pagination = new Pagination().setOverridePagination(true)

export const CountryService = {
  getCountries: () =>
    Grpc.snt
      .getCountries(new GetCountriesRequest().setPagination(pagination))
      .then(res => res.toObject()),
}
