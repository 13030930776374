// source: proto/commons/saved_search.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_commons_enum_pb = require('../../proto/commons/enum_pb.js');
goog.object.extend(proto, proto_commons_enum_pb);
var proto_commons_timestamp_pb = require('../../proto/commons/timestamp_pb.js');
goog.object.extend(proto, proto_commons_timestamp_pb);
goog.exportSymbol('proto.elude.proto.SavedSearch', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.SavedSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.SavedSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.SavedSearch.displayName = 'proto.elude.proto.SavedSearch';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.SavedSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.SavedSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.SavedSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SavedSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    departureLocationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    departureLocationType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    arrivalLocationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    arrivalLocationType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    departureDate: (f = msg.getDepartureDate()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    returnDate: (f = msg.getReturnDate()) && proto_commons_timestamp_pb.Timestamp.toObject(includeInstance, f),
    travellers: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.SavedSearch}
 */
proto.elude.proto.SavedSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.SavedSearch;
  return proto.elude.proto.SavedSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.SavedSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.SavedSearch}
 */
proto.elude.proto.SavedSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepartureLocationId(value);
      break;
    case 4:
      var value = /** @type {!proto.elude.proto.LocationTypeEnum} */ (reader.readEnum());
      msg.setDepartureLocationType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArrivalLocationId(value);
      break;
    case 6:
      var value = /** @type {!proto.elude.proto.LocationTypeEnum} */ (reader.readEnum());
      msg.setArrivalLocationType(value);
      break;
    case 7:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDepartureDate(value);
      break;
    case 8:
      var value = new proto_commons_timestamp_pb.Timestamp;
      reader.readMessage(value,proto_commons_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReturnDate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTravellers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.SavedSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.SavedSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.SavedSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.SavedSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDepartureLocationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDepartureLocationType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getArrivalLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getArrivalLocationType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDepartureDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReturnDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_commons_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTravellers();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.SavedSearch.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.elude.proto.SavedSearch.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 departure_location_id = 3;
 * @return {number}
 */
proto.elude.proto.SavedSearch.prototype.getDepartureLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setDepartureLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional LocationTypeEnum departure_location_type = 4;
 * @return {!proto.elude.proto.LocationTypeEnum}
 */
proto.elude.proto.SavedSearch.prototype.getDepartureLocationType = function() {
  return /** @type {!proto.elude.proto.LocationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.elude.proto.LocationTypeEnum} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setDepartureLocationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 arrival_location_id = 5;
 * @return {number}
 */
proto.elude.proto.SavedSearch.prototype.getArrivalLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setArrivalLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional LocationTypeEnum arrival_location_type = 6;
 * @return {!proto.elude.proto.LocationTypeEnum}
 */
proto.elude.proto.SavedSearch.prototype.getArrivalLocationType = function() {
  return /** @type {!proto.elude.proto.LocationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.elude.proto.LocationTypeEnum} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setArrivalLocationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Timestamp departure_date = 7;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.SavedSearch.prototype.getDepartureDate = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.SavedSearch} returns this
*/
proto.elude.proto.SavedSearch.prototype.setDepartureDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.clearDepartureDate = function() {
  return this.setDepartureDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SavedSearch.prototype.hasDepartureDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Timestamp return_date = 8;
 * @return {?proto.elude.proto.Timestamp}
 */
proto.elude.proto.SavedSearch.prototype.getReturnDate = function() {
  return /** @type{?proto.elude.proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto_commons_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.elude.proto.Timestamp|undefined} value
 * @return {!proto.elude.proto.SavedSearch} returns this
*/
proto.elude.proto.SavedSearch.prototype.setReturnDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.clearReturnDate = function() {
  return this.setReturnDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.SavedSearch.prototype.hasReturnDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 travellers = 9;
 * @return {number}
 */
proto.elude.proto.SavedSearch.prototype.getTravellers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.SavedSearch} returns this
 */
proto.elude.proto.SavedSearch.prototype.setTravellers = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


goog.object.extend(exports, proto.elude.proto);
