export const utils = {
  body: document.getElementsByTagName('body')[0],
  capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),
  capitalizeSentence: sentence =>
    sentence
      .split(' ')
      .map(word => utils.capitalize(word))
      .join(' '),
  generate: {
    cantorPair: (num1, num2) => ((num1 + num2) * (num1 + num2 + 1)) / 2 + num2,
    string: (length = 8) => Math.random().toString(36).substr(2, length),
  },
  map: {
    id: (obj, index) => {
      obj.id = obj.id ?? index
      return obj
    },
  },
  array: {
    getRandomElement: array => array[Math.floor(Math.random() * array.length)],
    defer: (array = [], delay = 100) =>
      array.reduce((a, c, i) => {
        setTimeout(() => a.push(c), delay * i)
        return a
      }, []),
    unique: array => Array.from(new Set(array)),
  },
  throttle: (func, wait = 100) => {
    let waiting, callTime, _this, _args
    function invoke() {
      const now = Date.now()
      if (now - callTime < wait) return
      func.apply(_this, _args)
      callTime = now
    }
    return function (...args) {
      if (waiting) return
      _this = this
      _args = args
      invoke()
      waiting = true
      setTimeout(() => {
        invoke()
        waiting = false
      }, wait)
    }
  },
  debounce: (func, timeout = 300) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => func.apply(this, args), timeout)
    }
  },
  units: {
    remToPx: rem =>
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize),
  },
  css: {
    getProperty: (element, property) =>
      window.getComputedStyle(element).getPropertyValue(property),
    setProperty: (element, property, value) =>
      element.style.setProperty(property, value),
    getVar: name =>
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--${name}`)
        .trim(),
    setVar: (name, value) =>
      document.documentElement.style.setProperty(`--${name}`, value),
    calcUnits: defaultUnit => x =>
      ['px', 'em', 'rem'].some(unit => x.includes(unit))
        ? x
        : `${x}${defaultUnit}`,
    parseStyles: input => {
      let out = {}
      if (Array.isArray(input))
        input.forEach(className => (out[className] = true))
      else if (typeof input === 'object') out = input
      else if (typeof input === 'string') {
        if (input.includes(':')) {
          input.split(';').forEach(kv => {
            if (!kv) return
            const pair = kv.split(':')
            out[pair[0].trim()] = pair[1].trim()
          })
        } else input.split(' ').forEach(className => (out[className] = true))
      }
      return out
    },
  },
  pipe:
    (...fns) =>
    input =>
      fns.reduce((v, f) => f(v), input),
  schema: (input, parseType = false) => {
    const inputType = typeof input
    if (input === undefined || input === null) return input
    if (inputType !== 'object') return parseType ? inputType : input
    if (Array.isArray(input)) {
      input = [...input]
      if (input.length) input.length = 1
      return [utils.schema(input[0], parseType)]
    }
    const o = {}
    Object.entries(input).forEach(
      ([k, v]) => (o[k] = utils.schema(v, parseType)),
    )
    return o
  },
}
