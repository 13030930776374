import Vue from 'vue'
import VueRouter from 'vue-router'
import { APP } from '../constants/APP'
import { store } from '../store/store'
import { routes } from './routes'
import { beforeEachSearch } from './routes.search'

const _routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return _routerPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  })
}
if (process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.meta.disableScrollBehavior) return
    return new Promise(resolve => {
      setTimeout(() => resolve({ x: 0, y: 0, left: 0, top: 0 }), 75)
    })
  },
})

const homePage = { visitor: '/', user: '/home' }
let isInitialVisit = true

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV === 'test') return next()
  document.title = to.matched.reduce((a, c) => c?.meta?.title || a, APP.TITLE)
  const isUserOnly = to.matched.some(route => route.meta.userOnly)
  const isVisitorOnly = to.matched.some(route => route.meta.visitorOnly)
  const isLoggedIn = store.state.AuthModule.isLoggedIn
  const is404 = !to.matched.length
  const wasInitialVisit = isInitialVisit
  isInitialVisit = false

  // TODO from nav guard if already at invalid site
  if (!isLoggedIn && isUserOnly) {
    return next(wasInitialVisit ? homePage.visitor : '/login')
  }
  if (isLoggedIn && isVisitorOnly) {
    return next(wasInitialVisit ? homePage.user : false)
  }
  if (is404) {
    store.dispatch('ToastModule/error', "Uups, that route doesn't exist.")
    if (wasInitialVisit)
      return next(isLoggedIn ? homePage.user : homePage.visitor)
    else return next(false)
  }
  // force search page on initial '/' visit
  if (wasInitialVisit) {
    if (to.path === '/') return next('/search')
  }

  const isSearchRoute = to.matched.some(route => route.meta?.searchRoute)
  if (isSearchRoute) beforeEachSearch(to, from, next)
  else next()
})
