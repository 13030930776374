<template>
  <grid class="app-wrap">
    <picture
      v-if="$route.fullPath === '/' || $route.fullPath.includes('/onboarding')"
    >
      <source type="image/webp" srcset="@/assets/bg-paper-tex.webp 400w" />
      <img src="@/assets/bg-paper-tex.png" class="bg-image" alt="Background" />
    </picture>
    <picture
      v-if="$route.fullPath === '/' || $route.fullPath.includes('/onboarding')"
    >
      <source type="image/webp" srcset="@/assets/bg-paper-tex.webp 400w" />
      <img
        fetchpriority="high"
        src="@/assets/bg-paper-tex.png"
        class="bg-image"
        alt="Background"
      />
    </picture>
    <Toast />
    <Transition name="float-s" appear>
      <Navigation v-show="showNav" class="app-nav" />
    </Transition>
    <main
      class="app-main"
      :data-show-nav="showNav"
      :data-fullscreen="fullScreen"
    >
      <Transition name="route-transition" mode="out-in" appear>
        <router-view />
        <!-- <router-view
          :key="
            $route.fullPath.includes('/onboarding') ? true : $route.fullPath
          "
        /> -->
      </Transition>
    </main>
  </grid>
</template>

<script>
import Navigation from '@/components/app/Navigation.vue'
import Toast from '@/components/app/Toast.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Navigation,
    Toast,
  },
  created() {
    this.onOpen()
    if (this.isLoggedIn) this.onLogin()
    window.addEventListener('beforeunload', () => this.onClose())
  },
  computed: {
    ...mapState('AuthModule', ['isLoggedIn']),
    showNav: vm => vm.$route.meta?.showNav,
    fullScreen: vm => vm.$route.meta?.fullScreen,
  },
  methods: mapActions(['onOpen', 'onLogin', 'onClose']),
}
</script>

<style scoped lang="scss">
.app-wrap {
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'main' 'nav';
  // @include s() {
  //   grid-template-rows: auto 1fr;
  //   grid-template-areas: 'nav' 'main';
  // }
}
.app-nav {
  grid-area: nav;
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 0;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1.5px 4px 0 rgba(0, 0, 0, 0.1),
    0 -1px 3px 0 rgba(0, 0, 0, 0.06);
  // @include s() {
  //   bottom: initial;
  //   top: 0;
  //   border-top: none;
  //   border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  //   box-shadow: 0 1.5px 4px 0 rgba(0, 0, 0, 0.1),
  //     0 1px 3px 0 rgba(0, 0, 0, 0.06);
  // }
}
.app-main {
  --padding-bottom: 32px;
  grid-area: main;
  &:not([data-fullscreen]) {
    padding-top: 20px;
    padding-bottom: var(--padding-bottom);
  }
  &[data-show-nav] {
    padding-bottom: calc(50px + var(--padding-bottom) + 5px);
  }
}

.bg-image {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: none;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.route-transition {
  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter-active {
    transition: opacity 0.15s ease-in-out;
  }
  &-leave-active {
    transition: opacity 0.075s ease-in-out;
  }
}
</style>
