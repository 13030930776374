import { GetWeatherByCoordinatesRequest } from '../../../util/gen/proto/location/location_request_pb'
import { Grpc } from './base/Grpc'
import { getCoordinates } from './CoordinatesService'

export const WeatherService = {
  getWeatherByCoordinates: () =>
    getCoordinates()
      .then(coordinates =>
        Grpc.snt.getWeatherByCoordinates(
          new GetWeatherByCoordinatesRequest().setCoordinates(coordinates),
        ),
      )
      .then(res => res.toObject()),
}
