// source: proto/models/country.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var proto_models_continent_pb = require('../../proto/models/continent_pb.js');
goog.object.extend(proto, proto_models_continent_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.elude.proto.Country', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.elude.proto.Country = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.elude.proto.Country, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.elude.proto.Country.displayName = 'proto.elude.proto.Country';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.elude.proto.Country.prototype.toObject = function(opt_includeInstance) {
  return proto.elude.proto.Country.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.elude.proto.Country} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Country.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isoCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isoCode3: jspb.Message.getFieldWithDefault(msg, 4, ""),
    iocCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dialCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    continentId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    continent: (f = msg.getContinent()) && proto_models_continent_pb.Continent.toObject(includeInstance, f),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.elude.proto.Country}
 */
proto.elude.proto.Country.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.elude.proto.Country;
  return proto.elude.proto.Country.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.elude.proto.Country} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.elude.proto.Country}
 */
proto.elude.proto.Country.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCode3(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIocCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDialCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContinentId(value);
      break;
    case 8:
      var value = new proto_models_continent_pb.Continent;
      reader.readMessage(value,proto_models_continent_pb.Continent.deserializeBinaryFromReader);
      msg.setContinent(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.elude.proto.Country.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.elude.proto.Country.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.elude.proto.Country} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.elude.proto.Country.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsoCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsoCode3();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIocCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDialCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContinentId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getContinent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_models_continent_pb.Continent.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.elude.proto.Country.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.elude.proto.Country.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iso_code = 3;
 * @return {string}
 */
proto.elude.proto.Country.prototype.getIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string iso_code_3 = 4;
 * @return {string}
 */
proto.elude.proto.Country.prototype.getIsoCode3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setIsoCode3 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ioc_code = 5;
 * @return {string}
 */
proto.elude.proto.Country.prototype.getIocCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setIocCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dial_code = 6;
 * @return {string}
 */
proto.elude.proto.Country.prototype.getDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 continent_id = 7;
 * @return {number}
 */
proto.elude.proto.Country.prototype.getContinentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setContinentId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Continent continent = 8;
 * @return {?proto.elude.proto.Continent}
 */
proto.elude.proto.Country.prototype.getContinent = function() {
  return /** @type{?proto.elude.proto.Continent} */ (
    jspb.Message.getWrapperField(this, proto_models_continent_pb.Continent, 8));
};


/**
 * @param {?proto.elude.proto.Continent|undefined} value
 * @return {!proto.elude.proto.Country} returns this
*/
proto.elude.proto.Country.prototype.setContinent = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.clearContinent = function() {
  return this.setContinent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.elude.proto.Country.prototype.hasContinent = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_active = 9;
 * @return {boolean}
 */
proto.elude.proto.Country.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.elude.proto.Country} returns this
 */
proto.elude.proto.Country.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


goog.object.extend(exports, proto.elude.proto);
