import './other/polyfills'

import Vue from 'vue'
import App from './App.vue'
import { vueConfig } from './main.config'
import { router } from './router/router'
import { store } from './store/store'
import './styles/index.scss'

Vue.config.productionTip = false
const { i18n } = vueConfig

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
