import { SEARCH } from '../../constants/SEARCH'
import { SearchService } from '../../services/SearchService'

const reservationStages = {
  INITIAL: { message: 'Gathering Payment Information' },
  COMPARING_PRICES: { message: 'Gathering Payment Information' },
  PREPARING_RESERVATION_DATA: { message: 'Gathering Trip Information' },
  RESERVING_FUNDS: { message: 'Confirming Payment Information' },
  CREATE_AIR_PNR: { message: 'Confirming Trip Information' },
  TICKETING_FLIGHT: { message: 'Confirming Trip Information' },
  CREATE_HOTEL_PNR: { message: 'Confirming Trip Information' },
  COLLECT_FUNDS: { message: 'Confirming Payment Information' },
  OK: { success: true, final: true },
  CANCEL_BOOKING: { final: true },
}

export const SearchReservation = () => ({
  actions: {
    createReservation(
      { rootState, rootGetters },
      { onStage, onSuccess, onError, onEnd },
    ) {
      const { trip, hotel, flight, room, discount } =
        rootGetters['SearchState/getActive']
      const searchType = rootGetters['SearchState/searchType']
      const { paymentId, passengers } = rootState.SearchState
      const meta = SEARCH.meta[searchType]

      const status = { success: false, ended: false }
      const triggerResponse = bookingId => {
        if (status.ended) return
        status.ended = true
        status.success ? onSuccess(bookingId) : onError()
        onEnd()
      }

      const payload = {
        tripId: trip.id,
        paymentMethodId: paymentId,
        travelerIds: passengers,
      }
      if (meta.hasFlights) {
        payload.flightId = flight?._id
        payload.flightGds = flight?.gds.name
      }
      if (meta.hasHotels) {
        payload.hotelId = hotel?._id
        payload.roomId = room?._id
        payload.hotelGds = hotel?.gds.name
      }
      if (discount) payload.promoCodeId = discount.id

      const stream = SearchService.createReservation(payload)

      onStage(reservationStages.INITIAL.message)

      stream.on('data', res => {
        const data = res.toObject()
        const { success, message, final } =
          reservationStages[data.status.message]

        if (message) onStage(message)
        if (final) {
          status.success = success
          triggerResponse(data)
        }
      })

      stream.on('error', triggerResponse)
      stream.on('end', triggerResponse)
    },
  },
})
