import { CountryService } from '../../services/CountryService'

export const CountryModule = () => ({
  state: {
    countries: [],
  },
  getters: {
    phoneCountries: s =>
      s.countries
        .filter(country => !!country.dialCode)
        .map(country => ({
          id: country.id,
          dialCode: country.dialCode,
          callCode: `${country.isoCode3 || country.isoCode} ${
            country.dialCode
          }`,
          name: country.name,
        })),
  },
  actions: {
    getCountries: ({ commit }) =>
      CountryService.getCountries().then(res => {
        commit('setCountries', res.countriesList)
        return res
      }),
  },
})
